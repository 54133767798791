import { OperatorType, TableDesc } from "./campaign";
import {
  DESTINATION_TYPES,
  SUB_TYPES,
  SYNC_PREFERENCE,
} from "./unifiedMapping";

export interface Person {
  company_name: string | null;
  email: string;
  first_name: string | null;
  id: string;
  last_name: string | null;
  person_created_time: string;
  phone_number: string | null;
  title: string | null;
}
export interface PersonComplete extends Person {
  name: string;
  unsubscribed: number;
  _created_time: string;
  _modified_time: string;
  _deleted_time: string | null;
}

export enum COLUMN_VALUE_TYPES {
  STRING = "string",
  NUMBER = "number",
  DATE = "date",
  DATETIME = "datetime",
  BOOL = "bool",
}

export interface PersonDetailsField extends TableDesc {
  column_tag?: string;
  value?: any;
}

export interface PersonEvent {
  event_name: string;
  event_date: string;
  event_properties: string;
  user_id: string;
  org_id: string;
}

export enum MARKETING_ACTIVITY_TYPE {
  CAMPAIGN = "campaign",
  FORM = "form",
  WEBSITE_ACTIVITY = "website_visit",
}

export interface PersonMarketingActivitiesDetails {
  timestamp: string;
  event: string;
  context: string;
  activity_type: MARKETING_ACTIVITY_TYPE;
  id: string;
  name: string;
  is_deleted: boolean;
  additional_data:
    | CampaignAdditionalData
    | FormAdditionalData
    | WebsiteActivityAdditionalData;
}

export interface CampaignAdditionalData {
  correlation_id: string;
  template: Template;
}

export interface FormAdditionalData {
  submission_id: string;
}

export interface WebsiteActivityAdditionalData {
  page_domain_path: string;
  page_url_scheme: PROTOCOL;
  page_url_fragment: string;
  referrer_domain_path: string;
  referrer_url_scheme: PROTOCOL;
  referrer_url_fragment: string;
  start_time: string;
  end_time: string;
  utm_campaign: string;
  utm_source: string;
  utm_medium: string;
  utm_content: string;
  utm_term: string;
  client_ip: string;
  user_agent_os: string;
  custom_parameters: { [key: string]: any };
}

export interface Template {
  id: string;
  name: string;
  is_deleted: boolean;
}

export interface PersonType {
  [key: string]: PersonDetailsField;
}

export interface PersonColumns {
  [columnName: string]: PersonDetailsField;
}

export type PersonLookup = {
  id: string;
  email: string;
  product_user_id?: string;
  org_id?: string;
};

export enum PERSON_ORG_MAPPING {
  ONE_TO_NONE = "one_to_none",
  ONE_TO_ONE = "one_to_one",
  MANY_TO_MANY = "many_to_many",
}

export type ProductPersonDetails = {
  person_id: string;
  org_id: string;
  product_user_id: string;
  email: string;
  user_properties: { [column: string]: string } | null;
};

export type PersonUpdateFields = {
  email: string;
  default_fields: { [key: string]: string };
  custom_fields: { [key: string]: string };
};

export interface UnsubUrl {
  url: string | null;
  updated_at: string;
  created_at: string;
}

export interface MarketingActivity {
  id: string;
  display: string;
  arguments: MarketingActivityArgument[] | null;
}

export interface MarketingActivityArgument {
  id: string;
  display: string;
  data_type: OperatorType;
  display_level: number;
  arguments: MarketingActivityArgument[] | null;
}

export enum MAPPING_FIELD {
  PERSON_MAPPING = "person_mapping",
  PRODUCT_USER_MAPPING = "product_user_mapping",
  ACCOUNT_MAPPING = "account_mapping",
}
export interface PersonDestination {
  name: string;
  type: DESTINATION_TYPES;
  display: string;
  custom: boolean;
  inferred: boolean;
  required: boolean;
  length: number | null;
  hidden: boolean;
  sub_type?: SUB_TYPES | null;
  primary_key?: boolean;
  read_only?: boolean;
  updatable?: boolean;
  value?: any;
  sync_preference?: SYNC_PREFERENCE;
}

export interface MappingDestination {
  mapping_field: MAPPING_FIELD;
  columns: PersonDestination[];
}

export interface PersonDestinationFields {
  [key: string]: PersonDestination;
}

export interface ProductPersonType {
  key: string;
  details: PersonDestination;
  onDataClick?: (organisationId: string) => void;
  isHyperLink?: boolean;
  redirectPage?: () => void;
}

export const MAP_TO_DESTINATION_TYPE: { [key: string]: DESTINATION_TYPES } = {
  bool: DESTINATION_TYPES.BOOLEAN,
  string: DESTINATION_TYPES.STRING,
  number: DESTINATION_TYPES.INTEGER,
  datetime: DESTINATION_TYPES.DATETIME,
  json: DESTINATION_TYPES.JSON,
};

export enum SESSION_EVENTS {
  PAGE_VISITED = "pageVisited",
}

export enum PROTOCOL {
  HTTP = "http",
  HTTPS = "https",
}

export type PageVisitType = {
  id: string;
  event_name: SESSION_EVENTS;
  event_time: string;
  page_domain_path: string;
  page_url_fragment: string;
  page_url_scheme: PROTOCOL;
};

export type SessionData = {
  session_id: string;
  page_visits: PageVisitType[];
};
