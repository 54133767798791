import { useBoolean, useDisclosure, VStack } from "@chakra-ui/react";
import { isEmpty } from "lodash";
import { useState, useEffect } from "react";
import {
  MappingElement,
  ConnectionListItem,
  SOURCES,
} from "../../../../../../../../common/types/unifiedMapping";
import SpinnerContainer from "../../../../../../../../components/SpinnerContainer";
import { useAppDispatch } from "../../../../../../../../store";
import { setDefaultMappingCrm } from "../../../../../connectionSlice";
import AutomapModal from "../../../../../../../../components/unified-mapping/AutomapModal";
import { AlertMessage } from "../../../../../../../../components/unified-mapping/AlertMessage";
import MappingTable from "./components/MappingTable/MappingTable";
import { SearchAndActionsBar } from "../../../../../../../../components/unified-mapping/SearchAndActionsBar";

export function PersonTab({
  automapping,
  updating,
  loading,
  openAddModal,
  data,
  removeRow,
  onChange,
  onSave,
  onCancel,
  crmSources,
}: {
  automapping: boolean;
  updating: boolean;
  loading: boolean;
  openAddModal: () => void;
  data: MappingElement[];
  removeRow: (index: string) => void;
  onChange: (data: MappingElement[]) => void;
  onSave: () => void;
  onCancel: () => void;
  crmSources: ConnectionListItem[];
}) {
  const dispatch = useAppDispatch();

  const [searchQuery, setSearchQuery] = useState("");
  const [isReadonly, setIsReadonly] = useBoolean(true);

  const {
    isOpen: isAlertOpen,
    onOpen: onAlertOpen,
    onClose: onAlertClose,
  } = useDisclosure();

  const {
    isOpen: isOpenAutomapModal,
    onOpen: onOpenAutomapModal,
    onClose: onCloseAutomapModal,
  } = useDisclosure();

  function setDefaultMapping(sourceType: SOURCES) {
    const connectionId =
      crmSources?.find((x) => x.source === sourceType)?.connection_id ?? null;
    if (connectionId) {
      const data = {
        connection_type: sourceType,
        connection_id: connectionId,
        auto_map: true,
      };
      dispatch(setDefaultMappingCrm(data));
      onCloseAutomapModal();
    }
  }

  function save() {
    onSave();
    setIsReadonly.on();
  }

  function cancelEditing() {
    setIsReadonly.on();
    onCancel();
  }

  useEffect(() => {
    if (crmSources && !crmSources.length) {
      onAlertOpen();
    } else {
      onAlertClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [crmSources]);

  return (
    <>
      {isAlertOpen && (
        <AlertMessage
          isOpen={isAlertOpen}
          onClose={onAlertClose}
          alertText="Note: Add sources in the overview screen to start syncing data into CRM Objects"
        />
      )}
      <VStack spacing="2">
        <SearchAndActionsBar
          toggleReadonly={setIsReadonly.toggle}
          readonlyMode={isReadonly}
          automapping={automapping}
          saving={updating}
          openAutomapModal={
            isEmpty(crmSources) ? undefined : onOpenAutomapModal
          }
          addAction={openAddModal}
          setQuery={setSearchQuery}
          save={save}
          cancel={cancelEditing}
        />

        <SpinnerContainer loading={loading}>
          <MappingTable
            readonly={isReadonly}
            data={data}
            query={searchQuery}
            removeRow={removeRow}
            crmSources={crmSources}
            onChange={onChange}
          />
        </SpinnerContainer>

        <AutomapModal
          crmSources={crmSources}
          next={setDefaultMapping}
          isOpen={isOpenAutomapModal}
          onCancel={onCloseAutomapModal}
        />
      </VStack>
    </>
  );
}
