import { Box, Center } from "@chakra-ui/react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import {
  CONNECTION_ID,
  SF_REAUTHENTICATION,
} from "../../../../common/constants/connection";
import {
  isInit,
  isSuccess,
  isLoading,
} from "../../../../common/helper/commonHelper";
import PrimaryHeader from "../../../../components/PrimaryHeader";
import SpinnerContainer from "../../../../components/SpinnerContainer";
import urls from "../../../../urls";
import {
  selectConnection,
  connectSalesforce,
  resetSalesforceData,
} from "../connectionSlice";

export default function SalesforceSuccess() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const search = useLocation().search;
  const code = new URLSearchParams(search).get("code");
  const { salesforce } = useSelector(selectConnection);

  useEffect(() => {
    const connectionId = localStorage.getItem(CONNECTION_ID);
    if (code && connectionId && isInit(salesforce.connecting)) {
      dispatch(
        connectSalesforce({
          code,
          connectionId,
        })
      );
    } else if (isSuccess(salesforce.connecting)) {
      const isReauthenticating = localStorage.getItem(SF_REAUTHENTICATION);
      const salesforceDetailsUrl = `${urls.salesforceRoute}/${salesforce.connection?.connection_id}`;
      if (isReauthenticating === "true") {
        navigate(salesforceDetailsUrl);
      } else {
        navigate(`${salesforceDetailsUrl}?permission=true`);
      }
    }
  }, [dispatch, navigate, code, salesforce.connection, salesforce.connecting]);

  useEffect(() => {
    return () => {
      dispatch(resetSalesforceData());
    };
  }, [dispatch]);

  return (
    <Box>
      <PrimaryHeader heading="Salesforce" />
      <SpinnerContainer
        height="90vh"
        loading={isLoading(salesforce.connecting)}
      >
        <Center
          h="90vh"
          hidden={
            isLoading(salesforce.connecting) || isInit(salesforce.connecting)
          }
        >
          Successfully Connected! Redirecting...
        </Center>
      </SpinnerContainer>
    </Box>
  );
}
