import { VStack, Divider, Text, Box, Grid, Flex } from "@chakra-ui/react";
import { cloneDeep } from "lodash";
import { useEffect, useMemo } from "react";
import {
  MappingElement,
  ConnectionListItem,
  SUB_TYPES,
  SOURCES,
} from "../../../../../../../../../../common/types/unifiedMapping";
import {
  getMappedColumns,
  selectConnection,
} from "../../../../../../../connectionSlice";
import { ColumnRow } from "./components/ColumnRow";
import { MappingTableColumnTitle } from "../../../../../../../../../../components/unified-mapping/MappingTableColumnTitle";
import { EmailColumnRows } from "./components/EmailColumnRows";
import { useSelector } from "react-redux";
import ITitle from "../../../../../../../../../../components/ITitle";
import {
  findSource,
  getObjectSettings,
} from "../../../../../../../../../../common/helper/unifiedMappingHelper";
import {
  ObjectSettings,
  SALESFORCE_OBJECT_TYPE,
} from "../../../../../../../../../../common/types/connection";
import { useAppDispatch } from "../../../../../../../../../../store";
import { IVirtualList } from "../../../../../../../../../../components/IVirtual";
import { hideResizeObserverError } from "../../../../../../../../../../common/helper/commonHelper";
import { EMAIL_FIELD_KEY } from "../../../../../../../../../../common/constants/form";

const ROW_HEIGHT = 60;

function TableTitle({ title }: { title: string }) {
  return (
    <ITitle
      fontWeight="md"
      fontSize="sm"
      color="text.100"
      title={title}
      px="2"
    />
  );
}

function ColumnHeaders({
  crmSources,
  data,
  objectSettings,
  bodyHasScroll,
}: {
  crmSources: ConnectionListItem[];
  data: MappingElement[];
  objectSettings: ObjectSettings | null;
  bodyHasScroll: boolean;
}) {
  const hasSalesforce = useMemo(() => {
    return crmSources.some((x) => x.source === SOURCES.SALESFORCE);
  }, [crmSources]);

  const hasNonSalesforceSource = useMemo(() => {
    return crmSources.some((x) => x.source !== SOURCES.SALESFORCE);
  }, [crmSources]);

  return (
    <Flex
      width="100%"
      alignItems="center"
      bg="oneOffs.tableHeader"
      roundedTop="md"
      sx={{ top: "0", margin: "0", zIndex: "1", position: "sticky" }}
    >
      <Grid
        width="100%"
        templateColumns="repeat(5, 1fr)"
        gap={4}
        p="3"
        mr={bodyHasScroll ? "15px" : undefined}
      >
        <TableTitle title="Person Property" />
        {crmSources.length !== 0 && (
          <>
            <TableTitle title="Source" />

            {hasNonSalesforceSource ||
            (objectSettings && objectSettings.contact) ? (
              <TableTitle title="Contact Property" />
            ) : (
              <Text></Text>
            )}

            {hasSalesforce && objectSettings && objectSettings.lead ? (
              <TableTitle title="Lead Property" />
            ) : (
              <Text></Text>
            )}

            <TableTitle title="Sync Preference" />
          </>
        )}
      </Grid>
      <Box w="32px"></Box>
    </Flex>
  );
}

function sourceColumnRows(
  rows: MappingElement[],
  query: string,
  removeRow: (index: string) => void,
  crmSources: ConnectionListItem[],
  onChange: (data: MappingElement[]) => void,
  readonly: boolean,
  objectSettings: ObjectSettings | null
) {
  function onChangeRow(updatedData: MappingElement) {
    let clonedRows = cloneDeep(rows);
    clonedRows = clonedRows.map((row) => {
      if (
        row.destination.name === SUB_TYPES.EMAIL &&
        updatedData.destination.name !== SUB_TYPES.EMAIL
      ) {
        // when a non email row is updated
        row = { ...updatedEmailRows(row, updatedData) };
      }

      if (row.tempId === updatedData.tempId) {
        row = { ...updatedData };
      }
      return row;
    });
    onChange(clonedRows);
  }

  const mappedContactFields: { [con: string]: string[] } = {};
  const mappedLeadFields: { [con: string]: string[] } = {};

  rows.forEach((x) => {
    if (x.source?.[0].field) {
      const contactSourceName =
        x.source?.[0].field[SALESFORCE_OBJECT_TYPE.CONTACT]?.name;
      if (contactSourceName) {
        if (mappedContactFields[x.source?.[0].connection_id]) {
          mappedContactFields[x.source?.[0].connection_id].push(
            contactSourceName
          );
        } else {
          mappedContactFields[x.source?.[0].connection_id] = [
            contactSourceName,
          ];
        }
      }

      const leadSourceName =
        x.source?.[0].field[SALESFORCE_OBJECT_TYPE.LEAD]?.name;
      if (leadSourceName) {
        if (mappedLeadFields[x.source?.[0].connection_id]) {
          mappedLeadFields[x.source?.[0].connection_id].push(leadSourceName);
        } else {
          mappedLeadFields[x.source?.[0].connection_id] = [leadSourceName];
        }
      }
    }
  });

  function renderColumnField(_: number, row: MappingElement) {
    const props = {
      remove: () => removeRow(row.destination.name),
    };
    if (crmSources.length) {
      if (row.destination.name === SUB_TYPES.EMAIL) {
        return (
          <EmailColumnRows
            rows={{ contact: mappedContactFields, lead: mappedLeadFields }}
            readonly={readonly}
            key={row.tempId}
            data={row}
            onChange={(row) => onChangeRow(row)}
            sources={crmSources}
            objectSettings={objectSettings}
          />
        );
      } else {
        return (
          <ColumnRow
            rows={{ contact: mappedContactFields, lead: mappedLeadFields }}
            readonly={readonly}
            key={row.tempId}
            data={row}
            onChange={(row) => onChangeRow(row)}
            sources={crmSources}
            selectedSource={row.source?.[0] || null}
            syncPreference={row.source?.[0]?.sync_preference || null}
            contact={row.source?.[0].field.contact || null}
            lead={row.source?.[0].field.lead || null}
            objectSettings={objectSettings}
            {...props}
          />
        );
      }
    } else {
      return (
        <MappingTableColumnTitle
          type={
            row.destination.sub_type === SUB_TYPES.EMAIL
              ? SUB_TYPES.EMAIL
              : row.destination.type
          }
          name={row.destination.display}
          removable={row.destination.custom}
        />
      );
    }
  }

  return (
    <IVirtualList
      list={rows
        .filter((x) =>
          x.destination.name.toLowerCase().includes(query.toLowerCase())
        )
        // Bring email row at the top
        .sort((x, y) => (x.destination.name === SUB_TYPES.EMAIL ? -1 : 0))}
      fetchingList={false}
      renderListItem={renderColumnField}
      containerProps={{
        height: "100%",
        width: "100%",
        overflowY: "auto",
        overflowX: "hidden",
      }}
    />
  );

  function updatedEmailRows(row: MappingElement, updatedData: MappingElement) {
    // add newly added source in email field mapping

    const clonedRow = cloneDeep(row);
    const emailSourceIds = clonedRow.source?.map((x) => x.connection_id);

    let newSources = updatedData?.source ? [...updatedData.source] : [];
    if (emailSourceIds) {
      newSources =
        updatedData.source?.filter(
          (x) => !emailSourceIds?.includes(x.connection_id)
        ) ?? [];
    }

    const existingSources = clonedRow.source ? [...clonedRow.source] : [];

    newSources.forEach((newSource) => {
      existingSources.push({
        ...newSource,
        ...{
          sync_preference: null,
          field: { contact: null, lead: null },
        },
      });
    });

    clonedRow.source = [...existingSources];

    return clonedRow;
  }
}

export default function MappingTable({
  readonly,
  data,
  query,
  removeRow,
  crmSources,
  onChange,
}: {
  readonly: boolean;
  data: MappingElement[];
  query: string;
  removeRow: (index: string) => void;
  crmSources: ConnectionListItem[];
  onChange: (data: MappingElement[]) => void;
}) {
  const dispatch = useAppDispatch();
  const {
    unifiedConnection: { salesforceConnections },
  } = useSelector(selectConnection);

  const salesforceSource = useMemo(
    () =>
      findSource(
        data
          .find((x) => x.destination.name === EMAIL_FIELD_KEY)
          ?.source?.find((x) => x.type === SOURCES.SALESFORCE) || null,
        crmSources
      ),
    [data, crmSources]
  );

  const objectSettings = useMemo(
    () => getObjectSettings(salesforceConnections, salesforceSource),
    [salesforceConnections, salesforceSource]
  );

  // Hack to unblock dev from getting resize observer loop error.
  // Will have to rewrite the component if we have to resolve the error. This error is silent in prod.
  useEffect(() => {
    window.addEventListener("error", hideResizeObserverError);
    return () => {
      window.removeEventListener("error", hideResizeObserverError);
    };
  }, []);

  useEffect(() => {
    dispatch(getMappedColumns());
  }, [dispatch]);

  const bodyHasScroll = useMemo(() => {
    return ROW_HEIGHT * data.length > window.innerHeight;
  }, [data.length]);

  return (
    <Box bg="white" w="100%" rounded="md">
      <ColumnHeaders
        crmSources={crmSources}
        data={data}
        objectSettings={objectSettings}
        bodyHasScroll={bodyHasScroll}
      />
      <VStack
        width="100%"
        divider={<Divider />}
        height="calc(100vh - 120px)"
        overflow="auto"
        spacing={0}
      >
        {sourceColumnRows(
          data,
          query,
          removeRow,
          crmSources,
          onChange,
          readonly,
          objectSettings
        )}
      </VStack>
    </Box>
  );
}
