import { Box, Text, HStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { TIMEZONE_FIELDS } from "../../../common/constants/settings";
import DropdownWithSearch from "../../../components/DropdownWithSearch";
import ITitle from "../../../components/ITitle";
import ContentContainer from "../../../components/v2/ContentContainer";
import ContentContainerWithHeader from "../../../components/v2/ContentContainerWithHeader";
import { useAppDispatch } from "../../../store";
import {
  createTimezonePreferenceSettings,
  getTimezonePreferenceSettings,
  selectSettings,
  updateTimezonePreferenceSettings,
} from "./settingsSlice";
import { EMPTY_CONTEXT } from "../../../common/constants/common";
import { useUpdateWithInitValue } from "../../../common/hooks/commonHooks";
import ViewAndEditButtons, {
  ACTIONS,
} from "../../../components/ViewAndEditButtons";
import HeaderDescText, { TEXT_TYPE } from "./components/HeaderDescText";

const PREFERNCE_OPTIONS = [
  { label: "System inferred field", value: TIMEZONE_FIELDS.SYSTEM_INFERRED },
  { label: "Custom timezone field", value: TIMEZONE_FIELDS.CUSTOM_TIMEZONE },
];

export default function TimeZoneSettings() {
  const dispatch = useAppDispatch();
  const {
    timezonePreference: { data: timezonePreferenceDetails },
  } = useSelector(selectSettings);

  const [mode, setMode] = useState<ACTIONS>(ACTIONS.VIEW);
  const [selectedOption, setSelectedOption] = useUpdateWithInitValue(
    timezonePreferenceDetails?.preferred_timezone_field
  );
  const [isError, setIsError] = useState(false);
  useEffect(() => {
    dispatch(getTimezonePreferenceSettings());
  }, [dispatch]);

  function onSaveClick() {
    if (selectedOption) {
      timezonePreferenceDetails
        ? dispatch(
            updateTimezonePreferenceSettings({
              preferredTimezone: selectedOption,
            })
          )
        : dispatch(
            createTimezonePreferenceSettings({
              preferredTimezone: selectedOption,
            })
          );
      setMode(ACTIONS.VIEW);
    } else {
      setIsError(true);
    }
  }

  function onCancel() {
    setSelectedOption(timezonePreferenceDetails?.preferred_timezone_field);
    setMode(ACTIONS.VIEW);
  }

  function DisplayButtonsBasedOnMode({
    mode = ACTIONS.VIEW,
  }: {
    mode?: ACTIONS;
  }) {
    return (
      <ViewAndEditButtons
        action={mode}
        saveButtonProps={{
          onClick: onSaveClick,
          isDisabled: !!isError,
        }}
        cancelButtonProps={{ onClick: onCancel }}
        editButtonProps={{ onClick: () => setMode(ACTIONS.EDIT) }}
      />
    );
  }

  return (
    <ContentContainerWithHeader mr="20px">
      <HStack justifyContent="flex-end" py={1}>
        <DisplayButtonsBasedOnMode mode={mode} />
      </HStack>
      <ContentContainer flexDir="column" h="80vh">
        <Box w="90%">
          <ITitle
            title="Timezone settings"
            fontWeight="600"
            color="blackAlpha.700"
            pb={8}
          />
          <HeaderDescText
            text="Inflection provides two different options to set the timezone for
              a contact"
            type={TEXT_TYPE.HEADER}
            mb={4}
          />
          <HeaderDescText
            text="1. System inferred timezone"
            type={TEXT_TYPE.HEADER}
            mb={1}
          />
          <HeaderDescText
            text="This allows the system to automatically update timezone
              information for the contact based on the contact's address."
            mb={8}
          />
          <HeaderDescText
            text="2. Custom timezone field"
            type={TEXT_TYPE.HEADER}
            mb={1}
          />

          <HeaderDescText
            text="This enables you to manually update a contact’s timezone field.
              The field can also be synced to a data connection."
            mb={12}
          />

          <HeaderDescText
            text="Timezone preference"
            type={TEXT_TYPE.HEADER}
            fontWeight={600}
            mb={5}
          />

          <HeaderDescText
            text="Select preference between system inferred and custom timezone when
              both values are present for a contact."
            type={TEXT_TYPE.HEADER}
            mb={6}
          />

          <HeaderDescText
            text="Field preference"
            type={TEXT_TYPE.DESCRIPTION}
            mb={2}
            color="blackAlpha.700"
          />

          {mode === ACTIONS.EDIT ? (
            <>
              <DropdownWithSearch
                options={PREFERNCE_OPTIONS}
                isSearchable
                value={PREFERNCE_OPTIONS.find(
                  (option) =>
                    option.value ===
                    (selectedOption ??
                      timezonePreferenceDetails?.preferred_timezone_field)
                )}
                onChange={(option) => {
                  setIsError(false);
                  setSelectedOption(option?.value as TIMEZONE_FIELDS);
                }}
                controlStyle={{
                  width: "430px",
                }}
              />
              {isError && (
                <Text fontSize="12px" color="red.500">
                  Please select a timezone preference
                </Text>
              )}
            </>
          ) : (
            <Text
              fontSize="14px"
              color="blackAlpha.900"
              fontWeight="400"
              mb={2}
            >
              {PREFERNCE_OPTIONS.find(
                (option) => option.value === selectedOption
              )?.label ?? EMPTY_CONTEXT}
            </Text>
          )}
        </Box>
      </ContentContainer>
    </ContentContainerWithHeader>
  );
}
