import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { sortBy } from "lodash";
import { toast } from "react-toastify";
import {
  createApiKeyApi,
  deactivateApiKeyApi,
} from "../../../common/api/auth/auth";
import {
  createConnectionApi,
  listConnectionsApi,
  listSourceTableColumnsApi,
  listSourceSchemaApi,
  listSourceTablesApi,
  setSchemaApi,
  listDestinationTablesApi,
  saveSyncApi,
  listSyncRunsApi,
  toggleSyncApi,
  updateConnectionApi,
  getSyncSummaryApi,
  getSyncDetailsApi,
  getActivityLogs,
  getWhiteListIpsApi,
  listSegmentErrorsApi,
  getSegmentEventReportApi,
  getSegmentErrorReportApi,
  listConnectionsUnifiedApi,
  getSalesforceObjectsApi,
  getUnifiedMappingApi,
  updateCrmUnifiedMappingApi,
  setDefaultCrmMappingApi,
  updateAccountUnifiedMappingApi,
  setDefaultAccountUnifiedMappingApi,
  getSegmentEventsStatusApi,
  updateDescriptionApi,
  toggleSegmentEventApi,
  toggleSegmentEventPropertyApi,
  toggleBypassPropertiesApi,
  getSegmentDetailsApi,
  setSegmentSyncApi,
  getSegmentNonTrackEventsStatusApi,
  getSegmentTrackEventsStatusApi,
  listDestinationColumnsApi,
  addSourceTablesApi,
  validateConnectionApi,
  getMappedColumnsApi,
} from "../../../common/api/integrations/connection";
import {
  connectSalesforceApi,
  createSalesforceConnectionApi,
  getSalesforceConnectionDetailsApi,
  listSalesforceSyncRunsApi,
  getSalesforceLoginUrlApi,
  updateSalesforceSettingsApi,
  recheckSalesforcePermissionApi,
  getSyncHistoryDetailsApi,
  pauseSalesforceSyncApi,
  resumeSalesforceSyncApi,
  deleteSalesforceConnectionApi,
  updateSalesforceObjectsApi,
  getApiUsageCountApi,
  setupCompleteApi,
  updateInclusionListApi,
  updateSelectiveSyncApi,
  updateSalesforceRecordsCreateUpdateSettingApi,
  updateSalesforceRecordsDeleteSettingApi,
  updateInflectionRecordsDeleteSettingApi,
  updateInflectionRecordsCreateUpdateSettingApi,
  startSalesforceSyncApi,
  listSalesforceSyncErrorsTypesApi,
  listSalesforceSyncErrorsApi,
  getSalesforceErrorQueryApi,
  archiveSalesforceSyncErrorApi,
  resyncSalesforceSyncErrorApi,
  removeInclusionListApi,
  getSalesforceConnectionListApi,
} from "../../../common/api/integrations/salesforce";
import {
  deleteAccountContactMappingApi,
  getAccountContactMappingApi,
  updateAccountContactMappingApi,
} from "../../../common/api/setting/setting";
import { CUSTOM_TABLE } from "../../../common/constants/campaign";
import {
  INITIAL_PAGINATION,
  LOADING_STATES,
} from "../../../common/constants/common";
import {
  createAsyncThunkWrapper,
  initializeLoadingData,
} from "../../../common/helper/commonHelper";
import { expireApiStatus } from "../../../common/slices/apiStatusSlice";
import { AccountContactAutoMappingElement } from "../../../common/types/AccountContactMapping";
import { STATUS } from "../../../common/constants/AccountContactMapping";
import { ApiKeyData } from "../../../common/types/auth";
import {
  LoadingWithData,
  DateTimeFilterPagintation,
  PaginationType,
} from "../../../common/types/common";
import {
  ActivityLog,
  Connection,
  ConnectionCreateConfig,
  ConnectionUpdateConfig,
  DateRange,
  DestinationTable,
  ReportRange,
  SalesforceConnection,
  SegmentError,
  SegmentReportRow,
  SourceColumn,
  SyncGetResp,
  SalesforceSyncRun,
  SyncSettings,
  SyncSummary,
  SyncTablesData,
  SyncRun,
  SalesforceSyncHistoryDetails,
  ObjectSettings,
  SetupCompleteData,
  InclusionList,
  ObjectDeleteRule,
  ObjectCreateRule,
  SALESFORCE_RECORD_CREATE,
  SALESFORCE_RECORD_DELETE,
  SalesforceSyncError,
  SalesforceSyncErrorQuery,
  ErrorType,
  SfResyncArchiveDataType,
  SegmentEventStatus,
  SegmentDescription,
  SegmentEventToggle,
  SegmentEventPropertyToggle,
  SegmentAsDestinationConfig,
  SegmentSyncConfig,
  SEGMENT_SYNC_TYPES,
  SegmentEventStatusTrack,
  SegmentEventStatusNonTrack,
  ConnectionReadAndWriteValidateConfig,
  ConnectionReadOnlyValidateConfig,
  ColumnToAssetMapping,
  SfCampaignSyncError,
} from "../../../common/types/connection";
import {
  DefaultCrmMapping,
  ConnectionListItem,
  Mapping,
  MappingElement,
  SalesforceConnectionDetailsDict,
  SelectiveSync,
  AccountMappingElement,
  DefaultAccountMapping,
  SOURCES,
  STATUS as STATUS_CONNECTIONS_LIST,
  ColumnDetailsDict,
} from "../../../common/types/unifiedMapping";
import { RootState } from "../../../store";
import { FORM_VENDORS } from "../../../common/types/form";
import { getSalesforceCampaignSyncErrorsApi } from "../../../common/api/salesforceIntegrations/campaign";

//TODO: If any data with it's loading variable, merge them to one using LoadingWithData type

export const CONNECTION_LIST_ALL_ACTION = "connection/list-all-connections";
const FILTERS_LIST_ACTION = "campaign/get-filter-list";
const PERSON_MAPPING = "person/mapping-destination";

const FORM_VENDORS_LIST = Object.values(FORM_VENDORS);

interface ErrorPayload {
  type: string;
  data: { code: number; description: string; exception: string };
}

interface ConnectionState {
  selectedService: string;
  connectionDetails: Connection | null;
  destinationTables: DestinationTable[] | null;
  fetchingDestinationTables: boolean;
  createCredsWizardStep: number;

  connectionList: Connection[] | null;
  fetchingConnectionList: boolean;
  connectionPageSize: number;
  connectionTotalPageCount: number | null;
  connectionCurrentPage: number;

  hostName: string;

  schemaList: string[] | null;
  selectedSchema: string;
  tableList: string[] | null;
  columnList: { [table_name: string]: SourceColumn[] };
  fetchingSourceTables: boolean;
  fetchingColumns: boolean;

  creatingNewConnection: boolean;
  createdNewConnection: boolean;
  updatingConnection: boolean;
  updatedConnection: boolean;
  validatingReadOnlyConnection: LOADING_STATES;
  validatingReadAndWriteConnection: LOADING_STATES;
  addedSchema: boolean;

  creatingTableConnection: boolean;
  createdTableConnection: boolean;

  savingSync: LOADING_STATES;
  togglingSync: boolean;

  fetchingSyncSummary: boolean;
  syncSummary: SyncSummary | null;

  fetchingSyncRuns: boolean;

  // TODO - change to pagination type
  syncRuns: {
    records: SyncRun[] | null;
    currentPage: number;
    pageSize: number;
    totalPageCount: number | null;
    recordCount: number | null;
  };

  fetchingSyncDetails: boolean;
  syncDetails: SyncGetResp | null;

  // TODO - change to pagination type
  activityLogs: {
    records: ActivityLog[] | null;
    pageSize: number;
    currentPageNumber: number;
    totalPageCount: number | null;
    recordCount: number | null;
  };
  fetchingActivityLog: boolean;

  fetchingWhiteListIps: boolean;
  whiteListIps: string[] | null;

  segment: {
    createSync: {
      type: SEGMENT_SYNC_TYPES | "";
      readApiKey: LoadingWithData<ApiKeyData>;
      writeApiKey: string;
    };
    syncDetails: LoadingWithData<SegmentSyncConfig & { read_api_key: string }>;
    sourceSyncDetails: {
      errors: PaginationType<SegmentError>;
      eventReport: LoadingWithData<SegmentReportRow[]>;
      errorReport: LoadingWithData<SegmentReportRow[]>;
    };
    destinationSyncDetails: {
      errors: PaginationType<SegmentError>;
      eventReport: LoadingWithData<SegmentReportRow[]>;
      errorReport: LoadingWithData<SegmentReportRow[]>;
    };
  };

  salesforce: {
    connectionList: LoadingWithData<SalesforceConnection[]>;
    creatingSalesforceConnection: LOADING_STATES;
    fetchingConnection: LOADING_STATES;
    connection: SalesforceConnection | null;
    loginUrl: string;
    connecting: LOADING_STATES;
    getLoginUrl: LOADING_STATES;
    savingSyncSettings: LOADING_STATES;
    savingObjectSettings: LOADING_STATES;
    fetchingErrorList: LOADING_STATES;
    syncRuns: PaginationType<SalesforceSyncRun>;
    campaignSyncErrors: SfCampaignSyncError[] | null;
    syncErrors: { [key: string]: PaginationType<SalesforceSyncError> };
    recheckingPermissions: LOADING_STATES;
    syncHistoryDetails: LoadingWithData<SalesforceSyncHistoryDetails | null>;
    pausingSync: LOADING_STATES;
    resumingSync: LOADING_STATES;
    startedSync: LOADING_STATES;
    deletingConnection: LOADING_STATES;
    apiUsageCount: LoadingWithData<number | null>;
    savingSetup: LOADING_STATES;
    savingSelectiveSync: LOADING_STATES;
    savingInclusionList: LOADING_STATES;
    savingRecordCreateSettings: LOADING_STATES;
    savingRecordDeleteSettings: LOADING_STATES;
    savingInflectionRecordCreateSettings: LOADING_STATES;
    savingInflectionRecordDeleteSettings: LOADING_STATES;
    sfErrorTypes: LoadingWithData<ErrorType[]>;
    sfAffectedRecords: PaginationType<SalesforceSyncErrorQuery>;
    archivedRecordCount: number;
    archivingSalesforceSyncError: LOADING_STATES;
    isResyncSuccess: boolean;
    resyncingSalesforceSyncError: LOADING_STATES;
  };
  bigQuery: {
    isReadOnlyPermission: boolean;
  };

  unifiedConnection: {
    connectionList: PaginationType<ConnectionListItem>;
    salesforceConnections: SalesforceConnectionDetailsDict;
    mapping: LoadingWithData<Mapping | null>;
    updatingMapping: LOADING_STATES;
    automapping: LOADING_STATES;
    dwColumnList: ColumnDetailsDict;
    mappedColumns: LoadingWithData<ColumnToAssetMapping>;
  };

  productDiscovery: {
    segmentEventStatus: LoadingWithData<SegmentEventStatus | null>;
    segmentEventStatusNonTrack: LoadingWithData<SegmentEventStatusNonTrack | null>;
    segmentEventStatusTrack: LoadingWithData<SegmentEventStatusTrack | null>;
    updatingDescription: LOADING_STATES;
    togglingEvent: LOADING_STATES;
    togglingProperty: LOADING_STATES;
    togglingBypass: LOADING_STATES;
  };

  accountContactAutoMapping: {
    mapping: AccountContactAutoMappingElement | null;
    fetchingMapping: LOADING_STATES;
    deletingMapping: LOADING_STATES;
    updatingMapping: LOADING_STATES;
  };
}

const SYNC_HISTORY_PAGE_SIZE = 10;
const CONNECTION_LIST_PAGE_SIZE = 30;
const ACTIVITY_LOG_PAGE_SIZE = 10;

const SALESFORCE_INIT = {
  connectionList: initializeLoadingData([]),
  creatingSalesforceConnection: LOADING_STATES.INIT,
  fetchingConnection: LOADING_STATES.INIT,
  connection: null,
  loginUrl: "",
  connecting: LOADING_STATES.INIT,
  getLoginUrl: LOADING_STATES.INIT,
  savingSyncSettings: LOADING_STATES.INIT,
  savingObjectSettings: LOADING_STATES.INIT,
  fetchingErrorList: LOADING_STATES.INIT,
  syncRuns: INITIAL_PAGINATION,
  campaignSyncErrors: [],
  syncErrors: {},
  recheckingPermissions: LOADING_STATES.INIT,
  syncHistoryDetails: initializeLoadingData(null),
  pausingSync: LOADING_STATES.INIT,
  resumingSync: LOADING_STATES.INIT,
  startedSync: LOADING_STATES.INIT,
  deletingConnection: LOADING_STATES.INIT,
  apiUsageCount: initializeLoadingData(null),
  savingSetup: LOADING_STATES.INIT,
  savingSelectiveSync: LOADING_STATES.INIT,
  savingInclusionList: LOADING_STATES.INIT,
  savingRecordCreateSettings: LOADING_STATES.INIT,
  savingRecordDeleteSettings: LOADING_STATES.INIT,
  savingInflectionRecordCreateSettings: LOADING_STATES.INIT,
  savingInflectionRecordDeleteSettings: LOADING_STATES.INIT,
  sfErrorTypes: initializeLoadingData([]),
  sfAffectedRecords: INITIAL_PAGINATION,
  archivedRecordCount: 0,
  archivingSalesforceSyncError: LOADING_STATES.INIT,
  isResyncSuccess: false,
  resyncingSalesforceSyncError: LOADING_STATES.INIT,
};

const initialState: ConnectionState = {
  selectedService: "",
  connectionDetails: null,
  destinationTables: null,
  fetchingDestinationTables: false,
  createCredsWizardStep: 1,

  connectionList: null,
  fetchingConnectionList: false,
  connectionPageSize: CONNECTION_LIST_PAGE_SIZE,
  connectionTotalPageCount: null,
  connectionCurrentPage: 1,

  hostName: "",

  schemaList: [],
  selectedSchema: "",
  tableList: null,
  columnList: {},
  fetchingSourceTables: false,
  fetchingColumns: false,

  creatingNewConnection: false,
  createdNewConnection: false,
  updatingConnection: false,
  updatedConnection: false,
  validatingReadOnlyConnection: LOADING_STATES.INIT,
  validatingReadAndWriteConnection: LOADING_STATES.INIT,
  addedSchema: false,

  creatingTableConnection: false,
  createdTableConnection: false,

  savingSync: LOADING_STATES.INIT,
  togglingSync: false,

  fetchingSyncSummary: false,
  syncSummary: null,

  syncRuns: {
    records: null,
    pageSize: SYNC_HISTORY_PAGE_SIZE,
    currentPage: 1,
    totalPageCount: null,
    recordCount: null,
  },
  fetchingSyncRuns: false,

  fetchingSyncDetails: false,
  syncDetails: null,

  activityLogs: {
    records: null,
    pageSize: ACTIVITY_LOG_PAGE_SIZE,
    currentPageNumber: 1,
    totalPageCount: null,
    recordCount: null,
  },
  fetchingActivityLog: false,

  fetchingWhiteListIps: false,
  whiteListIps: null,

  segment: {
    createSync: {
      type: "",
      readApiKey: initializeLoadingData({}),
      writeApiKey: "",
    },
    syncDetails: initializeLoadingData({}),
    sourceSyncDetails: {
      errors: INITIAL_PAGINATION,
      eventReport: initializeLoadingData([]),
      errorReport: initializeLoadingData([]),
    },
    destinationSyncDetails: {
      errors: INITIAL_PAGINATION,
      eventReport: initializeLoadingData([]),
      errorReport: initializeLoadingData([]),
    },
  },

  salesforce: SALESFORCE_INIT,

  unifiedConnection: {
    connectionList: INITIAL_PAGINATION,
    salesforceConnections: {},
    mapping: initializeLoadingData(null),
    updatingMapping: LOADING_STATES.INIT,
    automapping: LOADING_STATES.INIT,
    dwColumnList: {},
    mappedColumns: initializeLoadingData({}),
  },

  productDiscovery: {
    segmentEventStatus: initializeLoadingData(null),
    segmentEventStatusNonTrack: initializeLoadingData(null),
    segmentEventStatusTrack: initializeLoadingData(null),
    updatingDescription: LOADING_STATES.INIT,
    togglingEvent: LOADING_STATES.INIT,
    togglingProperty: LOADING_STATES.INIT,
    togglingBypass: LOADING_STATES.INIT,
  },

  accountContactAutoMapping: {
    mapping: null,
    updatingMapping: LOADING_STATES.INIT,
    deletingMapping: LOADING_STATES.INIT,
    fetchingMapping: LOADING_STATES.INIT,
  },
  bigQuery: {
    isReadOnlyPermission: false,
  },
};

export const createConnection = createAsyncThunk<
  { connectionInfo: { connection: Connection }; schemas?: string[] },
  ConnectionCreateConfig,
  {}
>(
  "connection/create-connection",
  async (data: ConnectionCreateConfig, { dispatch }) => {
    const connectionInfo = await createConnectionApi(data);
    dispatch(
      expireApiStatus([
        { actionName: CONNECTION_LIST_ALL_ACTION, expireAll: true },
      ])
    );

    // TODO - move this api call out of this async thunk
    if (data.typ !== SOURCES.BIGQUERY) {
      const { schemas } = await listSourceSchemaApi(
        connectionInfo.connection.id
      );
      return { connectionInfo, schemas };
    }

    return { connectionInfo };
  }
);

export const updateConnection = createAsyncThunk(
  "connection/update-connection",
  async (data: ConnectionUpdateConfig, { dispatch }) => {
    const response = await updateConnectionApi(data);

    dispatch(
      expireApiStatus([
        { actionName: CONNECTION_LIST_ALL_ACTION, expireAll: true },
      ])
    );
    // TODO - move this api call out of this async thunk
    if (response.connection.typ !== SOURCES.BIGQUERY) {
      const { schemas } = await listSourceSchemaApi(response.connection.id);
      return { response, schemas };
    }

    return { response };
  }
);

export const validateReadOnlyConnection = createAsyncThunk(
  "connection/validate-readonly-connection",
  async ({
    data,
    connId,
    sourceTables,
  }: {
    data: ConnectionReadOnlyValidateConfig;
    connId: string;
    sourceTables: string[];
  }) => {
    const response = await validateConnectionApi(data);
    if (response.success) {
      await addSourceTablesApi(connId, sourceTables);
    }
    return { response };
  }
);

export const validateReadAndWriteConnection = createAsyncThunk(
  "connection/validate-readandwrite-connection",
  async ({
    data,
    connId,
  }: {
    data: ConnectionReadAndWriteValidateConfig;
    connId: string;
  }) => {
    const response = await validateConnectionApi(data);
    if (response.success) {
      const { schemas } = await listSourceSchemaApi(connId);
      return { response, schemas };
    }
    return { response };
  }
);

export const schemaSet = createAsyncThunk(
  "connection/set-schema",
  async ({ id, schemaName }: { id: string; schemaName: string }) => {
    return await setSchemaApi(id, schemaName);
  }
);

export const listConnection = createAsyncThunk(
  "connection/list-connection",
  async (_, thunkApi) => {
    const { connection } = thunkApi.getState() as RootState;
    return await listConnectionsApi(
      connection.connectionPageSize,
      connection.connectionCurrentPage
    );
  }
);

export const listSourceTables = createAsyncThunk(
  "connection/list-source-tables",
  async ({ connectionId }: { connectionId: string }) => {
    return await listSourceTablesApi(connectionId);
  }
);

export const listDestinationTables = createAsyncThunk(
  "connection/list-destination-tables",
  async () => {
    return await listDestinationTablesApi();
  }
);

export const listSourceTableColumns = createAsyncThunk(
  "connection/list-columns",
  async ({ id, tableName }: { id: string; tableName: string }) => {
    const data = await listSourceTableColumnsApi(id, tableName);

    return { data, tableName };
  }
);

export const getSyncSummary = createAsyncThunk(
  "connection/get-sync-summary",
  async (connectionId: string) => {
    return await getSyncSummaryApi(connectionId);
  }
);

export const getSyncDetails = createAsyncThunk(
  "connection/get-sync-details",
  async (connectionId: string) => {
    return await getSyncDetailsApi(connectionId);
  }
);

export const saveSync = createAsyncThunk(
  "connection/save-sync",
  async (data: SyncTablesData) => {
    return await saveSyncApi(data);
  }
);

export const listSyncRuns = createAsyncThunk(
  "connection/list-sync-runs",
  async ({
    connectionId,
    startDate,
    endDate,
    pageNumber,
    pageSize,
  }: {
    connectionId: string;
  } & DateRange) => {
    return await listSyncRunsApi({
      connectionId,
      startTime: startDate,
      endTime: endDate,
      pageNumber,
      pageSize,
    });
  }
);

export const toggleSync = createAsyncThunk(
  "connection/toggle-sync",
  async (connectionId: string) => {
    return await toggleSyncApi(connectionId);
  }
);

export const activityLog = createAsyncThunk(
  "connection/get-activity-log",
  async ({
    connectionId,
    pageNumber,
    pageSize,
  }: {
    connectionId: string;
    pageNumber: number;
    pageSize: number;
  }) => {
    return await getActivityLogs({ connectionId, pageNumber, pageSize });
  }
);

export const whiteListedIps = createAsyncThunk(
  "connection/get-white-list-ips",
  async () => {
    return await getWhiteListIpsApi();
  }
);

// Segment
export const getSegmentDetails = createAsyncThunk(
  "connection/get-segment-details",
  async () => {
    return await getSegmentDetailsApi();
  }
);

export const listSegmentSourceErrors = createAsyncThunk(
  "connection/list-segment-source-errors",
  async ({ startDate, endDate, pageNumber, pageSize }: DateRange) => {
    return await listSegmentErrorsApi({
      startTime: startDate,
      endTime: endDate,
      syncType: SEGMENT_SYNC_TYPES.SOURCE,
      pageNumber,
      pageSize,
    });
  }
);

export const listSegmentDestinationErrors = createAsyncThunk(
  "connection/list-segment-destination-errors",
  async ({ startDate, endDate, pageNumber, pageSize }: DateRange) => {
    return await listSegmentErrorsApi({
      startTime: startDate,
      endTime: endDate,
      syncType: SEGMENT_SYNC_TYPES.DESTINATION,
      pageNumber,
      pageSize,
    });
  }
);

export const getSegmentSourceEventReport = createAsyncThunk(
  "connection/get-segment-source-event-report",
  async (data: ReportRange) => {
    return await getSegmentEventReportApi({
      ...data,
      syncType: SEGMENT_SYNC_TYPES.SOURCE,
    });
  }
);

export const getSegmentDestinationEventReport = createAsyncThunk(
  "connection/get-segment-destination-event-report",
  async (data: ReportRange) => {
    return await getSegmentEventReportApi({
      ...data,
      syncType: SEGMENT_SYNC_TYPES.DESTINATION,
    });
  }
);

export const getSegmentSourceErrorReport = createAsyncThunk(
  "connection/get-segment-source-error-report",
  async (data: ReportRange) => {
    return await getSegmentErrorReportApi({
      ...data,
      syncType: SEGMENT_SYNC_TYPES.SOURCE,
    });
  }
);

export const getSegmentDestinationErrorReport = createAsyncThunk(
  "connection/get-segment-destination-error-report",
  async (data: ReportRange) => {
    return await getSegmentErrorReportApi({
      ...data,
      syncType: SEGMENT_SYNC_TYPES.DESTINATION,
    });
  }
);

export const createSegmentReadApiKey = createAsyncThunk(
  "connection/segment/create-read-api-key",
  async () => {
    return await createApiKeyApi("read_key", SOURCES.SEGMENT);
  }
);

export const deactivateSegmentReadApiKey = createAsyncThunk(
  "connection/segment/deactivate-read-api-key",
  async (keyId: string) => {
    return await deactivateApiKeyApi(keyId);
  }
);

export const setSegmentSync = createAsyncThunk(
  "connection/segment/sync",
  async (destinationSettings: SegmentAsDestinationConfig) => {
    const { write_key } = destinationSettings;
    const syncDetails = {
      connection_type: write_key
        ? SEGMENT_SYNC_TYPES.SOURCE_AND_DESTINATION
        : SEGMENT_SYNC_TYPES.SOURCE,
      source_sync_settings: {
        is_sync_contacts_enabled: true,
        is_sync_organisations_enabled: true,
      },
      destination_sync_settings: write_key ? destinationSettings : undefined,
    };
    return await setSegmentSyncApi(syncDetails);
  }
);

// Salesforce
export const createSalesforceConnection = createAsyncThunk(
  "connection/create-salesforce-connection",
  async (name: string, { dispatch }) => {
    const response = createSalesforceConnectionApi(name);
    dispatch(
      expireApiStatus([
        { actionName: CONNECTION_LIST_ALL_ACTION, expireAll: true },
      ])
    );
    return response;
  }
);

export const getSalesforceLoginUrl = createAsyncThunk(
  "connection/get-salesforce-login-url",
  async () => {
    return await getSalesforceLoginUrlApi();
  }
);

export const connectSalesforce = createAsyncThunk(
  "connection/connect-salesforce",
  async ({ code, connectionId }: { code: string; connectionId: string }) => {
    return await connectSalesforceApi(code, connectionId);
  }
);

export const getSalesforceConnectionList = createAsyncThunk(
  "connection/get-salesforce-connection-list",
  async () => {
    return await getSalesforceConnectionListApi();
  }
);

export const getSalesforceConnectionDetails = createAsyncThunk(
  "connection/get-salesforce-connection-details",
  async (connectionId: string) => {
    return await getSalesforceConnectionDetailsApi(connectionId);
  }
);

export const getSfConnectionDetailsForUnifiedMapping = createAsyncThunk(
  "connection/get-sf-connection-details-unified-mapping",
  async (connectionId: string) => {
    return await getSalesforceConnectionDetailsApi(connectionId);
  }
);

export const getSyncHistoryDetails = createAsyncThunk(
  "connection/get-salesforce-sync-history-details",
  async (connectionId: string) => {
    return await getSyncHistoryDetailsApi(connectionId);
  }
);

export const updateSalesforceSettings = createAsyncThunk(
  "connection/update-salesforce-settings",
  async (data: SyncSettings, thunkApi) => {
    const {
      connection: { salesforce },
    } = thunkApi.getState() as RootState;
    return await updateSalesforceSettingsApi(
      data,
      salesforce.connection?.connection_id || ""
    );
  }
);

export const updateSalesforceObjects = createAsyncThunk(
  "connection/update-salesforce-objects",
  async (
    { data, toast }: { data: ObjectSettings; toast?: boolean },
    { getState }
  ) => {
    const {
      connection: { salesforce },
    } = getState() as RootState;
    return await updateSalesforceObjectsApi(
      data,
      salesforce.connection?.connection_id || ""
    );
  },
  {
    condition: (_, { getState }) => {
      const {
        connection: { salesforce },
      } = getState() as RootState;

      if (!salesforce.connection?.connection_id) {
        return false;
      }
    },
  }
);

export const listSalesforceSyncRuns = createAsyncThunk(
  "connection/get-salesforce-connection-run-list",
  async (
    data: {
      connectionId: string;
      shouldIncludeEmptyRuns: boolean;
    } & DateTimeFilterPagintation
  ) => {
    return await listSalesforceSyncRunsApi(data);
  }
);

export const recheckSalesforcePermission = createAsyncThunk(
  "connection/recheck-salesforce-permission",
  async (_, thunkApi) => {
    const {
      connection: {
        salesforce: { connection },
      },
    } = thunkApi.getState() as RootState;
    return await recheckSalesforcePermissionApi(
      connection?.connection_id ?? ""
    );
  }
);

export const pauseSalesforceSync = createAsyncThunk(
  "connection/pause-salesforce-sync",
  async (_, thunkApi) => {
    const {
      connection: {
        salesforce: { connection },
      },
    } = thunkApi.getState() as RootState;
    return await pauseSalesforceSyncApi(connection?.connection_id ?? "");
  }
);

export const resumeSalesforceSync = createAsyncThunk(
  "connection/resume-salesforce-sync",
  async (_, thunkApi) => {
    const {
      connection: {
        salesforce: { connection },
      },
    } = thunkApi.getState() as RootState;
    return await resumeSalesforceSyncApi(connection?.connection_id ?? "");
  }
);

export const deleteSalesforceConnection = createAsyncThunk(
  "connection/delete-salesforce-connection",
  async (_, { getState, dispatch }) => {
    const {
      connection: {
        salesforce: { connection },
      },
    } = getState() as RootState;
    const response = await deleteSalesforceConnectionApi(
      connection?.connection_id ?? ""
    );
    dispatch(
      expireApiStatus([
        { actionName: CONNECTION_LIST_ALL_ACTION, expireAll: true },
      ])
    );
    return response;
  }
);

export const getSalesforceApiUsageCount = createAsyncThunk(
  "connection/get-salesforce-api-usage-count",
  async (connectionId: string) => {
    return await getApiUsageCountApi(connectionId);
  }
);

export const getAllConnections = createAsyncThunkWrapper<
  {
    connections: ConnectionListItem[];
  },
  { isForced?: boolean } | undefined
>({
  actionName: CONNECTION_LIST_ALL_ACTION,
  dispatchFn: async (_, { getState }) => {
    const { connection } = getState() as RootState;
    return await listConnectionsUnifiedApi(
      connection.unifiedConnection.connectionList.pageSize,
      connection.unifiedConnection.connectionList.currentPageNo
    );
  },
  isCachable: true,
});

export const getSalesforceObjects = createAsyncThunk(
  "connection/get-all-sf-objects",
  async (connectionId: string, thunkApi) => {
    try {
      return await getSalesforceObjectsApi(connectionId);
    } catch (err: any) {
      // TODO - make type 'unknown' here and fix type error
      if (!err.response) {
        throw err;
      }
      return thunkApi.rejectWithValue({ data: err.response.data });
    }
  }
);

export const getUnifiedMapping = createAsyncThunk(
  "connection/get-unified-mapping",
  async () => {
    return await getUnifiedMappingApi();
  }
);

export const updateUnifiedMappingCrm = createAsyncThunk(
  "connection/update-unified-mapping-crm",
  async (mapping: MappingElement[], { dispatch }) => {
    const response = await updateCrmUnifiedMappingApi(mapping);
    dispatch(
      expireApiStatus([
        {
          actionName: FILTERS_LIST_ACTION,
          expireAll: true,
        },
        {
          actionName: PERSON_MAPPING,
          expireAll: true,
        },
      ])
    );
    return response;
  }
);

export const setDefaultMappingCrm = createAsyncThunk(
  "connection/set-default-mapping-crm",
  async (data: DefaultCrmMapping) => {
    return await setDefaultCrmMappingApi(data);
  }
);

export const updateUnifiedMappingAccount = createAsyncThunk(
  "connection/update-unified-mapping-account",
  async (mapping: AccountMappingElement[]) => {
    return await updateAccountUnifiedMappingApi(mapping);
  }
);

export const setDefaultMappingAccount = createAsyncThunk(
  "connection/set-default-mapping-account",
  async (data: DefaultAccountMapping) => {
    return await setDefaultAccountUnifiedMappingApi(data);
  }
);

export const completeSetup = createAsyncThunk(
  "connection/setup-complete",
  async (data: SetupCompleteData) => {
    return await setupCompleteApi(data);
  }
);

export const updateInclusionList = createAsyncThunk(
  "connection/inclusion-list-update",
  async (data: { connectionId: string; inclusionList: InclusionList }) => {
    return await updateInclusionListApi(data);
  }
);

export const removeInclusionList = createAsyncThunk(
  "connection/inclusion-list-remove",
  async (connectionId: string) => {
    return await removeInclusionListApi(connectionId);
  }
);

export const updateSelectiveSync = createAsyncThunk(
  "connection/selective-sync-update",
  async (data: {
    connectionId: string;
    selectiveSync: Partial<SelectiveSync>;
  }) => {
    return await updateSelectiveSyncApi(data);
  }
);

export const updateSalesforceRecordCreate = createAsyncThunk(
  "connection/salesforce-records-create-update",
  async (data: {
    connectionId: string;
    salesforceRecordCreateUpdate: SALESFORCE_RECORD_CREATE;
  }) => {
    return await updateSalesforceRecordsCreateUpdateSettingApi(data);
  }
);

export const updateSalesforceRecordDelete = createAsyncThunk(
  "connection/salesforce-records-delete",
  async (data: {
    connectionId: string;
    salesforceRecordDelete: SALESFORCE_RECORD_DELETE;
  }) => {
    return await updateSalesforceRecordsDeleteSettingApi(data);
  }
);

export const updateInflectionRecordCreate = createAsyncThunk(
  "connection/inflection-records-create-update",
  async (data: {
    connectionId: string;
    objectCreate: Partial<ObjectCreateRule>;
  }) => {
    return await updateInflectionRecordsCreateUpdateSettingApi(data);
  }
);

export const updateInflectionRecordDelete = createAsyncThunk(
  "connection/inflection-records-delete",
  async (data: {
    connectionId: string;
    objectDelete: Partial<ObjectDeleteRule>;
  }) => {
    return await updateInflectionRecordsDeleteSettingApi(data);
  }
);

export const listSalesforceSyncErrors = createAsyncThunk(
  "connection/inflection-salesforce-sync-errors",
  async (
    data: {
      connectionId: string;
      errorType: string;
    } & DateTimeFilterPagintation
  ) => {
    return await listSalesforceSyncErrorsApi(data);
  }
);

export const listSalesforceSyncErrorsTypes = createAsyncThunk(
  "connection/inflection-salesforce-sync-errors-types",
  async () => {
    return await listSalesforceSyncErrorsTypesApi();
  }
);

export const startSalesforceSync = createAsyncThunk(
  "connection/salesforce-sync-start",
  async (connectionId: string) => {
    return await startSalesforceSyncApi(connectionId);
  }
);

export const getSalesforceErrorQuery = createAsyncThunk(
  "connection/inflection-salesforce-sync-error-query",
  async (data: {
    errorClass: string;
    objectEmail?: string;
    pageNo?: number;
    pageSize?: number;
    keyword?: string;
  }) => {
    return await getSalesforceErrorQueryApi(data);
  }
);

export const archiveSalesforceSyncError = createAsyncThunk(
  "connection/inflection-salesforce-sync-error-archive",
  async (data: SfResyncArchiveDataType) => {
    return await archiveSalesforceSyncErrorApi(data);
  }
);

export const resyncSalesforceSyncError = createAsyncThunk(
  "connection/inflection-salesforce-sync-error-resync",
  async (data: SfResyncArchiveDataType) => {
    return await resyncSalesforceSyncErrorApi(data);
  }
);

export const getSegmentEventStatus = createAsyncThunk(
  "connection/segment-event-status",
  async () => {
    return await getSegmentEventsStatusApi();
  }
);

export const getSegmentTrackEventStatus = createAsyncThunk(
  "connection/segment-track-event-status",
  async ({
    pageSize,
    pageNumber,
    searchQuery,
  }: {
    pageSize: number;
    pageNumber: number;
    searchQuery: string;
  }) => {
    return await getSegmentTrackEventsStatusApi(
      pageSize,
      pageNumber,
      searchQuery
    );
  }
);

export const getSegmentNonTrackEventStatus = createAsyncThunk(
  "connection/segment-non-track-event-status",
  async () => {
    return await getSegmentNonTrackEventsStatusApi();
  }
);

export const updateSegmentDescription = createAsyncThunk(
  "connection/segment-event-description",
  async (data: SegmentDescription) => {
    return await updateDescriptionApi(data);
  }
);

export const toggleSegmentEvent = createAsyncThunk(
  "connection/segment-event-toggle",
  async (data: SegmentEventToggle) => {
    return await toggleSegmentEventApi(data);
  }
);

export const toggleSegmentEventProperty = createAsyncThunk(
  "connection/segment-event-property-toggle",
  async (data: SegmentEventPropertyToggle) => {
    return await toggleSegmentEventPropertyApi(data);
  }
);

export const toggleBypassProperties = createAsyncThunk(
  "connection/segment-bypass-properties",
  async (data: SegmentEventPropertyToggle) => {
    return await toggleBypassPropertiesApi(data);
  }
);

export const getAccountContactAutoMapping = createAsyncThunk(
  "connection/get-account-contact-auto-mapping",
  async (settingName: string) => {
    return await getAccountContactMappingApi(settingName);
  }
);

export const deleteAccountContactAutoMapping = createAsyncThunk(
  "connection/delete-account-contact-auto-mapping",
  async (settingName: string) => {
    return await deleteAccountContactMappingApi(settingName);
  }
);

export const updateAccountContactAutoMapping = createAsyncThunk(
  "connection/update-account-contact-auto-mapping",
  async (settingValue: AccountContactAutoMappingElement) => {
    return await updateAccountContactMappingApi(settingValue);
  }
);

export const listDwDestinationColumns = createAsyncThunk(
  "connection/list-dw-destination-columns",
  async ({
    connectionId,
    destinationTableName,
  }: {
    connectionId: string;
    destinationTableName: string;
  }) => {
    return await listDestinationColumnsApi(connectionId, destinationTableName);
  }
);

export const getMappedColumns = createAsyncThunk(
  "connection/get-mapped-columns",
  async () => {
    return await getMappedColumnsApi();
  }
);

export const getSalesforceCampaignSyncErrors = createAsyncThunk(
  "connection/get-salesforce-campaign-sync-errors",
  async ({
    startTimestamp,
    endTimestamp,
  }: {
    startTimestamp: number;
    endTimestamp: number;
  }) => {
    return await getSalesforceCampaignSyncErrorsApi(
      startTimestamp,
      endTimestamp
    );
  }
);

const connectionSlice = createSlice({
  name: "connection",
  initialState,
  reducers: {
    setConnectionsPageNo(state, action: PayloadAction<number>) {
      const pageNo = action.payload;
      if (pageNo && pageNo <= (state.connectionTotalPageCount ?? 1)) {
        state.connectionCurrentPage = pageNo;
      }
    },
    setSalesforceSyncHistoryPage(state, action: PayloadAction<number>) {
      const pageNo = action.payload;
      if (pageNo && pageNo <= (state.salesforce.syncRuns.totalPageCount ?? 1)) {
        state.salesforce.syncRuns.currentPageNo = pageNo;
        state.salesforce.syncRuns.changingPage = true;
      }
    },
    setAllConnectionsPageNo(state, action: PayloadAction<number>) {
      const pageNo = action.payload;
      if (
        pageNo &&
        pageNo <= (state.unifiedConnection.connectionList.totalPageCount ?? 1)
      ) {
        state.unifiedConnection.connectionList.currentPageNo = pageNo;
        state.unifiedConnection.connectionList.changingPage = true;
      }
    },
    setSalesforceErrorModalPage(state, action: PayloadAction<number>) {
      const pageNo = action.payload;
      if (
        pageNo &&
        pageNo <= (state.salesforce.sfAffectedRecords.totalPageCount ?? 1)
      ) {
        state.salesforce.sfAffectedRecords.currentPageNo = pageNo;
        state.salesforce.sfAffectedRecords.changingPage = true;
      } else {
        state.salesforce.sfAffectedRecords.currentPageNo = 1;
      }
    },
    wizardNextStep: (state) => {
      state.createCredsWizardStep++;
    },
    wizardPrevStep: (state) => {
      if (state.createCredsWizardStep > 1) {
        state.createCredsWizardStep--;
      }
    },
    wizardSetStep: (state, action: PayloadAction<number>) => {
      state.createCredsWizardStep = action.payload;
    },
    setSelectedSchema: (state, action: PayloadAction<string>) => {
      state.selectedSchema = action.payload;
    },
    setSelectedService: (state, action: PayloadAction<string>) => {
      state.selectedService = action.payload;
    },
    resetFlags: (state) => {
      state.addedSchema = false;
      state.createdNewConnection = false;
      state.creatingNewConnection = false;
      state.updatedConnection = false;
      state.updatingConnection = false;
      state.validatingReadOnlyConnection = LOADING_STATES.INIT;
      state.validatingReadAndWriteConnection = LOADING_STATES.INIT;
      state.bigQuery.isReadOnlyPermission = false;
      state.createCredsWizardStep = 1;
      state.createdTableConnection = false;
      state.selectedService = "";
      state.whiteListIps = null;
    },
    removeFromTableList: (state, action: PayloadAction<string>) => {
      if (state.tableList) {
        state.tableList = state.tableList.filter(
          (table) => table !== action.payload
        );
      }
    },
    addToTableList: (state, action: PayloadAction<string>) => {
      if (state.tableList) {
        state.tableList.push(action.payload);
      }
    },
    toggleTableColumnSelection: (
      state,
      action: PayloadAction<{
        columnName: string;
        tableName: string;
        selected: boolean;
      }>
    ) => {
      state.columnList[action.payload.tableName].forEach((col) => {
        if (col.name === action.payload.columnName) {
          col.selected = action.payload.selected;
        }
      });
    },
    removeFromColumnList: (state, action: PayloadAction<string>) => {
      delete state.columnList[action.payload];
    },
    selectAllColumnsInTableToggle: (
      state,
      action: PayloadAction<{ tableName: string; selected: boolean }>
    ) => {
      state.columnList[action.payload.tableName].forEach((col) => {
        col.selected = action.payload.selected;
      });
    },
    setSyncRunsPageNo(state, action: PayloadAction<number>) {
      const pageNo = action.payload;
      if (pageNo && pageNo <= (state.syncRuns.totalPageCount ?? 1)) {
        state.syncRuns.currentPage = pageNo;
      }
    },
    clearConnectionDetailsData(state) {
      state.syncRuns = initialState.syncRuns;
      state.syncSummary = null;
      state.connectionDetails = null;
      state.syncDetails = null;
      state.activityLogs = initialState.activityLogs;
      state.tableList = null;
      state.savingSync = LOADING_STATES.INIT;
    },
    setConnectionDetails(state, action: PayloadAction<Connection | null>) {
      state.connectionDetails = action.payload;
    },
    setActivityLogPage(state, action: PayloadAction<number>) {
      const pageNo = action.payload;
      if (pageNo && pageNo <= (state.activityLogs.totalPageCount ?? 1)) {
        state.activityLogs.currentPageNumber = pageNo;
      }
    },
    setSegmentSourceErrorPage(state, { payload: pageNo }: { payload: number }) {
      if (
        pageNo &&
        pageNo <= (state.segment.sourceSyncDetails.errors.totalPageCount ?? 1)
      ) {
        state.segment.sourceSyncDetails.errors.currentPageNo = pageNo;
        state.segment.sourceSyncDetails.errors.changingPage = true;
      }
    },
    setSegmentDestErrorPage(state, { payload: pageNo }: { payload: number }) {
      if (
        pageNo &&
        pageNo <=
          (state.segment.destinationSyncDetails.errors.totalPageCount ?? 1)
      ) {
        state.segment.destinationSyncDetails.errors.currentPageNo = pageNo;
        state.segment.destinationSyncDetails.errors.changingPage = true;
      }
    },
    setSegmentConnectionType(
      state,
      { payload }: { payload: SEGMENT_SYNC_TYPES }
    ) {
      state.segment.createSync.type = payload;
    },
    setSegmentDestinationKey(state, { payload }: { payload: string }) {
      state.segment.createSync.writeApiKey = payload;
    },
    resetSegmentSyncData(state) {
      state.segment.createSync.type = "";
      state.segment.createSync.writeApiKey = "";
    },
    resetSalesforceData(state) {
      state.salesforce = SALESFORCE_INIT;
    },
    resetSalesforceAffectedRecords(state) {
      state.salesforce.sfAffectedRecords = INITIAL_PAGINATION;
    },
    setIsReadOnly(state, { payload }: { payload: boolean }) {
      state.bigQuery.isReadOnlyPermission = payload;
    },
    setHostName(state, { payload }: { payload: string }) {
      state.hostName = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // Create connection
      .addCase(createConnection.pending, (state) => {
        state.creatingNewConnection = true;
      })
      .addCase(createConnection.fulfilled, (state, action) => {
        state.creatingNewConnection = false;
        state.createdNewConnection = true;
        state.connectionDetails = action.payload.connectionInfo.connection;
        state.hostName = action.payload.connectionInfo.connection.host_name;
        state.schemaList = action.payload.schemas ?? [];
        toast.success("Connection created successfully");
      })
      .addCase(createConnection.rejected, (state) => {
        state.creatingNewConnection = false;
      })

      // update connection
      .addCase(updateConnection.pending, (state) => {
        state.updatingConnection = true;
      })
      .addCase(updateConnection.fulfilled, (state, action) => {
        state.updatingConnection = false;
        state.updatedConnection = true;
        state.hostName = action.payload.response.connection.host_name;
        state.connectionList =
          state.connectionList?.map((conn) => {
            if (conn.id === action.payload.response.connection.id) {
              conn = action.payload.response.connection;
            }
            return conn;
          }) ?? null;
        state.connectionDetails = action.payload.response.connection;
        state.schemaList = action.payload.schemas ?? [];
        toast.success("Connection updated successfully");
      })
      .addCase(updateConnection.rejected, (state) => {
        state.updatingConnection = false;
      })

      //validate connection
      .addCase(validateReadOnlyConnection.pending, (state) => {
        state.validatingReadOnlyConnection = LOADING_STATES.LOADING;
      })
      .addCase(validateReadOnlyConnection.fulfilled, (state, action) => {
        if (action.payload.response.success) {
          toast.success("Connection validated successfully");
          state.validatingReadOnlyConnection = LOADING_STATES.SUCCESS;
        } else {
          toast.error(
            "Access Denied: Cannot access the project datasets. Check if the project id is correct and inflection has required permissions",
            {
              style: {
                width: "500px",
              },
            }
          );
          state.validatingReadOnlyConnection = LOADING_STATES.FAILED;
        }
      })
      .addCase(validateReadOnlyConnection.rejected, (state) => {
        state.validatingReadOnlyConnection = LOADING_STATES.FAILED;
      })

      .addCase(validateReadAndWriteConnection.pending, (state) => {
        state.validatingReadAndWriteConnection = LOADING_STATES.LOADING;
      })
      .addCase(validateReadAndWriteConnection.fulfilled, (state, action) => {
        state.schemaList = action.payload.schemas ?? [];

        if (action.payload.response.success) {
          toast.success("Connection validated successfully");
          state.validatingReadAndWriteConnection = LOADING_STATES.SUCCESS;
        } else {
          toast.error(
            "Access Denied: Cannot access the project datasets. Check if the project id is correct and the inflection dataset has been created",
            {
              style: {
                width: "500px",
              },
            }
          );
          state.validatingReadAndWriteConnection = LOADING_STATES.FAILED;
        }
      })
      .addCase(validateReadAndWriteConnection.rejected, (state) => {
        state.validatingReadAndWriteConnection = LOADING_STATES.FAILED;
      })

      // List connection
      .addCase(listConnection.pending, (state) => {
        state.fetchingConnectionList = true;
      })
      .addCase(listConnection.fulfilled, (state, action) => {
        state.connectionList = action.payload.records;
        state.fetchingConnectionList = false;
      })
      .addCase(listConnection.rejected, (state) => {
        state.fetchingConnectionList = false;
      })

      // Set Schema
      .addCase(schemaSet.pending, (state) => {
        state.updatingConnection = true;
      })
      .addCase(schemaSet.fulfilled, (state, action) => {
        state.updatingConnection = false;
        state.addedSchema = true;
        state.connectionDetails = action.payload.connection;
      })
      .addCase(schemaSet.rejected, (state) => {
        state.updatingConnection = false;
      })

      // List source tables
      .addCase(listSourceTables.pending, (state) => {
        state.fetchingSourceTables = true;
      })
      .addCase(listSourceTables.fulfilled, (state, action) => {
        state.fetchingSourceTables = false;
        state.tableList = action.payload.tables;
      })
      .addCase(listSourceTables.rejected, (state) => {
        state.fetchingSourceTables = false;
      })

      // List destination tables
      .addCase(listDestinationTables.pending, (state) => {
        state.fetchingDestinationTables = true;
      })
      .addCase(listDestinationTables.fulfilled, (state, action) => {
        state.fetchingDestinationTables = false;
        // add custom table option to show in destination table
        state.destinationTables = [
          ...action.payload.tables,
          {
            table_name: CUSTOM_TABLE,
            default: false,
            used_in_connection: "",
            columns: [],
            multi_source: true,
          },
        ];
      })
      .addCase(listDestinationTables.rejected, (state) => {
        state.fetchingSourceTables = false;
      })

      // List columns
      .addCase(listSourceTableColumns.pending, (state) => {
        state.fetchingColumns = true;
      })
      .addCase(listSourceTableColumns.fulfilled, (state, action) => {
        const cols: SourceColumn[] = action.payload.data.columns.map(
          (col: SourceColumn) => {
            return { ...col, selected: false };
          }
        );
        state.columnList[action.payload.tableName] = cols;
        state.fetchingColumns = false;
      })
      .addCase(listSourceTableColumns.rejected, (state) => {
        state.fetchingColumns = false;
      })

      // Get Sync Summary
      .addCase(getSyncSummary.pending, (state) => {
        state.fetchingSyncSummary = true;
      })
      .addCase(getSyncSummary.fulfilled, (state, action) => {
        state.fetchingSyncSummary = false;
        state.syncSummary = action.payload.sync_summary;
      })
      .addCase(getSyncSummary.rejected, (state) => {
        state.fetchingSyncSummary = false;
      })

      // Get Sync
      .addCase(getSyncDetails.pending, (state) => {
        state.fetchingSyncDetails = true;
      })
      .addCase(getSyncDetails.fulfilled, (state, action) => {
        state.fetchingSyncDetails = false;
        state.syncDetails = action.payload.sync;
      })
      .addCase(getSyncDetails.rejected, (state) => {
        state.fetchingSyncDetails = false;
      })

      // Save Sync
      .addCase(saveSync.pending, (state) => {
        state.savingSync = LOADING_STATES.LOADING;
      })
      .addCase(saveSync.fulfilled, (state, action) => {
        state.savingSync = LOADING_STATES.SUCCESS;
        state.connectionDetails = action.payload.connection;
        state.destinationTables = null;
        toast.success("Sync successfully saved");
      })
      .addCase(saveSync.rejected, (state) => {
        state.savingSync = LOADING_STATES.FAILED;
      })

      // Toggle Sync
      .addCase(toggleSync.pending, (state) => {
        state.togglingSync = true;
      })
      .addCase(toggleSync.fulfilled, (state, action) => {
        state.togglingSync = false;
        if (state.syncSummary) {
          state.syncSummary.sync_enabled = action.payload.sync.sync_enabled;
        }
        if (action.payload.sync.sync_enabled) {
          toast.success("Sync successfully resumed");
        } else {
          toast.success("Sync successfully paused");
        }
      })
      .addCase(toggleSync.rejected, (state) => {
        state.togglingSync = false;
      })

      // List Sync Runs
      .addCase(listSyncRuns.pending, (state) => {
        state.fetchingSyncRuns = true;
      })
      .addCase(listSyncRuns.fulfilled, (state, action) => {
        state.fetchingSyncRuns = false;
        state.syncRuns.records = action.payload.records;
        state.syncRuns.recordCount = action.payload.record_count;
        state.syncRuns.totalPageCount = action.payload.page_count;
      })
      .addCase(listSyncRuns.rejected, (state) => {
        state.fetchingSyncRuns = false;
      })

      .addCase(activityLog.pending, (state) => {
        state.fetchingActivityLog = true;
      })
      .addCase(activityLog.fulfilled, (state, action) => {
        state.fetchingActivityLog = false;
        state.activityLogs.records = action.payload.records;
        state.activityLogs.recordCount = action.payload.record_count;
        state.activityLogs.totalPageCount = action.payload.page_count;
      })
      .addCase(activityLog.rejected, (state) => {
        state.fetchingActivityLog = false;
      })

      .addCase(whiteListedIps.pending, (state) => {
        state.fetchingWhiteListIps = true;
      })
      .addCase(whiteListedIps.fulfilled, (state, action) => {
        state.fetchingWhiteListIps = false;
        state.whiteListIps = action.payload.whitelist_ips;
      })
      .addCase(whiteListedIps.rejected, (state) => {
        state.whiteListIps = [];
        state.fetchingWhiteListIps = false;
      })

      //segment
      //segment as source list errors
      .addCase(listSegmentSourceErrors.pending, (state) => {
        state.segment.sourceSyncDetails.errors.fetchingList = true;
      })
      .addCase(listSegmentSourceErrors.fulfilled, (state, action) => {
        state.segment.sourceSyncDetails.errors.list = action.payload.records;
        state.segment.sourceSyncDetails.errors.totalPageCount =
          action.payload.page_count;
        state.segment.sourceSyncDetails.errors.count =
          action.payload.record_count;
        state.segment.sourceSyncDetails.errors.fetchingList = false;
        state.segment.sourceSyncDetails.errors.changingPage = false;
      })
      .addCase(listSegmentSourceErrors.rejected, (state) => {
        state.segment.sourceSyncDetails.errors.totalPageCount = 0;
        state.segment.sourceSyncDetails.errors.fetchingList = false;
        state.segment.sourceSyncDetails.errors.changingPage = false;
      })

      //segment as source get event report
      .addCase(getSegmentSourceEventReport.pending, (state) => {
        state.segment.sourceSyncDetails.eventReport.loading =
          LOADING_STATES.LOADING;
      })
      .addCase(getSegmentSourceEventReport.fulfilled, (state, action) => {
        state.segment.sourceSyncDetails.eventReport.loading =
          LOADING_STATES.SUCCESS;
        state.segment.sourceSyncDetails.eventReport.data = sortBy(
          action.payload.rows,
          ["date", "hour"]
        );
      })
      .addCase(getSegmentSourceEventReport.rejected, (state) => {
        state.segment.sourceSyncDetails.eventReport.loading =
          LOADING_STATES.FAILED;
      })

      //segment as source get error report
      .addCase(getSegmentSourceErrorReport.pending, (state) => {
        state.segment.sourceSyncDetails.errorReport.loading =
          LOADING_STATES.LOADING;
      })
      .addCase(getSegmentSourceErrorReport.fulfilled, (state, action) => {
        state.segment.sourceSyncDetails.errorReport.loading =
          LOADING_STATES.SUCCESS;
        state.segment.sourceSyncDetails.errorReport.data = sortBy(
          action.payload.rows,
          ["date", "hour"]
        );
      })
      .addCase(getSegmentSourceErrorReport.rejected, (state) => {
        state.segment.sourceSyncDetails.errorReport.loading =
          LOADING_STATES.FAILED;
      })

      //segment as destination, list errors
      .addCase(listSegmentDestinationErrors.pending, (state) => {
        state.segment.destinationSyncDetails.errors.fetchingList = true;
      })
      .addCase(listSegmentDestinationErrors.fulfilled, (state, action) => {
        state.segment.destinationSyncDetails.errors.list =
          action.payload.records;
        state.segment.destinationSyncDetails.errors.totalPageCount =
          action.payload.page_count;
        state.segment.destinationSyncDetails.errors.count =
          action.payload.record_count;
        state.segment.destinationSyncDetails.errors.fetchingList = false;
        state.segment.destinationSyncDetails.errors.changingPage = false;
      })
      .addCase(listSegmentDestinationErrors.rejected, (state) => {
        state.segment.destinationSyncDetails.errors.totalPageCount = 0;
        state.segment.destinationSyncDetails.errors.fetchingList = false;
        state.segment.destinationSyncDetails.errors.changingPage = false;
      })

      //segment as destination event report
      .addCase(getSegmentDestinationEventReport.pending, (state) => {
        state.segment.destinationSyncDetails.eventReport.loading =
          LOADING_STATES.LOADING;
      })
      .addCase(getSegmentDestinationEventReport.fulfilled, (state, action) => {
        state.segment.destinationSyncDetails.eventReport.loading =
          LOADING_STATES.SUCCESS;
        state.segment.destinationSyncDetails.eventReport.data = sortBy(
          action.payload.rows,
          ["date", "hour"]
        );
      })
      .addCase(getSegmentDestinationEventReport.rejected, (state) => {
        state.segment.destinationSyncDetails.eventReport.loading =
          LOADING_STATES.FAILED;
      })

      //segment as destination error report
      .addCase(getSegmentDestinationErrorReport.pending, (state) => {
        state.segment.sourceSyncDetails.errorReport.loading =
          LOADING_STATES.LOADING;
      })
      .addCase(getSegmentDestinationErrorReport.fulfilled, (state, action) => {
        state.segment.sourceSyncDetails.errorReport.loading =
          LOADING_STATES.SUCCESS;
        state.segment.sourceSyncDetails.errorReport.data = sortBy(
          action.payload.rows,
          ["date", "hour"]
        );
      })
      .addCase(getSegmentDestinationErrorReport.rejected, (state) => {
        state.segment.sourceSyncDetails.errorReport.loading =
          LOADING_STATES.FAILED;
      })

      // create and update segment sync
      .addCase(setSegmentSync.pending, (state) => {
        state.segment.syncDetails.loading = LOADING_STATES.LOADING;
      })
      .addCase(setSegmentSync.fulfilled, (state, { payload }) => {
        state.segment.syncDetails.loading = LOADING_STATES.SUCCESS;
        const {
          source_sync_settings,
          destination_sync_settings,
          connection_type,
        } = payload;
        const { data } = state.segment.syncDetails;

        data.source_sync_settings = source_sync_settings;
        data.destination_sync_settings = destination_sync_settings;
        data.connection_type = connection_type;
      })
      .addCase(setSegmentSync.rejected, (state) => {
        state.segment.syncDetails.loading = LOADING_STATES.FAILED;
      })

      //get segment sync details
      .addCase(getSegmentDetails.pending, (state) => {
        state.segment.syncDetails.loading = LOADING_STATES.LOADING;
      })
      .addCase(getSegmentDetails.fulfilled, (state, { payload }) => {
        state.segment.syncDetails.loading = LOADING_STATES.SUCCESS;
        state.segment.syncDetails.data = payload;
      })
      .addCase(getSegmentDetails.rejected, (state) => {
        state.segment.syncDetails.loading = LOADING_STATES.FAILED;
      })

      // create/refresh segment as source, read api key
      .addCase(createSegmentReadApiKey.pending, (state) => {
        state.segment.createSync.readApiKey = initializeLoadingData({});
        state.segment.createSync.readApiKey.loading = LOADING_STATES.LOADING;
      })
      .addCase(createSegmentReadApiKey.fulfilled, (state, action) => {
        state.segment.createSync.readApiKey.loading = LOADING_STATES.SUCCESS;
        state.segment.createSync.readApiKey.data = action.payload.api_key;
      })
      .addCase(createSegmentReadApiKey.rejected, (state) => {
        state.segment.createSync.readApiKey.loading = LOADING_STATES.FAILED;
      })

      // deactivate the segment read api  key
      .addCase(deactivateSegmentReadApiKey.pending, (state) => {
        state.segment.createSync.readApiKey.loading = LOADING_STATES.LOADING;
      })
      .addCase(
        deactivateSegmentReadApiKey.fulfilled,
        (state, { payload, meta }) => {
          state.segment.createSync.readApiKey.loading = LOADING_STATES.SUCCESS;

          if (payload.api_key.state === STATUS.INACTIVE) {
            state.segment.createSync.readApiKey = initializeLoadingData({});
          }
        }
      )
      .addCase(deactivateSegmentReadApiKey.rejected, (state) => {
        state.segment.createSync.readApiKey.loading = LOADING_STATES.FAILED;
      })

      // create salesforce connection
      .addCase(createSalesforceConnection.pending, (state) => {
        state.salesforce.creatingSalesforceConnection = LOADING_STATES.LOADING;
      })
      .addCase(createSalesforceConnection.fulfilled, (state, action) => {
        state.salesforce.creatingSalesforceConnection = LOADING_STATES.SUCCESS;
        state.salesforce.connection = action.payload.connection;
      })
      .addCase(createSalesforceConnection.rejected, (state) => {
        state.salesforce.creatingSalesforceConnection = LOADING_STATES.FAILED;
      })

      // get salesforce login url
      .addCase(getSalesforceLoginUrl.pending, (state) => {
        state.salesforce.getLoginUrl = LOADING_STATES.LOADING;
      })
      .addCase(getSalesforceLoginUrl.fulfilled, (state, action) => {
        state.salesforce.loginUrl = action.payload.url;
        state.salesforce.getLoginUrl = LOADING_STATES.SUCCESS;
      })
      .addCase(getSalesforceLoginUrl.rejected, (state, action) => {
        state.salesforce.getLoginUrl = LOADING_STATES.FAILED;
      })

      // salesforce ouath login
      .addCase(connectSalesforce.pending, (state) => {
        state.salesforce.connecting = LOADING_STATES.LOADING;
      })
      .addCase(connectSalesforce.fulfilled, (state, action) => {
        state.salesforce.connecting = LOADING_STATES.SUCCESS;
        state.salesforce.connection = action.payload.connection;
      })
      .addCase(connectSalesforce.rejected, (state) => {
        state.salesforce.connecting = LOADING_STATES.FAILED;
      })

      // salesforce get details
      .addCase(getSalesforceConnectionDetails.pending, (state) => {
        state.salesforce.fetchingConnection = LOADING_STATES.LOADING;
      })
      .addCase(getSalesforceConnectionDetails.fulfilled, (state, action) => {
        state.salesforce.fetchingConnection = LOADING_STATES.SUCCESS;
        state.salesforce.connection = action.payload.connection;
      })
      .addCase(getSalesforceConnectionDetails.rejected, (state) => {
        state.salesforce.fetchingConnection = LOADING_STATES.FAILED;
      })

      // salesforce update settings
      .addCase(updateSalesforceSettings.pending, (state) => {
        state.salesforce.savingSyncSettings = LOADING_STATES.LOADING;
      })
      .addCase(updateSalesforceSettings.fulfilled, (state, action) => {
        state.salesforce.savingSyncSettings = LOADING_STATES.SUCCESS;
        if (state.salesforce.connection) {
          state.salesforce.connection = action.payload.connection;
          toast.success("Sync updated successfully");
        }
      })
      .addCase(updateSalesforceSettings.rejected, (state) => {
        state.salesforce.savingSyncSettings = LOADING_STATES.FAILED;
      })

      // salesforce update objects
      .addCase(updateSalesforceObjects.pending, (state) => {
        state.salesforce.savingObjectSettings = LOADING_STATES.LOADING;
      })
      .addCase(updateSalesforceObjects.fulfilled, (state, action) => {
        state.salesforce.savingObjectSettings = LOADING_STATES.SUCCESS;
        if (state.salesforce.connection) {
          state.salesforce.connection = action.payload.connection;
        }
        if (action.meta.arg.toast) {
          toast.success("Settings updated successfully");
        }
      })
      .addCase(updateSalesforceObjects.rejected, (state) => {
        state.salesforce.savingObjectSettings = LOADING_STATES.FAILED;
      })

      // salesforce error list
      .addCase(listSalesforceSyncRuns.pending, (state) => {
        state.salesforce.syncRuns.fetchingList = true;
      })
      .addCase(listSalesforceSyncRuns.fulfilled, (state, action) => {
        state.salesforce.syncRuns.fetchingList = false;
        state.salesforce.syncRuns.changingPage = false;
        state.salesforce.syncRuns.list = action.payload.records;
        state.salesforce.syncRuns.totalPageCount = action.payload.page_count;
        state.salesforce.syncRuns.count = action.payload.record_count;
      })
      .addCase(listSalesforceSyncRuns.rejected, (state) => {
        state.salesforce.syncRuns.fetchingList = false;
        state.salesforce.syncRuns.changingPage = false;
      })

      // Recheck permissions
      .addCase(recheckSalesforcePermission.pending, (state) => {
        state.salesforce.recheckingPermissions = LOADING_STATES.LOADING;
      })
      .addCase(recheckSalesforcePermission.fulfilled, (state, action) => {
        state.salesforce.recheckingPermissions = LOADING_STATES.SUCCESS;
        state.salesforce.connection = action.payload.connection;
      })
      .addCase(recheckSalesforcePermission.rejected, (state) => {
        state.salesforce.recheckingPermissions = LOADING_STATES.FAILED;
      })

      // Get sync history details
      .addCase(getSyncHistoryDetails.pending, (state) => {
        state.salesforce.syncHistoryDetails.loading = LOADING_STATES.LOADING;
      })
      .addCase(getSyncHistoryDetails.fulfilled, (state, action) => {
        state.salesforce.syncHistoryDetails.loading = LOADING_STATES.SUCCESS;
        state.salesforce.syncHistoryDetails.data = action.payload;
      })
      .addCase(getSyncHistoryDetails.rejected, (state) => {
        state.salesforce.syncHistoryDetails.loading = LOADING_STATES.FAILED;
      })

      // Pause salesforce sync
      .addCase(pauseSalesforceSync.pending, (state) => {
        state.salesforce.pausingSync = LOADING_STATES.LOADING;
      })
      .addCase(pauseSalesforceSync.fulfilled, (state, action) => {
        state.salesforce.pausingSync = LOADING_STATES.SUCCESS;
        state.salesforce.connection = action.payload.connection;
      })
      .addCase(pauseSalesforceSync.rejected, (state) => {
        state.salesforce.pausingSync = LOADING_STATES.FAILED;
      })

      // Resume salesforce sync
      .addCase(resumeSalesforceSync.pending, (state) => {
        state.salesforce.resumingSync = LOADING_STATES.LOADING;
      })
      .addCase(resumeSalesforceSync.fulfilled, (state, action) => {
        state.salesforce.resumingSync = LOADING_STATES.SUCCESS;
        state.salesforce.connection = action.payload.connection;
        toast.success("Sync resumed successfully");
      })
      .addCase(resumeSalesforceSync.rejected, (state) => {
        state.salesforce.resumingSync = LOADING_STATES.FAILED;
      })

      // Delete salesforce connection
      .addCase(deleteSalesforceConnection.pending, (state) => {
        state.salesforce.deletingConnection = LOADING_STATES.LOADING;
      })
      .addCase(deleteSalesforceConnection.fulfilled, (state, action) => {
        if (action.payload.connection) {
          state.unifiedConnection.connectionList = INITIAL_PAGINATION;
          toast.success("Connection deleted successfully");
          state.salesforce.deletingConnection = LOADING_STATES.SUCCESS;
        } else {
          state.salesforce.deletingConnection = LOADING_STATES.FAILED;
        }
      })
      .addCase(deleteSalesforceConnection.rejected, (state) => {
        state.salesforce.deletingConnection = LOADING_STATES.FAILED;
      })

      //Get Salesforce Api Usage Count
      .addCase(getSalesforceApiUsageCount.pending, (state) => {
        state.salesforce.apiUsageCount.loading = LOADING_STATES.LOADING;
      })
      .addCase(getSalesforceApiUsageCount.fulfilled, (state, action) => {
        state.salesforce.apiUsageCount.loading = LOADING_STATES.SUCCESS;
        state.salesforce.apiUsageCount.data = action.payload.api_usage_count;
      })
      .addCase(getSalesforceApiUsageCount.rejected, (state) => {
        state.salesforce.apiUsageCount.loading = LOADING_STATES.FAILED;
      })

      // List all connection (unified)
      .addCase(getAllConnections.pending, (state) => {
        state.unifiedConnection.connectionList.fetchingList = true;
      })
      .addCase(getAllConnections.fulfilled, (state, action) => {
        state.unifiedConnection.connectionList.list =
          action.payload.connections.filter(
            ({ source, status }) =>
              !(
                (
                  status === STATUS_CONNECTIONS_LIST.DISABLED &&
                  FORM_VENDORS_LIST.includes(source as unknown as FORM_VENDORS)
                )
                // TODO: Hotfix for Forms. Change the unknown type casting in V2.
              )
          );
        state.unifiedConnection.connectionList.totalPageCount = 1;
        state.unifiedConnection.connectionList.count =
          action.payload.connections.length;
        state.unifiedConnection.connectionList.fetchingList = false;
        state.unifiedConnection.connectionList.changingPage = false;
      })
      .addCase(getAllConnections.rejected, (state) => {
        state.unifiedConnection.connectionList.fetchingList = false;
        state.unifiedConnection.connectionList.changingPage = false;
      })

      // Salesforce objects
      .addCase(getSalesforceObjects.pending, (state, action) => {
        state.unifiedConnection.salesforceConnections[action.meta.arg] = {
          ...state.unifiedConnection.salesforceConnections[action.meta.arg],
          ...{ loading: LOADING_STATES.LOADING },
        };
      })
      .addCase(getSalesforceObjects.fulfilled, (state, action) => {
        state.unifiedConnection.salesforceConnections[action.meta.arg] = {
          ...state.unifiedConnection.salesforceConnections[action.meta.arg],
          ...{
            objects: action.payload.salesforce_objects,
            loading: LOADING_STATES.SUCCESS,
          },
        };
      })
      .addCase(getSalesforceObjects.rejected, (state, action) => {
        if (
          action.payload &&
          (action.payload as ErrorPayload).data?.exception ===
            "SalesforceAuthException"
        ) {
          state.unifiedConnection.salesforceConnections[action.meta.arg] = {
            ...state.unifiedConnection.salesforceConnections[action.meta.arg],
            needsReauthentication: true,
          };
        }
        state.unifiedConnection.salesforceConnections[action.meta.arg] = {
          ...state.unifiedConnection.salesforceConnections[action.meta.arg],
          ...{ loading: LOADING_STATES.FAILED },
        };
      })

      // Salesforce settings details
      .addCase(
        getSfConnectionDetailsForUnifiedMapping.pending,
        (state, action) => {
          state.unifiedConnection.salesforceConnections[action.meta.arg] = {
            ...state.unifiedConnection.salesforceConnections[action.meta.arg],
            ...{ loading: LOADING_STATES.LOADING },
          };
        }
      )
      .addCase(
        getSfConnectionDetailsForUnifiedMapping.fulfilled,
        (state, action) => {
          state.unifiedConnection.salesforceConnections[action.meta.arg] = {
            ...state.unifiedConnection.salesforceConnections[action.meta.arg],
            ...{
              details: action.payload.connection,
              loading: LOADING_STATES.SUCCESS,
            },
          };
        }
      )
      .addCase(
        getSfConnectionDetailsForUnifiedMapping.rejected,
        (state, action) => {
          state.unifiedConnection.salesforceConnections[action.meta.arg] = {
            ...state.unifiedConnection.salesforceConnections[action.meta.arg],
            ...{ loading: LOADING_STATES.FAILED },
          };
        }
      )

      // Salesforce get unified mapping
      .addCase(getUnifiedMapping.pending, (state) => {
        state.unifiedConnection.mapping.loading = LOADING_STATES.LOADING;
      })
      .addCase(getUnifiedMapping.fulfilled, (state, action) => {
        state.unifiedConnection.mapping.loading = LOADING_STATES.SUCCESS;
        state.unifiedConnection.mapping.data = action.payload.mapping;
      })
      .addCase(getUnifiedMapping.rejected, (state) => {
        state.unifiedConnection.mapping.loading = LOADING_STATES.FAILED;
      })

      // Salesforce update unified mapping
      .addCase(updateUnifiedMappingCrm.pending, (state) => {
        state.unifiedConnection.updatingMapping = LOADING_STATES.LOADING;
      })
      .addCase(updateUnifiedMappingCrm.fulfilled, (state, action) => {
        state.unifiedConnection.updatingMapping = LOADING_STATES.SUCCESS;
        state.unifiedConnection.mapping.data = action.payload.mapping;
        toast.success("Updated mapping successfully");
      })
      .addCase(updateUnifiedMappingCrm.rejected, (state) => {
        state.unifiedConnection.updatingMapping = LOADING_STATES.FAILED;
      })

      .addCase(updateUnifiedMappingAccount.pending, (state) => {
        state.unifiedConnection.updatingMapping = LOADING_STATES.LOADING;
      })
      .addCase(updateUnifiedMappingAccount.fulfilled, (state, action) => {
        state.unifiedConnection.updatingMapping = LOADING_STATES.SUCCESS;
        state.unifiedConnection.mapping.data = action.payload.mapping;
        toast.success("Updated mapping successfully");
      })
      .addCase(updateUnifiedMappingAccount.rejected, (state) => {
        state.unifiedConnection.updatingMapping = LOADING_STATES.FAILED;
      })

      .addCase(updateSelectiveSync.pending, (state) => {
        state.salesforce.savingSelectiveSync = LOADING_STATES.LOADING;
      })
      .addCase(updateSelectiveSync.fulfilled, (state, action) => {
        state.salesforce.savingSelectiveSync = LOADING_STATES.SUCCESS;
        if (state.salesforce.connection) {
          state.salesforce.connection.selective_sync =
            action.payload.connection.selective_sync;
        }
        toast.success("Updated sync successfully");
      })
      .addCase(updateSelectiveSync.rejected, (state) => {
        state.salesforce.savingSelectiveSync = LOADING_STATES.FAILED;
      })

      .addCase(setDefaultMappingCrm.pending, (state) => {
        state.unifiedConnection.automapping = LOADING_STATES.LOADING;
      })
      .addCase(setDefaultMappingCrm.fulfilled, (state, action) => {
        state.unifiedConnection.automapping = LOADING_STATES.SUCCESS;
        state.unifiedConnection.mapping.data = action.payload.mapping;
        toast.success("Updated mapping successfully");
      })
      .addCase(setDefaultMappingCrm.rejected, (state) => {
        state.unifiedConnection.automapping = LOADING_STATES.FAILED;
      })

      .addCase(setDefaultMappingAccount.pending, (state) => {
        state.unifiedConnection.automapping = LOADING_STATES.LOADING;
      })
      .addCase(setDefaultMappingAccount.fulfilled, (state, action) => {
        state.unifiedConnection.automapping = LOADING_STATES.SUCCESS;
        state.unifiedConnection.mapping.data = action.payload.mapping;
        toast.success("Updated mapping successfully");
      })
      .addCase(setDefaultMappingAccount.rejected, (state) => {
        state.unifiedConnection.automapping = LOADING_STATES.FAILED;
      })

      .addCase(completeSetup.pending, (state) => {
        state.salesforce.savingSetup = LOADING_STATES.LOADING;
      })
      .addCase(completeSetup.fulfilled, (state, action) => {
        state.salesforce.savingSetup = LOADING_STATES.SUCCESS;
        state.salesforce.connection = action.payload.connection;
        toast.success("Setup completed successfully");
      })
      .addCase(completeSetup.rejected, (state) => {
        state.salesforce.savingSetup = LOADING_STATES.FAILED;
      })

      .addCase(updateInclusionList.pending, (state) => {
        state.salesforce.savingInclusionList = LOADING_STATES.LOADING;
      })
      .addCase(updateInclusionList.fulfilled, (state, action) => {
        state.salesforce.savingInclusionList = LOADING_STATES.SUCCESS;
        state.salesforce.connection = action.payload.connection;
        toast.success("Updated inclusion list successfully");
      })
      .addCase(updateInclusionList.rejected, (state) => {
        state.salesforce.savingInclusionList = LOADING_STATES.FAILED;
      })

      .addCase(removeInclusionList.pending, (state) => {
        state.salesforce.savingInclusionList = LOADING_STATES.LOADING;
      })
      .addCase(removeInclusionList.fulfilled, (state, action) => {
        state.salesforce.savingInclusionList = LOADING_STATES.SUCCESS;
        state.salesforce.connection = action.payload.connection;
        toast.success("Removed inclusion list successfully");
      })
      .addCase(removeInclusionList.rejected, (state) => {
        state.salesforce.savingInclusionList = LOADING_STATES.FAILED;
      })

      .addCase(updateSalesforceRecordCreate.pending, (state) => {
        state.salesforce.savingRecordCreateSettings = LOADING_STATES.LOADING;
      })
      .addCase(updateSalesforceRecordCreate.fulfilled, (state, action) => {
        state.salesforce.savingRecordCreateSettings = LOADING_STATES.SUCCESS;
        state.salesforce.connection = action.payload.connection;
        toast.success("Updated settings successfully");
      })
      .addCase(updateSalesforceRecordCreate.rejected, (state) => {
        state.salesforce.savingRecordCreateSettings = LOADING_STATES.FAILED;
      })

      .addCase(updateSalesforceRecordDelete.pending, (state) => {
        state.salesforce.savingRecordDeleteSettings = LOADING_STATES.LOADING;
      })
      .addCase(updateSalesforceRecordDelete.fulfilled, (state, action) => {
        state.salesforce.savingRecordDeleteSettings = LOADING_STATES.SUCCESS;
        state.salesforce.connection = action.payload.connection;
        toast.success("Updated settings successfully");
      })
      .addCase(updateSalesforceRecordDelete.rejected, (state) => {
        state.salesforce.savingRecordDeleteSettings = LOADING_STATES.FAILED;
      })

      .addCase(updateInflectionRecordCreate.pending, (state) => {
        state.salesforce.savingInflectionRecordCreateSettings =
          LOADING_STATES.LOADING;
      })
      .addCase(updateInflectionRecordCreate.fulfilled, (state, action) => {
        state.salesforce.savingInflectionRecordCreateSettings =
          LOADING_STATES.SUCCESS;
        state.salesforce.connection = action.payload.connection;
        toast.success("Updated settings successfully");
      })
      .addCase(updateInflectionRecordCreate.rejected, (state) => {
        state.salesforce.savingInflectionRecordCreateSettings =
          LOADING_STATES.FAILED;
      })

      .addCase(updateInflectionRecordDelete.pending, (state) => {
        state.salesforce.savingInflectionRecordDeleteSettings =
          LOADING_STATES.LOADING;
      })
      .addCase(updateInflectionRecordDelete.fulfilled, (state, action) => {
        state.salesforce.savingInflectionRecordDeleteSettings =
          LOADING_STATES.SUCCESS;
        state.salesforce.connection = action.payload.connection;
        toast.success("Updated settings successfully");
      })
      .addCase(updateInflectionRecordDelete.rejected, (state) => {
        state.salesforce.savingInflectionRecordDeleteSettings =
          LOADING_STATES.FAILED;
      })

      //Salesforce Sync errors
      .addCase(listSalesforceSyncErrors.pending, (state) => {
        state.salesforce.fetchingErrorList = LOADING_STATES.LOADING;
      })
      .addCase(listSalesforceSyncErrors.fulfilled, (state, action) => {
        state.salesforce.fetchingErrorList = LOADING_STATES.SUCCESS;
        state.salesforce.syncErrors[action.meta.arg.errorType] = {
          ...state.salesforce.syncErrors[action.meta.arg.errorType],
          ...{
            list: action.payload.records,
            totalPageCount: action.payload.page_count,
            count: action.payload.record_count,
            currentPageNo: action.meta.arg.pageNumber,
            pageSize: action.meta.arg.pageSize,
          },
        };
      })
      .addCase(listSalesforceSyncErrors.rejected, (state) => {
        state.salesforce.fetchingErrorList = LOADING_STATES.FAILED;
      })

      //Salesforce Sync error types
      .addCase(listSalesforceSyncErrorsTypes.pending, (state) => {
        state.salesforce.sfErrorTypes.loading = LOADING_STATES.LOADING;
      })
      .addCase(listSalesforceSyncErrorsTypes.fulfilled, (state, action) => {
        state.salesforce.sfErrorTypes.loading = LOADING_STATES.SUCCESS;
        state.salesforce.sfErrorTypes.data = action.payload.error_types;
      })
      .addCase(listSalesforceSyncErrorsTypes.rejected, (state) => {
        state.salesforce.sfErrorTypes.loading = LOADING_STATES.FAILED;
      })

      //Salesforce Start
      .addCase(startSalesforceSync.pending, (state) => {
        state.salesforce.startedSync = LOADING_STATES.LOADING;
      })
      .addCase(startSalesforceSync.fulfilled, (state, action) => {
        state.salesforce.startedSync = LOADING_STATES.SUCCESS;
        state.salesforce.connection = action.payload.connection;
        toast.success("Sync started successfully");
      })
      .addCase(startSalesforceSync.rejected, (state) => {
        state.salesforce.startedSync = LOADING_STATES.FAILED;
      })

      .addCase(getSalesforceErrorQuery.pending, (state) => {
        state.salesforce.sfAffectedRecords.fetchingList = true;
      })
      .addCase(getSalesforceErrorQuery.fulfilled, (state, action) => {
        state.salesforce.sfAffectedRecords.list = action.payload.errors.records;
        state.salesforce.sfAffectedRecords.totalPageCount =
          action.payload.errors.page_count;
        state.salesforce.sfAffectedRecords.count =
          action.payload.errors.record_count;
        state.salesforce.sfAffectedRecords.fetchingList = false;
        state.salesforce.sfAffectedRecords.changingPage = false;
      })
      .addCase(getSalesforceErrorQuery.rejected, (state) => {
        state.salesforce.sfAffectedRecords.fetchingList = false;
        state.salesforce.sfAffectedRecords.totalPageCount = 0;
        state.salesforce.sfAffectedRecords.changingPage = false;
      })

      .addCase(archiveSalesforceSyncError.pending, (state) => {
        state.salesforce.archivingSalesforceSyncError = LOADING_STATES.LOADING;
      })
      .addCase(archiveSalesforceSyncError.fulfilled, (state, action) => {
        state.salesforce.archivingSalesforceSyncError = LOADING_STATES.SUCCESS;
        state.salesforce.archivedRecordCount = action.payload.updated_count;
        toast.success("Errors successfully archived");
      })
      .addCase(archiveSalesforceSyncError.rejected, (state) => {
        state.salesforce.archivingSalesforceSyncError = LOADING_STATES.FAILED;
      })

      .addCase(resyncSalesforceSyncError.pending, (state) => {
        state.salesforce.resyncingSalesforceSyncError = LOADING_STATES.LOADING;
      })
      .addCase(resyncSalesforceSyncError.fulfilled, (state, action) => {
        state.salesforce.resyncingSalesforceSyncError = LOADING_STATES.SUCCESS;
        state.salesforce.isResyncSuccess = action.payload.success;
        toast.success("Contacts successfully resynced");
      })
      .addCase(resyncSalesforceSyncError.rejected, (state) => {
        state.salesforce.resyncingSalesforceSyncError = LOADING_STATES.FAILED;
      })

      .addCase(getSegmentEventStatus.pending, (state) => {
        state.productDiscovery.segmentEventStatus.loading =
          LOADING_STATES.LOADING;
      })
      .addCase(getSegmentEventStatus.fulfilled, (state, action) => {
        state.productDiscovery.segmentEventStatus.data =
          action.payload.segment_event_status;
        state.productDiscovery.segmentEventStatus.loading =
          LOADING_STATES.SUCCESS;
      })
      .addCase(getSegmentEventStatus.rejected, (state) => {
        state.productDiscovery.segmentEventStatus.loading =
          LOADING_STATES.FAILED;
      })

      .addCase(getSegmentTrackEventStatus.pending, (state) => {
        state.productDiscovery.segmentEventStatusTrack.loading =
          LOADING_STATES.LOADING;
      })
      .addCase(getSegmentTrackEventStatus.fulfilled, (state, action) => {
        state.productDiscovery.segmentEventStatusTrack.data = action.payload;
        state.productDiscovery.segmentEventStatusTrack.loading =
          LOADING_STATES.SUCCESS;
      })
      .addCase(getSegmentTrackEventStatus.rejected, (state) => {
        state.productDiscovery.segmentEventStatusTrack.loading =
          LOADING_STATES.FAILED;
      })

      .addCase(getSegmentNonTrackEventStatus.pending, (state) => {
        state.productDiscovery.segmentEventStatusNonTrack.loading =
          LOADING_STATES.LOADING;
      })
      .addCase(getSegmentNonTrackEventStatus.fulfilled, (state, action) => {
        state.productDiscovery.segmentEventStatusNonTrack.data = action.payload;
        state.productDiscovery.segmentEventStatusNonTrack.loading =
          LOADING_STATES.SUCCESS;
      })
      .addCase(getSegmentNonTrackEventStatus.rejected, (state) => {
        state.productDiscovery.segmentEventStatusNonTrack.loading =
          LOADING_STATES.FAILED;
      })

      .addCase(updateSegmentDescription.pending, (state) => {
        state.productDiscovery.updatingDescription = LOADING_STATES.LOADING;
      })
      .addCase(updateSegmentDescription.fulfilled, (state, action) => {
        state.productDiscovery.updatingDescription = action.payload.success
          ? LOADING_STATES.SUCCESS
          : LOADING_STATES.FAILED;
      })
      .addCase(updateSegmentDescription.rejected, (state) => {
        state.productDiscovery.updatingDescription = LOADING_STATES.FAILED;
      })

      .addCase(toggleSegmentEvent.pending, (state) => {
        state.productDiscovery.togglingEvent = LOADING_STATES.LOADING;
      })
      .addCase(toggleSegmentEvent.fulfilled, (state, action) => {
        state.productDiscovery.togglingEvent = action.payload.status
          ? LOADING_STATES.SUCCESS
          : LOADING_STATES.FAILED;
      })
      .addCase(toggleSegmentEvent.rejected, (state) => {
        state.productDiscovery.togglingEvent = LOADING_STATES.FAILED;
      })

      .addCase(toggleSegmentEventProperty.pending, (state) => {
        state.productDiscovery.togglingProperty = LOADING_STATES.LOADING;
      })
      .addCase(toggleSegmentEventProperty.fulfilled, (state, action) => {
        state.productDiscovery.togglingProperty = action.payload.status
          ? LOADING_STATES.SUCCESS
          : LOADING_STATES.FAILED;
      })
      .addCase(toggleSegmentEventProperty.rejected, (state) => {
        state.productDiscovery.togglingProperty = LOADING_STATES.FAILED;
      })

      .addCase(toggleBypassProperties.pending, (state) => {
        state.productDiscovery.togglingBypass = LOADING_STATES.LOADING;
      })
      .addCase(toggleBypassProperties.fulfilled, (state, action) => {
        state.productDiscovery.togglingBypass = action.payload.status
          ? LOADING_STATES.SUCCESS
          : LOADING_STATES.FAILED;
      })
      .addCase(toggleBypassProperties.rejected, (state) => {
        state.productDiscovery.togglingBypass = LOADING_STATES.FAILED;
      })

      .addCase(getAccountContactAutoMapping.pending, (state) => {
        state.accountContactAutoMapping.fetchingMapping =
          LOADING_STATES.LOADING;
      })
      .addCase(getAccountContactAutoMapping.fulfilled, (state, action) => {
        state.accountContactAutoMapping.mapping = action.payload.setting_value;
        state.accountContactAutoMapping.fetchingMapping =
          LOADING_STATES.SUCCESS;
      })
      .addCase(getAccountContactAutoMapping.rejected, (state) => {
        state.accountContactAutoMapping.fetchingMapping = LOADING_STATES.FAILED;
      })

      .addCase(updateAccountContactAutoMapping.pending, (state) => {
        state.accountContactAutoMapping.updatingMapping =
          LOADING_STATES.LOADING;
      })
      .addCase(updateAccountContactAutoMapping.fulfilled, (state, action) => {
        state.accountContactAutoMapping.updatingMapping =
          LOADING_STATES.SUCCESS;
        state.accountContactAutoMapping.mapping = action.payload;
        toast.success("Updated mapping successfully");
      })
      .addCase(updateAccountContactAutoMapping.rejected, (state) => {
        state.accountContactAutoMapping.updatingMapping = LOADING_STATES.FAILED;
      })

      .addCase(deleteAccountContactAutoMapping.pending, (state) => {
        state.accountContactAutoMapping.deletingMapping =
          LOADING_STATES.LOADING;
      })
      .addCase(deleteAccountContactAutoMapping.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.accountContactAutoMapping.deletingMapping =
            LOADING_STATES.SUCCESS;
          toast.success("Deleted mapping successfully");
        } else {
          state.accountContactAutoMapping.deletingMapping =
            LOADING_STATES.FAILED;
        }
      })
      .addCase(deleteAccountContactAutoMapping.rejected, (state) => {
        state.accountContactAutoMapping.deletingMapping = LOADING_STATES.FAILED;
      })

      // List DW destination columns
      .addCase(listDwDestinationColumns.pending, (state, action) => {
        state.unifiedConnection.dwColumnList[action.meta.arg.connectionId] = {
          loading: LOADING_STATES.LOADING,
          data: [],
        };
      })
      .addCase(listDwDestinationColumns.fulfilled, (state, action) => {
        state.unifiedConnection.dwColumnList[action.meta.arg.connectionId] = {
          loading: LOADING_STATES.SUCCESS,
          data: action.payload.columns,
        };
      })
      .addCase(listDwDestinationColumns.rejected, (state, action) => {
        state.unifiedConnection.dwColumnList[action.meta.arg.connectionId] = {
          loading: LOADING_STATES.FAILED,
          data: [],
        };
      })

      // List Salesforce connections
      .addCase(getSalesforceConnectionList.pending, (state, action) => {
        state.salesforce.connectionList = {
          loading: LOADING_STATES.LOADING,
          data: [],
        };
      })
      .addCase(getSalesforceConnectionList.fulfilled, (state, action) => {
        state.salesforce.connectionList = {
          loading: LOADING_STATES.SUCCESS,
          data: action.payload.records,
        };
      })
      .addCase(getSalesforceConnectionList.rejected, (state, action) => {
        state.salesforce.connectionList = {
          loading: LOADING_STATES.FAILED,
          data: [],
        };
      })
      // Get already mapped columns
      .addCase(getMappedColumns.pending, (state, action) => {
        state.unifiedConnection.mappedColumns = {
          loading: LOADING_STATES.LOADING,
          data: {},
        };
      })
      .addCase(getMappedColumns.fulfilled, (state, action) => {
        state.unifiedConnection.mappedColumns = {
          loading: LOADING_STATES.SUCCESS,
          data: action.payload.column_to_assets_mapping,
        };
      })
      .addCase(getMappedColumns.rejected, (state, action) => {
        state.unifiedConnection.mappedColumns = {
          loading: LOADING_STATES.FAILED,
          data: {},
        };
      })

      // Get salesforce campaign sync errors
      .addCase(getSalesforceCampaignSyncErrors.pending, (state, action) => {
        state.salesforce.fetchingErrorList = LOADING_STATES.LOADING;
      })
      .addCase(getSalesforceCampaignSyncErrors.fulfilled, (state, action) => {
        state.salesforce.fetchingErrorList = LOADING_STATES.SUCCESS;
        if (action.payload.data?.length) {
          state.salesforce.campaignSyncErrors = action.payload.data;
        } else {
          state.salesforce.campaignSyncErrors = null;
        }
      })
      .addCase(getSalesforceCampaignSyncErrors.rejected, (state, action) => {
        state.salesforce.fetchingErrorList = LOADING_STATES.FAILED;
        state.salesforce.campaignSyncErrors = null;
      });
  },
});

export const {
  wizardNextStep,
  wizardPrevStep,
  wizardSetStep,
  resetFlags,
  setSelectedSchema,
  setSelectedService,
  removeFromTableList,
  addToTableList,
  toggleTableColumnSelection,
  removeFromColumnList,
  selectAllColumnsInTableToggle,
  setSyncRunsPageNo,
  setAllConnectionsPageNo,
  clearConnectionDetailsData,
  setIsReadOnly,
  setHostName,
  setConnectionDetails,
  setActivityLogPage,
  setConnectionsPageNo,
  setSegmentConnectionType,
  setSegmentDestinationKey,
  setSegmentSourceErrorPage,
  setSegmentDestErrorPage,
  resetSegmentSyncData,
  setSalesforceSyncHistoryPage,
  resetSalesforceData,
  setSalesforceErrorModalPage,
} = connectionSlice.actions;

export const selectConnection = (state: RootState) => state.connection;

export default connectionSlice.reducer;
