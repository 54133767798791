import { Flex, FormControl, FormErrorMessage } from "@chakra-ui/react";
import { memo, useCallback, useContext, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { NodeProps } from "reactflow";
import { ASSET_TYPES } from "../../../../../../common/constants/common";
import {
  isLoading,
  openAssetInNewTab,
} from "../../../../../../common/helper/commonHelper";
import { FlowStaticListActionOptions } from "../../../../../../common/types/campaign";
import { ReactSelectDefaultOptionsWithClick } from "../../../../../../common/types/common";
import { ActionNodeArgs } from "../../../../../../common/types/flow";
import DropdownWithSearch from "../../../../../../components/DropdownWithSearch";
import { AssetPreviewComponent } from "../../../../../../components/OptionHelper";
import { useAppDispatch } from "../../../../../../store";
import {
  listStaticListSummaryList,
  selectStaticList,
} from "../../../../staticlist/staticlistSlice";
import {
  CampaignBuilderContext,
  selectFlow,
  setFlowValidity,
} from "../../flowSlice";
import { WIDGET_OPTIONS_DETAILS } from "../constants";
import { isActionDataSame } from "../helpers";
import WidgetContainer from "../WidgetContainer";

function StaticListWidget({
  data: { action, groupId, isCandidate, props, selectedExit, selectedGoto },
}: NodeProps<ActionNodeArgs>) {
  const { activeErrorCheck } = useContext(CampaignBuilderContext);
  const { staticListSummaryList } = useSelector(selectStaticList);
  const { flowValidity } = useSelector(selectFlow);

  const dispatch = useAppDispatch();
  const { saveDraft, setActions, readonly } = props;
  const identities = useMemo(() => {
    return {
      actionId: action.action_id,
      groupId,
      branchId: action.branch_id,
    };
  }, [action, groupId]);
  const actionOptions = useMemo(
    () => action.action_options as FlowStaticListActionOptions,
    [action]
  );

  const setValidityCallback = useCallback(
    (valid: boolean) => {
      dispatch(setFlowValidity({ [identities.actionId]: valid }));
    },
    [dispatch, identities.actionId]
  );

  function setOptions(options: FlowStaticListActionOptions) {
    setActions(options, identities.actionId, groupId);
    saveDraft(options, { actionId: identities.actionId, groupId });
  }

  useEffect(() => {
    dispatch(listStaticListSummaryList());
  }, [dispatch]);

  useEffect(() => {
    setValidityCallback(!!actionOptions.static_list_id);
  }, [actionOptions, setValidityCallback]);

  function handleOptionChange(value: string | null) {
    setOptions({
      static_list_id: value,
    });
    setValidityCallback(!!value);
  }

  const staticLists: ReactSelectDefaultOptionsWithClick[] = useMemo(
    () =>
      staticListSummaryList.data.map((staticList) => {
        return {
          label: staticList.name,
          value: staticList.static_list_id,
          onPreview: () =>
            openAssetInNewTab(
              ASSET_TYPES.STATIC_LIST,
              staticList.static_list_id
            ),
        };
      }),
    [staticListSummaryList.data]
  );

  const optionDetails = useMemo(
    () => WIDGET_OPTIONS_DETAILS[action.action_type],
    [action.action_type]
  );

  return (
    <WidgetContainer
      invalidMessage={
        flowValidity[identities.actionId] ? "" : "Static list is required"
      }
      identities={identities}
      title={optionDetails.label}
      icon={optionDetails.icon}
      color={optionDetails.color}
      isCandidate={isCandidate}
      selectedExit={selectedExit}
      selectedGoto={selectedGoto}
      isDisabled={readonly}
    >
      <Flex
        justifyContent="center"
        alignItems="center"
        h="100%"
        w="100%"
        px="5"
        py="8"
      >
        <FormControl
          isInvalid={activeErrorCheck && !flowValidity[identities.actionId]}
        >
          <DropdownWithSearch
            options={staticLists}
            placeholder="Select static list"
            value={staticLists.find(
              (option) => option.value === actionOptions.static_list_id
            )}
            onChange={(option) => handleOptionChange(option?.value ?? null)}
            isInvalid={activeErrorCheck && !flowValidity[identities.actionId]}
            maxMenuHeight={300}
            isDisabled={readonly}
            isLoading={isLoading(staticListSummaryList.loading)}
            isSearchable
            menuPortalTarget={null}
            components={{
              Option: AssetPreviewComponent<ReactSelectDefaultOptionsWithClick>,
            }}
          />
          <FormErrorMessage>Static list is required</FormErrorMessage>
        </FormControl>
      </Flex>
    </WidgetContainer>
  );
}

export default memo(StaticListWidget, isActionDataSame);
