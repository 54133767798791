import {
  SalesforceCampaignBasic,
  SalesforceCampaign,
  SalesforceCampaignStatus,
} from "../../types/campaign";
import { SfCampaignSyncError } from "../../types/connection";
import { sfIntegrationsRequest } from "./api";

interface Error {
  errorCode: string;
  message: string;
  detail: string;
}

interface Meta {
  status: string;
  timestamp: string;
}

interface Pagination {
  pageNumber: number;
  pageSize: number;
  totalElements: number;
  totalPages: number;
}

interface InflectionResponse<T> {
  data: T;
  meta: Meta;
  pagination?: Pagination;
  errors?: Error[];
}

export async function getSalesforceCampaignsApi(
  forceRefresh: boolean = false
): Promise<InflectionResponse<SalesforceCampaignBasic[]>> {
  return sfIntegrationsRequest({
    method: "GET",
    url: "/salesforce/campaigns",
    isErrorHandled: true,
    params: { forceRefresh },
  });
}

export async function getSalesforceCampaignDetailsApi(
  id: string,
  forceRefresh: boolean = false
): Promise<InflectionResponse<SalesforceCampaign>> {
  return sfIntegrationsRequest({
    method: "GET",
    isErrorHandled: true,
    url: "/salesforce/campaigns",
    params: { id, forceRefresh },
  });
}

export async function getSalesforceCampaignStatusApi(
  forceRefresh: boolean = false
): Promise<InflectionResponse<SalesforceCampaignStatus[]>> {
  return sfIntegrationsRequest({
    method: "GET",
    url: "/salesforce/campaign-members/statuses",
    isErrorHandled: true,
    params: { forceRefresh },
  });
}

export async function getSalesforceCampaignSyncErrorsApi(
  startTimestamp: number,
  endTimestamp: number
): Promise<InflectionResponse<SfCampaignSyncError[]>> {
  return sfIntegrationsRequest({
    method: "GET",
    url: "/salesforce/campaign-members/sync-errors",
    params: {
      startTimestamp,
      endTimestamp,
    },
  });
}
