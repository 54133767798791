import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Flex,
  Heading,
  HStack,
  Icon,
  ListIcon,
  Stack,
  Text,
  UnorderedList,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { IconType } from "react-icons";
import {
  FaArrowRight,
  FaExclamationTriangle,
  FaMinusCircle,
  FaPauseCircle,
  FaPlayCircle,
} from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { LOADING_STATES } from "../../../../common/constants/common";
import { TAB } from "../../../../common/constants/unifiedMapping";
import {
  isFulfilled,
  isInit,
  isLoading,
  isSuccess,
  useQuery,
} from "../../../../common/helper/commonHelper";
import { LoadingWithData } from "../../../../common/types/common";
import { SalesforceConnection } from "../../../../common/types/connection";
import { Mapping } from "../../../../common/types/unifiedMapping";
import IButton from "../../../../components/IButton";
import IModal from "../../../../components/IModal";
import IPopoverButton from "../../../../components/IPopoverButton";
import SideBar from "../../../../components/SideBar";
import SpinnerContainer from "../../../../components/SpinnerContainer";
import ContentContainer from "../../../../components/v2/ContentContainer";
import ContentContainerWithHeader from "../../../../components/v2/ContentContainerWithHeader";
import SecondarySidebar from "../../../../components/v2/SecondarySidebar";
import SidebarBackButton from "../../../../components/v2/SidebarBackButton";
import { useAppDispatch } from "../../../../store";
import urls from "../../../../urls";
import {
  deleteSalesforceConnection,
  getSalesforceConnectionDetails,
  getSalesforceLoginUrl,
  getUnifiedMapping,
  pauseSalesforceSync,
  resetSalesforceData,
  resumeSalesforceSync,
  selectConnection,
  startSalesforceSync,
} from "../connectionSlice";
import SalesforceOverrideModal from "./components/SalesforceOverrideModal";
import SalesforcePermissionsModal from "./components/SalesforcePermissionsModal";
import SalesforceSettingsModal from "./components/SalesforceSettingsModal";
import SalesforceSummaryModal from "./components/SalesforceSummaryModal";
import SalesforceSyncDetails from "./components/SalesforceSyncDetails";
import SalesforceSyncErrors from "./components/SalesforceSyncErrors";
import SalesforceSyncHealthDetails from "./components/SalesforceSyncHealthDetails";
import SalesforceSyncHistory from "./components/SalesforceSyncHistory";
import InflectionToSalesforce from "./components/syncSettings/components/InflectionToSalesforce";
import SalesforceGeneralSettings from "./components/syncSettings/components/SalesforceGeneralSettings";
import SalesforceToInflection from "./components/syncSettings/components/SalesforceToInflection";
import { SF_REAUTHENTICATION } from "../../../../common/constants/connection";
import { ConfirmationModal } from "../../../../components/ConfirmationModal";
import { redirectToSalesforceLoginPage } from "../../../../common/helper/salesforceHelper";

function SyncStatusBanner({ resumeSync }: { resumeSync: () => void }) {
  return (
    <Box
      border="1px"
      borderColor="gray.100"
      bg="blue.50"
      px="6"
      py="5"
      borderRadius="xl"
    >
      <Stack direction="row" justify="space-between" spacing="2" pb="0.5">
        <Stack fontSize="sm" align="center" direction="row" color="gray.800">
          <Icon color="gray.900" as={FaExclamationTriangle} />
          <Text fontWeight="semibold">Salesforce connection is paused.</Text>
          <Text>
            The connection between Inflection and Salesforce is currently
            paused. No data is being exchanged.
          </Text>
        </Stack>
        <Box>
          <IButton
            _hover={{ bg: "brand.blue", color: "white" }}
            colorScheme="blue"
            variant="ghost"
            leftIcon={<FaPlayCircle />}
            onClick={resumeSync}
          >
            Resume sync
          </IButton>
        </Box>
      </Stack>
    </Box>
  );
}

function SyncDirectionSideBarItem({
  icon,
  fromText,
  toText,
}: {
  icon: IconType;
  fromText: string;
  toText: string;
}) {
  return (
    <HStack>
      <Text>{fromText}</Text> <Icon as={icon} fontSize="xs"></Icon>
      <Text>{toText}</Text>
    </HStack>
  );
}

const SIDEBAR_ITEMS = ["Sync History", "Sync Health"];
const SETTINGS_SIDEBAR_ITEMS = [
  `General Settings`,
  <SyncDirectionSideBarItem
    icon={FaArrowRight}
    fromText="Salesforce"
    toText="Inflection"
  />,
  <SyncDirectionSideBarItem
    icon={FaArrowRight}
    fromText="Inflection"
    toText="Salesforce"
  />,
];

function ListItemCustom({
  children,
  iconSelector,
}: {
  children: ReactNode;
  iconSelector?: boolean;
}) {
  return (
    <Flex>
      <Box>
        <ListIcon
          as={FaMinusCircle}
          color={iconSelector ? "black" : "red.400"}
        />
      </Box>
      <Box>{children}</Box>
    </Flex>
  );
}

function ConfirmationDialogBox({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    salesforce: { deletingConnection },
  } = useSelector(selectConnection);

  async function handleDisconnect() {
    const result = await dispatch(deleteSalesforceConnection());
    if (isFulfilled(result.meta.requestStatus)) {
      navigate(urls.unifiedMapping);
    }
  }

  return (
    <IModal
      isOpen={isOpen}
      onClose={onClose}
      size="lg"
      header={{
        title: `Disconnect Salesforce`,
      }}
      deleteButton={{
        label: "Disconnect",
        props: {
          onClick: handleDisconnect,
          isLoading: isLoading(deletingConnection),
        },
      }}
      secondaryButton={{
        label: "Cancel",
        props: {
          onClick: onClose,
          isDisabled: isLoading(deletingConnection),
        },
      }}
      modalBodyProps={{ p: 0 }}
    >
      <Alert status="error" fontSize="sm" mb="4">
        <AlertIcon />
        <AlertDescription>
          Do you want to disconnect Salesforce from Inflection?
        </AlertDescription>
      </Alert>
      <VStack alignItems="flex-start" spacing="8" px="5" pb="5">
        <Box>
          <Heading fontSize="sm" mb="2">
            The following will be removed:
          </Heading>
          <UnorderedList alignItems="flex-start" fontSize="sm">
            <ListItemCustom>Salesforce integration settings</ListItemCustom>
            <ListItemCustom>Contact field mappings</ListItemCustom>
            <ListItemCustom>Account field mappings</ListItemCustom>
            <ListItemCustom>Opportunity field mappings</ListItemCustom>
            <ListItemCustom>
              Custom object syncs, including settings and fields mappings
            </ListItemCustom>
          </UnorderedList>
        </Box>

        <Box>
          <Heading fontSize="sm" mb="2">
            The following will not be removed:
          </Heading>
          <UnorderedList fontSize="sm">
            <ListItemCustom iconSelector={true}>
              Inflection contacts created from Salesforce leads or contacts
            </ListItemCustom>
            <ListItemCustom iconSelector={true}>
              Inflection companies created by Salesforce accounts
            </ListItemCustom>
            <ListItemCustom iconSelector={true}>
              Inflection deals created by Salesforce opportunities
            </ListItemCustom>
            <ListItemCustom iconSelector={true}>
              Inflection property values added from Salesforce field mapping
            </ListItemCustom>
          </UnorderedList>
        </Box>
      </VStack>
    </IModal>
  );
}

function Header({
  connection,
  pauseSync,
  startSync,
  isPausing,
  disconnect,
  mapping,
  startedSync,
}: {
  connection: SalesforceConnection | null;
  pauseSync: () => void;
  startSync: () => void;
  isPausing: boolean;
  disconnect: () => void;
  mapping: LoadingWithData<Mapping | null>;
  startedSync: LOADING_STATES;
}) {
  const isStartButtonDisabled = useMemo(() => {
    return !mapping.data?.person_mapping?.mapping.some(
      (value) => value.source !== null
    );
  }, [mapping.data?.person_mapping?.mapping]);

  const popoverBodyComponent = (
    <Text fontSize="sm">
      You can start the sync only after you have mapped at least 1 Salesforce
      and Inflection field in the unified mapping{" "}
      <Link to={`${urls.unifiedMapping}?${TAB}=2`}>
        <Text
          as="span"
          textDecoration="underline"
          fontWeight="semibold"
          fontSize="sm"
        >
          Click Here
        </Text>
      </Link>
    </Text>
  );

  return (
    <HStack minH="45px" maxH="45px" flex={1} justifyContent="flex-end">
      <IPopoverButton
        colorScheme="blue"
        bg="brand.blue"
        isLoading={
          connection?.is_sync_enabled
            ? isPausing
            : isLoading(startedSync) || isLoading(mapping.loading)
        }
        leftIcon={
          connection?.is_sync_enabled ? (
            connection?.is_sync_active ? (
              <FaPauseCircle />
            ) : (
              <FaPlayCircle />
            )
          ) : (
            <FaPlayCircle />
          )
        }
        onClick={connection?.is_sync_enabled ? pauseSync : startSync}
        isDisabled={connection?.is_sync_enabled ? false : isStartButtonDisabled}
        disablePopover={
          connection?.is_sync_enabled ? true : !isStartButtonDisabled
        }
        popoverBodyComponent={popoverBodyComponent}
      >
        {connection?.is_sync_enabled
          ? connection?.is_sync_active
            ? "Pause sync"
            : "Resume sync"
          : "Start Sync"}
      </IPopoverButton>
      <IButton bg="brand.red" colorScheme="red" onClick={disconnect}>
        Disconnect
      </IButton>
    </HStack>
  );
}

export default function SalesforceDetailsNew() {
  const {
    salesforce: {
      fetchingConnection,
      connection,
      loginUrl,
      savingObjectSettings,
      savingSyncSettings,
      recheckingPermissions,
      pausingSync,
      resumingSync,
      deletingConnection,
      startedSync,
      getLoginUrl,
    },
    unifiedConnection: { mapping, salesforceConnections },
  } = useSelector(selectConnection);

  const {
    isOpen: isOpenPermissionsModal,
    onOpen: onOpenPermissionsModal,
    onClose: onClosePermissionsModal,
  } = useDisclosure();

  const {
    isOpen: isOpenSettingsModal,
    onOpen: onOpenSettingsModal,
    onClose: onCloseSettingsModal,
  } = useDisclosure();

  const {
    isOpen: isOpenDisconnectModal,
    onOpen: onOpenDisconnectModal,
    onClose: onCloseDisconnectModal,
  } = useDisclosure();

  const {
    isOpen: isOpenSummaryModal,
    onOpen: onOpenSummaryModal,
    onClose: onCloseSummaryModal,
  } = useDisclosure();

  const {
    isOpen: isOpenOverrideModal,
    onOpen: onOpenOverrideModal,
    onClose: onCloseOverrideModal,
  } = useDisclosure();

  const [stepNo, setStepNo] = useState(0);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const [selectedOption, setSelectedOption] = useState(0);
  const query = useQuery();

  const isFirstRun = useRef(true);

  const [isReauthenticatePromptOpen, setIsReauthenticatePromptOpen] =
    useState(false);
  const cancelRef = useRef(null);

  const goToConnectionList = useCallback(() => {
    navigate(urls.unifiedMapping);
  }, [navigate]);

  //This is a reference to the forwardRef in salesforceDetails for accessing the dateFilterHandler
  const childRef = useRef<{ filterWithDate: (date: Date) => void }>(null);

  const refresh = useCallback(() => {
    if (id) {
      dispatch(getSalesforceConnectionDetails(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (connection?.access_token === null) {
      onClosePermissionsModal();
      onOpenDisconnectModal();
    }
  }, [connection, onClosePermissionsModal, onOpenDisconnectModal]);

  useEffect(() => {
    if (query.get("permission")) {
      onOpenPermissionsModal();
      setStepNo(1);
      navigate(`${urls.salesforceRoute}/${id}`, { replace: true });
    }
  }, [query, navigate, onOpenPermissionsModal, id]);

  useEffect(() => {
    if (connection?.object_settings === null) {
      onOpenPermissionsModal();
      setStepNo(1);
    }
  }, [connection, onOpenPermissionsModal]);

  useEffect(() => {
    if (isFirstRun.current && connection) {
      isFirstRun.current = false;
      if (
        connection?.setup_complete === false &&
        connection?.object_settings !== null
      ) {
        onCloseSettingsModal();
        onOpenSummaryModal();
        setStepNo(3);
      }
    }
  }, [connection, onOpenSummaryModal, onCloseSettingsModal]);

  useEffect(() => {
    return () => {
      dispatch(resetSalesforceData());
    };
  }, [dispatch]);

  useEffect(() => {
    if (isSuccess(deletingConnection)) {
      goToConnectionList();
    }
  }, [deletingConnection, goToConnectionList]);

  useEffect(() => {
    if (isInit(mapping.loading)) {
      dispatch(getUnifiedMapping());
    }
  });

  function disconnect() {
    onOpenDisconnectModal();
  }

  useEffect(() => {
    if (id) {
      if (salesforceConnections[id]?.needsReauthentication) {
        setIsReauthenticatePromptOpen(true);
      }
      dispatch(getSalesforceConnectionDetails(id));
    }
  }, [dispatch, salesforceConnections, id]);

  function pauseSync() {
    if (connection?.is_sync_active) {
      dispatch(pauseSalesforceSync());
    } else {
      dispatch(resumeSalesforceSync());
    }
  }

  function startSync() {
    dispatch(startSalesforceSync(connection?.connection_id as string));
  }

  function nextStep() {
    onClosePermissionsModal();
    setStepNo(2);
    onOpenSettingsModal();
  }

  function goToOverrideStep() {
    onCloseSummaryModal();
    setStepNo(4);
    onOpenOverrideModal();
  }

  function goToFinishStep() {
    onCloseSettingsModal();
    setStepNo(3);
    onOpenSummaryModal();
  }

  function goPreviousBoxPermissions() {
    onCloseSettingsModal();
    setStepNo(1);
    onOpenPermissionsModal();
  }

  function goPreviousBoxSettings() {
    onCloseSummaryModal();
    setStepNo(2);
    onOpenSettingsModal();
  }

  function goBackSummary() {
    onCloseOverrideModal();
    setStepNo(3);
    onOpenSummaryModal();
  }

  function showErrorsDuringDuration(todayDate: Date) {
    childRef.current?.filterWithDate(todayDate);
    setSelectedOption(1);
  }

  function handleCancel() {
    navigate(urls.unifiedMapping);
    setIsReauthenticatePromptOpen(false);
  }

  function handleConfirm() {
    localStorage.setItem(SF_REAUTHENTICATION, "true");
    dispatch(getSalesforceLoginUrl());
  }

  useEffect(() => {
    if (loginUrl && connection?.connection_id) {
      redirectToSalesforceLoginPage(loginUrl, connection.connection_id);
    }
  }, [connection, loginUrl]);

  const tabs: Record<number, ReactNode> = useMemo(() => {
    return connection
      ? {
          0: (
            <Flex flexDir="column" gap="3">
              <SalesforceSyncDetails connectionId={connection!.connection_id} />
              <SalesforceSyncHistory
                connectionId={connection!.connection_id}
                showErrorsDuringDuration={showErrorsDuringDuration}
              />
            </Flex>
          ),
          1: (
            <Flex flexDir="column" gap="3">
              <SalesforceSyncHealthDetails
                connectionDetails={connection!}
                refreshAction={refresh}
                saving={isLoading(savingSyncSettings)}
              />
              <SalesforceSyncErrors
                connectionDetails={connection!}
                ref={childRef}
              />
            </Flex>
          ),
          2: (
            <SalesforceGeneralSettings
              objectSettings={connection?.object_settings ?? null}
              saving={isLoading(savingObjectSettings)}
            />
          ),
          3: (
            <SalesforceToInflection
              data={connection as SalesforceConnection}
              objectSettings={connection?.object_settings ?? null}
            />
          ),
          4: (
            <InflectionToSalesforce data={connection as SalesforceConnection} />
          ),
        }
      : {};
  }, [connection, refresh, savingObjectSettings, savingSyncSettings]);

  return (
    <Flex maxH="100vh">
      <SecondarySidebar
        heading={connection?.name || ""}
        backButton={
          <SidebarBackButton
            onClick={() => navigate(urls.unifiedMapping)}
            text="Back to connections"
          />
        }
      >
        <SideBar
          options={SIDEBAR_ITEMS}
          selected={selectedOption}
          select={setSelectedOption}
        />

        <Text p="2" mt="3" color="text.50" fontWeight="semibold" fontSize="sm">
          Sync Settings
        </Text>
        <Box pl="3">
          <SideBar
            options={SETTINGS_SIDEBAR_ITEMS}
            selected={selectedOption - SIDEBAR_ITEMS.length}
            select={(option) =>
              setSelectedOption(option + SIDEBAR_ITEMS.length)
            }
          />
        </Box>
      </SecondarySidebar>
      <SpinnerContainer loading={isLoading(fetchingConnection)} h="500px">
        <ContentContainerWithHeader>
          {connection &&
            !connection?.is_sync_active &&
            connection?.is_sync_enabled && (
              <Box px="5" pt="6" pb="3">
                <SyncStatusBanner
                  resumeSync={() => dispatch(resumeSalesforceSync())}
                />
              </Box>
            )}
          <Header
            connection={connection}
            isPausing={isLoading(resumingSync) || isLoading(pausingSync)}
            pauseSync={pauseSync}
            startSync={startSync}
            disconnect={disconnect}
            mapping={mapping}
            startedSync={startedSync}
          />

          <ContentContainer bg="unset" p="unset">
            {connection && tabs[selectedOption]}
          </ContentContainer>
        </ContentContainerWithHeader>
      </SpinnerContainer>

      {connection && stepNo === 1 && (
        <SalesforcePermissionsModal
          loading={recheckingPermissions}
          permissions={connection?.permissions}
          isOpen={isOpenPermissionsModal}
          onClose={nextStep}
        />
      )}

      {connection && stepNo === 2 && (
        <SalesforceSettingsModal
          connectionDetails={connection}
          isOpen={isOpenSettingsModal}
          onClose={goToFinishStep}
          loading={
            isLoading(savingSyncSettings) || isLoading(savingObjectSettings)
          }
          goPreviousBox={goPreviousBoxPermissions}
        />
      )}

      {connection && stepNo === 3 && (
        <SalesforceSummaryModal
          connectionDetails={connection}
          isOpen={isOpenSummaryModal}
          onClose={onCloseSummaryModal}
          goPreviousBox={goPreviousBoxSettings}
          goToOverrideStep={goToOverrideStep}
        />
      )}

      {connection && stepNo === 4 && (
        <SalesforceOverrideModal
          connectionDetails={connection}
          isOpen={isOpenOverrideModal}
          onClose={onCloseOverrideModal}
          goPreviousBox={goBackSummary}
        />
      )}

      <ConfirmationDialogBox
        isOpen={isOpenDisconnectModal}
        onClose={onCloseDisconnectModal}
      />

      <ConfirmationModal
        isOpen={isReauthenticatePromptOpen}
        cancelButtonText="Cancel"
        onCancel={handleCancel}
        confirmButtonText="Re-authenticate now"
        confirmButtonLoading={isLoading(getLoginUrl)}
        submitHandler={handleConfirm}
        cancelRef={cancelRef}
        title="Re-authenticate Salesforce"
      >
        <Text>
          Salesforce connection needs re-authentication. Sync is paused until
          the Salesforce account is re-authenticated.
        </Text>
      </ConfirmationModal>
    </Flex>
  );
}
