import { useBoolean, useDisclosure, VStack } from "@chakra-ui/react";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import {
  AccountMappingElement,
  ConnectionListItem,
  SOURCES,
} from "../../../../../../../../common/types/unifiedMapping";
import { useAppDispatch } from "../../../../../../../../store";
import { setDefaultMappingAccount } from "../../../../../connectionSlice";
import AutomapModal from "../../../../../../../../components/unified-mapping/AutomapModal";
import { AlertMessage } from "../../../../../../../../components/unified-mapping/AlertMessage";
import { SearchAndActionsBar } from "../../../../../../../../components/unified-mapping/SearchAndActionsBar";
import AccountMappingTable from "./components/AccountMappingTable/AccountMappingTable";
import SpinnerContainer from "../../../../../../../../components/SpinnerContainer";

export default function AccountTab({
  accountSources,
  data,
  onChange,
  removeRow,
  onSave,
  automapping,
  updatingMapping,
  onCancel,
  openAddModal,
  isAccountObjectPresent,
  isLoading,
}: {
  accountSources: ConnectionListItem[];
  data: AccountMappingElement[];
  onChange: (data: AccountMappingElement[]) => void;
  removeRow: (name: string) => void;
  onSave: () => void;
  automapping: boolean;
  updatingMapping: boolean;
  onCancel: () => void;
  openAddModal: () => void;
  isAccountObjectPresent: boolean;
  isLoading: boolean;
}) {
  const [isReadonly, setIsReadonly] = useBoolean(true);
  const [alertText, setAlertText] = useState("");
  const [query, setQuery] = useState("");
  const dispatch = useAppDispatch();

  const {
    isOpen: isOpenAutomapModal,
    onOpen: onOpenAutomapModal,
    onClose: onCloseAutomapModal,
  } = useDisclosure();

  const {
    isOpen: isAlertOpen,
    onOpen: onAlertOpen,
    onClose: onAlertClose,
  } = useDisclosure();

  function save() {
    onSave();
    setIsReadonly.on();
  }

  function cancelEditing() {
    setIsReadonly.on();
    onCancel();
  }

  function setAccountMapping(sourceType: SOURCES) {
    const connectionId =
      accountSources?.find((x) => x.source === sourceType)?.connection_id ??
      null;
    if (connectionId) {
      const data = {
        connection_type: sourceType,
        connection_id: connectionId,
        auto_map: true,
      };
      dispatch(setDefaultMappingAccount(data));
      onCloseAutomapModal();
    }
  }

  useEffect(() => {
    if (accountSources && !accountSources.length) {
      setAlertText(
        "Note: Add sources in the overview screen to start syncing data into Account Objects"
      );
      onAlertOpen();
    } else if (!isAccountObjectPresent) {
      setAlertText(
        "Note: Enable Account object in Data Connections -> CRM Connection -> Sync Settings to start syncing data into Account Objects"
      );
      onAlertOpen();
    } else {
      onAlertClose();
    }
  }, [
    isAccountObjectPresent,
    accountSources,
    onAlertClose,
    onAlertOpen,
    setAlertText,
  ]);

  return (
    <>
      {isAlertOpen && (
        <AlertMessage
          isOpen={isAlertOpen}
          onClose={onAlertClose}
          alertText={alertText}
        />
      )}
      <VStack width="100%" spacing="2">
        {isAccountObjectPresent && (
          <SearchAndActionsBar
            setQuery={setQuery}
            toggleReadonly={setIsReadonly.toggle}
            readonlyMode={isReadonly}
            automapping={automapping}
            saving={updatingMapping}
            openAutomapModal={
              isEmpty(accountSources) ? undefined : onOpenAutomapModal
            }
            save={save}
            cancel={cancelEditing}
            addAction={openAddModal}
          />
        )}
        <SpinnerContainer loading={isLoading}>
          <AccountMappingTable
            accountSources={accountSources}
            data={data}
            onChange={(data: AccountMappingElement[]) => onChange(data)}
            query={query}
            removeRow={(name: string) => removeRow(name)}
            readonly={isReadonly}
          />
        </SpinnerContainer>
        <AutomapModal
          crmSources={accountSources}
          next={setAccountMapping}
          isOpen={isOpenAutomapModal}
          onCancel={onCloseAutomapModal}
        />
      </VStack>
    </>
  );
}
