import { SALESFORCE_SYNC_STATE } from "../constants/campaign";
import { ACTIVITY_TYPE } from "../constants/connection";
import {
  INCLUSION_LIST_TYPES,
  MEMBERSHIP_ASSET_TYPES,
  OperatorType,
} from "./campaign";
import { UserSummary, PaginatedList } from "./common";
import { COLUMN_VALUE_TYPES } from "./person";
import { SelectiveSync, SOURCES } from "./unifiedMapping";

export interface SchemaList {
  schemas: string[];
}

export interface Connection {
  id: string;
  account_id: number;
  organisation_id: number;
  label: string;
  typ: SOURCES;
  version: number;
  user_name: string;
  host_name: string;
  port: number | null | undefined;
  database_name: string;
  schema_name: string;
  options: Options;
  sync_enabled: boolean;
  sync_interval: string;
  error: boolean;
  error_time: string | null;
  time_last_synced: string | null;
  time_created: string;
  time_updated: string;
}

export interface Options {}

export interface ConnectionConfig {
  host_name: string;
  label: string;
  port?: number;
  user_name?: string;
  password?: string;
  database_name?: string;
}

export interface ConnectionCreateConfig extends ConnectionConfig {
  typ: string;
}

export interface ConnectionUpdateConfig extends Partial<ConnectionConfig> {
  connection_id: string;
}
export interface ConnectionReadOnlyValidateConfig {
  host_name: string;
  schema_name: string;
  source_tables: string[];
  typ: string;
}

export type ConnectionReadAndWriteValidateConfig =
  Partial<ConnectionReadOnlyValidateConfig>;

export interface ValidationStatus {
  success: boolean;
  exception: string;
}

export interface AddSourceTableConfig {
  connection_id: string;
  source_tables: string[];
}
export interface ConnList {
  connections: Connection[];
}

export interface DestinationTable {
  multi_source: boolean;
  table_name: string;
  default: boolean;
  used_in_connection: string;
  columns: TableColumn[];
}

export interface TableColumn {
  name: string;
  required: boolean;
}

export interface SourceColumn {
  selected: boolean;
  autoincrement: boolean;
  comment: string;
  default: string;
  name: string;
  nullable: string;
  type: string;
}

export interface SyncTablesData {
  connection_id: string;
  tables: Table[];
  sync_interval: string;
  enable_sync: boolean;
}

export interface SyncGetResp {
  tables: TableResp[];
  sync_interval: string;
}

export interface Table {
  table_name: string;
  destination_table_name?: string;
  columns: Column[];
}

export interface Column {
  column_name: string;
  destination_column_name?: string;
}

export interface SyncRun {
  id: string;
  connection_id: string;
  version: number;
  state: string;
  error_time: string | null;
  error: { [key: string]: string } | null;
  records_synced_count: number;
  records_deleted_count: number;
  total_records_synced_count: number;
  total_records_deleted_count: number;
  time_sync_started: string;
  time_sync_ended: string;
  time_created: string;
  tables: SyncTable[];
}

interface SyncTable {
  table_name: string;
  version: number;
  sync_type: string;
  state: string;
  added_rows_s3_files: { [name: string]: number };
  deleted_rows_s3_files: { [name: string]: number };
  records_synced_count: number;
  records_deleted_count: number;
  error_message: null;
  write_errors: any[];
  time_sync_started: string;
  time_sync_ended: string;
}

export interface SyncDetails {
  account_id: number;
  database_name: string;
  error: boolean;
  error_time: string | null;
  host_name: string;
  id: string;
  label: string;
  options: any;
  organisation_id: number;
  password: string;
  port: number | null;
  schema_name: string;
  sync_enabled: boolean;
  sync_interval: string;
  sync_interval_in_seconds: number;
  time_created: string | null;
  time_last_synced: string | null;
  time_updated: string | null;
  typ: string;
  user_name: string;
  version: number;
}

export interface SyncSummary {
  connection_id: string;
  time_last_synced: string | null;
  last_sync_time_taken: SyncDuration | null;
  records_synced_count: number | null;
  total_records_synced_count: number | null;
  error: Record<string, string> | null;
  error_time: string | null;
  next_run_time: string | null;
  table_count: number;
  sync_enabled: boolean;
}

export interface SyncDuration {
  hours: number;
  minutes: number;
  seconds: number;
}

export interface TableResp {
  table_name: string;
  connection_id: string;
  destination_table_name: string;
  default_table: boolean;
  columns: ColumnResp[];
  time_updated: string;
  version: number;
  time_last_synced: string;
  time_created: string;
}

export interface ColumnResp {
  column_name: string;
  type: string;
  length: number | null;
  default_column: boolean;
  destination_column_name: string;
  primary_key: boolean;
  metadata: Metadata;
}

export interface Metadata {
  name: string;
  type: string;
  nullable: string;
  primary_key: string;
  default: string;
  autoincrement: string;
  comment: string;
}

export interface Activity {
  records: ActivityLog[];
  page_count: number;
  record_count: number;
}

export interface ActivityLog {
  activity_type: ACTIVITY_TYPE;
  id: string;
  connection_id: string;
  account_id: number;
  additional_info: ActivityLogAdditionalInfo | null;
  activity_time: string;
}

export interface ActivityLogAdditionalInfo {
  tables_added?: string[];
  tables_edited?: string[];
}

export interface SegmentReportRow {
  date: string;
  hour: number;
  count: number;
}

export interface SegmentError {
  error_id: string;
  time_created: string;
  exception: string;
  description: string;
  event: SegmentEvent;
}

export interface SegmentEvent {
  id: string;
  event_name: string;
  event_date: string;
  email: string;
  event_type: string;
  user_id: string;
  person_id: string;
}

export enum SEGMENT_SYNC_TYPES {
  SOURCE = "source",
  SOURCE_AND_DESTINATION = "both",
  DESTINATION = "destination", //not enabled as of now
}

export type SegmentDestinationSettings = Omit<
  SegmentAsDestinationConfig,
  "write_key"
>;

export type SegmentSourceDestination = Omit<
  SEGMENT_SYNC_TYPES,
  "SOURCE_AND_DESTINATION"
>;

interface SegmentAsSourceConfig {
  is_sync_contacts_enabled: boolean;
  is_sync_organisations_enabled: boolean;
}

interface SegmentSyncEmailOpened {
  is_enabled: boolean;
  unique_opens_only: boolean;
}

interface SegmentSyncEmailClicked {
  is_enabled: boolean;
  unique_clicks_only: boolean;
}
export interface SegmentAsDestinationConfig {
  write_key: string;
  sync_email_delivered?: boolean;
  sync_email_bounced?: boolean;
  sync_email_spam?: boolean;
  sync_email_unsubscribed?: boolean;
  sync_email_opened?: SegmentSyncEmailOpened;
  sync_email_link_click?: SegmentSyncEmailClicked;
}

export interface SegmentSyncConfig extends SegmentSyncReq {
  organisation_id: number;
  account_id: number;
  _id: number;
  version: number;
  time_created: string;
  time_updated: string;
}

export interface SegmentSyncReq {
  connection_type: SEGMENT_SYNC_TYPES;
  source_sync_settings: SegmentAsSourceConfig;
  destination_sync_settings?: SegmentAsDestinationConfig;
}

export interface ReportRange {
  time_unit: "hour" | "day";
  time_range: string;
  tz_offset: number;
}

export interface DateRange {
  startDate: string;
  endDate: string;
  pageNumber: number;
  pageSize: number;
}

export interface InclusionList {
  list_id: string;
  list_type: INCLUSION_LIST_TYPES;
}

export interface SalesforceConnection {
  connection_id: string;
  access_token: string;
  updated_at: string;
  has_minimum_permission: boolean;
  permissions: Permissions;
  name: string;
  org_jobs_max: number;
  org_daily_max: number;
  created_at: string;
  is_sync_enabled: boolean;
  created_by: UserSummary;
  updated_by: UserSummary;
  sync_settings: SyncSettings;
  object_settings: ObjectSettings;
  instance_url: string;
  is_sync_active: boolean;
  connection_type: string;
  setup_complete: boolean;
  inclusion_list: InclusionList;
  selective_sync: SelectiveSync;
  // old connections before this setting was introduced won't have these keys below,
  // so will be undefined
  // ----------
  salesforce_record_create: SALESFORCE_RECORD_CREATE | undefined;
  salesforce_record_delete: SALESFORCE_RECORD_DELETE | undefined;
  records_create_settings: Partial<ObjectCreateRule> | undefined;
  records_delete_settings: Partial<ObjectDeleteRule> | undefined;
  // ----------
  last_synced_time: string | undefined;
  next_schedule_time: string | undefined;
}

export interface ObjectSettings {
  lead: boolean;
  account: boolean;
  opportunity: boolean;
  contact: boolean;
}

export interface SyncSettings {
  max_allocated?: number;
  unit?: string;
  frequency?: string;
}

export interface Permissions {
  lead: Permission | null;
  account: Permission | null;
  opportunity: Permission | null;
  contact: Permission | null;
}

export interface Permission {
  full: boolean;
  write: boolean;
  read: boolean;
}

export interface SalesforceSyncRun {
  sync_run_id: string;
  organisation_id: string;
  start_time: string;
  connection_id: string;
  sync_read_count: SyncCount;
  sync_write_count: SyncCount;
  state: SALESFORCE_SYNC_STATE;
}

export interface SyncCount {
  account: SyncCountDetails;
  opportunity: SyncCountDetails;
  lead: SyncCountDetails;
  contact: SyncCountDetails;
}

export interface SyncCountDetails {
  total: number;
  updated: number;
  inserted: number;
  failed: number;
}

export interface ErrorTypes {
  error_types: ErrorType[];
}

export interface ErrorType {
  name: string;
  display: SALESFORCE_SYNC_ERROR_TYPE;
}

export interface SalesforceSyncHistoryDetails {
  total_account_count: number;
  total_lead_count: number;
  total_opportunity_count: number;
  total_contact_count: number;
  total_new_account_count: number;
  total_new_lead_count: number;
  total_new_opportunity_count: number;
  total_new_contact_count: number;
  next_sync_time: string | null;
  last_sync_time: string | null;
}

export interface DwServices {
  id: SOURCES;
  label: string;
  imgSrc: string;
  hidden: boolean;
}

export interface SetupCompleteData {
  connection_id: string;
  automap: boolean;
  start_sync: boolean;
}

export enum SALESFORCE_OBJECT_TYPE {
  CONTACT = "contact",
  LEAD = "lead",
  ACCOUNT = "account",
  OPPORTUNITY = "opportunity",
}

export enum SALESFORCE_RECORD_CREATE {
  CONTACT = "contact",
  LEAD = "lead",
  DO_NOTHING = "do_nothing",
}

export enum SALESFORCE_RECORD_DELETE {
  DELETE_RECORD = "delete_record",
  DO_NOTHING = "do_nothing",
}

export interface AssetField {
  id: string;
  name: string;
  type: MEMBERSHIP_ASSET_TYPES | INCLUSION_LIST_TYPES;
}

export enum INFLECTION_RECORD_DELETION {
  DELETE_RECORD = "delete_record",
  DO_NOTHING = "do_nothing",
}

export enum INFLECTION_RECORD_CREATION {
  CREATE_RECORD = "create_record",
  DO_NOTHING = "do_nothing",
}

export enum SALESFORCE_SYNC_ERROR_TYPE {
  OTHERS = "Others",
  PERMISSION = "Permission",
  NONE = "",
}

export type ObjectCreateRule = {
  [key in SALESFORCE_OBJECT_TYPE]: INFLECTION_RECORD_CREATION;
};

export type ObjectDeleteRule = {
  [key in SALESFORCE_OBJECT_TYPE]: INFLECTION_RECORD_DELETION;
};

export interface SalesforceSyncError {
  sync_run_id: string;
  sync_type: string;
  error_class: string;
  count: number;
  error_type: string;
  last_occurred: string;
  first_occurred: string;
  object_type: string;
  error_text: string;
}

export interface SalesforceSyncErrorQuery {
  object_email: string;
  object_type: string;
  _created_time: string;
  salesforce_id: string;
  error_class: string;
  name: string;
  inflection_id: string;

  // used in table but not present in interface
  error_text: string;
}

export interface SfErrors {
  errors: PaginatedList<SalesforceSyncErrorQuery>;
}

export interface SfResyncArchiveDataType {
  errorClassList: string[];
  startTime: string;
  endTime: string;
}

export interface SegmentPropertyDetail {
  count: number;
  sub_properties: any;
  enabled: boolean;
  last_seen: string;
  first_seen: string;
  description: string;
}

export interface SegmentProperty {
  [key: string]: SegmentPropertyDetail;
}

export interface SegmentEventType {
  description: string;
  properties: SegmentProperty;
  enabled: boolean;
  force_all_properties_processing: boolean;
  count?: number;
  first_seen?: string;
  last_seen?: string;
}

export interface SegmentSingleTrackEventType {
  [key: string]: SegmentEventType;
}

export interface SegmentTrackEventType {
  events: SegmentSingleTrackEventType;
  description: string;
  enabled: boolean;
}

export interface SegmentEventData {
  group: SegmentEventType;
  identify: SegmentEventType;
  track: SegmentTrackEventType;
}

export interface SegmentEventStatus {
  id: string;
  organisation_id: number;
  segment_events: SegmentEventData | null;
  force_all_events_processing: boolean;
}

export interface SegmentEventStatusNonTrack {
  events: {
    _id: string;
    organisation_id: number;
    segment_events: {
      group: SegmentEventType;
      identify: SegmentEventType;
    } | null;
    force_all_events_processing: boolean;
  } | null;
}

export interface SegmentEventStatusTrack {
  record_count: number;
  records: SegmentSingleTrackEventType | null;
  page_count: number;
}

export interface SegmentDescription {
  event_type: string;
  event_name?: string | null;
  description: string;
  event_property?: string | null;
}

export interface SegmentEventToggle {
  event_type: string;
  status: boolean;
  event_name?: string;
}

export interface SegmentEventPropertyToggle {
  event_type: string;
  status: boolean;
  event_name?: string;
  properties?: string[];
  all_properties?: boolean;
}

export const EVENT_TYPE = {
  GROUP: "group",
  IDENTIFY: "identify",
  TRACK: "track",
};

export interface MappingType {
  name: string;
  value: string;
  type: COLUMN_VALUE_TYPES | OperatorType;
}

export interface ColSave {
  column_name: string;
  destination_column_name: string;
  checked: boolean;
  primary_key?: boolean;
  disabled?: boolean;
  required?: boolean;
  isDestinationPresent?: boolean; // if destination column present in table.list
}

export interface TableLink {
  tempId: number;
  table_name: string;
  destination_table_name: string;
  columns: ColSave[];
  active?: boolean;
  disabled?: boolean;
}

export interface ColumnToAssetMapping {
  [column: string]: ColumnMappingDetails[];
}

export enum MAPPING_ASSET_TYPE {
  CAMPAIGN = "campaigns",
  FORMS = "forms",
}

export interface ColumnMappingDetails {
  name: string;
  id: string;
  asset_type: MAPPING_ASSET_TYPE.CAMPAIGN | MAPPING_ASSET_TYPE.FORMS;
}

export interface SfCampaignSyncError {
  journeyId: string;
  error: string;
  salesforceCampaignId: string;
  firstDetectedTimestamp: number;
  lastDetectedTimestamp: number;
  count: number;
}
