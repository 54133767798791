import {
  Circle,
  Flex,
  Icon,
  IconButton,
  Tooltip,
  useTheme,
} from "@chakra-ui/react";
import { ReactNode, useContext, useState } from "react";
import { IconType } from "react-icons";
import ITitle from "../../../../../components/ITitle";
import { useAppDispatch } from "../../../../../store";
import { MdClose } from "react-icons/md";
import { FaExclamation } from "react-icons/fa";
import {
  FlowStepSourceHandle,
  FlowStepTargetHandle,
  GotoLeftHandle,
  GotoRightHandle,
} from "./FlowHandle";
import { ActionNodeIdentifiers } from "../../../../../common/types/flow";
import WrapperForCandidate from "./WrapperForCandidate";
import WidgetIcon from "./WidgetIcon";
import { CampaignBuilderContext, removeFlowAction } from "../flowSlice";

export default function WidgetContainer({
  invalidMessage,
  identities,
  icon,
  title,
  color,
  children,
  isCandidate,
  selectedGoto,
  selectedExit,
  isDisabled,
}: {
  invalidMessage: string;
  identities: ActionNodeIdentifiers;
  icon: IconType;
  color: string;
  title: string;
  children: ReactNode;
  isCandidate?: boolean;
  selectedGoto?: string;
  selectedExit?: { actionId: string; conditionId: string };
  isDisabled?: boolean;
}) {
  const dispatch = useAppDispatch();
  const { campaignId, activeErrorCheck } = useContext(CampaignBuilderContext);
  const [isDeleting, setIsDeleting] = useState(false);
  async function removeAction() {
    setIsDeleting(true);
    await dispatch(
      removeFlowAction({
        campaignId,
        actionId: identities.actionId,
        groupId: identities.groupId,
      })
    );
    setIsDeleting(false);
  }
  const theme = useTheme();
  const red400 = theme.__cssVars["--chakra-colors-red-400"];

  return (
    <WrapperForCandidate
      isCandidate={isCandidate}
      identities={identities}
      selectedGoto={selectedGoto}
      selectedExit={selectedExit}
      isInvalid={activeErrorCheck && !!invalidMessage}
    >
      <Flex
        rounded="xl"
        bg="white"
        w="md"
        alignItems="center"
        flexDir="column"
        cursor="initial"
        position="relative"
        className="nopan"
      >
        <FlowStepTargetHandle />
        <GotoRightHandle />
        <GotoLeftHandle />
        <FlowStepSourceHandle />
        <Flex
          w="full"
          justifyContent="space-between"
          pt="5"
          px="4"
          alignItems="center"
        >
          <Flex alignItems="center">
            <WidgetIcon
              color={activeErrorCheck && invalidMessage ? red400 : color}
              ml="1"
              mr="3"
              icon={icon}
            />
            <ITitle
              title={title}
              fontSize="md"
              color="text.100"
              fontWeight="bold"
              capitalize={false}
            />
            {activeErrorCheck && invalidMessage && (
              <Tooltip
                label={invalidMessage}
                bg="red.400"
                color="white"
                hasArrow
              >
                <Circle
                  bg="red.400"
                  ml={3}
                  position="absolute"
                  right={-2.5}
                  top={-2}
                  p={1}
                >
                  <Icon fontSize="12" color="white" as={FaExclamation} />
                </Circle>
              </Tooltip>
            )}
          </Flex>
          <IconButton
            aria-label="Remove"
            color="gray.800"
            variant="ghost"
            size="sm"
            name="remove-widget"
            fontSize="xl"
            onClick={removeAction}
            icon={<MdClose />}
            isLoading={isDeleting}
            isDisabled={isDisabled}
          />
        </Flex>
        {children}
      </Flex>
    </WrapperForCandidate>
  );
}
