import { VStack, Text } from "@chakra-ui/react";
import { useEffect } from "react";
import { SingleValue } from "react-select";
import { ApiToDelimiterMapping } from "../../../../../../../common/constants/AccountContactMapping";
import { LOADING_STATES } from "../../../../../../../common/constants/common";
import { isLoading } from "../../../../../../../common/helper/commonHelper";
import { AccountContactAutoMappingElement } from "../../../../../../../common/types/AccountContactMapping";
import DropdownWithSearch from "../../../../../../../components/DropdownWithSearch";

const options = [
  {
    label: "Multiple values whitespace separated",
    value: "whitespace",
  },
  {
    label: "Multiple values comma separated",
    value: "comma",
  },
  {
    label: "Single value",
    value: "none",
  },
];

type DelimiterType = "whitespace" | "comma" | "none";

export default function AccountAutoDelimiter({
  readonlyMode,
  accountAutoMapping,
  fetchingMapping,
  delimiterValue,
  setDelimiterValue,
  isCancelled,
}: {
  readonlyMode: boolean;
  accountAutoMapping: AccountContactAutoMappingElement;
  fetchingMapping: LOADING_STATES;
  delimiterValue: { label: string; value: string | null };
  setDelimiterValue: (delimiterValue: {
    label: string;
    value: string | null;
  }) => void;
  isCancelled: boolean;
}) {
  useEffect(() => {
    if (accountAutoMapping) {
      if (isCancelled) {
        if (!accountAutoMapping.delimiter) {
          setDelimiterValue({
            label: options[2].label,
            value: options[2].value,
          });
        } else {
          setDelimiterValue({
            label:
              ApiToDelimiterMapping[
                accountAutoMapping.delimiter as DelimiterType
              ].value,
            value: accountAutoMapping.delimiter,
          });
        }
      }
    }
  }, [accountAutoMapping, setDelimiterValue, isCancelled]);

  function handleDelimiterChange(
    option: SingleValue<{ label: string; value: string }>
  ) {
    if (option?.value) {
      setDelimiterValue({
        label: ApiToDelimiterMapping[option?.value as DelimiterType].value,
        value: option?.value as string,
      });
    }
  }

  return (
    <VStack alignItems="flex-start">
      <Text fontSize={12} color="gray.500">
        Account Field
      </Text>
      {readonlyMode ? (
        <Text>{delimiterValue.label}</Text>
      ) : (
        <DropdownWithSearch
          isLoading={isLoading(fetchingMapping)}
          options={options}
          isSearchable={true}
          value={options.find((x) => x.value === delimiterValue.value)}
          getOptionLabel={(x) => x.label}
          getOptionValue={(x) => x.value}
          onChange={(option) => handleDelimiterChange(option)}
          controlStyle={{
            width: "250px",
          }}
        />
      )}
    </VStack>
  );
}
