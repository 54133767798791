import { DateTimeFilterPagintation, PaginatedList } from "../../types/common";
import {
  InclusionList,
  ObjectCreateRule,
  ObjectDeleteRule,
  ObjectSettings,
  SalesforceConnection,
  SalesforceSyncError,
  SalesforceSyncHistoryDetails,
  SalesforceSyncRun,
  SetupCompleteData,
  SALESFORCE_RECORD_CREATE,
  SALESFORCE_RECORD_DELETE,
  SyncSettings,
  ErrorTypes,
  SfErrors,
  SfResyncArchiveDataType,
} from "../../types/connection";
import { SelectiveSync } from "../../types/unifiedMapping";
import { integrationsRequest as request } from "./api";

export async function updateSalesforceObjectsApi(
  data: ObjectSettings,
  connectionId: string
): Promise<{ connection: SalesforceConnection }> {
  return await request({
    url: "/salesforce/sync/objects.update",
    data: { object_settings: data, connection_id: connectionId },
  });
}

export async function updateSalesforceSettingsApi(
  data: SyncSettings,
  connectionId: string
): Promise<{ connection: SalesforceConnection }> {
  return await request({
    url: "/salesforce/sync/settings.update",
    data: { max_allocated: data.max_allocated, connection_id: connectionId },
  });
}

export async function createSalesforceConnectionApi(
  name: string
): Promise<{ connection: SalesforceConnection }> {
  return await request({
    url: "/salesforce/connection.create",
    data: { name },
  });
}

export async function getSalesforceLoginUrlApi(): Promise<{ url: string }> {
  return await request({
    url: "/salesforce/oauth.url",
    data: null,
  });
}

export async function connectSalesforceApi(
  code: string,
  connectionId: string
): Promise<{ connection: SalesforceConnection }> {
  return await request({
    url: "/salesforce/oauth.login",
    data: { code, connection_id: connectionId },
  });
}

export async function getSalesforceConnectionListApi(): Promise<
  PaginatedList<SalesforceConnection>
> {
  return await request({
    url: "/salesforce/connection.list",
    data: {},
    shouldRetryApiCall: true,
  });
}

export async function getSalesforceConnectionDetailsApi(
  connectionId: string
): Promise<{ connection: SalesforceConnection }> {
  return await request({
    url: "/salesforce/connection.get",
    data: { connection_id: connectionId },
    shouldRetryApiCall: true,
  });
}

export async function listSalesforceSyncRunsApi(
  data: {
    connectionId: string;
    shouldIncludeEmptyRuns: boolean;
  } & DateTimeFilterPagintation
): Promise<PaginatedList<SalesforceSyncRun>> {
  return await request({
    url: "/salesforce/sync/runs.list",
    data: {
      connection_id: data.connectionId,
      start_time: data.startTime,
      end_time: data.endTime,
      page_number: data.pageNumber,
      page_size: data.pageSize,
      should_include_empty_runs: data.shouldIncludeEmptyRuns,
    },
    shouldRetryApiCall: true,
  });
}

export async function listSalesforceSyncErrorsApi(
  data: {
    connectionId: string;
    errorType: string;
  } & DateTimeFilterPagintation
): Promise<PaginatedList<SalesforceSyncError>> {
  return await request({
    url: "/salesforce/sync/errors.list",
    data: {
      connection_id: data.connectionId,
      error_type: data.errorType,
      start_time: data.startTime,
      end_time: data.endTime,
      page_number: data.pageNumber,
      page_size: data.pageSize,
    },
    shouldRetryApiCall: true,
  });
}

export async function listSalesforceSyncErrorsTypesApi(): Promise<ErrorTypes> {
  return await request({
    url: "/salesforce/sync/errors.types",
    shouldRetryApiCall: true,
  });
}

export async function recheckSalesforcePermissionApi(
  connectionId: string
): Promise<{ connection: SalesforceConnection }> {
  return await request({
    url: "/salesforce/sync/permissions.recheck",
    data: {
      connection_id: connectionId,
    },
  });
}

export async function getSyncHistoryDetailsApi(
  connectionId: string
): Promise<SalesforceSyncHistoryDetails> {
  return await request({
    url: "/salesforce/sync/history.details",
    data: {
      connection_id: connectionId,
    },
    shouldRetryApiCall: true,
  });
}

export async function pauseSalesforceSyncApi(
  connectionId: string
): Promise<{ connection: SalesforceConnection }> {
  return await request({
    url: "/salesforce/sync/sync.pause",
    data: {
      connection_id: connectionId,
    },
  });
}

export async function resumeSalesforceSyncApi(
  connectionId: string
): Promise<{ connection: SalesforceConnection }> {
  return await request({
    url: "/salesforce/sync/sync.resume",
    data: {
      connection_id: connectionId,
    },
  });
}

export async function deleteSalesforceConnectionApi(
  connectionId: string
): Promise<{ connection: boolean }> {
  return await request({
    url: "/salesforce/connection.delete",
    data: {
      connection_id: connectionId,
    },
  });
}

export async function getApiUsageCountApi(
  connectionId: string
): Promise<{ api_usage_count: number }> {
  return await request({
    url: "/salesforce/sync/usage.count",
    data: {
      connection_id: connectionId,
    },
    shouldRetryApiCall: true,
  });
}

export async function setupCompleteApi(
  data: SetupCompleteData
): Promise<{ connection: SalesforceConnection }> {
  return await request({
    url: "/salesforce/connection.complete",
    data: {
      connection_id: data.connection_id,
      automap: data.automap,
      start_sync: data.start_sync,
    },
  });
}

export async function updateInclusionListApi(data: {
  connectionId: string;
  inclusionList: InclusionList;
}): Promise<{ connection: SalesforceConnection }> {
  return await request({
    url: "/salesforce/sync/settings/inclusion.update",
    data: {
      connection_id: data.connectionId,
      inclusion_list: data.inclusionList,
    },
  });
}

export async function removeInclusionListApi(
  connectionId: string
): Promise<{ connection: SalesforceConnection }> {
  return await request({
    url: "/salesforce/sync/settings/inclusion.remove",
    data: {
      connection_id: connectionId,
    },
  });
}

export async function updateSelectiveSyncApi(data: {
  connectionId: string;
  selectiveSync: Partial<SelectiveSync>;
}): Promise<{ connection: SalesforceConnection }> {
  return await request({
    url: "/salesforce/sync/settings/selective.update",
    data: {
      connection_id: data.connectionId,
      selective_sync: data.selectiveSync,
    },
  });
}

export async function updateSalesforceRecordsCreateUpdateSettingApi(data: {
  connectionId: string;
  salesforceRecordCreateUpdate: SALESFORCE_RECORD_CREATE;
}): Promise<{ connection: SalesforceConnection }> {
  return await request({
    url: "/salesforce/sync/settings/sf-records-create.update",
    data: {
      connection_id: data.connectionId,
      salesforce_record_create: data.salesforceRecordCreateUpdate,
    },
  });
}

export async function updateSalesforceRecordsDeleteSettingApi(data: {
  connectionId: string;
  salesforceRecordDelete: SALESFORCE_RECORD_DELETE;
}): Promise<{ connection: SalesforceConnection }> {
  return await request({
    url: "/salesforce/sync/settings/sf-records-delete.update",
    data: {
      connection_id: data.connectionId,
      salesforce_record_delete: data.salesforceRecordDelete,
    },
  });
}

export async function updateInflectionRecordsCreateUpdateSettingApi(data: {
  connectionId: string;
  objectCreate: Partial<ObjectCreateRule>;
}): Promise<{ connection: SalesforceConnection }> {
  return await request({
    url: "/salesforce/sync/settings/records-create.update",
    data: {
      connection_id: data.connectionId,
      object_create: data.objectCreate,
    },
  });
}

export async function updateInflectionRecordsDeleteSettingApi(data: {
  connectionId: string;
  objectDelete: Partial<ObjectDeleteRule>;
}): Promise<{ connection: SalesforceConnection }> {
  return await request({
    url: "/salesforce/sync/settings/records-delete.update",
    data: {
      connection_id: data.connectionId,
      object_delete: data.objectDelete,
    },
  });
}

export async function resyncSalesforcePersonApi(
  email: string
): Promise<{ success: boolean }> {
  return await request({
    url: "/salesforce/sync/person.resync",
    data: { email },
  });
}

export async function remapSalesforcePersonApi(
  sfId: string,
  email: string
): Promise<{ success: boolean }> {
  return await request({
    url: "/salesforce/sync/person.remap",
    data: { email, sf_id: sfId },
  });
}

export async function getSalesforcePersonSyncErrorApi(
  email: string
): Promise<{ error: { rows: SalesforceSyncError[] } }> {
  return await request({
    url: "/salesforce/sync/errors.latest",
    data: { email },
    shouldRetryApiCall: true,
  });
}

export async function startSalesforceSyncApi(
  connectionId: string
): Promise<{ connection: SalesforceConnection }> {
  return await request({
    url: "/salesforce/sync/sync.start",
    data: {
      connection_id: connectionId,
    },
  });
}

export async function getSalesforceErrorQueryApi(data: {
  errorClass: string;
  objectEmail?: string;
  pageNo?: number;
  pageSize?: number;
  keyword?: string;
}): Promise<SfErrors> {
  return await request({
    url: "/salesforce/sync/error-class.query",
    data: {
      error_class: data.errorClass,
      object_email: data.objectEmail,
      page_number: data.pageNo,
      page_size: data.pageSize,
      keyword: data.keyword,
    },
    shouldRetryApiCall: true,
  });
}

export async function archiveSalesforceSyncErrorApi(
  data: SfResyncArchiveDataType
): Promise<{ updated_count: number }> {
  return await request({
    url: "/salesforce/sync/error.archive",
    data: {
      error_names: data.errorClassList,
      start_time: data.startTime,
      end_time: data.endTime,
    },
  });
}

export async function resyncSalesforceSyncErrorApi(
  data: SfResyncArchiveDataType
): Promise<{ success: boolean }> {
  return await request({
    url: "/salesforce/sync/errors.resync",
    data: {
      error_names: data.errorClassList,
      start_time: data.startTime,
      end_time: data.endTime,
    },
  });
}
