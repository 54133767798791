import {
  Box,
  Flex,
  HStack,
  VStack,
  Text,
  useBoolean,
  Center,
} from "@chakra-ui/react";
import { cloneDeep, isArray, isNull, isEmpty } from "lodash";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  ReactNode,
} from "react";
import { useSelector } from "react-redux";
import { selectCampaign } from "../../pages/dashboard/campaign/campaignSlice";
import {
  CONNECTOR,
  DynamicListChild,
  DynamicListChildL2,
  OperatorType,
  DynamicListChildL1,
  TYPE,
  ValueTypes,
} from "../../common/types/campaign";
import { MarketingActivityArgument } from "../../common/types/person";
import RemoveRowCloseButton from "../RemoveRowCloseButton";
import { FilterGroupBox } from "./FilterGroupBox";
import DynamicListLogicGate from "./DynamicListLogicGate";
import {
  DynamicListValueFields,
  ValueSelectFields,
} from "./DynamicListValueFields";
import {
  CAMPAIGN_LIST_INIT,
  PROPERTY_FILTER_ID,
  CHILD_FILTER_TYPE,
  TEMPLATE_LIST_INIT,
  VALUE_ALL,
} from "../../common/constants/campaign";
import {
  FrequencySelector,
  StringOperatorValueFilter,
  TimeFrameSelector,
} from "./CommonFilterRows";
import {
  getOperatorDetails,
  isFulfilled,
  isInit,
  isLoading,
} from "../../common/helper/commonHelper";
import { useAppDispatch } from "../../store";
import { validateFilterGroup } from "../../pages/dashboard/campaign/helper/validationHelper";
import { DynamicListContext } from "./DynamicList";
import { RiMailCheckLine, RiMailSettingsFill } from "react-icons/ri";
import {
  selectDynamicList,
  getTemplatesOfCampaign,
  getFormDetails,
  listAllCampaigns,
} from "./dynamicListSlice";
import { listAllForms, selectForm } from "../../pages/dashboard/form/formSlice";
import { getFormDetailsApi } from "../../common/api/integrations/form";
import { AllFields } from "../../common/types/form";
import {
  FORM_FIELD_PROPERTY_GROUP_CHILD,
  FORM_IS_NOT_OPERATOR,
  FORM_IS_OPERATOR,
} from "../../common/constants/form";
import AddPropertyButton from "./AddPropertyButton";
import {
  addEllipsisToText,
  findFirstGroupIndex,
  findFormSessionGroupInFilterGroup,
  getOperatorsListForDisplay,
  initMarketingActivity,
  isFormSubmittedEvent,
  isTypeGroup,
  findChildWithFilter,
} from "../../common/helper/dynamicListHelper";
import ActivitySelector from "./ActivitySelector";
import { IoDocumentTextSharp } from "react-icons/io5";
import { FaLink, FaRegCalendarAlt } from "react-icons/fa";
import AddFrequencyButton from "./AddFrequencyButton";
import AddTimeFrameButton from "./AddTimeFrameButton";
import FormSessionPropertyGroupsContainer from "./FormSessionPropertyGroupsContainer";
import { DL_FIELD, FREQUENCY_ACTION } from "../../common/constants/dynamicList";

const IS_OPERATOR = "asset_is_equal";
const OPERATORS_WITH_ARG_LIST = [
  IS_OPERATOR,
  FORM_IS_NOT_OPERATOR,
  FORM_IS_OPERATOR,
];
const templateRow: DynamicListChildL2 = initMarketingActivity({
  filter: PROPERTY_FILTER_ID.TEMPLATE,
});

function convertToLabelValue(list: AllFields) {
  return Object.values(list).map((x) => {
    return { label: x.source.display, value: x.source.name };
  });
}

function identifyFormFilter(data: DynamicListChild) {
  return data.children?.some((x) => {
    return (
      isTypeGroup(x.type) &&
      x.children?.length &&
      x.children.some(({ filter }) => filter === PROPERTY_FILTER_ID.FORM)
    );
  });
}

export default function MarketingActivityFilterGroup({
  data,
  label,
  id,
  onChange,
  onRemove,
  isReadOnly,
}: {
  data: DynamicListChild;
  label: string;
  id: string;
  onChange: (data: any) => void;
  onRemove: () => void;
  isReadOnly?: boolean;
}) {
  const {
    marketingEventNamesList,
    operators: { data: operators },
    operatorsArgCount,
  } = useSelector(selectDynamicList);
  const { activeErrorCheck } = useContext(DynamicListContext);

  const marketingActivity = (data.children[0].value?.[0] ?? "") as string;

  function getEventListWithValue(value: string | null) {
    return value
      ? marketingEventNamesList.data
          ?.find((x) => x.id === value)
          ?.arguments?.map((x) => x.id) ?? []
      : [];
  }

  const marketingActivityList = getEventListWithValue(marketingActivity);

  const timeFrameRowIndex: number | null = useMemo(() => {
    const hasTimeFrame = isArray(data.children)
      ? data.children.findIndex(
          (child) => child.filter === CHILD_FILTER_TYPE.TIME_FRAME
        )
      : null;
    return hasTimeFrame && hasTimeFrame >= 0 ? hasTimeFrame : null;
  }, [data.children]);

  const linkFilterRowIndex: number | null = useMemo(() => {
    const haslinkFilter = isArray(data.children)
      ? data.children.findIndex(
          (child) => child.filter === CHILD_FILTER_TYPE.LINK_CLICKED
        )
      : null;

    return haslinkFilter && haslinkFilter >= 0 ? haslinkFilter : null;
  }, [data.children]);

  const canShowFrequencyFilter = marketingActivityList.includes(
    CHILD_FILTER_TYPE.FREQUENCY
  );
  const canShowTimeFrameFilter = marketingActivityList.includes(
    CHILD_FILTER_TYPE.TIME_FRAME
  );
  const canShowLinkFilter = marketingActivityList.includes(
    CHILD_FILTER_TYPE.LINK_CLICKED
  );
  // Hiding add property group button only for forms
  const isFormSubmittedActivity = isFormSubmittedEvent(marketingActivity);
  const isFormPropertyExist =
    isFormSubmittedActivity && identifyFormFilter(data);

  const formPropertyFilter = marketingActivityList.includes(
    PROPERTY_FILTER_ID.FORM
  );
  const formSessionGroupIndex = isFormSubmittedActivity
    ? findFormSessionGroupInFilterGroup(data.children)
    : null;

  function onActivityChange(value: string) {
    let dataCopy = cloneDeep(data);
    // reset data in property groups when filter group activity changes
    if (dataCopy.children[0].value?.[0] !== value) {
      const eventList = getEventListWithValue(value) ?? null;

      dataCopy.children = dataCopy.children.filter(
        (child: DynamicListChildL1) => {
          if (child.type === TYPE.EXPRESSION) {
            //type:expression -> 2 children possible , link filter and time frame.
            // time frame allowed for all marketing activity

            switch (child.filter) {
              case CHILD_FILTER_TYPE.LINK_CLICKED:
                return eventList?.includes(CHILD_FILTER_TYPE.LINK_CLICKED);
              default:
                return true;
            }
          }

          //filter all type:group out
          return false;
        }
      );

      if (!eventList?.includes(CHILD_FILTER_TYPE.FREQUENCY)) {
        dataCopy.operator = null;
        dataCopy.value = null;
      }
    }
    dataCopy.children[0].value = [value];
    if (value) {
      dataCopy.children[0].validation_error = "";
    }
    onChange(dataCopy);
  }

  function onFrequencyChange(
    field: FREQUENCY_ACTION,
    value: string | number | ValueTypes
  ) {
    const dataCopy = cloneDeep(data);
    switch (field) {
      case FREQUENCY_ACTION.ADD:
        dataCopy.operator = "exactly";
        dataCopy.value = [0];
        break;
      case FREQUENCY_ACTION.OPERATOR:
        dataCopy.operator = value as string;
        dataCopy.value = [];
        break;
      case FREQUENCY_ACTION.VALUE:
        dataCopy.value = value as ValueTypes;
        break;
      case FREQUENCY_ACTION.REMOVE:
        dataCopy.operator = null;
        dataCopy.value = null;
        break;
    }
    dataCopy.validation_error = "";
    onChange(dataCopy);
  }

  function onAddTimeFrame() {
    const dataCopy = cloneDeep(data);
    dataCopy.children.splice(1, 0, {
      ...initMarketingActivity({ filter: CHILD_FILTER_TYPE.TIME_FRAME }),
    });
    onChange(dataCopy);
  }

  function onAddLinkFilter() {
    const dataCopy = cloneDeep(data);
    dataCopy.children.splice(1, 0, {
      ...initMarketingActivity({ filter: CHILD_FILTER_TYPE.LINK_CLICKED }),
    });
    onChange(dataCopy);
  }

  function onAddPropertyGroup() {
    const dataCopy = cloneDeep(data);
    const newPropertyGroup: DynamicListChildL1 = {
      type: TYPE.GROUP,
      name: "",
      filter_type: null,
      filter: null,
      property: null,
      operator: null,
      value: null,
      connector: CONNECTOR.AND,
      children: [],
    };

    if (marketingActivityList.includes(PROPERTY_FILTER_ID.CAMPAIGN)) {
      const campaignPropertyGroupChild = {
        ...initMarketingActivity({ filter: PROPERTY_FILTER_ID.CAMPAIGN }),
      };
      newPropertyGroup.children?.push(campaignPropertyGroupChild);
    }
    if (marketingActivityList.includes(PROPERTY_FILTER_ID.TEMPLATE)) {
      const templatePropertyGroupChild: DynamicListChildL2 = { ...templateRow };
      newPropertyGroup.children?.push(templatePropertyGroupChild);
    }
    if (marketingActivityList.includes(PROPERTY_FILTER_ID.REASON)) {
      const reasonPropertyGroupChild: DynamicListChildL2 = {
        ...initMarketingActivity({ filter: PROPERTY_FILTER_ID.REASON }),
      };
      newPropertyGroup.children?.push(reasonPropertyGroupChild);
    }
    if (marketingActivityList.includes(PROPERTY_FILTER_ID.FORM)) {
      const formPropertyGroupChild: DynamicListChildL2 = {
        ...initMarketingActivity({
          filter: PROPERTY_FILTER_ID.FORM,
          connector: CONNECTOR.EMPTY,
        }),
      };
      newPropertyGroup.children?.push(formPropertyGroupChild);
    }

    if (!isNull(formSessionGroupIndex)) {
      // make sure that form session is the last property group
      dataCopy.children.splice(formSessionGroupIndex, 0, newPropertyGroup);
    } else {
      dataCopy.children.push(newPropertyGroup);
    }

    onChange(dataCopy);
  }

  function onAddFormSessionParamsGroup() {
    const filterGroupCopy = cloneDeep(data);

    const formWebSessionPropGroup: DynamicListChildL1 = {
      ...initMarketingActivity({
        type: TYPE.GROUP,
        filter: PROPERTY_FILTER_ID.FORM_SUBMISSION_SESSION,
      }),
      filter_type: null,
      children: [
        {
          ...initMarketingActivity({
            connector: CONNECTOR.EMPTY,
            type: TYPE.GROUP,
          }),
          filter_type: null,
          children: [
            { ...initMarketingActivity({ connector: CONNECTOR.EMPTY }) },
          ],
        },
      ],
    };

    filterGroupCopy.children?.push(formWebSessionPropGroup);

    onChange(filterGroupCopy);
  }

  function onRemoveRow(index: number) {
    const dataCopy = cloneDeep(data);
    dataCopy.children = dataCopy.children.filter((_, i) => i !== index);
    onChange(dataCopy);
  }

  function onFilterDataChange(
    field: DL_FIELD,
    index: number,
    value: string | number | CONNECTOR | ValueTypes
  ) {
    const dataCopy = cloneDeep(data);
    switch (field) {
      case DL_FIELD.OPERATOR:
        dataCopy.children[index].operator = value as string;
        dataCopy.children[index].value = [];
        break;
      case DL_FIELD.VALUE:
        dataCopy.children[index].value = value as ValueTypes;
        break;
    }

    const { filterGroup } = validateFilterGroup(
      dataCopy,
      false,
      operatorsArgCount
    );
    onChange(filterGroup);
  }

  function onPropertyGroupChange(newData: DynamicListChildL1[]) {
    const dataCopy = cloneDeep(data);
    dataCopy.children = newData;
    onChange(dataCopy);
  }

  function onChangeFilterGroupName(value: string) {
    const dataCopy = cloneDeep(data);
    dataCopy.name = value;
    onChange(dataCopy);
  }

  function AddEmailProperty() {
    return (
      <AddPropertyButton
        onClick={onAddPropertyGroup}
        leftIcon={
          formPropertyFilter ? (
            <IoDocumentTextSharp fontSize="12px" />
          ) : (
            <RiMailSettingsFill fontSize="12px" />
          )
        }
        isDisabled={!marketingActivity}
        hidden={isReadOnly || isFormPropertyExist}
      >
        {`Add ${formPropertyFilter ? "form" : "email journey"} property`}
      </AddPropertyButton>
    );
  }

  function AddPropertyButtonsRow() {
    const hidePropertyButtons = isReadOnly || !marketingActivity;
    return (
      <HStack pb="2">
        {canShowFrequencyFilter && !data.operator && (
          <AddFrequencyButton
            onClick={() => onFrequencyChange(FREQUENCY_ACTION.ADD, "")}
            hidden={hidePropertyButtons}
          />
        )}

        {canShowTimeFrameFilter && isNull(timeFrameRowIndex) && (
          <AddTimeFrameButton
            onClick={onAddTimeFrame}
            hidden={hidePropertyButtons}
          />
        )}

        {canShowLinkFilter && isNull(linkFilterRowIndex) && (
          <AddPropertyButton
            onClick={onAddLinkFilter}
            children="Add link property"
            leftIcon={<FaLink fontSize="12px" />}
            hidden={hidePropertyButtons}
          />
        )}

        {(findFirstGroupIndex(data.children) === -1 ||
          isFormSubmittedActivity) && <AddEmailProperty />}

        {isFormSubmittedActivity && isNull(formSessionGroupIndex) && (
          <AddPropertyButton
            onClick={onAddFormSessionParamsGroup}
            children="Add web session parameters"
            hidden={isReadOnly}
          />
        )}
      </HStack>
    );
  }

  function frequencyLinkTimeFramerFilters() {
    return (
      <VStack w="100%" pb={isReadOnly ? 0 : "2"}>
        {canShowFrequencyFilter && data.operator && operators && (
          <FrequencySelector
            numberOperators={operators[OperatorType.FREQUENCY_COUNT]}
            operator={data.operator}
            onOperatorChange={(value: string) =>
              onFrequencyChange(FREQUENCY_ACTION.OPERATOR, value)
            }
            value={data.value as ValueTypes}
            onValueChange={(value: ValueTypes) =>
              onFrequencyChange(FREQUENCY_ACTION.VALUE, value)
            }
            isReadOnly={isReadOnly}
            validationError={activeErrorCheck ? data.validation_error : ""}
            showRemoveButton={!!(!isReadOnly && data.operator)}
            onRemoveRow={() => onFrequencyChange(FREQUENCY_ACTION.REMOVE, "")}
            icon={FaRegCalendarAlt}
          />
        )}

        {canShowTimeFrameFilter && !isNull(timeFrameRowIndex) && operators && (
          <>
            <TimeFrameSelector
              dateOperators={operators[OperatorType.AGGREGATE_TIMEFRAME]}
              setOperator={(value) =>
                onFilterDataChange(DL_FIELD.OPERATOR, timeFrameRowIndex, value)
              }
              operator={data.children[timeFrameRowIndex]?.operator}
              value={data.children[timeFrameRowIndex]?.value as ValueTypes}
              setValue={(value) =>
                onFilterDataChange(DL_FIELD.VALUE, timeFrameRowIndex, value)
              }
              showRemoveButton={true}
              onRemoveRow={() => onRemoveRow(timeFrameRowIndex)}
              validationError={
                activeErrorCheck
                  ? data.children[timeFrameRowIndex]?.validation_error
                  : ""
              }
              isReadOnly={isReadOnly}
            />
          </>
        )}

        {canShowLinkFilter && !isNull(linkFilterRowIndex) && operators && (
          <>
            <StringOperatorValueFilter
              filter={CHILD_FILTER_TYPE.LINK_CLICKED}
              stringOperators={operators[OperatorType.LINK_STRING]}
              setOperator={(value) =>
                onFilterDataChange(DL_FIELD.OPERATOR, linkFilterRowIndex, value)
              }
              operator={data.children[linkFilterRowIndex]?.operator}
              value={data.children[linkFilterRowIndex]?.value as ValueTypes}
              setValue={(value) =>
                onFilterDataChange(DL_FIELD.VALUE, linkFilterRowIndex, value)
              }
              onRemoveRow={() => onRemoveRow(linkFilterRowIndex)}
              validationError={
                activeErrorCheck
                  ? data.children[linkFilterRowIndex]?.validation_error
                  : ""
              }
              isReadOnly={isReadOnly}
            />
          </>
        )}
      </VStack>
    );
  }

  const wrapperStyle = isReadOnly
    ? {
        px: "0",
        py: "0",
      }
    : {
        px: "3",
        py: "2",
        bg: "grayV2.200",
      };

  return (
    <FilterGroupBox
      id={id}
      label={label}
      onRemove={onRemove}
      groupName={data.name}
      onGroupNameChange={onChangeFilterGroupName}
      isReadOnly={isReadOnly}
    >
      <VStack alignItems="flex-start" {...wrapperStyle} w="100%" spacing={0}>
        {data.children && (
          <>
            <ActivitySelector
              displayText={addEllipsisToText(
                "performed a marketing activity of"
              )}
              activityList={
                marketingEventNamesList.data?.map((x) => ({
                  label: x.display,
                  value: x.id,
                })) || []
              }
              value={marketingActivity}
              onChange={(value) => onActivityChange(value)}
              validationError={
                activeErrorCheck ? data.children[0].validation_error : ""
              }
              isReadOnly={isReadOnly}
              icon={RiMailCheckLine}
            />

            {frequencyLinkTimeFramerFilters()}

            {!isReadOnly && <AddPropertyButtonsRow />}

            <PropertyGroupContainer
              title={`${
                formPropertyFilter ? "Form" : "Email journey"
              } properties`}
              data={data.children}
              mainFilter={marketingActivity}
              parentId={id}
              onChange={onPropertyGroupChange}
              isReadOnly={isReadOnly}
              addPropertyGroupContent={
                !isReadOnly && (
                  <Flex w="100%" p="3">
                    <AddEmailProperty />
                  </Flex>
                )
              }
            />
          </>
        )}
      </VStack>
    </FilterGroupBox>
  );
}

function PropertyGroupContainer({
  data,
  parentId,
  onChange,
  isReadOnly,
  mainFilter = "",
  title,
  addPropertyGroupContent,
}: {
  data: DynamicListChildL1[];
  parentId: string;
  onChange: (data: DynamicListChildL1[]) => void;
  title: string;
  mainFilter?: string;
  isReadOnly?: boolean;
  addPropertyGroupContent?: ReactNode;
}) {
  //form web session index is always at last
  const formSessionGroupIndex = isFormSubmittedEvent(mainFilter)
    ? findFormSessionGroupInFilterGroup(data)
    : null;

  const hasFormSessionGroup = !isNull(formSessionGroupIndex);
  const separatedFormSessionGroup = hasFormSessionGroup
    ? data.slice(0, formSessionGroupIndex)
    : data;

  const firstGroupIndex = findFirstGroupIndex(data);

  function onRemovePropertyGroup(index: number) {
    const dataCopy = cloneDeep(data);
    dataCopy.splice(index, 1);
    onChange(dataCopy);
  }

  function onChangePropertyGroup(
    updateData: DynamicListChildL1,
    index: number
  ) {
    const dataCopy = cloneDeep(data);
    dataCopy[index] = updateData;
    onChange(dataCopy);
  }

  function setConnector(newConnector: CONNECTOR, index: number) {
    const dataCopy = cloneDeep(data);
    dataCopy[index].connector = newConnector;
    onChange(dataCopy);
  }

  function notFirstConnector(index: number) {
    return data.findIndex((i) => i.type === TYPE.GROUP) !== index;
  }

  function onChangeFormSessionPropGroups(propertyGroups: DynamicListChildL2[]) {
    if (hasFormSessionGroup) {
      const filterGroupChildren = cloneDeep(data);
      if (isEmpty(propertyGroups)) {
        filterGroupChildren.splice(formSessionGroupIndex, 1);
      } else {
        filterGroupChildren[formSessionGroupIndex].children = propertyGroups;
      }

      onChange(filterGroupChildren);
    }
  }

  return (
    <VStack alignItems="flex-start" spacing={2} w="100%">
      <VStack
        bg={mainFilter ? "grayV2.400" : undefined}
        w="100%"
        rounded="md"
        alignItems="flex-start"
        justifyContent="flex-start"
        hidden={
          firstGroupIndex === -1 || firstGroupIndex === formSessionGroupIndex
        }
      >
        <Flex px="2" mt="2">
          <Text fontSize={isReadOnly ? "xs" : "sm"} fontWeight="600">
            {title}
          </Text>
        </Flex>
        {separatedFormSessionGroup.map((item, index) => (
          <>
            {isTypeGroup(item.type) && (
              <>
                <Flex
                  alignItems={isReadOnly ? "baseline" : "flex-start"}
                  gap={isReadOnly ? 1 : 0}
                  px="2"
                  w="100%"
                >
                  {notFirstConnector(index) && (
                    <DynamicListLogicGate
                      operator={item.connector}
                      handleChange={(conn) => setConnector(conn, index)}
                      marginTop={1}
                      isReadOnly={isReadOnly}
                      mr="2"
                    />
                  )}

                  <PropertyGroup
                    mainFilter={data[0].value}
                    data={item}
                    onRemove={() => onRemovePropertyGroup(index)}
                    onChange={(data) => onChangePropertyGroup(data, index)}
                    isReadOnly={isReadOnly}
                  />
                </Flex>
              </>
            )}
          </>
        ))}
        {addPropertyGroupContent}
      </VStack>

      {hasFormSessionGroup && (
        <>
          {firstGroupIndex !== formSessionGroupIndex && (
            <DynamicListLogicGate
              operator={data[formSessionGroupIndex]?.connector}
              handleChange={(conn) => setConnector(conn, formSessionGroupIndex)}
              marginTop={1}
              isReadOnly={isReadOnly}
              mr="2"
            />
          )}

          <FormSessionPropertyGroupsContainer
            propertyGroups={data[formSessionGroupIndex]?.children ?? []}
            onChangePropertyGroups={onChangeFormSessionPropGroups}
            mainFormFilter={mainFilter || ""}
            parentId={parentId}
            isReadOnly={isReadOnly}
          />
        </>
      )}
    </VStack>
  );
}

function PropertyGroup({
  mainFilter,
  data,
  onRemove,
  onChange,
  isReadOnly,
}: {
  mainFilter: ValueTypes | null;
  data: DynamicListChildL1;
  onRemove: () => void;
  onChange: (data: DynamicListChildL1) => void;
  isReadOnly?: boolean;
}) {
  const dispatch = useAppDispatch();

  const { campaignDetails } = useSelector(selectCampaign);
  const { allFormsSummary } = useSelector(selectForm);
  const { formDetailsList, totalCampaignList } = useSelector(selectDynamicList);

  const {
    marketingEventNamesList: { data: marketingEventNamesList },
    templateListOfCampaign,
  } = useSelector(selectDynamicList);

  const [templateList, setTemplateList] =
    useState<{ label: string; value: string }[]>(TEMPLATE_LIST_INIT);

  const [formFieldsList, setFormsFieldsList] = useState<
    { label: string; value: string }[]
  >([]);

  const [fetchingFormFields, setFetchingFormFields] = useBoolean(false);

  const getFormFields = useCallback(
    async (formId: string) => {
      setFetchingFormFields.on();
      const res = await dispatch(getFormDetails(formId));
      setFetchingFormFields.off();
      if (isFulfilled(res.meta.requestStatus)) {
        const payload = res.payload as Awaited<
          ReturnType<typeof getFormDetailsApi>
        >;
        const fields = convertToLabelValue(payload.form.all_fields);
        setFormsFieldsList(fields);
      }
    },
    [dispatch, setFetchingFormFields]
  );

  const findFormFields = useCallback(
    (formId: string) => {
      if (formDetailsList[formId]?.data?.all_fields) {
        setFormsFieldsList(
          convertToLabelValue(
            formDetailsList[formId].data?.all_fields as AllFields
          )
        );
      }
    },
    [formDetailsList]
  );

  useEffect(() => {
    if (formDetailsList) {
      const formFilterProperty = data.children?.find(
        (x) => x.operator === FORM_IS_OPERATOR
      );
      if (formFilterProperty?.value?.length) {
        findFormFields(formFilterProperty?.value?.[0] as string);
      }
    }
  }, [data.children, findFormFields, formDetailsList]);

  const formsList = useMemo(
    () =>
      allFormsSummary.data.map((form) => {
        return { label: form.name, value: form.form_id };
      }),
    [allFormsSummary]
  );

  const campaignFilter = useMemo(
    () => findChildWithFilter(data.children, PROPERTY_FILTER_ID.CAMPAIGN),
    [data.children]
  );
  const templateFilter = useMemo(
    () => findChildWithFilter(data.children, PROPERTY_FILTER_ID.TEMPLATE),
    [data.children]
  );
  const reasonFilter = useMemo(
    () => findChildWithFilter(data.children, PROPERTY_FILTER_ID.REASON),
    [data.children]
  );
  const formFilter = useMemo(
    () => findChildWithFilter(data.children, PROPERTY_FILTER_ID.FORM),
    [data.children]
  );
  const formFieldFilter = useMemo(
    () => findChildWithFilter(data.children, PROPERTY_FILTER_ID.FORM_FIELD),
    [data.children]
  );

  const templateListLoading = useMemo(() => {
    const campaignId = campaignFilter?.value[0] as string;
    if (campaignId) {
      const templates = templateListOfCampaign[campaignId];
      return isLoading(templates?.loading);
    }
    return false;
  }, [campaignFilter, templateListOfCampaign]);

  useEffect(() => {
    const campaignId = campaignFilter?.value[0] as string;
    if (
      campaignFilter?.operator === IS_OPERATOR &&
      campaignId &&
      campaignId !== VALUE_ALL
    ) {
      const templates =
        templateListOfCampaign[campaignId]?.data.map((template) => {
          return {
            value: template.template_id,
            label: template.name,
          };
        }) ?? [];
      setTemplateList([...TEMPLATE_LIST_INIT, ...templates]);
    }
  }, [templateListOfCampaign, campaignFilter?.value, campaignFilter?.operator]);

  const campaignList: { label: string; value: string }[] = useMemo(() => {
    const data = totalCampaignList.data
      .filter(
        (item) =>
          campaignDetails.data.campaign_id !== item.campaign_id &&
          (item.activated_at || item.deactivated_at)
      )
      .map((item) => {
        return {
          label: item.name,
          value: item.campaign_id,
        };
      });
    return [...CAMPAIGN_LIST_INIT, ...data];
  }, [campaignDetails.data.campaign_id, totalCampaignList]);

  useEffect(() => {
    if (isInit(allFormsSummary.loading)) {
      dispatch(listAllForms());
    }
  }, [dispatch, allFormsSummary.loading]);

  useEffect(() => {
    dispatch(listAllCampaigns());
  }, [dispatch]);

  useEffect(() => {
    const campaignId = campaignFilter?.value[0];
    if (
      campaignFilter?.operator === IS_OPERATOR &&
      campaignId &&
      campaignId !== VALUE_ALL
    )
      dispatch(getTemplatesOfCampaign(campaignId as string));
  }, [dispatch, campaignFilter?.value, campaignFilter?.operator]);

  function onChangeHandler(
    newData: DynamicListChildL2,
    type: PROPERTY_FILTER_ID
  ) {
    const dataCopy = cloneDeep(data);
    if (dataCopy.children) {
      const index = dataCopy.children.findIndex(
        (child) => child.filter === type
      );
      if (index > -1) {
        dataCopy.children[index] = newData;

        if (type === PROPERTY_FILTER_ID.CAMPAIGN) {
          const templateFilterIndex = dataCopy.children.findIndex(
            (child) => child.filter === PROPERTY_FILTER_ID.TEMPLATE
          );
          if (templateFilterIndex > -1) {
            dataCopy.children[templateFilterIndex] = templateRow;
          }
        }

        if (type === PROPERTY_FILTER_ID.FORM) {
          const formFieldFilterIndex = dataCopy.children.findIndex(
            (child) => child.filter === PROPERTY_FILTER_ID.FORM_FIELD
          );

          if (
            dataCopy.children[index].operator === FORM_IS_NOT_OPERATOR ||
            formFieldFilterIndex > -1
          ) {
            // remove form field filter if form filter is updated
            dataCopy.children = [dataCopy.children[index]];
          }
        }
      }
      onChange(dataCopy);
    }
  }

  useEffect(() => {
    const formId = data.children?.[0].value?.[0] as string;
    const isFormOperator = data.children?.[0].operator === FORM_IS_OPERATOR;
    const hasFormFieldRow = data.children?.[1];
    if (
      isFormOperator &&
      formId &&
      hasFormFieldRow &&
      !formDetailsList[formId]
    ) {
      getFormFields(formId as string);
    }
  }, [data.children, formDetailsList, getFormFields]);

  function addFormFieldFilter() {
    const dataCopy = cloneDeep(data);

    if (dataCopy.children) {
      // if form id filter and form is operator is selected fetch form fields
      if (dataCopy.children[0].value?.[0]) {
        getFormFields(dataCopy.children?.[0].value?.[0] as string);
      }
      dataCopy.children.push(FORM_FIELD_PROPERTY_GROUP_CHILD);
    }
    onChange(dataCopy);
  }

  function onRemoveFormFieldFilterGroup() {
    const dataCopy = cloneDeep(data);
    const formFieldFilterIndex =
      dataCopy.children?.findIndex(
        (child) => child.filter === PROPERTY_FILTER_ID.FORM_FIELD
      ) ?? -1;
    if (formFieldFilterIndex > -1) {
      dataCopy.children?.splice(formFieldFilterIndex, 1);
    }
    onChange(dataCopy);
  }

  function getArgumentDetails(type: string) {
    return mainFilter
      ? marketingEventNamesList
          ?.find((x) => x.id === mainFilter[0])
          ?.arguments?.find((x) => x.id === type)
      : undefined;
  }

  return (
    <Flex
      w="100%"
      flexWrap="nowrap"
      alignItems="baseline"
      justifyContent="space-between"
      px={isReadOnly ? 2 : 0}
    >
      <VStack spacing="1" alignItems="flex-start" w="100%">
        {campaignFilter && (
          <PropertyGroupRow
            onChange={(data) =>
              onChangeHandler(data, PROPERTY_FILTER_ID.CAMPAIGN)
            }
            data={campaignFilter}
            argumentData={getArgumentDetails(PROPERTY_FILTER_ID.CAMPAIGN)}
            argumentSelectList={campaignList}
            isReadOnly={isReadOnly}
            isListLoading={isLoading(totalCampaignList.loading)}
          />
        )}
        {templateFilter && (
          <PropertyGroupRow
            onChange={(data) =>
              onChangeHandler(data, PROPERTY_FILTER_ID.TEMPLATE)
            }
            data={templateFilter}
            argumentData={getArgumentDetails(PROPERTY_FILTER_ID.TEMPLATE)}
            argumentSelectList={templateList}
            isReadOnly={isReadOnly}
            isListLoading={templateListLoading}
          />
        )}
        {reasonFilter && (
          <PropertyGroupRow
            onChange={(data) =>
              onChangeHandler(data, PROPERTY_FILTER_ID.REASON)
            }
            data={reasonFilter}
            argumentData={getArgumentDetails(PROPERTY_FILTER_ID.REASON)}
            isReadOnly={isReadOnly}
          />
        )}
        {formFilter && (
          <>
            <HStack>
              <PropertyGroupRow
                onChange={(data) =>
                  onChangeHandler(data, PROPERTY_FILTER_ID.FORM)
                }
                data={formFilter}
                argumentData={getArgumentDetails(PROPERTY_FILTER_ID.FORM)}
                argumentSelectList={formsList}
                isListLoading={isLoading(allFormsSummary.loading)}
                isReadOnly={isReadOnly}
              />
            </HStack>
            {formFilter.operator === FORM_IS_OPERATOR &&
              !!formFilter.value?.length &&
              !formFieldFilter && (
                <AddPropertyButton
                  hidden={isReadOnly}
                  onClick={addFormFieldFilter}
                  children="Add form field"
                  leftIcon={<IoDocumentTextSharp fontSize="12px" />}
                  width="150px"
                />
              )}
          </>
        )}
        {formFieldFilter && (
          <FormFieldPropertyGroupRow
            onChange={(data) =>
              onChangeHandler(data, PROPERTY_FILTER_ID.FORM_FIELD)
            }
            data={formFieldFilter}
            argumentData={getArgumentDetails(PROPERTY_FILTER_ID.FORM_FIELD)}
            argumentSelectList={formFieldsList}
            isListLoading={fetchingFormFields}
            isReadOnly={isReadOnly}
            onRemove={onRemoveFormFieldFilterGroup}
          />
        )}
      </VStack>
      {!isReadOnly && <RemoveRowCloseButton onClick={onRemove} />}
    </Flex>
  );
}

function PropertyGroupRow({
  data,
  onChange,
  argumentData,
  argumentSelectList,
  isReadOnly,
  isListLoading = false,
}: {
  data: DynamicListChildL2;
  onChange: (data: DynamicListChildL2) => void;
  argumentData?: MarketingActivityArgument;
  argumentSelectList?: { label: string; value: string }[];
  isReadOnly?: boolean;
  isListLoading?: boolean;
}) {
  const {
    operators: { data: operators },
  } = useSelector(selectDynamicList);

  const { activeErrorCheck } = useContext(DynamicListContext);

  const operatorDetails = useMemo(
    () => getOperatorDetails(data.operator, operators, argumentData?.data_type),
    [data.operator, operators, argumentData]
  );

  function setOperator(operator: string) {
    const dataCopy = cloneDeep(data);
    dataCopy.operator = operator;
    dataCopy.value = [];
    dataCopy.validation_error = "";
    onChange(dataCopy);
  }

  function handleValueChange(val: ValueTypes) {
    const dataCopy = cloneDeep(data);
    if (
      operatorDetails?.arguments_types?.length &&
      val.length &&
      val.some((x) => x === "")
    ) {
      dataCopy.validation_error = "Invalid values";
    } else {
      dataCopy.validation_error = "";
    }
    dataCopy.value = val;
    onChange(dataCopy);
  }

  const wrapperStyle = isReadOnly
    ? {
        p: "0",
        spacing: "1",
      }
    : {
        spacing: "3",
        py: "2",
        gridGap: "2",
        width: "100%",
      };

  const passArgumentSelectList = OPERATORS_WITH_ARG_LIST.includes(
    data.operator ?? ""
  );

  const commonFieldProps = {
    validationError: activeErrorCheck ? data.validation_error : "",
    isReadOnly,
  };

  if (argumentData) {
    return (
      <HStack alignItems="center" wrap="wrap" {...wrapperStyle}>
        {isReadOnly ? (
          <Text fontSize="sm" textAlign="right">
            {argumentData.display}
          </Text>
        ) : (
          <HStack h="33px">
            <Text fontSize="sm" textAlign="right">
              {addEllipsisToText(argumentData.display)}
            </Text>
          </HStack>
        )}
        {operators && (
          <ValueSelectFields
            options={getOperatorsListForDisplay(
              argumentData.data_type,
              operators
            )}
            value={(data.operator as string) || ""}
            onChange={setOperator}
            {...commonFieldProps}
          />
        )}
        {data.operator && operatorDetails && (
          <DynamicListValueFields
            value={data.value}
            onChange={handleValueChange}
            argumentTypes={operatorDetails.arguments_types}
            argumentSelectList={
              passArgumentSelectList ? argumentSelectList : undefined
            }
            filter={data.filter as PROPERTY_FILTER_ID}
            noOfArguments={operatorDetails.arguments}
            helperText={operatorDetails.display_2}
            isLoading={isListLoading}
            {...commonFieldProps}
          />
        )}
      </HStack>
    );
  }

  return <></>;
}

function FormFieldPropertyGroupRow({
  data,
  onChange,
  argumentData,
  argumentSelectList,
  isReadOnly,
  isListLoading = false,
  onRemove,
}: {
  data: DynamicListChildL2;
  onChange: (data: DynamicListChildL2) => void;
  argumentData?: MarketingActivityArgument;
  argumentSelectList?: { label: string; value: string }[];
  isReadOnly?: boolean;
  isListLoading?: boolean;
  onRemove: () => void;
}) {
  const {
    operators: { data: operators },
  } = useSelector(selectDynamicList);

  const { activeErrorCheck } = useContext(DynamicListContext);

  const operatorDetails = useMemo(
    () => getOperatorDetails(data.operator, operators, argumentData?.data_type),
    [data.operator, operators, argumentData]
  );

  function setPropertyValue(
    key: DL_FIELD.OPERATOR | DL_FIELD.PROPERTY,
    val: string
  ) {
    const dataCopy = cloneDeep(data);
    dataCopy[key] = val;
    dataCopy.value = [];
    dataCopy.validation_error = "";
    onChange(dataCopy);
  }

  function handleValueChange(val: ValueTypes) {
    const dataCopy = cloneDeep(data);
    if (
      operatorDetails?.arguments_types?.length &&
      val.length &&
      val.some((x) => x === "")
    ) {
      dataCopy.validation_error = "Invalid values";
    } else {
      dataCopy.validation_error = "";
    }
    dataCopy.value = val;
    onChange(dataCopy);
  }

  const wrapperStyle = isReadOnly
    ? {
        p: "0",
        spacing: "1",
      }
    : {
        spacing: "3",
        gridGap: "2",
      };

  const commonFieldProps = {
    validationError: activeErrorCheck ? data.validation_error : "",
    isReadOnly,
  };

  if (argumentData) {
    return (
      <Flex w="100%" alignItems="flex-start">
        <HStack alignItems="flex-start" wrap="wrap" flex="1" {...wrapperStyle}>
          <Center h="33px">
            {isReadOnly ? (
              <Text fontSize="sm" textAlign="right">
                {argumentData.display}
              </Text>
            ) : (
              <Text fontSize="sm" textAlign="right">
                {addEllipsisToText(argumentData.display)}
              </Text>
            )}
          </Center>
          <ValueSelectFields
            options={argumentSelectList ?? []}
            value={data.property || ""}
            onChange={(val) => setPropertyValue(DL_FIELD.PROPERTY, val)}
            {...commonFieldProps}
          />

          {operators && data.property && (
            <ValueSelectFields
              options={getOperatorsListForDisplay(
                OperatorType.STRING,
                operators
              )}
              value={(data.operator as string) || ""}
              onChange={(val) => setPropertyValue(DL_FIELD.OPERATOR, val)}
              {...commonFieldProps}
            />
          )}

          {data.operator && operatorDetails && (
            <DynamicListValueFields
              value={data.value}
              onChange={handleValueChange}
              argumentTypes={operatorDetails.arguments_types}
              filter={data.filter as PROPERTY_FILTER_ID}
              noOfArguments={operatorDetails.arguments}
              helperText={operatorDetails.display_2}
              isLoading={isListLoading}
              stackStyleProps={{ minWidth: "200px" }}
              {...commonFieldProps}
            />
          )}
        </HStack>
        <Box>{!isReadOnly && <RemoveRowCloseButton onClick={onRemove} />}</Box>
      </Flex>
    );
  }

  return <></>;
}
