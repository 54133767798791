import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  ResponsiveValue,
  Flex,
  Icon,
  ModalBodyProps,
  BoxProps,
  Box,
  DrawerFooter,
  ModalHeaderProps,
  ModalOverlayProps,
} from "@chakra-ui/react";
import React, { forwardRef, ReactNode } from "react";
import { FaArrowLeft } from "react-icons/fa";
import IButton from "../../../../components/IButton";

const CommonDrawer = forwardRef(function CommonDrawer({
  goBack,
  title,
  isOpen,
  size,
  placement = "left",
  onClose,
  btnRef,
  variant,
  children,
  drawerBodyProps,
  drawerHeaderProps,
  drawerContentProps,
  additionalHeaderContent,
  closeOnOverlayClick,
  drawerOverlayProps,
  footer,
}: {
  goBack?: () => void;
  title: string | ReactNode;
  isOpen: boolean;
  size?:
    | ResponsiveValue<
        | (string & {})
        | "xs"
        | "sm"
        | "md"
        | "lg"
        | "xl"
        | "2xl"
        | "3xl"
        | "4xl"
        | "full"
      >
    | undefined;
  placement?: "left" | "right" | "top" | "bottom";
  onClose: () => void;
  btnRef?: React.MutableRefObject<any>;
  variant?: string;
  children: ReactNode;
  drawerBodyProps?: ModalBodyProps;
  drawerHeaderProps?: ModalHeaderProps;
  drawerContentProps?: BoxProps;
  additionalHeaderContent?: ReactNode;
  closeOnOverlayClick?: boolean;
  footer?: ReactNode;
  drawerOverlayProps?: ModalOverlayProps;
}) {
  return (
    <Drawer
      isOpen={isOpen}
      placement={placement}
      onClose={onClose}
      finalFocusRef={btnRef?.current}
      size={size ?? "lg"}
      variant={variant ?? "primary"}
      closeOnOverlayClick={closeOnOverlayClick}
    >
      <DrawerOverlay {...drawerOverlayProps} />
      <DrawerContent {...drawerContentProps}>
        {!!goBack && (
          <Flex alignItems="center" mt="20px" ml="20px">
            <IButton
              size="md"
              aria-label="Go back"
              variant="link"
              name="return-button"
              onClick={goBack}
              fontSize="xs"
              leftIcon={<Icon fontSize="10px" as={FaArrowLeft} />}
            >
              Back
            </IButton>
          </Flex>
        )}

        <DrawerHeader {...drawerHeaderProps}>
          <Box display="flex" justifyContent="space-between">
            {title}
            {additionalHeaderContent}
            <DrawerCloseButton mt={!!additionalHeaderContent ? "4px" : "0px"} />
          </Box>
        </DrawerHeader>
        <DrawerBody {...drawerBodyProps}>{children}</DrawerBody>
        {footer && <DrawerFooter>{footer}</DrawerFooter>}
      </DrawerContent>
    </Drawer>
  );
});

const MemoizedCommonDrawer = React.memo(CommonDrawer);
export default MemoizedCommonDrawer;
