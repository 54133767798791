import { VStack } from "@chakra-ui/react";
import { cloneDeep } from "lodash";
import { ObjectSettings } from "../../../../../../../../../../../common/types/connection";
import {
  ConnectionListItem,
  MappedFieldsDict,
  MappingElement,
  Source,
  SYNC_PREFERENCE,
} from "../../../../../../../../../../../common/types/unifiedMapping";
import { ColumnRow } from "./ColumnRow";

export function EmailColumnRows({
  rows,
  sources,
  data,
  onChange,
  readonly,
  objectSettings,
}: {
  rows: MappedFieldsDict;
  sources: ConnectionListItem[];
  data: MappingElement;
  onChange: (data: MappingElement) => void;
  readonly: boolean;
  objectSettings: ObjectSettings | null;
}) {
  const props = {
    sources: sources,
    remove: () => {},
  };

  function onChangeLocal(updatedData: MappingElement) {
    const clonedData = cloneDeep(data);
    let updatedSources: Source[] | null = null;
    if (clonedData.source) {
      updatedSources = clonedData.source.map((source) => {
        if (
          updatedData.source &&
          source.connection_id === updatedData.source[0].connection_id
        ) {
          source = { ...updatedData.source[0] };
          // Always use two way sync for email
          source.sync_preference = SYNC_PREFERENCE.TWO_WAY;
        }
        return source;
      });
    } else {
      updatedSources = cloneDeep(updatedData.source);
    }
    clonedData.source = updatedSources ? [...updatedSources] : null;
    onChange(clonedData);
  }

  return (
    <VStack width="100%">
      {data.source ? (
        data.source?.map((source, index) => {
          const dataWithSingleSource = { ...data, source: [source] };
          return (
            <ColumnRow
              rows={rows}
              readonly={readonly}
              data={dataWithSingleSource}
              onChange={(data) => onChangeLocal(data)}
              disableSource={true}
              hideTitle={index > 0}
              {...props}
              selectedSource={source}
              syncPreference={
                source?.sync_preference || SYNC_PREFERENCE.TWO_WAY
              }
              contact={source.field.contact}
              lead={source.field.lead}
              objectSettings={objectSettings}
              hideSyncPref
            />
          );
        })
      ) : (
        <ColumnRow
          rows={rows}
          readonly={readonly}
          data={data}
          onChange={(data) => onChangeLocal(data)}
          sources={sources}
          selectedSource={null}
          syncPreference={null}
          contact={null}
          lead={null}
          remove={() => {}}
          objectSettings={objectSettings}
          hideSyncPref
        />
      )}
    </VStack>
  );
}
