import { Flex, Icon, Text } from "@chakra-ui/react";
import { FaUserCircle } from "react-icons/fa";
import { useSelector } from "react-redux";
import { WIDGET_CONTAINER_CLASS } from "./constants";
import { selectCampaign } from "../../campaignSlice";
import { FlowStepSourceHandle } from "./FlowHandle";
import {
  addSuffixForPlural,
  getPersonLabel,
} from "../../../../../common/helper/commonHelper";
import { useContext, useMemo } from "react";
import { CampaignBuilderContext } from "../flowSlice";

export default function DynamicListCount({ data }: { data: {} }) {
  const {
    dynamicListCount: { data: dynamicListCount },
  } = useSelector(selectCampaign);

  const { campaignContext } = useContext(CampaignBuilderContext);

  const contactsEnteredJourneyText = useMemo(() => {
    const contactsEnteredJourney = dynamicListCount ?? 0;
    return `${contactsEnteredJourney} ${addSuffixForPlural(
      getPersonLabel(campaignContext),
      contactsEnteredJourney
    )} ${contactsEnteredJourney > 1 ? "have" : "has"} entered the journey`;
  }, [dynamicListCount, campaignContext]);

  return (
    <Flex
      w="sm"
      alignItems="center"
      flexDir="column"
      className={WIDGET_CONTAINER_CLASS}
    >
      <Flex
        alignItems="center"
        backgroundColor="white"
        borderRadius="md"
        boxShadow="md"
        p={2}
      >
        <Icon as={FaUserCircle} fontSize="md" mr="2" />
        <Text fontSize="sm" color="gray.500" fontWeight="bold">
          {contactsEnteredJourneyText}
        </Text>
      </Flex>
      <FlowStepSourceHandle />
    </Flex>
  );
}
