import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  Person,
  PersonComplete,
  PersonEvent,
  MappingDestination,
  PersonUpdateFields,
  MAPPING_FIELD,
  PersonDestinationFields,
  PersonMarketingActivitiesDetails,
  PageVisitType,
} from "../../../common/types/person";
import {
  EmailSentDetails,
  MarketingPersonListItem,
} from "../../../common/types/campaign";
import {
  listPersonApi,
  listPersonActivitiesApi,
  getPersonApi,
  getEmailSentApi,
  updatePersonApi,
  removePersonApi,
  createPersonApi,
  listProductPersonApi,
  listMarketingPersonApi,
  getOrganisationTraitsApi,
  listPersonMarketingActivitiesApi,
  getSessionSummaryApi,
} from "../../../common/api/campaign/person";
import { RootState } from "../../../store";
import {
  LoadingWithData,
  PaginationType,
  SearchAssetsType,
  SearchAssetWithPage,
} from "../../../common/types/common";
import {
  INITIAL_PAGINATION,
  INITIAL_PAGINATION_15_ITEMS,
  LOADING_STATES,
} from "../../../common/constants/common";
import { toast } from "react-toastify";
import {
  createAsyncThunkWrapper,
  initializeLoadingData,
} from "../../../common/helper/commonHelper";
import { SOURCES } from "../../../common/types/unifiedMapping";
import {
  getSalesforcePersonSyncErrorApi,
  remapSalesforcePersonApi,
  resyncSalesforcePersonApi,
} from "../../../common/api/integrations/salesforce";
import { SalesforceSyncError } from "../../../common/types/connection";
import { getMappingDestinationApi } from "../../../common/api/integrations/connection";
interface PersonState {
  personDetails: LoadingWithData<PersonComplete[]>;
  personMappingDetails: LoadingWithData<PersonDestinationFields>;
  updatingPersonDetails: LOADING_STATES;
  person: PaginationType<Person>;
  personActivity: PaginationType<PersonEvent>;

  personMarketingActivity: PaginationType<PersonMarketingActivitiesDetails>;
  emailSent: LoadingWithData<{ [key: string]: EmailSentDetails }>;
  deletingPerson: LOADING_STATES;
  //productPersonList is dynamic data ie, The data can vary depending on the product_user
  productPersonList: LoadingWithData<{ [key: string]: any }[]>;
  marketingPersonList: LoadingWithData<MarketingPersonListItem[]>;
  syncErrorList: LoadingWithData<SalesforceSyncError[]>;
  remappingPerson: LOADING_STATES;
  organisationDetails: LoadingWithData<{ [key: string]: any }>;
  sessionSummaryList: {
    [key: string]: LoadingWithData<PageVisitType[] | null>;
  };
}

const initialState: PersonState = {
  personDetails: initializeLoadingData([]),
  personMappingDetails: initializeLoadingData([]),
  updatingPersonDetails: LOADING_STATES.INIT,
  person: INITIAL_PAGINATION_15_ITEMS,
  personActivity: INITIAL_PAGINATION_15_ITEMS,

  personMarketingActivity: INITIAL_PAGINATION_15_ITEMS,
  emailSent: initializeLoadingData({}),
  deletingPerson: LOADING_STATES.INIT,
  productPersonList: initializeLoadingData([]),
  marketingPersonList: initializeLoadingData([]),
  syncErrorList: initializeLoadingData([]),
  remappingPerson: LOADING_STATES.INIT,
  organisationDetails: initializeLoadingData({}),
  sessionSummaryList: {},
};

export const listPerson = createAsyncThunk(
  "person/list",
  async (
    { pageNo, searchKeyword, columnsToSearchIn, filters }: SearchAssetWithPage,
    thunkApi
  ) => {
    const {
      person: {
        person: { pageSize },
      },
    } = thunkApi.getState() as RootState;
    return await listPersonApi({
      pageSize,
      pageNo,
      searchKeyword,
      columnsToSearchIn,
      filters,
    });
  }
);

export const listPersonActivity = createAsyncThunk(
  "person-activity/list",
  async (
    {
      personId,
      searchKeyword,
      columnsToSearchIn,
    }: { personId: string } & SearchAssetsType,
    thunkApi
  ) => {
    const { person } = thunkApi.getState() as RootState;
    return await listPersonActivitiesApi({
      pageSize: person.personActivity.pageSize,
      pageNo: person.personActivity.currentPageNo,
      personId,
      searchKeyword,
      columnsToSearchIn,
    });
  }
);

export const listPersonMarketingActivity = createAsyncThunk(
  "person-marketing-activity/list",
  async (
    {
      personId,
      startDate,
      endDate,
    }: { personId: string; startDate: string; endDate: string },
    thunkApi
  ) => {
    const { person } = thunkApi.getState() as RootState;
    return await listPersonMarketingActivitiesApi(
      person.personMarketingActivity.pageSize,
      person.personMarketingActivity.currentPageNo,
      personId,
      startDate,
      endDate
    );
  }
);

export const getEmailSent = createAsyncThunk(
  "person-marketing-activity/get-email",
  async ({
    personId,
    correlationId,
  }: {
    personId: string;
    correlationId: string;
  }) => {
    return await getEmailSentApi(personId, correlationId);
  }
);

export const removePerson = createAsyncThunk(
  "person/remove",
  async ({ id, email }: { id: string; email: string }) => {
    return await removePersonApi(id, email);
  }
);

export const getPerson = createAsyncThunk("person/get", async (id: string) => {
  return await getPersonApi(id);
});

export const updatePerson = createAsyncThunk(
  "person/update",
  async (data: PersonUpdateFields) => {
    return await updatePersonApi(data);
  }
);

export const createPersonWithEmail = createAsyncThunk(
  "person/create",
  async (email: string) => {
    return await createPersonApi(email);
  }
);

export const getProductPerson = createAsyncThunk(
  "person/product-person/get",
  async (id: string) => {
    return await listProductPersonApi(id);
  }
);

export const getPersonMappingDetails = createAsyncThunkWrapper({
  actionName: "person/mapping-destination",
  dispatchFn: async () => {
    return await getMappingDestinationApi();
  },
  isCachable: true,
});

export const listMarketingPerson = createAsyncThunk(
  "person/marketing-person/list",
  async ({ email, source }: { email: string; source: SOURCES }) => {
    return await listMarketingPersonApi(email, source);
  }
);

export const resyncSalesforcePerson = createAsyncThunk(
  "person/marketing-person/resync",
  async (email: string) => {
    return await resyncSalesforcePersonApi(email);
  }
);

export const remapSalesforcePerson = createAsyncThunk(
  "person/marketing-person/remap",
  async ({ email, sfId }: { email: string; sfId: string }) => {
    return await remapSalesforcePersonApi(sfId, email);
  }
);

export const getSalesforcePersonSyncError = createAsyncThunk(
  "person/marketing-person/get-sf-sync-error",
  async ({ email }: { email: string }) => {
    return await getSalesforcePersonSyncErrorApi(email);
  }
);

export const getOrganisationTraits = createAsyncThunk(
  "person/organisation-trait",
  async (orgId: string) => {
    return await getOrganisationTraitsApi(orgId);
  }
);

export const getSessionSummary = createAsyncThunk(
  "person/session-summary",
  async ({ personId, sessionId }: { personId: string; sessionId: string }) => {
    return await getSessionSummaryApi(personId, sessionId);
  }
);

function getPersonDestinationFields(
  destination: MappingDestination[]
): PersonDestinationFields {
  const destinationFields = destination
    .find((mappingDetails) => {
      return mappingDetails.mapping_field === MAPPING_FIELD.PERSON_MAPPING;
    })
    ?.columns.map((details) => {
      return { [details.name]: { ...details } };
    });
  return Object.assign({}, ...destinationFields!);
}

const personDbSlice = createSlice({
  name: "person",
  initialState,
  reducers: {
    setActivityPage(state, action: PayloadAction<number>) {
      const pageNo = action.payload;
      if (pageNo && pageNo <= (state.personActivity.totalPageCount ?? 1)) {
        state.personActivity.currentPageNo = pageNo;
        state.personActivity.changingPage = true;
      }
    },
    setMarketingActivityPage(state, action: PayloadAction<number>) {
      const pageNo = action.payload;
      if (
        pageNo &&
        pageNo <= (state.personMarketingActivity.totalPageCount ?? 1)
      ) {
        state.personMarketingActivity.currentPageNo = pageNo;
        state.personMarketingActivity.changingPage = true;
      }
    },
    resetPersonDetailsPage(state) {
      state.personActivity = INITIAL_PAGINATION;
      state.personDetails = initializeLoadingData([]);
      state.marketingPersonList = initializeLoadingData([]);
    },
    resetPersonList(state) {
      state.person = initialState.person;
    },
    resetMarketingActivityPage(state) {
      state.personMarketingActivity = INITIAL_PAGINATION;
      state.emailSent = initializeLoadingData({});
      state.sessionSummaryList = {};
    },
  },
  extraReducers: (builder) => {
    builder
      // person list
      .addCase(listPerson.pending, (state, action) => {
        state.person.fetchingList = true;
        state.person.currentPageNo = action.meta.arg.pageNo;
      })
      .addCase(listPerson.fulfilled, (state, action) => {
        state.person.list = action.payload.records;
        state.person.totalPageCount = action.payload.page_count;
        state.person.count = action.payload.record_count;
        state.person.fetchingList = false;
        state.person.changingPage = false;
      })
      .addCase(listPerson.rejected, (state) => {
        state.person.totalPageCount = 0;
        state.person.fetchingList = false;
        state.person.changingPage = false;
      })

      // Get person details
      .addCase(getPerson.pending, (state) => {
        state.personDetails.loading = LOADING_STATES.LOADING;
      })
      .addCase(getPerson.fulfilled, (state, action) => {
        state.personDetails.loading = LOADING_STATES.SUCCESS;
        state.personDetails.data = action.payload.rows;
      })
      .addCase(getPerson.rejected, (state) => {
        state.personDetails.loading = LOADING_STATES.FAILED;
      })

      // create person
      .addCase(createPersonWithEmail.fulfilled, (state, action) => {
        if (action.payload.success)
          toast.success("Contact created successfully");
      })

      // person activity list
      .addCase(listPersonActivity.pending, (state) => {
        state.personActivity.fetchingList = true;
      })
      .addCase(listPersonActivity.fulfilled, (state, action) => {
        state.personActivity.list = action.payload.records;
        state.personActivity.totalPageCount = action.payload.page_count;
        state.personActivity.count = action.payload.record_count;
        state.personActivity.fetchingList = false;
        state.personActivity.changingPage = false;
      })
      .addCase(listPersonActivity.rejected, (state) => {
        state.personActivity.totalPageCount = 0;
        state.personActivity.fetchingList = false;
        state.personActivity.changingPage = false;
      })

      // person marketing activity list
      .addCase(listPersonMarketingActivity.pending, (state) => {
        state.personMarketingActivity.fetchingList = true;
      })
      .addCase(listPersonMarketingActivity.fulfilled, (state, action) => {
        state.personMarketingActivity.list = action.payload.records;
        state.personMarketingActivity.totalPageCount =
          action.payload.page_count;
        state.personMarketingActivity.count = action.payload.record_count;
        state.personMarketingActivity.fetchingList = false;
        state.personMarketingActivity.changingPage = false;
      })
      .addCase(listPersonMarketingActivity.rejected, (state) => {
        state.personMarketingActivity.totalPageCount = 0;
        state.personMarketingActivity.fetchingList = false;
        state.personMarketingActivity.changingPage = false;
      })

      // get the sent email template
      .addCase(getEmailSent.pending, (state) => {
        state.emailSent.loading = LOADING_STATES.LOADING;
      })
      .addCase(getEmailSent.fulfilled, (state, action) => {
        state.emailSent.loading = LOADING_STATES.SUCCESS;
        state.emailSent.data = {
          ...state.emailSent.data,
          [action.meta.arg.correlationId]: action.payload.email,
        };
      })
      .addCase(getEmailSent.rejected, (state) => {
        state.emailSent.loading = LOADING_STATES.FAILED;
      })

      // updating contact
      .addCase(updatePerson.pending, (state) => {
        state.updatingPersonDetails = LOADING_STATES.LOADING;
      })
      .addCase(updatePerson.fulfilled, (state, action) => {
        if (action.payload.status) {
          state.updatingPersonDetails = LOADING_STATES.SUCCESS;
          toast.success("Contact updated succesfully");
        } else {
          state.updatingPersonDetails = LOADING_STATES.FAILED;
          toast.error("Contact updation failed");
        }
      })
      .addCase(updatePerson.rejected, (state) => {
        state.updatingPersonDetails = LOADING_STATES.FAILED;
      })

      // remove contact
      .addCase(removePerson.pending, (state, action) => {
        state.deletingPerson = LOADING_STATES.LOADING;
      })
      .addCase(removePerson.fulfilled, (state, action) => {
        if (action.payload.success) {
          toast.success("Contact deleted successfully");
          state.deletingPerson = LOADING_STATES.SUCCESS;
        } else {
          state.deletingPerson = LOADING_STATES.FAILED;
          toast.error("Contact deletion failed");
        }
      })
      .addCase(removePerson.rejected, (state, action) => {
        state.deletingPerson = LOADING_STATES.FAILED;
      })

      // product person
      .addCase(getProductPerson.pending, (state) => {
        state.productPersonList.loading = LOADING_STATES.LOADING;
      })
      .addCase(getProductPerson.fulfilled, (state, action) => {
        state.productPersonList.loading = LOADING_STATES.SUCCESS;
        state.productPersonList.data = action.payload.product_users;
      })
      .addCase(getProductPerson.rejected, (state) => {
        state.productPersonList.loading = LOADING_STATES.FAILED;
      })

      // marketing person list
      .addCase(listMarketingPerson.pending, (state) => {
        state.marketingPersonList.loading = LOADING_STATES.LOADING;
      })
      .addCase(listMarketingPerson.fulfilled, (state, action) => {
        state.marketingPersonList.loading = LOADING_STATES.SUCCESS;
        state.marketingPersonList.data = action.payload.rows;
      })
      .addCase(listMarketingPerson.rejected, (state) => {
        state.marketingPersonList.loading = LOADING_STATES.FAILED;
      })

      // resync salesforce person
      .addCase(resyncSalesforcePerson.pending, (state) => {
        state.marketingPersonList.loading = LOADING_STATES.LOADING;
      })
      .addCase(resyncSalesforcePerson.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.marketingPersonList.loading = LOADING_STATES.SUCCESS;
          toast.success("Record succesfully scheduled to sync");
        } else {
          state.marketingPersonList.loading = LOADING_STATES.FAILED;
          toast.error("Resync record failed");
        }
      })
      .addCase(resyncSalesforcePerson.rejected, (state) => {
        state.marketingPersonList.loading = LOADING_STATES.FAILED;
      })

      // remap salesforce person
      .addCase(remapSalesforcePerson.pending, (state) => {
        state.remappingPerson = LOADING_STATES.LOADING;
      })
      .addCase(remapSalesforcePerson.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.remappingPerson = LOADING_STATES.SUCCESS;
          state.marketingPersonList.loading = LOADING_STATES.INIT;
          toast.success("Remapped successfuly!");
        } else {
          state.remappingPerson = LOADING_STATES.FAILED;
          toast.error("Remap failed!");
        }
      })
      .addCase(remapSalesforcePerson.rejected, (state) => {
        state.marketingPersonList.loading = LOADING_STATES.FAILED;
      })

      // get sync error
      .addCase(getSalesforcePersonSyncError.pending, (state) => {
        state.syncErrorList.loading = LOADING_STATES.LOADING;
      })
      .addCase(getSalesforcePersonSyncError.fulfilled, (state, action) => {
        state.syncErrorList.loading = LOADING_STATES.SUCCESS;
        state.syncErrorList.data = action.payload.error.rows;
      })
      .addCase(getSalesforcePersonSyncError.rejected, (state) => {
        state.syncErrorList.loading = LOADING_STATES.FAILED;
      })

      //get person mapping details
      .addCase(getPersonMappingDetails.pending, (state) => {
        state.personMappingDetails.loading = LOADING_STATES.LOADING;
      })
      .addCase(getPersonMappingDetails.fulfilled, (state, action) => {
        state.personMappingDetails.loading = LOADING_STATES.SUCCESS;
        //we need only person mapping details here
        state.personMappingDetails.data = getPersonDestinationFields(
          action.payload.destination
        );
      })
      .addCase(getPersonMappingDetails.rejected, (state) => {
        state.personMappingDetails.loading = LOADING_STATES.FAILED;
      })

      .addCase(getOrganisationTraits.pending, (state) => {
        state.organisationDetails.loading = LOADING_STATES.LOADING;
      })
      .addCase(getOrganisationTraits.fulfilled, (state, action) => {
        state.organisationDetails.loading = LOADING_STATES.SUCCESS;
        state.organisationDetails.data = action.payload.organisation_details[0];
      })
      .addCase(getOrganisationTraits.rejected, (state) => {
        state.organisationDetails.loading = LOADING_STATES.FAILED;
      })

      //get session details
      .addCase(getSessionSummary.pending, (state, { meta: { arg } }) => {
        state.sessionSummaryList = {
          ...state.sessionSummaryList,
          [arg.sessionId]: {
            loading: LOADING_STATES.LOADING,
            data: null,
          },
        };
      })
      .addCase(
        getSessionSummary.fulfilled,
        (state, { payload: { data }, meta: { arg } }) => {
          state.sessionSummaryList = {
            ...state.sessionSummaryList,
            [arg.sessionId]: {
              loading: LOADING_STATES.SUCCESS,
              data: data.page_visits,
            },
          };
        }
      )
      .addCase(getSessionSummary.rejected, (state, { meta: { arg } }) => {
        state.sessionSummaryList = {
          ...state.sessionSummaryList,
          [arg.sessionId]: {
            loading: LOADING_STATES.FAILED,
            data: null,
          },
        };
      });
  },
});

export const {
  setActivityPage,
  resetPersonDetailsPage,
  setMarketingActivityPage,
  resetMarketingActivityPage,
  resetPersonList,
} = personDbSlice.actions;

export const selectPerson = (state: RootState) => state.person;

export default personDbSlice.reducer;
