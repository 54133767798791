import {
  Box,
  Card,
  CardBody,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Portal,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { cloneDeep } from "lodash";
import {
  memo,
  MutableRefObject,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { FaCheck, FaPencilAlt, FaRegLightbulb, FaTimes } from "react-icons/fa";
import { IoLogOut } from "react-icons/io5";
import { useSelector } from "react-redux";
import { NodeProps } from "reactflow";
import { FLOW_ACTIONS } from "../../../../../../common/constants/campaign";
import { onEnterKeySubmit } from "../../../../../../common/helper/commonHelper";
import {
  DynamicListType,
  FlowGroupActionOptions,
  GroupExitCriteriaType,
} from "../../../../../../common/types/campaign";
import {
  ActionNodeArgs,
  ActionNodeIdentifiers,
} from "../../../../../../common/types/flow";
import { useAppDispatch } from "../../../../../../store";
import CampaignExitCriteria from "../../../exit-criteria/CampaignExitCriteria";
import {
  addFlowEdge,
  CampaignBuilderContext,
  removeFlowAction,
  removeFlowEdge,
  selectFlow,
  setFlowValidity,
} from "../../flowSlice";
import { HANDLE_IDS, WIDGET_OPTIONS_DETAILS } from "../constants";
import {
  ExitCriteriaHandle,
  FlowStepSourceHandle,
  FlowStepTargetHandle,
  GotoLeftHandle,
  GotoRightHandle,
} from "../FlowHandle";
import { GroupExitCriteria } from "../GroupExitCriteria";
import { isActionDataSame } from "../helpers";
import WidgetIcon from "../WidgetIcon";
import WrapperForCandidate from "../WrapperForCandidate";

const optionDetails = WIDGET_OPTIONS_DETAILS[FLOW_ACTIONS.GROUP];
const defaultGroupName = "Nested journey";

function WorkflowGroupHeader({
  lock,
  identities,
  groupName,
  saveGroupName,
  onExitCriteriaOpen,
  isExitCriteriaDisabled,
  campaignId,
}: {
  lock: boolean;
  identities: ActionNodeIdentifiers;
  groupName: string;
  saveGroupName: (name: string) => void;
  onExitCriteriaOpen: () => void;
  isExitCriteriaDisabled: boolean;
  campaignId: string;
}) {
  const dispatch = useAppDispatch();
  const [isEditingName, setIsEditingName] = useState(false);
  const [draftName, setDraftName] = useState(groupName);
  const [isDeleting, setIsDeleting] = useState(false);

  async function removeAction() {
    setIsDeleting(true);
    await dispatch(removeFlowAction({ ...identities, campaignId }));
    setIsDeleting(false);
  }

  function saveName() {
    setIsEditingName(false);
    saveGroupName(draftName);
  }

  return (
    <HStack
      justifyContent="space-between"
      borderBottom="1px solid white"
      w="100%"
      pl={5}
      height="50px"
      bg="white"
      borderTopRadius="xl"
    >
      {isEditingName ? (
        <HStack>
          <InputGroup>
            <Input
              value={draftName}
              onChange={(e) => setDraftName(e.target.value)}
              placeholder="Enter group name"
              name="group-rename"
              bg="transparent"
              fontSize="16px"
              border={0}
              borderRadius={0}
              onKeyDown={(e) => {
                draftName && onEnterKeySubmit(e, saveName);
              }}
              _focus={{
                border: 0,
                boxShadow: "none",
              }}
              autoFocus
              onFocus={(e) => e.target.select()}
            />
            <InputRightElement pr={2} cursor="pointer">
              <Icon
                as={FaTimes}
                title="Reset"
                color="gray.500"
                _hover={{
                  color: "black",
                }}
                onClick={() => {
                  setIsEditingName(false);
                  setDraftName(groupName ?? defaultGroupName);
                }}
                mr={2}
              />
              <Icon
                as={FaCheck}
                title="Set"
                color="gray.500"
                _hover={{
                  color: "black",
                }}
                onClick={saveName}
              />
            </InputRightElement>
          </InputGroup>
        </HStack>
      ) : (
        <HStack
          _hover={{
            "&>button": {
              opacity: 1,
              visibility: "visible",
              transition: "500ms all",
            },
          }}
          spacing={1}
          w="80%"
          pointerEvents={lock ? "none" : undefined}
        >
          <WidgetIcon
            color={optionDetails.color}
            ml="1"
            mr="3"
            opacity={1}
            icon={optionDetails.icon}
          />
          <Text
            fontSize="16px"
            fontWeight="bold"
            overflow="hidden"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            w="fit-content"
          >
            {groupName}
          </Text>
          <IconButton
            icon={<Icon as={FaPencilAlt} fontSize="12px" />}
            aria-label="edit-group-name"
            bg="transparent"
            color="gray.600"
            _hover={{
              background: "transparent",
              color: "black",
            }}
            onClick={() => setIsEditingName(true)}
            visibility="hidden"
            opacity={0}
          />
        </HStack>
      )}
      <HStack spacing={1} className={lock ? "disabled-edit" : undefined}>
        <IconButton
          icon={<Icon as={IoLogOut} fontSize="18px" />}
          aria-label="remove-group"
          bg="transparent"
          rounded="full"
          _hover={{
            bg: "transparent",
            color: "black",
          }}
          color="gray.600"
          onClick={onExitCriteriaOpen}
          isDisabled={isExitCriteriaDisabled || lock}
          title="Add exit criteria"
        />
        <IconButton
          icon={<Icon as={FaTimes} />}
          aria-label="remove-group"
          bg="transparent"
          rounded="full"
          _hover={{
            bg: "transparent",
            color: "black",
          }}
          color="gray.600"
          onClick={removeAction}
          isLoading={isDeleting}
          isDisabled={lock}
        />
      </HStack>
    </HStack>
  );
}

function HelperForExitConnect({
  isLoading,
  resetLastAction,
  childLessCondition,
}: {
  isLoading: boolean;
  resetLastAction: () => void;
  childLessCondition?: DynamicListType | null;
}) {
  return (
    <Card
      position="fixed"
      top={0}
      right={18}
      size="sm"
      fontSize="12px"
      mt={"80px"}
      w="400px"
    >
      <CardBody>
        <VStack w="100%">
          <HStack
            spacing={2}
            w="100%"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <IconButton
              icon={<Icon as={FaRegLightbulb} fontSize="16px" />}
              aria-label="help-icon-loading"
              background="yellow.400"
              color="white"
              rounded="full"
              size="sm"
              colorScheme="yellow"
              cursor="initial"
              isLoading={isLoading}
            />
            <Text fontSize="14px">
              Connect to a flow step when any of the following events occur
            </Text>
            <IconButton
              icon={<FaTimes fontSize="14px" />}
              variant="ghost"
              aria-label="close-help"
              rounded="full"
              size="xs"
              onClick={resetLastAction}
              isDisabled={isLoading}
            />
          </HStack>
          <Box maxH="300px" w="100%" overflowY="auto" overflowX="hidden">
            <CampaignExitCriteria
              data={childLessCondition ?? null}
              onChange={() => {}}
              isReadOnly={true}
            />
          </Box>
        </VStack>
      </CardBody>
    </Card>
  );
}

function WorkFlowGroupWidget({
  data: {
    childLessExit,
    exitLinkData,
    action,
    groupId,
    isCandidate,
    lock,
    props,
    selectedExit,
    selectedGoto,
    height,
    width,
  },
}: NodeProps<ActionNodeArgs>) {
  const dispatch = useAppDispatch();
  const { campaignId, activeErrorCheck } = useContext(CampaignBuilderContext);
  const { isEditingFlow } = useSelector(selectFlow);
  const { saveDraft, setActions, selectExit, FlowContainerRef, readonly } =
    props;
  const identities = useMemo(() => {
    return {
      actionId: action.action_id,
      groupId,
      branchId: action.branch_id,
    };
  }, [action, groupId]);
  const actionOptions = useMemo(
    () => action.action_options as FlowGroupActionOptions,
    [action]
  );

  const [isDeleting, setIsDeleting] = useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedCondition, setSelectedCondition] =
    useState<GroupExitCriteriaType | null>(null);
  const [lastChild, setLastChild] = useState<ActionNodeIdentifiers | null>(
    null
  );

  const childLessCondition = useMemo(() => {
    const exitCriteria = actionOptions.exit_criteria?.find(
      (condition) => condition.condition_id === childLessExit
    )?.conditions;
    if (exitCriteria && exitCriteria.length) return exitCriteria[0];
  }, [childLessExit, actionOptions.exit_criteria]);

  const isValid = useMemo(
    () =>
      actionOptions.children?.nodes.filter((node) => !node.is_deleted).length &&
      !childLessCondition,
    [actionOptions.children, childLessCondition]
  );

  useEffect(() => {
    dispatch(setFlowValidity({ [identities.actionId]: !!isValid }));
  }, [dispatch, isValid, identities.actionId]);

  function setOptions(options: FlowGroupActionOptions, shouldRender = false) {
    setActions(options, identities.actionId, groupId);
    saveDraft(
      options,
      { actionId: identities.actionId, groupId },
      shouldRender
    );
  }

  function saveGroupName(name: string) {
    const newAction = cloneDeep(actionOptions);
    newAction.name = name;
    setOptions(newAction);
  }

  function saveExitCriteria(condition: DynamicListType | null) {
    const newData = cloneDeep(actionOptions);
    const newConditions = condition ? [condition] : null;
    let isNewCriteria = false;
    if (newData.exit_criteria) {
      if (selectedCondition) {
        const index = newData.exit_criteria.findIndex(
          (criteria) => criteria.condition_id === selectedCondition.condition_id
        );
        if (index > -1) {
          newData.exit_criteria[index].conditions = newConditions;
        }
        isNewCriteria = false;
      } else {
        newData.exit_criteria.push({
          conditions: newConditions,
        });
        isNewCriteria = true;
      }
    } else {
      newData.exit_criteria = [{ conditions: newConditions }];
      isNewCriteria = true;
    }
    setOptions(newData, isNewCriteria);
    onClose();
  }

  async function removeEdgeAction(condition?: GroupExitCriteriaType) {
    setIsDeleting(true);
    const conditionId =
      condition?.condition_id ?? selectedCondition?.condition_id;
    await dispatch(
      removeFlowEdge({
        campaignId,
        source: identities.actionId,
        conditionId: conditionId,
      })
    );
    setIsDeleting(false);
    if (exitLinkData && conditionId) {
      const linkData = exitLinkData[conditionId];
      setLastChild(linkData?.target ?? null);
    }
    setSelectedCondition(null);
    onClose();
  }

  function addEdge() {
    createExitEdge(lastChild!.actionId, lastChild!.branchId);
    setLastChild(null);
  }

  const createExitEdge = useCallback(
    (id: string, branchId?: string) => {
      if (selectedExit) {
        dispatch(
          addFlowEdge({
            campaignId,
            source: selectedExit.actionId,
            target: id,
            conditionId: selectedExit.conditionId,
            branchId,
          })
        );
      }
    },
    [dispatch, campaignId, selectedExit]
  );

  function resetLastAction() {
    if (lastChild?.actionId && lastChild.branchId) {
      addEdge();
    } else {
      removeExit(childLessExit!);
    }
  }

  function removeExit(conditionId: string) {
    const newData = cloneDeep(actionOptions);
    if (newData.exit_criteria) {
      const index = newData.exit_criteria.findIndex(
        (criteria) => criteria.condition_id === conditionId
      );
      if (index > -1) {
        newData.exit_criteria.splice(index, 1);
      }
    }
    setOptions(newData, true);
    onClose();
    setSelectedCondition(null);
  }

  return (
    <WrapperForCandidate
      isCandidate={isCandidate}
      identities={identities}
      selectedGoto={selectedGoto}
      selectedExit={selectedExit}
      onClick={(e) => {
        e.stopPropagation();
      }}
      isInvalid={activeErrorCheck && !isValid}
    >
      <Box
        height={height}
        width={width}
        bg="rgba(108, 136, 161, 0.38)"
        borderRadius="xl"
        position="relative"
        cursor="grab !important"
      >
        <FlowStepTargetHandle />
        <FlowStepSourceHandle />
        {Array(3)
          .fill(true)
          .map((_, index) => {
            const leftHandleId = `${HANDLE_IDS.LEFT_SOURCE}-${index}`;
            const rightHandleId = `${HANDLE_IDS.RIGHT_SOURCE}-${index}`;
            const leftCondition = actionOptions.exit_criteria?.find(
              (condition) =>
                exitLinkData &&
                condition.condition_id &&
                exitLinkData[condition.condition_id]?.sourceHandle ===
                  leftHandleId
            );
            const rightCondition = actionOptions.exit_criteria?.find(
              (condition) =>
                exitLinkData &&
                condition.condition_id &&
                exitLinkData[condition.condition_id]?.sourceHandle ===
                  rightHandleId
            );
            return (
              <>
                <ExitCriteriaHandle
                  handleId={leftHandleId}
                  isHidden={!leftCondition}
                  isDisabled={!!lock}
                  isReadOnly={readonly}
                  index={index}
                  onClick={() => {
                    setSelectedCondition(leftCondition ?? null);
                    onOpen();
                    selectExit(leftCondition?.condition_id ?? null);
                  }}
                  onRemoveExitCriteria={() => {
                    removeExit(leftCondition!.condition_id!);
                  }}
                />
                <ExitCriteriaHandle
                  handleId={rightHandleId}
                  isHidden={!rightCondition}
                  isDisabled={!!lock}
                  isReadOnly={readonly}
                  index={index}
                  onClick={() => {
                    setSelectedCondition(rightCondition ?? null);
                    onOpen();
                    selectExit(rightCondition?.condition_id ?? null);
                  }}
                  onRemoveExitCriteria={() => {
                    removeExit(rightCondition!.condition_id!);
                  }}
                />
              </>
            );
          })}
        <GotoRightHandle className="grouping-goto-handle" />
        <GotoLeftHandle className="grouping-goto-handle" />

        <WorkflowGroupHeader
          lock={!!lock || !!readonly}
          identities={identities}
          groupName={actionOptions?.name || defaultGroupName}
          saveGroupName={saveGroupName}
          onExitCriteriaOpen={onOpen}
          isExitCriteriaDisabled={
            actionOptions.exit_criteria?.length === 3 || !isValid
          }
          campaignId={campaignId}
        />

        {isOpen && (
          <GroupExitCriteria
            groupName={actionOptions.name ?? defaultGroupName}
            isOpen={isOpen}
            onClose={() => {
              onClose();
              setSelectedCondition(null);
            }}
            data={
              selectedCondition?.conditions?.length
                ? selectedCondition.conditions[0]
                : null
            }
            onChange={saveExitCriteria}
            removeEdge={removeEdgeAction}
            removeExitCriteria={() => {
              removeExit(selectedCondition!.condition_id!);
            }}
            readonly={readonly}
          />
        )}
        <Portal
          containerRef={
            FlowContainerRef as MutableRefObject<HTMLElement | null>
          }
        >
          {childLessExit && (
            <HelperForExitConnect
              isLoading={isDeleting || isEditingFlow}
              resetLastAction={resetLastAction}
              childLessCondition={childLessCondition}
            />
          )}
        </Portal>
      </Box>
    </WrapperForCandidate>
  );
}

export default memo(WorkFlowGroupWidget, isActionDataSame);
