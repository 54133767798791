import { ChangeEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { createPersonApi } from "../../../../common/api/campaign/person";
import {
  onEnterKeySubmit,
  validateEmail,
} from "../../../../common/helper/commonHelper";
import IModal from "../../../../components/IModal";
import InputFieldWithError from "../../../../components/InputFieldWithError";
import { useAppDispatch } from "../../../../store";
import urls from "../../../../urls";
import { createPersonWithEmail } from "../personDbSlice";

export default function CreatePersonModal({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [creatingPerson, setCreatingPerson] = useState(false);
  const [activeClick, setActiveClick] = useState(false);

  function handleEmailChange(input: string) {
    setEmail(input);
    setIsValid(!!input && validateEmail(input));
  }

  async function createPerson() {
    setActiveClick(true);
    if (isValid) {
      setCreatingPerson(true);
      const createPersonResponse = await dispatch(createPersonWithEmail(email));
      const successResponse = createPersonResponse.payload as Awaited<
        ReturnType<typeof createPersonApi>
      >;
      if (successResponse && successResponse.success) {
        if (successResponse.person_id) {
          closeWithResetData();
          navigate(`${urls.person}/${successResponse.person_id}`);
        }
      }
      setCreatingPerson(false);
    }
  }

  function closeWithResetData() {
    setEmail("");
    setIsValid(false);
    setCreatingPerson(false);
    setActiveClick(false);
    onClose();
  }

  return (
    <IModal
      header={{ title: "Create a new contact" }}
      primaryButton={{
        label: "Create contact",
        props: {
          isDisabled: activeClick && !isValid,
          onClick: createPerson,
          isLoading: creatingPerson,
        },
      }}
      secondaryButton={{
        label: "Cancel",
        props: {
          onClick: closeWithResetData,
        },
      }}
      isOpen={isOpen}
      onClose={closeWithResetData}
    >
      <InputFieldWithError
        labelText="Email"
        formLabelProps={{ color: "gray.500", fontWeight: "500" }}
        type="email"
        value={email}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          handleEmailChange(e.target.value)
        }
        placeholder="Enter email"
        onKeyDown={(e) => onEnterKeySubmit(e, createPerson)}
        errorMsg={activeClick && !isValid ? "Valid email is required" : ""}
        autoFocus
      />
    </IModal>
  );
}
