import { RouteItem } from "../../common/types/common";
import urls from "../../urls";
import Campaigns from "./campaign/CampaignList";
import EmailTemplateEditor from "./templates/EmailTemplateEditor";
import TemplateList from "./templates/TemplateList";
import ConnectionTableImport from "./connection/tableImport/ConnectionTableImport";
import ConnectionDetails from "./connection/connectionDetails/ConnectionDetails";
import Impersonate from "../account/Impersonate";
import PersonDb from "./persondb/PersonDb";
import PersonDashboard from "./persondb/PersonDashboard";
import UserList from "./users/UserList";
import SsoConfiguration from "./settings/SsoConfiguration";
import EmailToken from "./emailtoken/EmailToken";
import EmailTokenList from "./emailtoken/EmailTokenList";
import Unsubscribed from "../account/Unsubscribed";
import StaticLists from "./staticlist/StaticLists";
import StaticListContacts from "./staticlist/StaticListContacts";
import EmailSettings from "./settings/EmailSettings";
import SalesforceSuccess from "./connection/salesforce/SalesforceSuccess";
import WebhookList from "./webhook/WebhookList";
import Webhook from "./webhook/Webhook";
import Settings from "./settings/Settings";
import DataManagement from "./connection/mapping/DataManagement";
import AccountDashboard from "./accountdb/AccountDashboard";
import Accounts from "./accountdb/AccountDb";
import SegmentConnection from "./connection/segment/SegmentConnection";
import SalesforceDetailsNew from "./connection/salesforce/SalesforceDetailsNew";
import FormList from "./form/FormList";
import Form from "./form/Form";
import FormProvider from "./form/FormProvider";
import BatchCampaign from "./campaign/BatchCampaign";
import TriggerCampaign from "./campaign/trigger-campaign/TriggerCampaign";

export const routes: RouteItem[] = [
  {
    path: urls.connectionDetails,
    name: "Connection Details",
    component: ConnectionDetails,
    protected: true,
  },
  {
    path: urls.connectionConfigurationEdit,
    name: "Connection Config Edit",
    component: ConnectionTableImport,
    protected: true,
  },
  {
    path: urls.email,
    name: "Emails",
    component: TemplateList,
    protected: true,
  },
  {
    path: urls.emailEdit,
    name: "Email Edit",
    component: EmailTemplateEditor,
    protected: true,
  },
  //Support for old url
  {
    path: urls.template,
    name: "Emails",
    component: TemplateList,
    protected: true,
  },
  //Support for old url
  {
    path: urls.templateEdit,
    name: "Email Edit",
    component: EmailTemplateEditor,
    protected: true,
  },
  {
    path: urls.journey,
    name: "Journeys",
    component: Campaigns,
    protected: true,
  },
  {
    path: urls.home,
    name: "Journeys",
    component: Campaigns,
    protected: true,
  },
  {
    path: urls.triggerJourneyEdit,
    name: "Trigger Journey Edit",
    component: TriggerCampaign, // Need to change to trigger component
    protected: true,
  },
  {
    path: urls.batchJourneyEdit,
    name: "Batch Journey Edit",
    component: BatchCampaign,
    protected: true,
  },
  //Support for old url
  {
    path: urls.campaign,
    name: "Journeys",
    component: Campaigns,
    protected: true,
  },
  //Support for old url
  {
    path: urls.campaignEdit,
    name: "Journey Edit",
    component: BatchCampaign,
    protected: true,
  },
  //Support for old url
  {
    path: urls.journeyEdit,
    name: "Journey Edit",
    component: BatchCampaign,
    protected: true,
  },
  {
    path: urls.webhook,
    name: "Webhooks",
    component: WebhookList,
    protected: true,
  },
  {
    path: urls.webhookEdit,
    name: "Webhook Edit",
    component: Webhook,
    protected: true,
  },
  {
    path: urls.form,
    name: "Forms",
    component: FormList,
    protected: true,
  },
  {
    path: urls.formEdit,
    name: "Form Edit",
    component: Form,
    protected: true,
  },
  {
    path: urls.formIntegration,
    name: "Form Provider",
    component: FormProvider,
    protected: true,
  },
  {
    path: urls.ssoConfiguration,
    name: "Sso Configuration",
    component: SsoConfiguration,
    protected: true,
  },
  {
    path: urls.emailSettings,
    name: "Email Settings",
    component: EmailSettings,
    protected: true,
  },
  {
    path: urls.impersonate,
    name: "Impersonate",
    component: Impersonate,
    protected: true,
  },
  {
    path: urls.person,
    name: "Contact Database",
    component: PersonDb,
    protected: true,
  },
  {
    path: urls.personDetails,
    name: "Contact Details",
    component: PersonDashboard,
    protected: true,
  },
  {
    path: urls.account,
    name: "Account Database",
    component: Accounts,
    protected: true,
  },
  {
    path: urls.accountDetails,
    name: "Account Details",
    component: AccountDashboard,
    protected: true,
  },
  {
    path: urls.users,
    name: "User List",
    component: UserList,
    protected: true,
  },
  {
    path: urls.tokenDetailsEdit,
    name: "Token Edit",
    component: EmailToken,
    protected: true,
  },
  {
    path: urls.token,
    name: "Tokens",
    component: EmailTokenList,
    protected: true,
  },
  {
    path: urls.unsubscribed,
    name: "Unsubscribed",
    component: Unsubscribed,
    protected: true,
  },
  {
    path: urls.segment,
    name: "Segment Details",
    component: SegmentConnection,
    protected: true,
  },
  {
    path: urls.staticList,
    name: "Static Lists",
    component: StaticLists,
    protected: true,
  },
  {
    path: urls.staticListContacts,
    name: "Contacts in Static Lists",
    component: StaticListContacts,
    protected: true,
  },
  {
    path: urls.settings,
    name: "Settings",
    component: Settings,
    protected: true,
  },
  {
    path: urls.unifiedMapping,
    name: "Connections",
    component: DataManagement,
    protected: true,
  },
  {
    path: urls.salesforceRedirect,
    name: "Salesforce Connection",
    component: SalesforceSuccess,
    protected: true,
  },
  {
    path: urls.salesforceDetails,
    name: "Salesforce Details",
    component: SalesforceDetailsNew,
    protected: true,
  },
];
