import { cloneDeep, isEmpty } from "lodash";
import { FORMS_SOURCES } from "../constants/connection";
import { NONE_DESTINATION } from "../constants/unifiedMapping";
import { SALESFORCE_OBJECT_TYPE } from "../types/connection";
import {
  DESTINATION_TYPES,
  MappingElement,
  SalesforceObject,
  Source,
  ConnectionListItem,
  DATA_TYPES,
  SOURCES,
  AccountSource,
  SalesforceConnectionDetailsDict,
  SYNC_PREFERENCE,
} from "../types/unifiedMapping";

export function findFields({
  objects,
  objectType,
  fieldDataType,
}: {
  objects: SalesforceObject[] | undefined;
  objectType: SALESFORCE_OBJECT_TYPE;
  fieldDataType: DESTINATION_TYPES;
}) {
  if (objects) {
    const fields = objects
      ?.filter((x) => x.object === objectType)
      .map((y) => y.fields)
      .flat()
      .filter((x) => x.type === fieldDataType);
    return fields;
  } else {
    return [];
  }
}

export function addTempIds(mapping?: MappingElement[]) {
  const mappingCopy = cloneDeep(mapping) || [];
  let mappingsWithId = null;
  if (mappingCopy) {
    mappingsWithId = mappingCopy.map((x) => {
      x.tempId = Math.random();

      x.source = x.source
        ? x.source.map((y) => {
            if (!y.field.contact) {
              y.field.contact = NONE_DESTINATION;
            }
            if (!y.field.lead) {
              y.field.lead = NONE_DESTINATION;
            }
            return y;
          })
        : null;
      return x;
    });
  }
  return mappingsWithId;
}

export function onChangeEmailRowData(
  updatedData: MappingElement,
  data: MappingElement,
  onChange: (data: MappingElement) => void
) {
  const clonedData = cloneDeep(data);
  let updatedSources: Source[] | null = null;
  if (clonedData.source) {
    updatedSources = clonedData.source.map((source: Source) => {
      if (
        updatedData.source &&
        source.connection_id === updatedData.source[0].connection_id
      ) {
        source = { ...updatedData.source[0] };
      }
      return source;
    });
  } else {
    updatedSources = cloneDeep(updatedData.source);
  }
  clonedData.source = updatedSources ? [...updatedSources] : null;
  onChange(clonedData);
}

export function getSalesforceId(list: ConnectionListItem[] | null): string {
  return list
    ? list.find((item) => item.source === SOURCES.SALESFORCE)?.connection_id ??
        ""
    : "";
}

export function getCrmAndSalesforceSource(
  list: ConnectionListItem[] | null
): ConnectionListItem[] {
  return !isEmpty(list)
    ? list?.filter(
        (item) =>
          (item.data_type.includes(DATA_TYPES.CRM) ||
            item.source === SOURCES.SALESFORCE) &&
          !FORMS_SOURCES.includes(item.source)
      ) ?? []
    : [];
}

export function findSource(
  source: AccountSource | Source | null,
  sourceList: ConnectionListItem[]
): ConnectionListItem | null {
  return source
    ? sourceList.find((src) => src.connection_id === source?.connection_id) ||
        null
    : null;
}

export function getObjectSettings(
  salesforceConnections: SalesforceConnectionDetailsDict,
  sourceField: ConnectionListItem | null
) {
  return sourceField && salesforceConnections[sourceField?.connection_id]
    ? salesforceConnections[sourceField?.connection_id].details
        ?.object_settings ?? null
    : null;
}

export function isSalesforcePreference(value: SYNC_PREFERENCE | undefined) {
  return value === SYNC_PREFERENCE.ALWAYS_USE_SALESFORCE;
}
