import {
  Flex,
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
} from "@chakra-ui/react";
import { formatISO, isEqual } from "date-fns";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  ASSET_TYPES,
  CUSTOM_DATE_RANGE_LABELS,
  getStartDate,
} from "../../../../common/constants/common";
import {
  isLoading,
  isSuccess,
  openAssetInNewTab,
} from "../../../../common/helper/commonHelper";
import { TemplatePrimaryDetails } from "../../../../common/types/campaign";
import { ReactSelectDefaultOptionsWithClick } from "../../../../common/types/common";
import CustomDateRangePicker from "../../../../components/CustomDateRangePicker";
import DropdownWithSearch from "../../../../components/DropdownWithSearch";
import { AssetPreviewComponent } from "../../../../components/OptionHelper";
import SpinnerContainer from "../../../../components/SpinnerContainer";
import { useAppDispatch } from "../../../../store";
import CampaignDetails from "./CampaignDetails";
import CampaignHistory from "./CampaignHistory";
import {
  getAggregateData,
  getOverAllBounceData,
  getEmailClientClickData,
  getEmailClientOpenData,
  getTemplatesOfCampaign,
  getTopnLinks,
  selectCampaignReports,
  setCampaignReportsRange,
  setCampaignReportsTemplateFilter,
  getBounceClassificationInfo,
} from "./campaignReportsSlice";
import { DeliveryInfo, EmailClient, TopLinks, EventMatrix } from "./Matrix";
import RecipientsReports from "./RecipientsReports";

function ReportFilter() {
  const dispatch = useAppDispatch();
  const [[startDate, endDate], setDateOption] = useState([
    getStartDate(CUSTOM_DATE_RANGE_LABELS.ALL_TIME),
    new Date(),
  ]);
  const [templateOption, setTemplateOption] = useState<string>("");
  const { templatesByCampaign } = useSelector(selectCampaignReports);

  useEffect(() => {
    dispatch(getTemplatesOfCampaign());
  }, [dispatch]);

  const templateOptions: { label: string; value: string }[] = useMemo(() => {
    const options: ReactSelectDefaultOptionsWithClick[] =
      templatesByCampaign.data.map((template: TemplatePrimaryDetails) => {
        const { name, template_id: id } = template;
        return {
          label: name,
          value: id,
          onPreview: () => openAssetInNewTab(ASSET_TYPES.TEMPLATE, id),
        };
      });
    return [{ label: "All Templates", value: "" }, ...options];
  }, [templatesByCampaign]);

  useEffect(() => {
    dispatch(
      setCampaignReportsRange({
        start: formatISO(startDate),
        end: formatISO(endDate),
      })
    );
  }, [dispatch, startDate, endDate]);

  useEffect(() => {
    dispatch(setCampaignReportsTemplateFilter(templateOption || null));
  }, [dispatch, templateOption]);

  function onDateChange(start: Date, end: Date) {
    if (!(isEqual(startDate, start) && isEqual(endDate, end))) {
      setDateOption([start, end]);
    }
  }

  return (
    <HStack alignItems="center" height="100%">
      <DropdownWithSearch
        options={templateOptions}
        value={
          templateOption
            ? templateOptions.find((option) => option.value === templateOption)
            : { label: "All Templates", value: "" }
        }
        onChange={(option) => setTemplateOption(option?.value ?? "")}
        controlStyle={{ width: "200px" }}
        components={{
          Option: AssetPreviewComponent<ReactSelectDefaultOptionsWithClick>,
        }}
      />
      <Text>Sent in</Text>
      <CustomDateRangePicker
        startDate={startDate}
        endDate={endDate}
        onDateRangeChange={onDateChange}
      />
    </HStack>
  );
}

function Overview() {
  const {
    aggregateData: { loading: aggregateDataLoading },
  } = useSelector(selectCampaignReports);

  return (
    <VStack pr={4} width="100%">
      <SpinnerContainer loading={isLoading(aggregateDataLoading)}>
        {isSuccess(aggregateDataLoading) && (
          <>
            <DeliveryInfo />
            <EventMatrix />
            <TopLinks />
            <EmailClient />
          </>
        )}
      </SpinnerContainer>
      <CampaignHistory />
    </VStack>
  );
}

export default function CampaignReports() {
  const dispatch = useAppDispatch();

  const {
    filterData: { timeRange, templateId },
  } = useSelector(selectCampaignReports);

  useEffect(() => {
    if (timeRange.startDate && timeRange.endDate) {
      dispatch(getAggregateData());
      dispatch(getOverAllBounceData());
      dispatch(getEmailClientClickData());
      dispatch(getEmailClientOpenData());
      dispatch(getTopnLinks());
    }
  }, [dispatch, timeRange, templateId]);

  useEffect(() => {
    dispatch(getBounceClassificationInfo());
  }, [dispatch]);

  return (
    <Tabs size="md" variant="line">
      <Flex
        height="65px"
        marginBottom="0"
        justifyContent="center"
        borderBottom="2px solid"
        borderColor="gray.200"
      >
        <Flex justifyContent="space-between" alignItems="flex-end" w="100%">
          <TabList borderBottom={1}>
            <Tab>Overview</Tab>
            <Tab>Recipients</Tab>
          </TabList>
          <ReportFilter />
        </Flex>
      </Flex>
      <TabPanels w="100%">
        <TabPanel bg="white" w="100%">
          <VStack width="100%" spacing={10}>
            <Overview />
            <CampaignDetails />
          </VStack>
        </TabPanel>
        <TabPanel bg="white">
          <Flex flexDirection="column" alignItems="center" width="100%">
            <HStack w="100%" alignItems="flex-start">
              <RecipientsReports />
            </HStack>
          </Flex>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}
