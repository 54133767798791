import { HStack, VStack } from "@chakra-ui/react";
import { ReactNode } from "react";
import HeaderDescText, { TEXT_TYPE } from "./HeaderDescText";
import EmailSettingsBadgeInput from "./EmailSettingsBadgeInput";
import { ACTIONS } from "../../../../components/ViewAndEditButtons";
import { getTwoStepUnsubState } from "../../../../common/helper/settingsHelper";

export default function TwoStepUnsubscribe({
  mode,
  unsubState,
  onChange,
  loading,
  headerTxt,
  children,
}: {
  mode: ACTIONS;
  unsubState: boolean;
  onChange: (field: boolean) => void;
  loading?: boolean;
  headerTxt?: string;
  children?: ReactNode;
}) {
  return (
    <VStack alignItems="flex-start" w="100%">
      <HStack justifyContent="space-between" w="100%">
        {headerTxt && (
          <HeaderDescText text={headerTxt} type={TEXT_TYPE.HEADER} w="80%" />
        )}

        <EmailSettingsBadgeInput
          editSwitchProps={{
            name: "two-step-opt-out",
            onChange: () => onChange(!unsubState),
            isChecked: unsubState,
          }}
          mode={mode}
          state={getTwoStepUnsubState(unsubState)}
          loading={loading}
        />
      </HStack>
      {children ?? (
        <HeaderDescText text="With our two-click opt-out process, recipients will be prompted to confirm their decision when clicking the unsubscribe link in your email." />
      )}
    </VStack>
  );
}
