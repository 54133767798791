import {
  Center,
  Container,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  FormErrorMessage,
  HStack,
  Icon,
  Skeleton,
  Text,
  useDisclosure,
  VStack,
  Wrap,
} from "@chakra-ui/react";
import { cloneDeep, isNaN } from "lodash";
import {
  memo,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import {
  FILTER_TABLE_NAME,
  FILTER_TYPE,
  FLOW_ACTIONS,
} from "../../../../../../common/constants/campaign";
import {
  isLoading,
  isBlank,
  isArgumentMany,
} from "../../../../../../common/helper/commonHelper";
import {
  BranchByDataActionOptions,
  BranchByTokenActionOptions,
  BranchByValueActionOptions,
  BranchingCondition,
  BranchingConditionDef,
  CAMPAIGN_CONTEXT,
  EmailTokenDetails,
  OperatorDetails,
  OperatorType,
  TableDescWithId,
  TableList,
  TYPE,
} from "../../../../../../common/types/campaign";
import {
  ActionNodeArgs,
  ComputeOperatorDetails,
} from "../../../../../../common/types/flow";
import { DESTINATION_TYPES } from "../../../../../../common/types/unifiedMapping";
import DropdownWithSearch from "../../../../../../components/DropdownWithSearch";
import {
  listAllEmailTokens,
  selectEmailToken,
} from "../../../../emailtoken/emailTokenSlice";
import {
  FILTER_TEXT,
  FILTER_TYPE_TEXT,
  TYPE_TO_DESTINATION_TYPE,
  ValidTokenDatatypes,
  VALID_BRANCH_TOKEN_DATA_TYPES,
  WIDGET_OPTIONS_DETAILS,
} from "../constants";
import WidgetContainer from "../WidgetContainer";
import { useDrag, useDrop } from "react-dnd";
import { EditableFieldByType } from "../../../../../../components/EditableFieldByType";
import IButton, { BUTTON } from "../../../../../../components/IButton";
import { FaGripVertical, FaPlus } from "react-icons/fa";
import {
  DynamicListValueFields,
  ValueFieldsReadonly,
} from "../../../../../../components/dynamic-list/DynamicListValueFields";
import { isActionDataSame, operatorDisplayName } from "../helpers";
import RowWrapperWithButtons from "../RowWrapperWithButtons";
import WrapperWithScrollBar from "../WrapperWithScrollbar";
import { NodeProps } from "reactflow";
import DndWrapper from "../../../../../../components/DndWrapper";
import { useAppDispatch } from "../../../../../../store";
import IModal from "../../../../../../components/IModal";
import {
  selectDynamicList,
  getComputeOperators,
  getOperators,
} from "../../../../../../components/dynamic-list/dynamicListSlice";
import {
  CampaignBuilderContext,
  selectFlow,
  setFlowValidity,
} from "../../flowSlice";
import { TokenDropdownOption } from "../../../../../../components/OptionHelper";

function OperatorationRow({
  operators,
  condition,
  setCondition,
  type,
  activeErrorCheck,
  isOldOperator = false,
  readonly,
}: {
  operators: (ComputeOperatorDetails | OperatorDetails)[];
  condition: BranchingConditionDef;
  setCondition: (conditions: BranchingConditionDef) => void;
  type: DESTINATION_TYPES | OperatorType;
  activeErrorCheck: boolean;
  isOldOperator?: boolean;
  readonly?: boolean;
}) {
  function onOperatorChange(
    operator: ComputeOperatorDetails | OperatorDetails | null
  ) {
    let operandCount = 0;
    if (isOldOperator) {
      const noOfCount = Number((operator as OperatorDetails)?.arguments);
      operandCount = isNaN(noOfCount) ? 0 : noOfCount;
    } else {
      operandCount = ((operator as ComputeOperatorDetails)?.operands ?? 1) - 1;
    }
    if (operator) {
      setCondition({
        ...condition,
        operator: operator.id,
        value: Array(operandCount).fill(null),
      });
    }
  }

  function valueChange(updatedValue: string | number, i: number) {
    const newValues = cloneDeep(condition.value);
    newValues[i] = updatedValue;
    setCondition({
      ...condition,
      value: newValues,
    });
  }

  const operatorDetails =
    operators.find(
      (option: ComputeOperatorDetails | OperatorDetails) =>
        option.id === condition.operator
    ) ?? null;

  function computeOperatorsFields() {
    return (
      <>
        {Array((operatorDetails as ComputeOperatorDetails).operands - 1)
          .fill("")
          .map((_, i) => {
            return (
              <HStack key={i}>
                {i === 1 && <Text>and</Text>}
                <EditableFieldByType
                  type={type as DESTINATION_TYPES}
                  value={condition.value[i] ?? ""}
                  onChange={(val) => valueChange(val, i)}
                  isInValid={activeErrorCheck && isBlank(condition.value[i])}
                  isDisabled={readonly}
                />
              </HStack>
            );
          })}
      </>
    );
  }

  return (
    <>
      {!!operators.length && (
        <DropdownWithSearch<ComputeOperatorDetails | OperatorDetails>
          options={operators}
          getOptionLabel={(option) =>
            isOldOperator
              ? (option as OperatorDetails).display
              : (option as ComputeOperatorDetails).display_name
          }
          getOptionValue={(option) => option.id}
          value={operatorDetails}
          onChange={(option) => onOperatorChange(option)}
          isSearchable={true}
          isInvalid={activeErrorCheck && !operatorDetails}
          id="set-branching-operator"
          placeholder="Select operator"
          isDisabled={readonly}
        />
      )}
      {isOldOperator && operatorDetails ? (
        <DynamicListValueFields
          value={condition.value}
          validationError={activeErrorCheck ? "value cannot be empty" : ""}
          onChange={(val) =>
            setCondition({
              ...condition,
              value: val,
            })
          }
          argumentTypes={(operatorDetails as OperatorDetails).arguments_types}
          helperText={(operatorDetails as OperatorDetails).display_2}
          noOfArguments={(operatorDetails as OperatorDetails).arguments}
          isDisabled={readonly}
        />
      ) : (
        (operatorDetails as ComputeOperatorDetails)?.operands &&
        computeOperatorsFields()
      )}
    </>
  );
}

export function BranchingConditionEditOverlay({
  optionDisplay,
  operators,
  condition,
  setCondition,
  type,
  isOpen,
  onClose,
  isOldOperator = false,
  readonly,
  extraChildren,
  isSubmitLoading,
  title = "Branching condition",
  submitButtonText = "Save",
  isModal,
}: {
  optionDisplay?: string;
  operators: (ComputeOperatorDetails | OperatorDetails)[];
  condition: BranchingConditionDef;
  setCondition: (conditions: BranchingConditionDef) => void;
  type: DESTINATION_TYPES | OperatorType;
  isOpen: boolean;
  onClose: () => void;
  isOldOperator?: boolean;
  readonly?: boolean;
  extraChildren?: ReactNode;
  isSubmitLoading?: boolean;
  title?: string;
  submitButtonText?: string;
  isModal?: boolean;
}) {
  const [isValid, setIsValid] = useState(false);
  const [tempCondition, setTempCondition] = useState(condition);
  const [activeErrorCheck, setActiveErrorCheck] = useState(false);

  useEffect(() => {
    setTempCondition(condition);
  }, [condition, type]);

  useEffect(() => {
    if (tempCondition.operator) {
      const operatorDetails = operators.find(
        (operator: ComputeOperatorDetails | OperatorDetails) =>
          operator.id === tempCondition.operator
      );
      let isValueLengthCorrect = false;
      if (operatorDetails) {
        if (isOldOperator) {
          const argumentCount = (operatorDetails as OperatorDetails).arguments;
          if (isArgumentMany(argumentCount)) {
            isValueLengthCorrect = !!tempCondition.value.length;
          } else if (!isNaN(Number(argumentCount))) {
            isValueLengthCorrect =
              tempCondition.value.length === Number(argumentCount);
          }
        } else {
          const argumentCount =
            (operatorDetails as ComputeOperatorDetails).operands - 1;
          isValueLengthCorrect = tempCondition.value.length === argumentCount;
        }
      }
      setIsValid(
        tempCondition.value &&
          isValueLengthCorrect &&
          tempCondition.value.every((value) => !isBlank(value))
      );
    } else {
      setIsValid(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tempCondition.operator, tempCondition.value]);

  useEffect(() => {
    if (isOpen) setTempCondition(condition);
  }, [condition, isOpen]);

  function onCancel() {
    setActiveErrorCheck(false);
    onClose();
  }

  function saveCondition() {
    if (isValid) {
      setCondition(tempCondition);
      onCancel();
    } else {
      setActiveErrorCheck(true);
    }
  }

  if (isModal) {
    return (
      <IModal
        isOpen={isOpen}
        onClose={onCancel}
        header={{ title: title }}
        size="2xl"
        primaryButton={{
          label: "Save",
          props: {
            onClick: saveCondition,
            isDisabled: activeErrorCheck && !isValid,
            isLoading: isSubmitLoading,
            hidden: readonly,
          },
        }}
        secondaryButton={{
          label: "Cancel",
          props: {
            onClick: onCancel,
            hidden: readonly,
          },
        }}
        modalBodyProps={{ p: 0 }}
      >
        <HStack
          wrap="wrap"
          gridGap={2.5}
          bg="gray.100"
          px={6}
          py={6}
          id="set-branching-condition"
          alignItems="flex-start"
        >
          {extraChildren ? (
            extraChildren
          ) : (
            <Center h="36px" w="fit-content">
              <Text>{optionDisplay}</Text>
            </Center>
          )}
          <OperatorationRow
            condition={tempCondition}
            operators={operators}
            setCondition={setTempCondition}
            activeErrorCheck={activeErrorCheck}
            type={type}
            isOldOperator={isOldOperator}
            readonly={readonly}
          />
        </HStack>
      </IModal>
    );
  } else {
    return (
      <Drawer isOpen={isOpen} onClose={onCancel} size="lg" variant="primary">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader fontSize="16px">{title}</DrawerHeader>
          <DrawerBody>
            {extraChildren}
            <HStack
              wrap="wrap"
              gridGap={2.5}
              id="set-branching-condition"
              alignItems="flex-start"
              hidden={!optionDisplay}
              fontSize="14px"
            >
              <Center h="36px" w="fit-content">
                <Text>{optionDisplay}</Text>
              </Center>
              <OperatorationRow
                condition={tempCondition}
                operators={operators}
                setCondition={setTempCondition}
                activeErrorCheck={activeErrorCheck}
                type={type}
                isOldOperator={isOldOperator}
                readonly={readonly}
              />
            </HStack>
          </DrawerBody>
          <DrawerFooter>
            <Flex
              alignItems="center"
              justifyContent="flex-end"
              width="100%"
              hidden={readonly || !optionDisplay}
              mt={5}
            >
              <HStack>
                <IButton
                  variant={BUTTON.SECONDARY}
                  onClick={onCancel}
                  name="modal-secondary-button"
                >
                  Cancel
                </IButton>
                <IButton
                  variant={BUTTON.PRIMARY}
                  name="modal-primary-button"
                  onClick={saveCondition}
                  isDisabled={activeErrorCheck && !isValid}
                  isLoading={isSubmitLoading}
                >
                  {submitButtonText}
                </IButton>
              </HStack>
            </Flex>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    );
  }
}

function ConditionRow({
  condition,
  moveCard,
  operators,
  setCondition,
  removeCondition,
  type,
  index,
  optionDisplay,
  isOldOperator = false,
  readonly,
}: {
  condition: BranchingConditionDef;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
  operators: (ComputeOperatorDetails | OperatorDetails)[];
  setCondition: (conditions: BranchingConditionDef) => void;
  removeCondition: () => void;
  type: DESTINATION_TYPES | OperatorType;
  index: number;
  optionDisplay?: string;
  isOldOperator?: boolean;
  readonly?: boolean;
}) {
  const ref = useRef<HTMLDivElement | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [{ handlerId }, drop] = useDrop({
    accept: "box",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const itemData = item as {
        condition: BranchingCondition;
        index: number;
      };
      const dragIndex = itemData.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      moveCard(dragIndex, hoverIndex);
      itemData.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: "box",
    item: () => {
      return { condition, index };
    },
    canDrag: !readonly,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  const operatorDetails = operators.find(
    (operator: ComputeOperatorDetails | OperatorDetails) =>
      operator.id === condition.operator
  );

  const argumentTypes = isOldOperator
    ? (operatorDetails as OperatorDetails)?.arguments_types
    : Array(
        Number((operatorDetails as ComputeOperatorDetails)?.operands ?? 1) - 1
      ).fill(type);

  const {
    operators: { loading: oldOperatorLoading },
    computeOperators: { loading: newOperatorLoading },
  } = useSelector(selectDynamicList);

  const isLoadingOperator = isOldOperator
    ? isLoading(oldOperatorLoading)
    : isLoading(newOperatorLoading);

  return (
    <>
      <RowWrapperWithButtons
        onEdit={onOpen}
        onRemove={removeCondition}
        ref={ref}
        style={{ opacity }}
        data-handler-id={handlerId}
        mb={1}
        readonly={readonly}
      >
        {!isLoadingOperator && (
          <Icon as={FaGripVertical} ref={drag} cursor="grab" />
        )}
        <Skeleton minW="300px" minH="20px" isLoaded={!isLoadingOperator}>
          <Wrap fontSize="14px" maxW="100%">
            <Text>{operatorDisplayName(operatorDetails, isOldOperator)} </Text>
            <ValueFieldsReadonly
              value={condition.value}
              argumentTypes={argumentTypes}
              isMany={
                isOldOperator &&
                isArgumentMany((operatorDetails as OperatorDetails)?.arguments)
              }
              limit={6}
            />
          </Wrap>
        </Skeleton>
      </RowWrapperWithButtons>
      <BranchingConditionEditOverlay
        optionDisplay={optionDisplay}
        condition={condition}
        operators={operators}
        setCondition={setCondition}
        type={type}
        isOpen={isOpen}
        onClose={onClose}
        isOldOperator={isOldOperator}
        readonly={readonly}
        isModal
      />
    </>
  );
}

function ConditionList({
  optionDisplay,
  operators,
  conditions,
  setConditions,
  type,
  actionId,
  isOldOperator = false,
  readonly,
}: {
  optionDisplay: string;
  operators: ComputeOperatorDetails[] | OperatorDetails[];
  conditions: BranchingCondition[];
  setConditions: (conditions: BranchingCondition[]) => void;
  type: DESTINATION_TYPES | OperatorType;
  actionId: string;
  isOldOperator?: boolean;
  readonly?: boolean;
}) {
  const moveCard = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      let newConditions = cloneDeep(conditions);
      const draggedCondition = cloneDeep(newConditions[dragIndex]);
      newConditions.splice(dragIndex, 1);
      newConditions.splice(hoverIndex, 0, draggedCondition);
      setConditions(
        newConditions.map((condition, index) => {
          return { ...condition, sequence_number: index + 1 };
        })
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [conditions]
  );

  function setCondition(i: number, condition: BranchingConditionDef) {
    const newConditions = cloneDeep(conditions);
    newConditions[i].condition = condition;
    setConditions(newConditions);
  }

  function removeCondition(i: number) {
    let newConditions = cloneDeep(conditions);
    newConditions.splice(i, 1);
    setConditions(newConditions);
  }

  return (
    <Container id={`dnd-${actionId}`} p={0}>
      <DndWrapper id={`dnd-${actionId}`}>
        <WrapperWithScrollBar maxHeight="150px" actionId={actionId}>
          {conditions.map((condition, index) => (
            <ConditionRow
              optionDisplay={optionDisplay}
              condition={condition?.condition}
              operators={operators}
              type={type}
              moveCard={moveCard}
              setCondition={(condition) => setCondition(index, condition)}
              removeCondition={() => removeCondition(index)}
              index={index}
              isOldOperator={isOldOperator}
              key={index}
              readonly={readonly}
            />
          ))}
        </WrapperWithScrollBar>
      </DndWrapper>
    </Container>
  );
}

function AddConditionButton({ onClick }: { onClick: () => void }) {
  return (
    <IButton
      size="sm"
      variant="link"
      leftIcon={<Icon color="brand.blue" as={FaPlus} fontSize={"14px"}></Icon>}
      onClick={onClick}
      color="brand.blue"
      name="add-custom"
      alignSelf="flex-start"
      pl={2}
    >
      Add condition
    </IButton>
  );
}

function BranchByTokenWidget({
  data,
  activeErrorCheck,
  setOptions,
  actionId,
  readonly,
}: {
  data: BranchByTokenActionOptions;
  activeErrorCheck: boolean;
  setOptions: (options: BranchByTokenActionOptions) => void;
  actionId: string;
  readonly?: boolean;
}) {
  const dispatch = useAppDispatch();
  const {
    emailTokenList: { listAll: allEmailTokenList },
  } = useSelector(selectEmailToken);

  const { computeBooleanOperators } = useSelector(selectDynamicList);

  useEffect(() => {
    dispatch(getComputeOperators());
    dispatch(listAllEmailTokens());
  }, [dispatch]);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const tokenOptions = allEmailTokenList.data
    .filter((token) =>
      VALID_BRANCH_TOKEN_DATA_TYPES.includes(
        token.return_type as ValidTokenDatatypes
      )
    )
    .map((token) => {
      return {
        ...token,
        return_type:
          TYPE_TO_DESTINATION_TYPE[token.return_type as ValidTokenDatatypes],
      };
    });
  const selectedToken = data.token;
  const selectedOption = tokenOptions.find(
    (token) => token.display === selectedToken
  );
  let filteredBooleanOperators: ComputeOperatorDetails[] = [];
  let operandType = DESTINATION_TYPES.STRING;
  if (selectedOption && computeBooleanOperators) {
    operandType = (selectedOption as EmailTokenDetails)
      .return_type as DESTINATION_TYPES;
    filteredBooleanOperators = Object.values(computeBooleanOperators).filter(
      (operatorDetails) => {
        return (
          operatorDetails.operand_data_type.includes(
            operandType as DESTINATION_TYPES
          ) && operatorDetails.id !== "set_value"
        );
      }
    );
  }

  function updateToken(token: EmailTokenDetails | null) {
    const newData = cloneDeep(data) as BranchByTokenActionOptions;
    if (token) {
      newData["token"] = token?.display;
      if (
        TYPE_TO_DESTINATION_TYPE[token.return_type as ValidTokenDatatypes] !==
        operandType
      ) {
        newData.conditions = [];
      }
      setOptions(newData);
    }
  }

  function addConditions(condition: BranchingConditionDef) {
    const conditions = cloneDeep(data.conditions);
    if (conditions) {
      conditions?.push({
        sequence_number: conditions.length + 1,
        condition,
      });
      setOptions({
        ...data,
        conditions,
      });
    } else {
      setOptions({
        ...data,
        conditions: [
          {
            sequence_number: 1,
            condition,
          },
        ],
      });
    }
  }

  function updateConditions(conditions: BranchingCondition[]) {
    const options = cloneDeep(data);
    options.conditions = conditions;
    setOptions(options);
  }

  return (
    <VStack
      justifyContent="center"
      alignItems="center"
      h="100%"
      w="100%"
      px={3}
      py={6}
      spacing={4}
    >
      <FormControl isInvalid={activeErrorCheck && !selectedOption} px={2}>
        <DropdownWithSearch<EmailTokenDetails>
          options={tokenOptions}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.display}
          placeholder="Select token"
          value={selectedOption}
          onChange={(option) => updateToken(option)}
          isInvalid={activeErrorCheck && !selectedOption}
          isLoading={isLoading(allEmailTokenList.loading)}
          maxMenuHeight={300}
          isSearchable
          controlStyle={{ minWidth: "200px" }}
          id="branch-by-token"
          isDisabled={readonly}
          menuPortalTarget={null}
          components={{
            Option: TokenDropdownOption,
          }}
        />
        <FormErrorMessage>Option can't be empty</FormErrorMessage>
      </FormControl>
      {selectedOption && data.conditions?.length && (
        <ConditionList
          optionDisplay={selectedOption?.name}
          conditions={data.conditions ?? []}
          operators={filteredBooleanOperators}
          setConditions={updateConditions}
          type={operandType}
          actionId={actionId}
          readonly={readonly}
        />
      )}
      {selectedOption && !readonly && <AddConditionButton onClick={onOpen} />}
      <BranchingConditionEditOverlay
        optionDisplay={selectedOption?.name}
        condition={{
          type: TYPE.EXPRESSION,
          filter_type: FILTER_TYPE_TEXT,
          filter: FILTER_TEXT,
          operator: null,
          value: [],
        }}
        operators={filteredBooleanOperators}
        setCondition={addConditions}
        type={operandType}
        isOpen={isOpen}
        onClose={onClose}
        isModal
      />
    </VStack>
  );
}

function BranchByValueWidget({
  data,
  activeErrorCheck,
  setOptions,
  actionId,
  readonly,
}: {
  data: BranchByValueActionOptions;
  activeErrorCheck: boolean;
  setOptions: (options: BranchByValueActionOptions) => void;
  actionId: string;
  readonly?: boolean;
}) {
  const dispatch = useAppDispatch();

  const { campaignContext } = useContext(CampaignBuilderContext);

  const { operators, filterList } = useSelector(selectDynamicList);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const tableList = useMemo(
    () =>
      filterList[campaignContext ?? CAMPAIGN_CONTEXT.PERSON]?.[
        FILTER_TYPE.PERSON
      ],
    [campaignContext, filterList]
  );

  function formatTableList(list?: TableList) {
    if (list && (list as TableList)[FILTER_TABLE_NAME.PERSON]) {
      return Object.entries((list as TableList)[FILTER_TABLE_NAME.PERSON])
        .filter(([_, value]) => !value.ui_hidden)
        .map(([key, value]) => {
          return { ...value, id: key };
        });
    } else {
      return [];
    }
  }

  const columnOptions = formatTableList(tableList?.data);
  const selectedColumn = data.filter;
  const selectedOption = columnOptions.find(
    (column) => column.id === selectedColumn
  );
  let filteredOperators: OperatorDetails[] = [];
  let operandType = OperatorType.STRING;
  if (selectedOption && operators.data) {
    operandType = (selectedOption as TableDescWithId).type as OperatorType;
    filteredOperators = Object.values(operators.data[operandType]);
  }

  useEffect(() => {
    dispatch(getOperators());
  }, [dispatch]);

  function updateColumn(column: TableDescWithId | null) {
    const newData = cloneDeep(data);
    const { conditions } = newData;
    if (column) {
      let newConditions: BranchingCondition[] = [];
      if (column.type === operandType) {
        if (conditions?.length) {
          newConditions = conditions.map((conditionStep) => {
            const { condition } = conditionStep;
            condition.filter = column.id;
            return {
              ...conditionStep,
              condition: {
                ...condition,
              },
            };
          });
        }
      }
      setOptions({
        ...data,
        filter_type: FILTER_TYPE.PERSON,
        filter: column.id,
        conditions: newConditions,
      });
    }
  }

  function addConditions(condition: BranchingConditionDef) {
    let conditions = cloneDeep(data.conditions);
    if (conditions) {
      conditions.push({
        sequence_number: conditions.length + 1,
        condition,
      });
    } else {
      conditions = [
        {
          sequence_number: 1,
          condition,
        },
      ];
    }
    setOptions({
      ...data,
      conditions,
    });
  }

  function updateConditions(conditions: BranchingCondition[]) {
    const options = cloneDeep(data);
    options.conditions = conditions;
    setOptions(options);
  }

  return (
    <VStack
      justifyContent="center"
      alignItems="center"
      h="100%"
      w="100%"
      px={3}
      pt={8}
      pb={6}
      spacing={4}
    >
      <FormControl isInvalid={activeErrorCheck && !selectedOption} px={2}>
        <DropdownWithSearch<TableDescWithId>
          options={columnOptions}
          getOptionLabel={(option) => option.display_name || option.id}
          getOptionValue={(option) => option.id}
          placeholder="Select column"
          value={selectedOption}
          onChange={(option) => updateColumn(option)}
          isInvalid={activeErrorCheck && !selectedOption}
          isLoading={isLoading(tableList?.loading)}
          maxMenuHeight={300}
          isSearchable
          id="branch-by-value"
          isDisabled={readonly}
          menuPortalTarget={null}
        />
        <FormErrorMessage>Option can't be empty</FormErrorMessage>
      </FormControl>
      {selectedOption && data.conditions?.length && (
        <ConditionList
          optionDisplay={selectedOption?.display_name || selectedOption.id}
          conditions={data.conditions ?? []}
          operators={filteredOperators}
          setConditions={updateConditions}
          type={operandType}
          actionId={actionId}
          isOldOperator
          readonly={readonly}
        />
      )}
      {selectedOption && !readonly && <AddConditionButton onClick={onOpen} />}
      <BranchingConditionEditOverlay
        optionDisplay={selectedOption?.display_name || selectedOption?.id}
        condition={{
          type: TYPE.EXPRESSION,
          filter_type: FILTER_TYPE.PERSON,
          filter: data.filter || "",
          operator: null,
          value: [],
        }}
        operators={filteredOperators}
        setCondition={addConditions}
        type={operandType}
        isOpen={isOpen}
        onClose={onClose}
        isOldOperator
        readonly={readonly}
        isModal
      />
    </VStack>
  );
}

function BranchByDataWidget({
  data: { action, groupId, isCandidate, props, selectedExit, selectedGoto },
}: NodeProps<ActionNodeArgs>) {
  const isTokenFilter = useMemo(
    () => action.action_type === FLOW_ACTIONS.BRANCH_BY_TOKEN,
    [action.action_type]
  );

  const { saveDraft, setActions, readonly } = props;
  const identities = useMemo(() => {
    return {
      actionId: action.action_id,
      groupId,
      branchId: action.branch_id,
    };
  }, [action, groupId]);
  const actionOptions = useMemo(
    () => action.action_options as BranchByDataActionOptions,
    [action]
  );

  const { activeErrorCheck } = useContext(CampaignBuilderContext);
  const { flowValidity } = useSelector(selectFlow);
  const dispatch = useAppDispatch();

  const setValidityCallback = useCallback(
    (valid: boolean) => {
      dispatch(setFlowValidity({ [identities.actionId]: valid }));
    },
    [dispatch, identities.actionId]
  );

  function setOptions(options: BranchByDataActionOptions) {
    setActions(options, identities.actionId, groupId);
    saveDraft(options, { actionId: identities.actionId, groupId }, true);
  }

  useEffect(() => {
    setValidityCallback(!!actionOptions?.conditions?.length);
  }, [actionOptions, setValidityCallback, actionOptions.conditions]);

  const optionDetails = useMemo(
    () => WIDGET_OPTIONS_DETAILS[action.action_type],
    [action.action_type]
  );

  return (
    <WidgetContainer
      invalidMessage={
        flowValidity[identities.actionId] ? "" : "Add conditions for branching"
      }
      identities={identities}
      title={optionDetails.label}
      icon={optionDetails.icon}
      color={optionDetails.color}
      isCandidate={isCandidate}
      selectedExit={selectedExit}
      selectedGoto={selectedGoto}
      isDisabled={readonly}
    >
      {isTokenFilter ? (
        <BranchByTokenWidget
          data={actionOptions}
          setOptions={setOptions}
          activeErrorCheck={activeErrorCheck}
          actionId={identities.actionId}
          readonly={readonly}
        />
      ) : (
        <BranchByValueWidget
          data={actionOptions}
          setOptions={setOptions}
          activeErrorCheck={activeErrorCheck}
          actionId={identities.actionId}
          readonly={readonly}
        />
      )}
    </WidgetContainer>
  );
}

export default memo(BranchByDataWidget, isActionDataSame);
