import { VStack } from "@chakra-ui/react";
import { SalesforceConnection } from "../../../../../../../common/types/connection";
import InflectionToSalesforceInclusionList from "./InflectionToSalesforceInclusionList";
import InflectionToSalesforceRecord from "./InlfectionToSalesforceRecord";

export default function InflectionToSalesforce({
  data,
}: {
  data: SalesforceConnection;
}) {
  return (
    <VStack alignItems="flex-start" spacing={6} p="5" bg="white" rounded="md">
      <InflectionToSalesforceInclusionList data={data} />
      <InflectionToSalesforceRecord data={data} />
    </VStack>
  );
}
