import { VStack, Divider, Text } from "@chakra-ui/react";
import { convertToString } from "../../../../common/helper/commonHelper";
import ITitle from "../../../../components/ITitle";

export function SyncStats({
  label,
  value,
  isComingSoon = false,
}: {
  label: string;
  value: string | number | null;
  isComingSoon?: boolean;
}) {
  return (
    <VStack alignItems="left">
      <ITitle
        fontSize="sm"
        fontWeight="semibold"
        color="gray.400"
        title={label}
        isComingSoon={isComingSoon}
      />
      <Text
        fontSize="md"
        fontWeight="semibold"
        color={isComingSoon ? "gray.400" : "gray.600"}
      >
        {convertToString(value, "-")}
      </Text>
      <Divider width="100px" height="2px" />
    </VStack>
  );
}
