import { Box, useTheme, VStack } from "@chakra-ui/react";
import { cloneDeep } from "lodash";
import { useEffect, useCallback, useMemo, useRef, useContext } from "react";
import { useSelector } from "react-redux";
import { ActionOptions } from "../../../../common/types/campaign";
import { selectCampaign, getDynamicListCount } from "../campaignSlice";
import { resetLoadingStateEmailDefault } from "../../settings/settingsSlice";
import {
  FILTER_TYPE,
  FLOW_ACTIONS,
} from "../../../../common/constants/campaign";
import ReactFlow, {
  MiniMap,
  Controls,
  useNodesState,
  useEdgesState,
  Edge,
  ReactFlowProvider,
  Node,
  useReactFlow,
  EdgeMarker,
} from "reactflow";
import {
  ActionNodeIdentifiers,
  FlowGraph,
} from "../../../../common/types/flow";
import {
  dynamicListCountNode,
  getLayoutedElements,
  getNodeId,
  appendGotoEdge,
  preorderTraversal,
  createAdjacentGraphs,
  appendExitLinks,
  appendProps,
  fetchMiniMapNodeColor,
  fetchMiniMapStrokeColor,
} from "./components/helpers";
import {
  NODE_TYPES,
  defaultEdgeMarkerProps,
  edgeStyle,
  exitEdgeMarkerProps,
  exitEdgeSelectedStyle,
  gotoEdgeMarkerProps,
  gotoEdgeSelectedStyle,
} from "./components/constants";
import { useAppDispatch } from "../../../../store";
import { LayoutContext } from "../../../../layout/LayoutWithSideNavBar";
import EmailWidget from "./components/widgets/EmailWidget";
import DelayWidget from "./components/widgets/DelayWidget";
import EmailAlertWidget from "./components/widgets/EmailAlertWidget";
import StaticListWidget from "./components/widgets/StaticListWidget";
import DynamicListCount from "./components/DynamicListCount";
import WebhookWidget from "./components/widgets/WebhookWidget";
import BranchByFilterWidget from "./components/widgets/BranchByFilterWidget";
import BranchByDataWidget from "./components/widgets/BranchByDataWidget";
import EdgeLabel from "./components/EdgeLabel";
import UpdateValueWidget from "./components/widgets/UpdateValueWidget";
import GotoWidget from "./components/widgets/GotoWidget";
import SmoothSmartEdge from "./components/SmoothSmartEdge";
import WorkFlowGroupWidget from "./components/widgets/WorkFlowGroupWidget";
import AddButtonNode from "./components/AddButton";
import CustomStepEdge from "./components/CustomStepEdge";
import { getFilterList } from "../../../../components/dynamic-list/dynamicListSlice";
import SalesforceCampaignSyncWidget from "./components/widgets/SalesforceCampaignSyncWidget";
import {
  CampaignBuilderContext,
  selectFlow,
  setDraftFlowActionOptions,
  updateFlowAction,
} from "./flowSlice";

const EDGE_VARIANTS = {
  smart: SmoothSmartEdge,
  custom: CustomStepEdge,
};

const NODE_VARIANTS = {
  [NODE_TYPES.INSERT_NODE]: AddButtonNode,
  [NODE_TYPES.DYNAMIC_LIST_COUNT]: DynamicListCount,
  [NODE_TYPES.EDGE_LABEL]: EdgeLabel,
  [FLOW_ACTIONS.DELAY]: DelayWidget,
  [FLOW_ACTIONS.ADD_TO_STATIC_LIST]: StaticListWidget,
  [FLOW_ACTIONS.BRANCH_BY_FILTER]: BranchByFilterWidget,
  [FLOW_ACTIONS.BRANCH_BY_TOKEN]: BranchByDataWidget,
  [FLOW_ACTIONS.BRANCH_BY_VALUE]: BranchByDataWidget,
  [FLOW_ACTIONS.GOTO]: GotoWidget,
  [FLOW_ACTIONS.GROUP]: WorkFlowGroupWidget,
  [FLOW_ACTIONS.REMOVE_FROM_STATIC_LIST]: StaticListWidget,
  [FLOW_ACTIONS.SALESFORCE]: SalesforceCampaignSyncWidget,
  [FLOW_ACTIONS.SALESFORCE_CAMPAIGN]: SalesforceCampaignSyncWidget,
  [FLOW_ACTIONS.SEND_EMAIL]: EmailWidget,
  [FLOW_ACTIONS.SEND_INTERNAL_EMAIL]: EmailAlertWidget,
  [FLOW_ACTIONS.UPDATE_VALUE]: UpdateValueWidget,
  [FLOW_ACTIONS.WEBHOOK]: WebhookWidget,
};

export function FlowGraphUI({ readonly }: { readonly?: boolean }) {
  const dispatch = useAppDispatch();
  const {
    dynamicListCount: { data: dynamicListCount },
  } = useSelector(selectCampaign);

  const { campaignContext, campaignId } = useContext(CampaignBuilderContext);

  const {
    flow: { data: flow },
  } = useSelector(selectFlow);

  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const ref = useRef<HTMLDivElement | null>(null);
  const reactFlowInstance = useReactFlow();
  const { isSidebarCollapsed } = useContext(LayoutContext);
  const theme = useTheme();

  const colors = {
    grayAlpha200: theme.__cssVars["--chakra-colors-brand-grayAlpha-200"],
    shadowTheme: theme.__cssVars["--chakra-shadows-md"],
  };

  const centerPos = useMemo(
    () =>
      reactFlowInstance.project({
        x: (window.innerWidth + (isSidebarCollapsed ? 410 : 265)) / 2,
        y: 0,
      })?.x ?? 0,
    [reactFlowInstance, isSidebarCollapsed]
  );

  const getNodesAndEdges = useCallback(
    async (flowData: FlowGraph) => {
      // setting up graph with parent as key and children as an array value
      const { childGraph, ancestorGraph } = createAdjacentGraphs(
        flowData.nodes,
        flowData.links
      );

      const sortedNodes: Node[] = [dynamicListCountNode()];
      const sortedEdges: Edge[] = [];

      // this is where everythings starts! call of the recursive function to sort nodes on the rendering order,
      //  add edges with label and add `add flow step` button
      const gotoLinks: Edge[] = [];
      const groupExits: Edge[] = [];

      preorderTraversal(
        getNodeId(NODE_TYPES.DYNAMIC_LIST_COUNT, ""),
        flowData.nodes,
        sortedNodes,
        sortedEdges,
        childGraph,
        gotoLinks,
        groupExits
      );
      // Layout of graph with sorted nodes and edges which will decide the positions of nodes
      const { nodes: nodeList, edges: edgeList } = await getLayoutedElements(
        sortedNodes,
        sortedEdges,
        centerPos
      );
      return { nodeList, edgeList, gotoLinks, groupExits, ancestorGraph };
    },
    [centerPos]
  );

  const setNodesWithFlow = useCallback(
    async (flowData: FlowGraph) => {
      const { nodeList, edgeList, gotoLinks, groupExits, ancestorGraph } =
        await getNodesAndEdges(flowData);
      const { nodes: nodesWithGoto, edges: edgesWithGoto } = appendGotoEdge(
        nodeList,
        edgeList,
        gotoLinks,
        ancestorGraph
      );
      const { nodes: nodesWithExit, edges: edgesWithExit } = appendExitLinks(
        nodesWithGoto,
        edgesWithGoto,
        groupExits,
        ancestorGraph
      );
      const { nodes, edges } = appendProps(
        nodesWithExit,
        {
          saveDraft,
          setActions: updateActionsRemote,
          selectGoto,
          selectExit,
          FlowContainerRef: ref,
          readonly,
        },
        edgesWithExit,
        { readonly }
      );
      setNodes(nodes);
      setEdges(edges);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setNodes, setEdges, getNodesAndEdges, readonly]
  );

  useEffect(() => {
    setNodesWithFlow(flow);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flow, readonly]);

  useEffect(() => {
    return () => {
      dispatch(resetLoadingStateEmailDefault());
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getFilterList({
        filterType: FILTER_TYPE.PERSON,
        campaignContext: campaignContext,
      })
    );
  }, [campaignContext, dispatch]);

  useEffect(() => {
    if (dynamicListCount === null && campaignId) {
      dispatch(getDynamicListCount(campaignId));
    }
  }, [campaignId, dynamicListCount, dispatch]);

  const updateActionsRemote = useCallback(
    (actionOptions: ActionOptions, id: string, groupId?: string) => {
      dispatch(setDraftFlowActionOptions({ actionOptions, id, groupId }));
      setNodes((nds) => {
        let newNodes = cloneDeep(nds);
        const index = newNodes.findIndex(
          (nodes) => nodes.data?.action?.action_id === id
        );
        newNodes[index].data.action.action_options = actionOptions;
        return newNodes;
      });
    },
    [dispatch, setNodes]
  );

  const selectGoto = useCallback(
    (id: string | null) => {
      setNodes((nds) => {
        const nodes = cloneDeep(nds);
        nodes.forEach((node) => {
          const selectedGoto = id;
          node.data = { ...node.data, selectedGoto };
        });
        return nodes;
      });
      setEdges((eds) => {
        let edges = cloneDeep(eds);
        const newEdges = edges.map((edge) => {
          const isHighlighted = id && edge.id.includes(id);
          const markerEnd = edge.markerEnd as EdgeMarker | undefined;
          return {
            ...edge,
            style: isHighlighted ? gotoEdgeSelectedStyle : edgeStyle,
            selected: !!isHighlighted,
            markerEnd:
              markerEnd &&
              (isHighlighted
                ? { ...markerEnd, ...gotoEdgeMarkerProps }
                : { ...markerEnd, ...defaultEdgeMarkerProps }),
          };
        });
        return newEdges;
      });
    },
    [setNodes, setEdges]
  );

  const selectExit = useCallback(
    (conditionId: string | null) => {
      setEdges((eds) => {
        let edges = cloneDeep(eds);
        const newEdges = edges.map((edge) => {
          const isHighlighted = edge.data?.conditionId === conditionId;
          const markerEnd = edge.markerEnd as EdgeMarker | undefined;
          return {
            ...edge,
            style: isHighlighted ? exitEdgeSelectedStyle : edgeStyle,
            selected: isHighlighted,
            markerEnd:
              markerEnd &&
              (isHighlighted
                ? { ...markerEnd, ...exitEdgeMarkerProps }
                : { ...markerEnd, ...defaultEdgeMarkerProps }),
          };
        });
        return newEdges;
      });
    },
    [setEdges]
  );

  const saveDraft = useCallback(
    (
      flowAction: ActionOptions,
      identities: ActionNodeIdentifiers,
      shouldRender?: boolean
    ) => {
      dispatch(
        updateFlowAction({
          campaignId: campaignId,
          actionId: identities.actionId,
          actionOptions: flowAction,
          groupId: identities.groupId,
          shouldRender,
        })
      );
    },
    [campaignId, dispatch]
  );

  return (
    <Box
      flex={1}
      style={{ cursor: "grab" }}
      position="relative"
      ref={ref}
      h="calc(100vh - 66px)"
      width="100%"
      p={0}
    >
      {!!nodes.length && (
        <ReactFlow
          nodes={nodes}
          onNodesChange={onNodesChange}
          edges={edges}
          onEdgesChange={onEdgesChange}
          onInit={() => reactFlowInstance.fitView()}
          nodeTypes={NODE_VARIANTS}
          nodesDraggable={false}
          panOnScroll={true}
          minZoom={0.1}
          maxZoom={1}
          deleteKeyCode={null}
          edgeTypes={EDGE_VARIANTS}
          snapGrid={[15, 15]}
          snapToGrid
          fitView
        >
          <MiniMap
            maskColor={colors.grayAlpha200}
            nodeStrokeColor={(n) =>
              fetchMiniMapStrokeColor(n.type as FLOW_ACTIONS | NODE_TYPES)
            }
            nodeColor={(n) =>
              fetchMiniMapNodeColor(n.type as FLOW_ACTIONS | NODE_TYPES)
            }
            zoomable
            nodeStrokeWidth={8}
            style={{ translate: "-35px" }}
          />
          <Controls
            showInteractive={false}
            style={{ boxShadow: colors.shadowTheme }}
            position="bottom-right"
          />
        </ReactFlow>
      )}
    </Box>
  );
}

export default function CampaignSmartflow({
  readonly,
}: {
  readonly?: boolean;
}) {
  return (
    <VStack
      h="100vh"
      width="100%"
      p={0}
      position="relative"
      bg="oneOffs.reactFlow"
    >
      <ReactFlowProvider>
        <FlowGraphUI readonly={readonly} />
      </ReactFlowProvider>
    </VStack>
  );
}
