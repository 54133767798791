import {
  Flex,
  useDisclosure,
  Box,
  Icon,
  Text,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import { capitalize, debounce } from "lodash";
import {
  useCallback,
  useEffect,
  useState,
  useMemo,
  memo,
  useContext,
} from "react";
import { NodeProps } from "reactflow";
import {
  DELAY_OPTION_VALUES,
  DELAY_TYPE,
  FLOW_ACTIONS,
} from "../../../../../../common/constants/campaign";
import { DelayActionOptions } from "../../../../../../common/types/campaign";
import { ActionNodeArgs } from "../../../../../../common/types/flow";
import AddDelayDrawer from "../AddDelayDrawer";
import { WIDGET_OPTIONS_DETAILS } from "../constants";
import { isActionDataSame } from "../helpers";
import InfoBanner from "../../../../../../components/InfoBanner";
import WidgetContainer from "../WidgetContainer";
import { VscSettings } from "react-icons/vsc";
import { formatDateString } from "../../../../../../components/DateTimeRangeFilter";
import {
  addSuffixForPlural,
  formatTime,
} from "../../../../../../common/helper/commonHelper";
import { EMPTY_CONTEXT } from "../../../../../../common/constants/common";
import {
  CampaignBuilderContext,
  selectFlow,
  setFlowValidity,
} from "../../flowSlice";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../../../../store";
import { WrapperForEmptyFlowState } from "../WrapperForEmptyFlowState";
import { FaRegClock } from "react-icons/fa";

const optionDetails = WIDGET_OPTIONS_DETAILS[FLOW_ACTIONS.DELAY];
const EMPTY_CONTEXT_FOR_DELAY = "--";

// NOTE: Adding empty states even though it is inaccessible by current flow, to accomodate empty state in existing journeys
function DelayWidgetBeforeTypeSelect({ onClick }: { onClick: () => void }) {
  return (
    <WrapperForEmptyFlowState
      setUpFlowStep={onClick}
      emptyStateDetails={{
        icon: FaRegClock,
        header: "Add a delay before the next step",
        subHeader: "Click here to set up the delay flow step",
      }}
    />
  );
}
export function DelayWidgetAfterTypeSelect({
  isInvalid,
  isReadOnly,
  onOpenAddDelayDrawer,
  subTextToDisplay,
  mainTextToDisplay,
}: {
  isInvalid: boolean;
  isReadOnly?: boolean;
  onOpenAddDelayDrawer: () => void;
  subTextToDisplay: string | undefined;
  mainTextToDisplay: string | undefined;
}) {
  return (
    <>
      <Grid
        gridTemplateColumns="80px 1fr 35px"
        cursor={isReadOnly ? "pointer" : "default"}
      >
        <GridItem fontSize="14px">
          <Text fontWeight={600} color="brandBlue.600" maxW="250px">
            {mainTextToDisplay}
          </Text>
        </GridItem>
        <GridItem>
          <Text color="brandBlue.500" maxW="250px" mb={2} fontSize="14px">
            <Text>{subTextToDisplay}</Text>
          </Text>
        </GridItem>
        <GridItem ml={2}>
          <Box
            bg="grayV2.200"
            borderRadius={6}
            h={6}
            w={6}
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{ cursor: "pointer" }}
            _hover={{
              bg: "grayV2.300",
            }}
            hidden={isReadOnly}
            onClick={onOpenAddDelayDrawer}
          >
            <Icon as={VscSettings} size="14px" color="brandBlue.500" />
          </Box>
        </GridItem>
      </Grid>
      {/* redo with design revamp */}
      <Text fontSize="14px" color="red.500" pt={1} hidden={!isInvalid}>
        Delay details incomplete
      </Text>
    </>
  );
}

function DelayWidget({
  data: { action, groupId, isCandidate, props, selectedExit, selectedGoto },
}: NodeProps<ActionNodeArgs>) {
  const { saveDraft, setActions, readonly } = props;
  const dispatch = useAppDispatch();
  const identities = useMemo(() => {
    return {
      actionId: action.action_id,
      groupId,
      branchId: action.branch_id,
    };
  }, [action, groupId]);
  const actionOptions = useMemo(
    () => action.action_options as DelayActionOptions,
    [action]
  );

  const { activeErrorCheck } = useContext(CampaignBuilderContext);
  const { flowValidity } = useSelector(selectFlow);

  const setValidityCallback = useCallback(
    (valid: boolean) => {
      dispatch(setFlowValidity({ [identities.actionId]: valid }));
    },
    [dispatch, identities.actionId]
  );

  function setOptions(options: DelayActionOptions) {
    setActions(options, identities.actionId, groupId);
    debouncedSave(options, { actionId: identities.actionId, groupId });
  }

  const debouncedSave = useMemo(() => debounce(saveDraft, 2000), [saveDraft]);

  function setAddDelayOptions(options: DelayActionOptions) {
    setOptions(options);
    onCloseAddDelayDrawer();
  }

  const delayValidityCheck = useMemo(() => {
    let validity = !!actionOptions.type;
    if (actionOptions.type === DELAY_TYPE.DELAY_BY) {
      const delayBy = actionOptions.delay_by;
      validity = !!(delayBy?.delay_unit && delayBy?.delay_value);
    } else if (actionOptions.type === DELAY_TYPE.DELAY_UNTIL) {
      const delayUntil = actionOptions?.delay_until;
      validity = !!actionOptions.delay_until?.option;

      switch (delayUntil?.option) {
        case DELAY_OPTION_VALUES.FIRST_DAY_OF_MONTH:
          validity = !!delayUntil?.run_time;
          break;
        case DELAY_OPTION_VALUES.LAST_DAY_OF_MONTH:
          validity = !!delayUntil?.run_time;
          break;
        case DELAY_OPTION_VALUES.TIME_OF_DAY:
          validity = !!delayUntil?.run_time;
          break;
        case DELAY_OPTION_VALUES.SPECIFIC_DATE_TIME:
          validity = !!(delayUntil?.run_time && delayUntil?.run_date);
          break;
        case DELAY_OPTION_VALUES.TIME_IN_WEEK:
          validity = !!(
            delayUntil?.week_days?.length !== 0 && delayUntil?.run_time
          );
          break;
      }
    }
    return validity;
  }, [actionOptions]);

  useEffect(() => {
    setValidityCallback(delayValidityCheck);
  }, [setValidityCallback, delayValidityCheck]);

  const {
    isOpen: isOpenAddDelayDrawer,
    onOpen: onOpenAddDelayDrawer,
    onClose: onCloseAddDelayDrawer,
  } = useDisclosure();

  const [subTextToDisplay, setSubTextToDisplay] = useState<string | undefined>(
    EMPTY_CONTEXT_FOR_DELAY
  );
  const [mainTextToDisplay, setMainTextToDisplay] = useState<
    string | undefined
  >(EMPTY_CONTEXT);

  const [timeZoneText, setTimeZoneText] = useState<string>("");
  const [showInfoBanner, setShowInfoBanner] = useState(false);

  const showRunTime = useMemo(() => {
    const runTime =
      actionOptions.type === DELAY_TYPE.DELAY_UNTIL &&
      actionOptions.delay_until?.run_time;
    return runTime ? formatTime({ time: runTime }) : EMPTY_CONTEXT_FOR_DELAY;
  }, [actionOptions]);

  const showTextBasedOnDelayOption = useCallback(() => {
    if (actionOptions.type === DELAY_TYPE.DELAY_BY) {
      const delayBy = actionOptions.delay_by;
      const unit = delayBy?.delay_unit
        ? addSuffixForPlural(
            delayBy?.delay_unit?.slice(0, -1).toLowerCase() ?? "",
            delayBy?.delay_value ?? 0
          )
        : EMPTY_CONTEXT_FOR_DELAY;
      const value = delayBy?.delay_value ?? EMPTY_CONTEXT_FOR_DELAY;
      return `${value} ${unit}`;
    } else if (actionOptions.type === DELAY_TYPE.DELAY_UNTIL) {
      const delayUntil = actionOptions.delay_until;
      switch (delayUntil?.option) {
        case DELAY_OPTION_VALUES.FIRST_DAY_OF_MONTH:
          return `First day of the month at ${showRunTime}`;

        case DELAY_OPTION_VALUES.LAST_DAY_OF_MONTH:
          return `Last day of the month at ${showRunTime}`;

        case DELAY_OPTION_VALUES.SPECIFIC_DATE_TIME:
          return delayUntil.run_time
            ? `${formatDateString({
                date: delayUntil?.run_date,
                tzIndependent: true,
              })} at ${formatTime({ time: delayUntil?.run_time })}`
            : EMPTY_CONTEXT_FOR_DELAY;

        case DELAY_OPTION_VALUES.TIME_OF_DAY:
          return delayUntil?.run_time
            ? formatTime({ time: delayUntil?.run_time })
            : EMPTY_CONTEXT_FOR_DELAY;

        case DELAY_OPTION_VALUES.TIME_IN_WEEK:
          const formattedDays = !!delayUntil.week_days?.length
            ? delayUntil.week_days?.map((day) => capitalize(day)).join(", ")
            : EMPTY_CONTEXT_FOR_DELAY;

          const time = delayUntil.run_time
            ? `${formatTime({ time: delayUntil?.run_time })}`
            : EMPTY_CONTEXT_FOR_DELAY;
          return `${formattedDays} at ${time}`;

        default:
          return EMPTY_CONTEXT_FOR_DELAY;
      }
    }
  }, [actionOptions, showRunTime]);

  const showDelayTypeText = useMemo(() => {
    switch (actionOptions.type) {
      case DELAY_TYPE.DELAY_BY:
        return "Delay by: ";
      case DELAY_TYPE.DELAY_UNTIL:
        return "Delay until: ";
    }
  }, [actionOptions]);

  useEffect(() => {
    if (isOpenAddDelayDrawer === false) {
      setSubTextToDisplay(showTextBasedOnDelayOption);
      setMainTextToDisplay(showDelayTypeText);
      setShowInfoBanner(actionOptions.type === DELAY_TYPE.DELAY_UNTIL);
      setTimeZoneText(
        actionOptions.type === DELAY_TYPE.DELAY_UNTIL &&
          actionOptions.delay_until?.is_recipient_timezone
          ? "recipient"
          : "workspace"
      );
    }
  }, [
    isOpenAddDelayDrawer,
    actionOptions,
    showDelayTypeText,
    showTextBasedOnDelayOption,
  ]);

  return (
    <WidgetContainer
      invalidMessage={
        flowValidity[identities.actionId] ? "" : "Fields are invalid"
      }
      identities={identities}
      title={optionDetails.label}
      icon={optionDetails.icon}
      color={optionDetails.color}
      isCandidate={isCandidate}
      selectedExit={selectedExit}
      selectedGoto={selectedGoto}
      isDisabled={readonly}
    >
      <Flex h="100%" w="100%" px="5" py="4" flexDirection="column">
        {!actionOptions.type ? (
          <DelayWidgetBeforeTypeSelect onClick={onOpenAddDelayDrawer} />
        ) : (
          <>
            <DelayWidgetAfterTypeSelect
              isInvalid={activeErrorCheck && !delayValidityCheck}
              isReadOnly={readonly}
              onOpenAddDelayDrawer={onOpenAddDelayDrawer}
              subTextToDisplay={subTextToDisplay}
              mainTextToDisplay={mainTextToDisplay}
            />
            <InfoBanner
              hidden={!showInfoBanner}
              width="100%"
              height="30px"
              p={1}
              mt={2}
              bg="white"
              color="brandBlue.500"
              iconProps={{ fontSize: "16px" }}
            >
              <Text pt="2px">
                Scheduled according to {timeZoneText} timezone
              </Text>
            </InfoBanner>
          </>
        )}
      </Flex>
      <AddDelayDrawer
        isInvalid={activeErrorCheck && !delayValidityCheck}
        isOpen={isOpenAddDelayDrawer}
        onClose={onCloseAddDelayDrawer}
        actionOptions={actionOptions}
        setOptions={setAddDelayOptions}
      />
    </WidgetContainer>
  );
}

export default memo(DelayWidget, isActionDataSame);
