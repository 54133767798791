import { ButtonGroup } from "@chakra-ui/react";
import { FaPencilAlt } from "react-icons/fa";
import { toast } from "react-toastify";
import { LOADING_STATES } from "../../../../../../../common/constants/common";
import { isLoading } from "../../../../../../../common/helper/commonHelper";
import { AccountContactAutoMappingElement } from "../../../../../../../common/types/AccountContactMapping";
import { PersonDestination } from "../../../../../../../common/types/person";
import IButton, { BUTTON } from "../../../../../../../components/IButton";
import { useAppDispatch } from "../../../../../../../store";
import { updateAccountContactAutoMapping } from "../../../../connectionSlice";

export default function SaveAndCancelBar({
  readonlyMode,
  setIsReadonly,
  isEnabled,
  accountField,
  contactField,
  delimiterValue,
  isCancelled,
  setIsCancelled,
  updatingMapping,
  isHidden,
}: {
  readonlyMode: boolean;
  setIsReadonly: (readonlyMode: boolean) => void;
  isEnabled: boolean;
  accountField: PersonDestination;
  contactField: PersonDestination;
  delimiterValue: { label: string; value: string | null };
  isCancelled: boolean;
  setIsCancelled: (isCancelled: boolean) => void;
  updatingMapping: LOADING_STATES;
  isHidden: boolean;
}) {
  const dispatch = useAppDispatch();
  function handleCancel() {
    setIsCancelled(true);
    setIsReadonly(!readonlyMode);
  }
  function validateValues(
    accountField: PersonDestination,
    contactField: PersonDestination,
    delimiterValue: { label: string; value: string | null }
  ) {
    if (accountField && contactField && delimiterValue) {
      return !(
        accountField?.name === "" ||
        contactField.name === "" ||
        delimiterValue.value === ""
      );
    } else {
      return false;
    }
  }

  function handleSave() {
    const isValid = validateValues(accountField, contactField, delimiterValue);
    if (isValid) {
      const data: AccountContactAutoMappingElement = {
        is_enabled: isEnabled,
        account_field: accountField?.name,
        person_field: contactField?.name,
        delimiter:
          delimiterValue.value === "none" ? null : delimiterValue.value,
      };
      dispatch(updateAccountContactAutoMapping(data));
      setIsCancelled(true);
      setIsReadonly(!readonlyMode);
    } else {
      toast.warning("Incomplete fields please fill all the fields");
    }
  }

  function handleEdit() {
    setIsCancelled(false);
    setIsReadonly(!readonlyMode);
  }

  return readonlyMode ? (
    <IButton
      variant={BUTTON.SECONDARY}
      leftIcon={<FaPencilAlt />}
      size="sm"
      onClick={handleEdit}
      hidden={isHidden}
    >
      Edit
    </IButton>
  ) : (
    <ButtonGroup>
      <IButton
        variant={BUTTON.SECONDARY}
        onClick={handleCancel}
        isDisabled={isLoading(updatingMapping)}
        hidden={isHidden}
      >
        Cancel
      </IButton>
      <IButton
        variant={BUTTON.PRIMARY}
        onClick={handleSave}
        isLoading={isLoading(updatingMapping)}
        hidden={isHidden}
      >
        Save
      </IButton>
    </ButtonGroup>
  );
}
