import {
  Action,
  AnyAction,
  CombinedState,
  combineReducers,
  configureStore,
  ThunkAction,
} from "@reduxjs/toolkit";

import constantReducer from "./common/slices/constantsSlice";
import accountReducer from "./pages/account/accountSlice";
import connectionReducer from "./pages/dashboard/connection/connectionSlice";
import campaignReducer from "./pages/dashboard/campaign/campaignSlice";
import templateReducer from "./pages/dashboard/templates/templateSlice";
import settingsReducer from "./pages/dashboard/settings/settingsSlice";
import personReducer from "./pages/dashboard/persondb/personDbSlice";
import userReducer from "./pages/dashboard/users/userSlice";
import emailTokenReducer from "./pages/dashboard/emailtoken/emailTokenSlice";
import staticlistReducer from "./pages/dashboard/staticlist/staticlistSlice";
import campaignReportsReducer from "./pages/dashboard/campaign/campaignreports/campaignReportsSlice";
import webhookReducer from "./pages/dashboard/webhook/webhookSlice";
import accountDirectoryReducer from "./pages/dashboard/accountdb/accountDbSlice";
import tagReducer from "./pages/dashboard/tag/tagSlice";
import { useDispatch } from "react-redux";
import apiStatusReducer from "./common/slices/apiStatusSlice";
import formReducer from "./pages/dashboard/form/formSlice";
import dynamicListReducer from "./components/dynamic-list/dynamicListSlice";
import triggerCampaignReducer from "./pages/dashboard/campaign/trigger-campaign/triggerCampaignSlice";
import exportReducer from "./pages/dashboard/export/exportSlice";
import contactUploadReducer from "./pages/dashboard/contactUpload/contactUploadSlice";
import flowReducer from "./pages/dashboard/campaign/flow/flowSlice";

const reducers = {
  account: accountReducer,
  constants: constantReducer,
  connection: connectionReducer,
  campaign: campaignReducer,
  person: personReducer,
  accountDirectory: accountDirectoryReducer,
  template: templateReducer,
  settings: settingsReducer,
  user: userReducer,
  emailToken: emailTokenReducer,
  staticlist: staticlistReducer,
  campaignReports: campaignReportsReducer,
  webhook: webhookReducer,
  tag: tagReducer,
  apiStatus: apiStatusReducer,
  form: formReducer,
  export: exportReducer,
  dynamicList: dynamicListReducer,
  triggerCampaign: triggerCampaignReducer,
  contactUpload: contactUploadReducer,
  flow: flowReducer,
};

const combinedReducer = combineReducers(reducers);

type reducerState = { [key in keyof typeof reducers]: any };

const rootReducer = (
  state: CombinedState<reducerState> | undefined,
  action: AnyAction
) => {
  //Clearing the store when user logs out

  if (action.type === "auth/logoutUser/fulfilled") {
    return combinedReducer(undefined, action);
  }
  return combinedReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
});

export type AppDispatch = typeof store.dispatch;
// Our dispatch does not take types for thunks into account and thus the return type is typed incorrectly
export const useAppDispatch = () => useDispatch<AppDispatch>();
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
