import { useEffect, useState } from "react";
import MemoizedCommonDrawer from "../campaign/components/CommonDrawer";
import CsvFileUploader from "./components/CsvFileUploader";
import CsvToContactDbMapper from "./components/CsvToContactDbMapper";
import ContactUploadReport from "./components/ContactUploadReport";
import {
  UPLOAD_CONTACTS_TO,
  IMPORT_CONTACTS_CSV,
} from "../../../common/types/contactUpload";
import { useSelector } from "react-redux";
import {
  resetAllUploadDetails,
  selectContactUpload,
} from "./contactUploadSlice";
import { HStack, Text } from "@chakra-ui/react";
import { useAppDispatch } from "../../../store";
import { resetData } from "../staticlist/staticlistSlice";

export default function ContactUploaderCsv({
  isOpen,
  onClose,
  onImport,
  uploadTo,
}: {
  isOpen: boolean;
  onClose: () => void;
  onImport: () => void;
  uploadTo: UPLOAD_CONTACTS_TO;
}) {
  const [currentStep, setCurrentStep] = useState<IMPORT_CONTACTS_CSV>(
    IMPORT_CONTACTS_CSV.UPLOAD_FILE
  );

  const {
    fileUpload: { fileName },
  } = useSelector(selectContactUpload);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!isOpen) {
      setCurrentStep(IMPORT_CONTACTS_CSV.UPLOAD_FILE);
      dispatch(resetData());
      setTimeout(() => {
        dispatch(resetAllUploadDetails());
      }, 500);
    }
  }, [isOpen, dispatch]);

  function goToPrevStep() {
    setCurrentStep((prev) => prev - 1);
  }

  function goToNextStep() {
    setCurrentStep((prev) => prev + 1);
  }

  function onCompleteImport() {
    onImport();
    onClose();
  }

  function getCurrentStepContent() {
    switch (currentStep) {
      case IMPORT_CONTACTS_CSV.UPLOAD_FILE:
        return <CsvFileUploader goToNext={goToNextStep} />;
      case IMPORT_CONTACTS_CSV.MAP_CONTACT_FIELDS:
        return <CsvToContactDbMapper goToNext={goToNextStep} />;
      case IMPORT_CONTACTS_CSV.UPLOAD_SUMMARY:
        return (
          <ContactUploadReport
            goBack={goToPrevStep}
            uploadTo={uploadTo}
            onCompleteImport={onCompleteImport}
          />
        );
    }
  }
  const inUploadFileStep = currentStep === IMPORT_CONTACTS_CSV.UPLOAD_FILE;

  return (
    <MemoizedCommonDrawer
      isOpen={isOpen}
      placement="right"
      title={
        <HStack alignItems="baseline">
          <Text>Import Contacts </Text>
          {!inUploadFileStep && (
            <Text
              color="gray.500"
              fontWeight="normal"
              fontSize="14px"
              maxW="75%"
            >
              {`(${fileName})`}
            </Text>
          )}
        </HStack>
      }
      onClose={onClose}
      size="xl"
    >
      {getCurrentStepContent()}
    </MemoizedCommonDrawer>
  );
}
