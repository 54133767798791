import { campaignRequestV2 } from "./api";
import {
  ExportSummary,
  ExportResourceResp,
  ExportCampaignReport,
  ExportStatusResp,
} from "../../types/export";

export async function exportDynamicListApi(
  dynamicListId: string
): Promise<ExportResourceResp> {
  return campaignRequestV2({
    url: `/assets/dynamic-lists/${dynamicListId}/export`,
  });
}

export async function exportStaticListApi(
  staticListId: string
): Promise<ExportResourceResp> {
  return campaignRequestV2({
    url: `/assets/static-lists/${staticListId}/export`,
  });
}

export async function exportCampaignReportApi({
  campaignId,
  reportType,
  templateId,
}: ExportCampaignReport): Promise<ExportResourceResp> {
  return campaignRequestV2({
    url: `/campaigns/${campaignId}/report/export`,
    data: {
      report_type: reportType,
      template_id: templateId,
    },
  });
}

export async function getExportStatusApi(
  exportId: string
): Promise<ExportStatusResp> {
  return campaignRequestV2({
    url: `/exports/${exportId}`,
    method: "GET",
  });
}

export async function abortExportApi(exportId: string): Promise<ExportSummary> {
  return campaignRequestV2({
    url: `/exports/${exportId}/abort`,
  });
}
