import { PaginatedList } from "../../types/common";
import {
  Connection,
  SchemaList,
  DestinationTable,
  SyncTablesData,
  SyncRun,
  ConnectionCreateConfig,
  ConnectionUpdateConfig,
  SyncSummary,
  SyncGetResp,
  Activity,
  SegmentError,
  SegmentReportRow,
  ReportRange,
  SourceColumn,
  SegmentSourceDestination,
  SegmentSyncConfig,
  SEGMENT_SYNC_TYPES,
  SegmentSyncReq,
  SegmentEventStatus,
  SegmentDescription,
  SegmentEventToggle,
  SegmentEventPropertyToggle,
  SegmentEventStatusTrack,
  SegmentEventStatusNonTrack,
  SyncDetails,
  ValidationStatus,
  ConnectionReadOnlyValidateConfig,
  ConnectionReadAndWriteValidateConfig,
  ColumnToAssetMapping,
} from "../../types/connection";
import { MappingDestination } from "../../types/person";
import {
  AccountMappingElement,
  ConnectionListItem,
  DefaultAccountMapping,
  DefaultCrmMapping,
  DwDestination,
  Mapping,
  MappingElement,
  SalesforceObject,
} from "../../types/unifiedMapping";
import { campaignRequest } from "../campaign/api";
import { integrationsRequest as request } from "./api";

export async function createConnectionApi(
  data: ConnectionCreateConfig
): Promise<{ connection: Connection }> {
  return await request({
    url: "/warehouse/connection/create",
    data,
  });
}

export async function updateConnectionApi(
  data: ConnectionUpdateConfig
): Promise<{ connection: Connection }> {
  return await request({
    url: "/warehouse/connection/update",
    data,
  });
}

export async function validateConnectionApi(
  data: ConnectionReadOnlyValidateConfig | ConnectionReadAndWriteValidateConfig
): Promise<ValidationStatus> {
  return await request({
    url: "/warehouse/connection/bigquery.test",
    data,
  });
}

export async function addSourceTablesApi(
  connectionId: string,
  sourceTables: string[]
): Promise<{ success: boolean }> {
  return await request({
    url: "/warehouse/source/tables.add",
    data: { connection_id: connectionId, source_tables: sourceTables },
  });
}

export async function setSchemaApi(
  connection_id: string,
  schema_name: string
): Promise<{ connection: Connection }> {
  return await request({
    url: "/warehouse/connection/schema.set",
    data: { connection_id, schema_name },
  });
}

export async function listConnectionsApi(
  pageSize: number,
  pageNumber: number
): Promise<PaginatedList<Connection>> {
  return await request({
    url: "/warehouse/connection/list",
    data: { page_size: pageSize, page_number: pageNumber },
  });
}

export async function listConnectionsUnifiedApi(
  pageSize: number,
  pageNumber: number
): Promise<{ connections: ConnectionListItem[] }> {
  return await request({
    url: "/generic/connections.list",
    shouldRetryApiCall: true,
  });
}

export async function listSourceSchemaApi(
  connection_id: string
): Promise<SchemaList> {
  return await request({
    url: "/warehouse/source/schema.list",
    data: { connection_id },
  });
}

export async function listSourceTablesApi(
  connection_id: string
): Promise<{ tables: string[] }> {
  return await request({
    url: "/warehouse/source/table.list",
    data: { connection_id },
  });
}

export async function listSourceTableColumnsApi(
  connection_id: string,
  table_name: string
): Promise<{ columns: SourceColumn[] }> {
  return await request({
    url: "/warehouse/source/column.list",
    data: { connection_id, table_name },
  });
}

export async function getSyncSummaryApi(connectionId: string): Promise<{
  sync_summary: SyncSummary;
}> {
  return await request({
    url: "/warehouse/sync/summary",
    data: { connection_id: connectionId },
    shouldRetryApiCall: true,
  });
}

export async function getSyncDetailsApi(
  connectionId: string
): Promise<{ sync: SyncGetResp }> {
  return await request({
    url: "/warehouse/sync/get",
    data: { connection_id: connectionId },
  });
}

export async function saveSyncApi(
  data: SyncTablesData
): Promise<{ connection: Connection }> {
  return await request({
    url: "/warehouse/sync/save",
    data,
  });
}

export async function listSyncRunsApi({
  connectionId,
  startTime,
  endTime,
  pageNumber,
  pageSize,
}: {
  connectionId: string;
  startTime: string;
  endTime: string;
  pageNumber: number;
  pageSize: number;
}): Promise<PaginatedList<SyncRun>> {
  return await request({
    url: "/warehouse/sync/run/list",
    data: {
      connection_id: connectionId,
      start_time: startTime,
      end_time: endTime,
      page_number: pageNumber,
      page_size: pageSize,
    },
    shouldRetryApiCall: true,
  });
}

export async function toggleSyncApi(
  connection_id: string,
  sync_enabled?: boolean
): Promise<{
  sync: SyncDetails;
}> {
  return await request({
    url: "/warehouse/sync/toggle",
    data: { connection_id, sync_enabled },
  });
}

export async function getWhiteListIpsApi(): Promise<{
  whitelist_ips: string[];
}> {
  return await request({
    url: "/warehouse/connection/whitelist_ip.list",
    data: null,
  });
}

export async function listDestinationTablesApi(): Promise<{
  tables: DestinationTable[];
}> {
  return await request({
    url: "/warehouse/destination/table.list",
    data: null,
  });
}

export async function listDestinationColumnsApi(
  connectionId: string,
  destinationTableName: string
): Promise<{
  columns: DwDestination[];
}> {
  return await request({
    url: "/warehouse/connection/destination/column.list",
    data: {
      connection_id: connectionId,
      destination_table_name: destinationTableName,
    },
  });
}

export async function getActivityLogs({
  pageNumber,
  pageSize,
  connectionId,
  endTime,
  startTime,
}: {
  pageNumber: number;
  pageSize: number;
  connectionId?: string;
  endTime?: string;
  startTime?: string;
}): Promise<Activity> {
  return await request({
    url: "/activity-log/list",
    data: {
      page_size: pageSize,
      page_number: pageNumber,
      connection_id: connectionId,
    },
    shouldRetryApiCall: true,
  });
}

export async function getSalesforceObjectsApi(
  connectionId: string
): Promise<{ salesforce_objects: SalesforceObject[] }> {
  return await request({
    url: "/salesforce/sync/objects.get",
    data: {
      force: true,
      connection_id: connectionId,
    },
    isErrorHandled: true,
    shouldRetryApiCall: true,
  });
}

export async function setDefaultCrmMappingApi(
  data: DefaultCrmMapping
): Promise<{ mapping: Mapping }> {
  return await request({
    url: "/unified-mapping/crm-mapping.set-default",
    data,
  });
}

export async function updateCrmUnifiedMappingApi(
  mapping: MappingElement[]
): Promise<{ mapping: Mapping }> {
  return await request({
    url: "/unified-mapping/crm-mapping.update",
    data: { person_mapping: { mapping } },
  });
}

export async function getUnifiedMappingApi(): Promise<{ mapping: Mapping }> {
  return await request({
    url: "/unified-mapping/mapping.get",
    data: null,
    shouldRetryApiCall: true,
  });
}

export async function getMappingDestinationApi(): Promise<{
  destination: MappingDestination[];
}> {
  return request({
    url: "/unified-mapping/person-mapping.get-destination",
    data: null,
    shouldRetryApiCall: true,
  });
}

export async function setDefaultAccountUnifiedMappingApi(
  data: DefaultAccountMapping
): Promise<{ mapping: Mapping }> {
  return request({
    url: "/unified-mapping/account-mapping.set-default",
    data,
  });
}

export async function updateAccountUnifiedMappingApi(
  mapping: AccountMappingElement[]
): Promise<{ mapping: Mapping }> {
  return request({
    url: "/unified-mapping/account-mapping.update",
    data: { mapping },
  });
}

//segment

export async function listSegmentErrorsApi({
  syncType,
  startTime,
  endTime,
  pageSize,
  pageNumber,
}: {
  syncType: Omit<SEGMENT_SYNC_TYPES, "SOURCE_AND_DESTINATION">;
  startTime: string;
  endTime: string;
  pageSize: number;
  pageNumber: number;
}): Promise<PaginatedList<SegmentError>> {
  return await request({
    url: `/segment/${syncType}/errors.list`,
    data: {
      start_time: startTime,
      end_time: endTime,
      page_size: pageSize,
      page_number: pageNumber,
    },
    shouldRetryApiCall: true,
  });
}

export async function getSegmentErrorReportApi({
  time_unit,
  time_range,
  tz_offset,
  syncType,
}: ReportRange & { syncType: SegmentSourceDestination }): Promise<{
  rows: SegmentReportRow[];
}> {
  return await request({
    url: `/segment/${syncType}/errors.report`,
    data: {
      time_unit,
      time_range,
      tz_offset,
    },
    shouldRetryApiCall: true,
  });
}

export async function getSegmentEventReportApi({
  time_unit,
  time_range,
  tz_offset,
  syncType,
}: ReportRange & { syncType: SegmentSourceDestination }): Promise<{
  rows: SegmentReportRow[];
}> {
  return await request({
    url: `/segment/${syncType}/events.report`,
    data: {
      time_unit,
      time_range,
      tz_offset,
    },
    shouldRetryApiCall: true,
  });
}

export async function getSegmentDetailsApi(): Promise<
  SegmentSyncConfig & { read_api_key: string }
> {
  return request({
    method: "GET",
    url: "/segment/connection.get",
    data: null,
    shouldRetryApiCall: true,
  });
}

export async function setSegmentSyncApi({
  connection_type,
  source_sync_settings,
  destination_sync_settings,
}: SegmentSyncReq): Promise<SegmentSyncConfig> {
  return request({
    url: "/segment/sync/config",
    data: {
      connection_type,
      source_sync_settings,
      destination_sync_settings,
    },
  });
}

export async function getSegmentEventsStatusApi(): Promise<{
  segment_event_status: SegmentEventStatus | null;
}> {
  return request({
    url: "/segment/events/status",
    shouldRetryApiCall: true,
  });
}

export async function getSegmentTrackEventsStatusApi(
  pageSize: number,
  pageNumber: number,
  searchQuery: string
): Promise<SegmentEventStatusTrack> {
  return request({
    url: "/segment/events/status/track_events",
    data: {
      page_size: pageSize,
      page_number: pageNumber,
      search: searchQuery,
    },
    shouldRetryApiCall: true,
  });
}

export async function getSegmentNonTrackEventsStatusApi(): Promise<SegmentEventStatusNonTrack | null> {
  return request({
    url: "/segment/events/status/non_track_events",
    shouldRetryApiCall: true,
  });
}

export async function updateDescriptionApi(
  data: SegmentDescription
): Promise<{ success: boolean }> {
  return request({
    url: "/segment/events/update.description",
    data: data,
  });
}

export async function toggleSegmentEventApi(
  data: SegmentEventToggle
): Promise<{ status: boolean }> {
  return request({
    url: "/segment/events/toggle.event",
    data: data,
  });
}

export async function toggleSegmentEventPropertyApi(
  data: SegmentEventPropertyToggle
): Promise<{ status: true }> {
  return request({
    url: "/segment/events/toggle.properties",
    data: data,
  });
}

export async function toggleBypassPropertiesApi(
  data: SegmentEventPropertyToggle
): Promise<{ status: boolean }> {
  return request({
    url: "/segment/events/bypass.properties",
    data: data,
  });
}

export async function getMappedColumnsApi(): Promise<{
  column_to_assets_mapping: ColumnToAssetMapping;
}> {
  return campaignRequest({
    url: "/assets/column-value/dependency.get",
    data: null,
  });
}
