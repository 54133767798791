import { Flex } from "@chakra-ui/react";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getSalesforceConnectionDetailsApi } from "../../../../common/api/integrations/salesforce";
import { TAB } from "../../../../common/constants/unifiedMapping";
import { isFulfilled, useQuery } from "../../../../common/helper/commonHelper";
import {
  ConnectionListItem,
  SOURCES,
} from "../../../../common/types/unifiedMapping";
import SideBar from "../../../../components/SideBar";
import ContentContainerWithHeader from "../../../../components/v2/ContentContainerWithHeader";
import SecondarySidebar from "../../../../components/v2/SecondarySidebar";
import { useAppDispatch } from "../../../../store";
import urls from "../../../../urls";
import {
  selectConnection,
  resetSalesforceData,
  getSalesforceConnectionDetails,
  getAllConnections,
  getSfConnectionDetailsForUnifiedMapping,
  getSalesforceObjects,
  getUnifiedMapping,
  listDwDestinationColumns,
} from "../connectionSlice";
import AccountMappingTab from "./components/accountMapping/AccountMappingTab";
import CrmDataTab from "./components/crmTab/CrmDataTab";
import AutoAccountSettings from "./components/dataObjects/AccountAutoSettings";
import OverviewTab from "./components/overviewTab/OverviewTab";
import ProductDataExplorer from "./components/ProductDataExplorer/ProductDataExplorer";
import {
  getCrmAndSalesforceSource,
  getSalesforceId,
} from "../../../../common/helper/unifiedMappingHelper";
import { DW_SOURCES } from "../../../../common/constants/connection";

const SIDEBAR_ITEMS = [
  "Data Connections",
  "Contact Fields",
  "Account Fields",
  "Product Data Explorer",
  "Contact to Account Matching",
];

const MARKETING_PERSON = "marketing_person";

function EmptyContainerFallback() {
  return (
    <ContentContainerWithHeader>
      <></>
    </ContentContainerWithHeader>
  );
}

export default function DataManagement() {
  const dispatch = useAppDispatch();
  const {
    unifiedConnection: {
      connectionList,
      connectionList: { list },
      salesforceConnections,
      dwColumnList,
    },
    salesforce: { connection },
  } = useSelector(selectConnection);

  const query = useQuery();
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState(0);

  useEffect(() => {
    const tabNumber = query.get(TAB);
    if (tabNumber && isFinite(+tabNumber) && +tabNumber > 0) {
      setSelectedOption(+tabNumber - 1);
      navigate(urls.unifiedMapping, { replace: true });
    }
  }, [navigate, query]);

  useEffect(() => {
    dispatch(getAllConnections({ isForced: true }));
  }, [dispatch]);

  const salesforceConnectionId = useMemo(() => getSalesforceId(list), [list]);

  // hide non salesforce sources in CRM
  const crmAndSalesforceSource: ConnectionListItem[] = useMemo(
    () => getCrmAndSalesforceSource(connectionList.list),
    [connectionList.list]
  );

  useEffect(() => {
    if (!connection && salesforceConnectionId) {
      dispatch(getSalesforceConnectionDetails(salesforceConnectionId));
    }
  }, [connection, dispatch, salesforceConnectionId]);

  useEffect(() => {
    crmAndSalesforceSource.forEach(async (source) => {
      if (
        source.source === SOURCES.SALESFORCE &&
        !salesforceConnections[source.connection_id]
      ) {
        const res = await dispatch(
          getSfConnectionDetailsForUnifiedMapping(source.connection_id)
        );
        if (isFulfilled(res.meta.requestStatus)) {
          const objects = res.payload as Awaited<
            ReturnType<typeof getSalesforceConnectionDetailsApi>
          >;
          if (
            objects &&
            objects.connection.access_token &&
            !salesforceConnections[objects.connection.connection_id]
          ) {
            dispatch(getSalesforceObjects(objects.connection.connection_id));
          }
        }
      }
      if (
        DW_SOURCES.includes(source.source) &&
        !dwColumnList[source.connection_id]
      ) {
        dispatch(
          listDwDestinationColumns({
            connectionId: source.connection_id,
            destinationTableName: MARKETING_PERSON,
          })
        );
      }
    });
  }, [dispatch, crmAndSalesforceSource, salesforceConnections, dwColumnList]);

  useEffect(() => {
    dispatch(getUnifiedMapping());
  }, [dispatch]);

  const settingTabs: Record<number, ReactNode> = useMemo(() => {
    return {
      0: <OverviewTab />,
      1: <CrmDataTab crmSources={crmAndSalesforceSource} />,
      2: <AccountMappingTab accountSources={crmAndSalesforceSource} />,
      3: <ProductDataExplorer />,
      4: <AutoAccountSettings />,
    };
  }, [crmAndSalesforceSource]);

  useEffect(() => {
    return () => {
      dispatch(resetSalesforceData());
    };
  }, [dispatch]);

  return (
    <Flex maxH="100vh">
      <SecondarySidebar heading="Connections">
        <SideBar
          options={SIDEBAR_ITEMS}
          selected={selectedOption}
          select={setSelectedOption}
        />
      </SecondarySidebar>
      {settingTabs[selectedOption] ?? <EmptyContainerFallback />}
    </Flex>
  );
}
