import { ButtonProps, HStack } from "@chakra-ui/react";
import { MdEdit } from "react-icons/md";
import IButton, { BUTTON } from "./IButton";
import { IoIosCloseCircle } from "react-icons/io";
import { FaSave } from "react-icons/fa";

export enum ACTIONS {
  VIEW = "VIEW",
  EDIT = "EDIT",
}

export default function ViewAndEditButtons({
  action,
  editButtonProps,
  cancelButtonProps,
  saveButtonProps,
}: {
  action: ACTIONS;
  editButtonProps?: ButtonProps;
  cancelButtonProps?: ButtonProps;
  saveButtonProps?: ButtonProps;
}) {
  return (
    <>
      {action === ACTIONS.VIEW ? (
        <IButton
          leftIcon={<MdEdit size="16px" />}
          children="Edit"
          p={2}
          {...editButtonProps}
        />
      ) : (
        <HStack>
          <IButton
            variant={BUTTON.SECONDARY}
            leftIcon={<IoIosCloseCircle size="16px" />}
            children="Cancel"
            p={2}
            {...cancelButtonProps}
          />
          <IButton
            leftIcon={<FaSave size="16px" />}
            children="Save"
            p={2}
            {...saveButtonProps}
          />
        </HStack>
      )}
    </>
  );
}
