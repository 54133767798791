import {
  Flex,
  FormControl,
  FormErrorMessage,
  Box,
  HStack,
  Icon,
  useDisclosure,
  Text,
  Grid,
  GridItem,
  Image,
} from "@chakra-ui/react";
import { cloneDeep, debounce, isNull } from "lodash";
import {
  useState,
  useCallback,
  useEffect,
  useMemo,
  memo,
  useContext,
} from "react";
import { MdInfo } from "react-icons/md";
import { VscSettings } from "react-icons/vsc";
import { useSelector } from "react-redux";
import { NodeProps } from "reactflow";
import { FLOW_ACTIONS } from "../../../../../../common/constants/campaign";
import {
  addPunctuationSeparators,
  isLoading,
  validateEmailConfigInputs,
} from "../../../../../../common/helper/commonHelper";
import { SendEmailActionOptions } from "../../../../../../common/types/campaign";
import { ActionNodeArgs } from "../../../../../../common/types/flow";
import {
  CcBccListType,
  EmailConfigChangeTracker,
  TemplateExhaustive,
  TemplateType,
} from "../../../../../../common/types/template";
import { useAppDispatch } from "../../../../../../store";
import {
  selectTemplate,
  addToTemplateCollection,
  getTemplate,
  resetTemplateDetails,
} from "../../../../templates/templateSlice";
import { EMAIL_PREVIEW_VARIANTS, WIDGET_OPTIONS_DETAILS } from "../constants";
import { isActionDataSame } from "../helpers";
import EmailTemplatePreview from "../TemplatePreviewDrawer";
import TemplatesDrawer from "../TemplatesDrawer";
import WidgetContainer from "../WidgetContainer";
import {
  EMPTY_CONTEXT,
  LOADING_STATES,
} from "../../../../../../common/constants/common";
import ISkeleton, {
  SKELETON_VARIANT,
} from "../../../../../../components/ISkeleton";
import { selectSettings } from "../../../../settings/settingsSlice";
import { INIT_EMAIL_CONFIG_CHANGE_TRACKER } from "../../../../../../common/constants/template";
import {
  CampaignBuilderContext,
  selectFlow,
  setFlowValidity,
} from "../../flowSlice";
import {
  getDisplayTextForOverridenFields,
  isEqualRecipientLists,
} from "../../../../../../common/helper/templateHelper";
import { FaMailBulk } from "react-icons/fa";
import { WrapperForEmptyFlowState } from "../WrapperForEmptyFlowState";

const optionDetails = WIDGET_OPTIONS_DETAILS[FLOW_ACTIONS.SEND_EMAIL];

function checkEqualityForCcBcc(
  actionOptionField?: CcBccListType,
  selectedTemplateField?: CcBccListType
) {
  if (!actionOptionField) return false;
  return !isEqualRecipientLists(
    actionOptionField ?? [],
    selectedTemplateField ?? []
  );
}

function checkEqualityForPreheader(
  actionOptionField?: string | undefined | null,
  selectedTemplateField?: string | undefined | null
) {
  const isActionOptionOverriddenToNull = isNull(actionOptionField);
  const isTemplateFieldSet = !isNull(selectedTemplateField);

  const isTemplateSetWithoutActionOption =
    isActionOptionOverriddenToNull && isTemplateFieldSet;
  const areFieldsDifferent = actionOptionField !== selectedTemplateField;
  return !!(
    isTemplateSetWithoutActionOption ||
    (actionOptionField && areFieldsDifferent)
  );
}

function checkEqualityForConfigField({
  actionOptionField,
  selectedTemplateField,
  globalDefaultField,
}: {
  actionOptionField: string | undefined | null;
  selectedTemplateField: string | undefined | null;
  globalDefaultField?: string | undefined | null;
}) {
  if (
    actionOptionField &&
    actionOptionField !== (selectedTemplateField || globalDefaultField)
  ) {
    return true;
  }
  return false;
}

// NOTE: Adding empty states even though it is inaccessible by current flow, to accomodate empty state in existing journeys
function EmailWidgetBeforeTemplateSelect({ onClick }: { onClick: () => void }) {
  return (
    <WrapperForEmptyFlowState
      setUpFlowStep={onClick}
      emptyStateDetails={{
        icon: FaMailBulk,
        header: "Select an email template",
        subHeader: "Click here to browse the template library",
      }}
    />
  );
}

export function EmailWidgetAfterTemplateSelect({
  selectedTemplate,
  actionOptions,
  isReadonly,
  openTemplateUpdatePreview,
}: {
  selectedTemplate: TemplateExhaustive;
  actionOptions: SendEmailActionOptions;
  isReadonly?: boolean;
  openTemplateUpdatePreview: () => void;
}) {
  const dispatch = useAppDispatch();

  const subject = useMemo(() => {
    let subjectValue = EMPTY_CONTEXT;

    if (actionOptions?.subject) {
      subjectValue = actionOptions.subject;
    } else if (selectedTemplate.subject) {
      subjectValue = selectedTemplate.subject;
    }
    return subjectValue;
  }, [actionOptions, selectedTemplate]);

  function handleOnClickEdit() {
    openTemplateUpdatePreview();
    dispatch(
      getTemplate({ id: selectedTemplate.template_id, isPreview: true })
    );
  }

  return (
    <>
      <Grid
        gridTemplateColumns="90px 1fr 30px"
        gap={3}
        cursor={isReadonly ? "pointer" : "default"}
        onClick={isReadonly ? handleOnClickEdit : undefined}
      >
        <GridItem onClick={handleOnClickEdit} _hover={{ cursor: "pointer" }}>
          <Box
            bg="gray.200"
            borderRadius="10%"
            w="93px"
            h="100px"
            position="relative"
            display="flex"
            overflow="hidden"
            justifyContent="center"
            alignItems="center"
            role="group"
            boxShadow="0px 0px 5px rgba(0, 0, 0, 0.05)"
          >
            {selectedTemplate.previews ? (
              <Image
                src={selectedTemplate.previews?.img_thumb}
                alt="email asset unavailable"
                height="fit-contemt"
                maxH="100%"
              />
            ) : (
              <Box
                w="70px"
                h="100%"
                bg="whiteAlpha.900"
                fontSize="6px"
                color="gray.400"
                pt={10}
                pl={2}
              >
                No template added
              </Box>
            )}
          </Box>
        </GridItem>
        <GridItem
          fontSize="sm"
          onClick={handleOnClickEdit}
          _hover={{ cursor: "pointer" }}
        >
          <Text
            fontWeight={600}
            color="brandBlue.600"
            maxW="250px"
            overflow="hidden"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
          >
            {selectedTemplate.name ?? EMPTY_CONTEXT}
          </Text>
          <Text
            fontWeight={800}
            color="brandBlue.500"
            pt={3}
            maxW="250px"
            overflow="hidden"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
          >
            Subject:{" "}
            <Text as="span" color="blackAlpha.900" fontWeight={200}>
              {subject}
            </Text>
          </Text>
        </GridItem>
        <GridItem ml={2}>
          <Box
            bg="grayV2.200"
            borderRadius={6}
            h={6}
            w={6}
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{ cursor: "pointer" }}
            _hover={{
              bg: "grayV2.300",
            }}
            hidden={isReadonly}
            onClick={handleOnClickEdit}
          >
            <Icon as={VscSettings} size="14px" color="brandBlue.500" />
          </Box>
        </GridItem>
      </Grid>
    </>
  );
}

function EmailAssetInput({
  selectedTemplate,
  isSelectedTemplateLoading,
  actionOptions,
  emailConfigChange,
  handleSelectChange,
  onSave,
  isReadonly,
  isInvalid,
}: {
  selectedTemplate: TemplateExhaustive;
  isSelectedTemplateLoading: LOADING_STATES;
  actionOptions: SendEmailActionOptions;
  emailConfigChange: EmailConfigChangeTracker;
  handleSelectChange: (templateDetails: SendEmailActionOptions) => void;
  onSave: (dataOptions: SendEmailActionOptions) => void;
  isReadonly?: boolean;
  isInvalid?: boolean;
}) {
  const dispatch = useAppDispatch();
  const {
    isOpen: isTemplateDrawerOpen,
    onOpen: onTemplateDrawerOpen,
    onClose: onTemplateDrawerClose,
  } = useDisclosure();

  const [emailPreviewVariant, setEmailPreviewVariant] =
    useState<EMAIL_PREVIEW_VARIANTS | null>(null);

  function onCloseTemplatePreviewModal() {
    setEmailPreviewVariant(null);
    dispatch(resetTemplateDetails());
  }

  function closeAllModal() {
    onCloseTemplatePreviewModal();
    onTemplateDrawerClose();
  }

  function goBackToTemplateList() {
    onCloseTemplatePreviewModal();
    onTemplateDrawerOpen();
  }

  function onSelectTemplate(templateDetails: TemplateType) {
    handleSelectChange(templateDetails);
    closeAllModal();
  }

  return (
    <>
      {isLoading(isSelectedTemplateLoading) ? (
        <ISkeleton variant={SKELETON_VARIANT.TEMPLATE} />
      ) : (
        <FormControl isInvalid={isInvalid}>
          {selectedTemplate ? (
            <EmailWidgetAfterTemplateSelect
              selectedTemplate={selectedTemplate}
              actionOptions={actionOptions}
              isReadonly={isReadonly}
              openTemplateUpdatePreview={() =>
                setEmailPreviewVariant(EMAIL_PREVIEW_VARIANTS.UPDATION)
              }
            />
          ) : (
            <EmailWidgetBeforeTemplateSelect onClick={onTemplateDrawerOpen} />
          )}
          <FormErrorMessage>Select an email template</FormErrorMessage>
        </FormControl>
      )}
      <TemplatesDrawer
        isOpen={isTemplateDrawerOpen}
        onClose={onTemplateDrawerClose}
        handleSelectChange={onSelectTemplate}
        openTemplateSelectionPreview={() =>
          setEmailPreviewVariant(EMAIL_PREVIEW_VARIANTS.SELECTION)
        }
      />
      <EmailTemplatePreview
        isOpen={!!emailPreviewVariant}
        variant={
          isReadonly ? EMAIL_PREVIEW_VARIANTS.READONLY : emailPreviewVariant
        }
        closeAllModal={closeAllModal}
        handleSelectChange={onSelectTemplate}
        actionOptions={actionOptions}
        onSave={onSave}
        goBack={goBackToTemplateList}
        emailConfigChange={emailConfigChange}
        onTemplateDrawerOpen={onTemplateDrawerOpen}
      />
    </>
  );
}

function EmailWidget({
  data: { action, groupId, isCandidate, props, selectedExit, selectedGoto },
}: NodeProps<ActionNodeArgs>) {
  const { saveDraft, setActions, readonly } = props;
  const identities = useMemo(() => {
    return {
      actionId: action.action_id,
      groupId,
      branchId: action.branch_id,
    };
  }, [action, groupId]);
  let actionOptions = useMemo(
    () => action.action_options as SendEmailActionOptions,
    [action]
  );

  const { templateCollection } = useSelector(selectTemplate);
  const { flowValidity } = useSelector(selectFlow);

  const { activeErrorCheck } = useContext(CampaignBuilderContext);

  const dispatch = useAppDispatch();

  const [isEmailConfigValid, setIsEmailConfigValid] = useState(true);
  const [isTemplatePresent, setIsTemplatePresent] = useState(true);
  const [isTemplateChanged, setIsTemplateChanged] = useState(false);
  const [emailConfigChange, setEmailConfigChange] =
    useState<EmailConfigChangeTracker>(INIT_EMAIL_CONFIG_CHANGE_TRACKER);

  const setValidityCallback = useCallback(
    (valid: boolean) => {
      dispatch(setFlowValidity({ [identities.actionId]: valid }));
    },
    [dispatch, identities.actionId]
  );

  const debouncedSave = useMemo(() => debounce(saveDraft, 2000), [saveDraft]);

  function setSelectOptions(options: SendEmailActionOptions) {
    setActions(options, identities.actionId, groupId);
    debouncedSave(options, { actionId: identities.actionId, groupId });
  }

  function setInputOptions(options: SendEmailActionOptions) {
    setActions(options, identities.actionId, groupId);
  }

  function handleSelectChange(templateDetails: SendEmailActionOptions) {
    if (
      actionOptions.template_id &&
      templateDetails.template_id !== actionOptions.template_id
    ) {
      setIsTemplateChanged(true);
    }
    setSelectOptions({ template_id: templateDetails.template_id });
    setValidityCallback(!!templateDetails.template_id);
    setEmailConfigChange(INIT_EMAIL_CONFIG_CHANGE_TRACKER);
  }

  const {
    globalDefault: { data: globalDefault },
  } = useSelector(selectSettings);

  const selectedTemplate =
    templateCollection.data[actionOptions?.template_id as string];

  const isSelectedTemplateLoading = templateCollection.loading;

  function checkForOverride() {
    let changes = { ...INIT_EMAIL_CONFIG_CHANGE_TRACKER };
    if (actionOptions && selectedTemplate) {
      changes.fromEmailEmail = checkEqualityForConfigField({
        actionOptionField: actionOptions?.from_email?.email,
        selectedTemplateField: selectedTemplate?.from_email?.email,
        globalDefaultField: globalDefault?.from_email?.email,
      });
      changes.fromEmailName = checkEqualityForConfigField({
        actionOptionField: actionOptions?.from_email?.name,
        selectedTemplateField: selectedTemplate?.from_email?.name,
        globalDefaultField: globalDefault?.from_email?.name,
      });
      changes.replyTo = checkEqualityForConfigField({
        actionOptionField: actionOptions?.reply_to,
        selectedTemplateField: selectedTemplate?.reply_to,
        globalDefaultField: globalDefault?.reply_to,
      });
      changes.subject = checkEqualityForConfigField({
        actionOptionField: actionOptions?.subject,
        selectedTemplateField: selectedTemplate?.subject,
      });
      changes.preheader = checkEqualityForPreheader(
        actionOptions?.preheader,
        selectedTemplate?.preheader
      );
      changes.cc = checkEqualityForCcBcc(
        actionOptions?.cc_email_data_set,
        selectedTemplate?.cc_email_data_set
      );
      changes.bcc = checkEqualityForCcBcc(
        actionOptions?.bcc_email_data_set,
        selectedTemplate?.bcc_email_data_set
      );
    }
    setEmailConfigChange(changes);
  }

  const isOverride = Object.values(emailConfigChange).some((value) => value);
  const overridenFields = getDisplayTextForOverridenFields(emailConfigChange);

  useEffect(() => {
    if (selectedTemplate) {
      const dataOptions = {
        template_id: selectedTemplate.template_id,
        subject: actionOptions.subject ?? selectedTemplate.subject ?? "",
        preheader:
          actionOptions.preheader ?? selectedTemplate.preheader ?? null,
        from_email: {
          name:
            actionOptions.from_email?.name ??
            selectedTemplate.from_email?.name ??
            globalDefault?.from_email?.name ??
            "",
          email:
            actionOptions.from_email?.email ??
            selectedTemplate.from_email?.email ??
            globalDefault?.from_email?.email ??
            "",
        },
        reply_to:
          actionOptions.reply_to ??
          selectedTemplate.reply_to ??
          globalDefault?.reply_to ??
          "",
        bcc_email_data_set: actionOptions.bcc_email_data_set ?? [],
        cc_email_data_set: actionOptions.cc_email_data_set ?? [],
      };
      const { validity } = validateEmailConfigInputs(dataOptions);
      setIsEmailConfigValid(validity);
      setIsTemplatePresent(!!selectedTemplate.content);
      setValidityCallback(validity && !!selectedTemplate.content);
    }
  }, [selectedTemplate, actionOptions, globalDefault, setValidityCallback]);

  useEffect(() => {
    actionOptions.template_id &&
      dispatch(addToTemplateCollection(actionOptions.template_id as string));
    checkForOverride();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionOptions?.template_id, templateCollection.data, dispatch]);

  function onSave(dataOptions: SendEmailActionOptions) {
    actionOptions = cloneDeep(dataOptions);
    setInputOptions(dataOptions);
    checkForOverride();
    saveDraft(dataOptions, { actionId: identities.actionId, groupId });
  }

  const validationMessageToShow = useCallback(() => {
    if (!isEmailConfigValid && !isTemplatePresent)
      return "Fields are invalid & Template is not present";
    else if (!isEmailConfigValid) return "Fields are invalid";
    else if (!isTemplatePresent) return "Template is not present";
    else return "";
  }, [isEmailConfigValid, isTemplatePresent]);

  return (
    <>
      <WidgetContainer
        invalidMessage={
          flowValidity[identities.actionId] ? "" : validationMessageToShow()
        }
        identities={identities}
        title={optionDetails.label}
        icon={optionDetails.icon}
        color={optionDetails.color}
        isCandidate={isCandidate}
        selectedExit={selectedExit}
        selectedGoto={selectedGoto}
        isDisabled={readonly}
      >
        <Flex h="100%" w="100%" px="5" py="4" flexDirection="column">
          <Box
            bg="brandOrange.50"
            fontSize="xs"
            mb={2}
            borderRadius={4}
            p={1}
            color="brandOrange.100"
            hidden={!isTemplateChanged || isOverride}
          >
            <HStack>
              <Icon as={MdInfo} fontSize="md" />
              <Text>
                Email asset changed, journey reports will be subject to change
              </Text>
            </HStack>
          </Box>
          <Box
            bg="brandOrange.50"
            fontSize="xs"
            mb={2}
            borderRadius={4}
            p={1}
            color="brandOrange.100"
            hidden={!isOverride}
          >
            <HStack>
              <Icon as={MdInfo} fontSize="md" />
              <Text>
                This asset has overrides for{" "}
                {addPunctuationSeparators(overridenFields)}
              </Text>
            </HStack>
          </Box>
          <EmailAssetInput
            selectedTemplate={selectedTemplate}
            isSelectedTemplateLoading={isSelectedTemplateLoading}
            handleSelectChange={handleSelectChange}
            actionOptions={actionOptions}
            onSave={onSave}
            emailConfigChange={emailConfigChange}
            isReadonly={readonly}
            isInvalid={activeErrorCheck && !actionOptions.template_id}
          />
        </Flex>
      </WidgetContainer>
    </>
  );
}

export default memo(EmailWidget, isActionDataSame);
