import {
  HStack,
  Icon,
  Link,
  VStack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { cloneDeep, isNull, set, isEmpty, isEqual } from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  isFulfilled,
  isInit,
  isLoading,
  validateEmailConfigInputs,
  isValidUrl,
} from "../../../common/helper/commonHelper";
import { GlobalEmailConfigType } from "../../../common/types/campaign";
import ContentContainer from "../../../components/v2/ContentContainer";
import ContentContainerWithHeader from "../../../components/v2/ContentContainerWithHeader";
import { useAppDispatch } from "../../../store";
import GlobalEmailConfiguration from "./components/GlobalEmailConfiguration";
import UnsubscribeUrl from "./components/UnsubscribeEmailConfiguration";
import {
  createUnsubUrl,
  deleteUnsubUrl,
  updateUnsubUrl,
  getUnsubscribeSettings,
  getUnsubUrl,
  resetLoadingStateEmailDefault,
  selectSettings,
  toggleUnsubscribeSettings,
  createGlobalEmailConfig,
  updateGlobalEmailConfig,
} from "./settingsSlice";
import TwoStepUnsubscribe from "./components/TwoStepUnsubscribe";
import ViewAndEditButtons, {
  ACTIONS,
} from "../../../components/ViewAndEditButtons";
import ITitle from "../../../components/ITitle";
import HeaderDescText, { TEXT_TYPE } from "./components/HeaderDescText";
import {
  EMAIL_CONFIG_ERRORS_INIT,
  EMAIL_CONFIG_FIELDS,
  GLOBAL_EMAIL_CONFIG_INIT,
  GLOBAL_EMAIL_CONFIG_VALID_FIELDS,
  GLOBAL_EMAIL_SENDER_META,
} from "../../../common/constants/template";
import { toast } from "react-toastify";
import { CcBccListType } from "../../../common/types/template";
import { ModalHandlerProps } from "../../../common/types/common";
import { FaExclamationTriangle } from "react-icons/fa";
import { ConfirmationModal } from "../../../components/ConfirmationModal";
import { ANTI_SPAM_POLICY_LINK } from "../../../common/constants/common";

const GLOBAL_EMAIL_SETTINGS_ERRORS_INIT = {
  ...EMAIL_CONFIG_ERRORS_INIT,
  unsubUrl: "",
};

export function GlobalBccWarningPopup({
  isOpen,
  onClose,
  onConfirm,
}: ModalHandlerProps) {
  return (
    <ConfirmationModal
      isOpen={isOpen}
      onCancel={onClose}
      submitHandler={onConfirm}
      title="Bcc warning"
      cancelButtonText="Cancel"
      confirmButtonText="Proceed"
      icon={
        <Icon as={FaExclamationTriangle} color="orange" mr={3} fontSize="18" />
      }
      footerContent={
        <Link
          isExternal
          href={ANTI_SPAM_POLICY_LINK}
          fontSize="14px"
          fontWeight="semibold"
          w="70%"
        >
          Privacy and anti-spam policy
        </Link>
      }
    >
      <Text fontSize="14px" fontWeight="semibold" pt={1}>
        Global BCC email will be set for all new and existing emails including
        those in ongoing journeys.
      </Text>

      <Text fontSize="14px" pt={4}>
        Misuse may lead to privacy violations and negative impacts on your email
        campaigns, email metrics and sender reputation.
      </Text>
    </ConfirmationModal>
  );
}

export default function EmailSettings() {
  const {
    globalDefault: {
      data: globalEmailConfigDefault,
      fetching: fetchingGlobalEmailDefault,
      updating: updatingGlobalEmailDefault,
    },
    unsubUrl: {
      url: unsubUrl,
      fetching: fetchingUnsubUrl,
      updating: updatingUnsubUrl,
    },
    twoStepUnsub: { data: twoStepUnsub, loading: twoStepUnsubLoading },
  } = useSelector(selectSettings);

  const [action, setAction] = useState(ACTIONS.VIEW);
  const [globalEmailConfig, setGlobalEmailConfig] =
    useState<GlobalEmailConfigType>(
      globalEmailConfigDefault ?? GLOBAL_EMAIL_CONFIG_INIT
    );
  const [is2StepUnsub, setIs2StepUnsub] = useState(
    twoStepUnsub.two_step_enabled
  );
  const [unsubscribeUrl, setUnsubscribeUrl] = useState<null | string>(
    unsubUrl ? unsubUrl : null
  );
  const [errors, setErrors] = useState(GLOBAL_EMAIL_SETTINGS_ERRORS_INIT);
  const {
    isOpen: isOpenBccWarning,
    onClose: onCloseBccWarning,
    onOpen: onOpenBccWarning,
  } = useDisclosure();

  const dispatch = useAppDispatch();

  useEffect(() => {
    setIs2StepUnsub(twoStepUnsub.two_step_enabled);
  }, [twoStepUnsub.two_step_enabled]);

  useEffect(() => {
    setUnsubscribeUrl(unsubUrl);
  }, [unsubUrl]);

  useEffect(() => {
    if (isInit(twoStepUnsubLoading)) {
      dispatch(getUnsubscribeSettings());
    }
  }, [dispatch, twoStepUnsubLoading]);

  useEffect(() => {
    if (isInit(fetchingUnsubUrl)) {
      dispatch(getUnsubUrl());
    }
  }, [dispatch, fetchingUnsubUrl]);

  useEffect(() => {
    return () => {
      dispatch(resetLoadingStateEmailDefault());
    };
  }, [dispatch]);

  async function saveUnsubUrl(url: string | null) {
    if (url !== null && !isValidUrl(url)) {
      setErrors((prev) => ({ ...prev, unsubUrl: "Enter a valid url" }));
      return;
    }

    if (url !== unsubUrl) {
      if (isNull(unsubUrl) && url) {
        return await dispatch(createUnsubUrl(url));
      } else if (isNull(url)) {
        return await dispatch(deleteUnsubUrl());
      } else {
        return await dispatch(updateUnsubUrl(url));
      }
    }
  }

  async function saveGlobalEmailConfig(emailConfig: GlobalEmailConfigType) {
    if (!isEqual(emailConfig, globalEmailConfigDefault)) {
      const { validity, errors } = validateEmailConfigInputs(
        {
          ...emailConfig,
          ...GLOBAL_EMAIL_SENDER_META,
        },
        GLOBAL_EMAIL_CONFIG_VALID_FIELDS
      );

      setErrors((prev) => ({ ...prev, ...errors }));

      if (validity) {
        return isNull(globalEmailConfigDefault)
          ? dispatch(createGlobalEmailConfig(emailConfig))
          : dispatch(updateGlobalEmailConfig(emailConfig));
      }
    }
  }

  function onChangeEmailConfig(
    name: EMAIL_CONFIG_FIELDS,
    value: string | CcBccListType
  ) {
    setGlobalEmailConfig((prev) => {
      const newEmailConfig = cloneDeep(prev);
      set(newEmailConfig, name, value);

      return newEmailConfig;
    });
    set(errors, name, "");
    setErrors(errors);
  }

  function checkIfBccDataChanged() {
    return (
      !isEqual(
        globalEmailConfig.bcc_email_data_set,
        globalEmailConfigDefault?.bcc_email_data_set
      ) && !isEmpty(globalEmailConfig.bcc_email_data_set)
    );
  }

  async function onSaveEdit() {
    const responses = [];

    if (checkIfBccDataChanged() && !isOpenBccWarning) {
      onOpenBccWarning();
      return;
    }

    if (is2StepUnsub !== twoStepUnsub.two_step_enabled) {
      responses.push(await dispatch(toggleUnsubscribeSettings()));
    }

    const unsubResp = await saveUnsubUrl(unsubscribeUrl);
    unsubResp && responses.push(unsubResp);

    const emailConfigResp = await saveGlobalEmailConfig(globalEmailConfig);
    emailConfigResp && responses.push(emailConfigResp);

    if (
      !isEmpty(responses) &&
      responses.every((resp) => isFulfilled(resp?.meta.requestStatus))
    ) {
      setAction(ACTIONS.VIEW);
      toast.success("Updated email settings successfully");
    } else if (isEmpty(responses)) {
      toast.warn("Email settings should be updated before save");
    } else {
      toast.error("Failed to update the email settings");
    }
    onCloseBccWarning();
  }

  function onCancelEdit() {
    //reset all data
    setIs2StepUnsub(twoStepUnsub.two_step_enabled);
    setUnsubscribeUrl(unsubUrl);
    setGlobalEmailConfig(globalEmailConfigDefault ?? GLOBAL_EMAIL_CONFIG_INIT);
    setErrors(GLOBAL_EMAIL_SETTINGS_ERRORS_INIT);

    setAction(ACTIONS.VIEW);
  }

  const isPageLoading =
    isLoading(fetchingGlobalEmailDefault) ||
    updatingGlobalEmailDefault ||
    isLoading(fetchingUnsubUrl) ||
    isLoading(updatingUnsubUrl) ||
    isLoading(twoStepUnsubLoading);

  return (
    <ContentContainerWithHeader mr="20px">
      <HStack justifyContent="flex-end" py={1}>
        <ViewAndEditButtons
          action={action}
          editButtonProps={{
            isLoading: isPageLoading,
            onClick: () => setAction(ACTIONS.EDIT),
          }}
          cancelButtonProps={{
            isLoading: isPageLoading,
            onClick: onCancelEdit,
          }}
          saveButtonProps={{ isLoading: isPageLoading, onClick: onSaveEdit }}
        />
      </HStack>

      <ContentContainer flexDir="column">
        <VStack alignItems="flex-start" w="100%">
          <ITitle
            title="Email settings"
            fontWeight={600}
            color="blackAlpha.700"
          />
          <HeaderDescText
            text="In this section, you can customize how recipients interact with your
            emails and predefine configurations for each email."
            type={TEXT_TYPE.HEADER}
          />

          <VStack alignItems="flex-start" py={4} w="100%">
            <HeaderDescText
              text="Unsubscribe configuration"
              type={TEXT_TYPE.HEADER}
              fontWeight={600}
            />

            <UnsubscribeUrl
              headerTxt="1. Custom opt-out confirmation page"
              mode={action}
              unsubUrl={unsubscribeUrl}
              onChange={(url) => {
                setErrors((prev) => ({ ...prev, unsubUrl: "" }));
                setUnsubscribeUrl(url);
              }}
              errorMsg={errors.unsubUrl}
              loading={isLoading(fetchingUnsubUrl)}
            />

            <TwoStepUnsubscribe
              headerTxt="2. Two step opt-out"
              mode={action}
              unsubState={is2StepUnsub}
              onChange={setIs2StepUnsub}
              loading={isLoading(twoStepUnsubLoading)}
            />

            <VStack alignItems="flex-start" py={3} spacing={3}>
              <HeaderDescText
                text="Global email configuration"
                type={TEXT_TYPE.HEADER}
                fontWeight={600}
              />
              <HeaderDescText
                text="Configure email details across all email templates in your workspace."
                type={TEXT_TYPE.DESCRIPTION}
              />
              <GlobalEmailConfiguration
                mode={action}
                emailConfig={globalEmailConfig}
                onChange={onChangeEmailConfig}
                errors={errors}
                w="450px"
                additionalCcBccContent={
                  <VStack alignItems="flex-start" w="700px" mb={2}>
                    <HeaderDescText
                      text="Global Bcc configuration"
                      type={TEXT_TYPE.HEADER}
                      fontWeight={600}
                    />
                    <HeaderDescText
                      text="Configure the list of email addresses that will be included in the Bcc field for each email template in your workspace."
                      type={TEXT_TYPE.DESCRIPTION}
                    />
                  </VStack>
                }
              />
            </VStack>
          </VStack>
        </VStack>
      </ContentContainer>
      <GlobalBccWarningPopup
        isOpen={isOpenBccWarning}
        onClose={onCloseBccWarning}
        onConfirm={onSaveEdit}
      />
    </ContentContainerWithHeader>
  );
}
