import {
  HStack,
  VStack,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  IconButton,
  useDisclosure,
  Icon,
} from "@chakra-ui/react";
import { MdClose } from "react-icons/md";
import {
  IoChevronDownSharp,
  IoChevronUpSharp,
  IoWarning,
} from "react-icons/io5";
import { resetExports, selectExport, abortAllExports } from "./exportSlice";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import ExportItem from "./components/ExportItem";
import AbortExportModal from "./components/AbortExportModal";
import { useEffect, useMemo } from "react";
import { useAppDispatch } from "../../../store";
import {
  addSuffixForPlural,
  isFailedExport,
} from "../../../common/helper/commonHelper";
import { usePreventRefresh } from "../../../common/hooks/commonHooks";

function sortByDateTime(date1: string, date2: string) {
  return new Date(date2).getTime() - new Date(date1).getTime();
}

function addSuffixToExport(num: number) {
  return addSuffixForPlural("Export", num);
}

export default function ExportListAccordion() {
  const { exportsInProgress, exportsProcessed } = useSelector(selectExport);
  const dispatch = useAppDispatch();

  const {
    isOpen: isOpenAbortModal,
    onOpen: onOpenAbortModal,
    onClose: onCloseAbortModal,
  } = useDisclosure();
  const {
    isOpen: isOpenExports,
    onClose: onCloseExports,
    onOpen: onOpenExports,
  } = useDisclosure();

  const exports = useMemo(
    () =>
      Object.keys(exportsInProgress).sort((export1, export2) =>
        sortByDateTime(
          exportsInProgress[export1].requestedAt,
          exportsInProgress[export2].requestedAt
        )
      ),
    [exportsInProgress]
  );

  const numInProgress = exports.length - exportsProcessed.length;

  useEffect(() => {
    if (!isEmpty(exportsInProgress)) {
      onOpenExports();
    } else {
      onCloseExports();
    }
  }, [exportsInProgress, onCloseExports, onOpenExports]);

  useEffect(() => {
    if (!isOpenExports) {
      setTimeout(() => {
        dispatch(resetExports());
      }, 500);
    }
  }, [dispatch, isOpenExports]);

  function onCloseExportsList() {
    if (numInProgress >= 1) {
      onOpenAbortModal();
    } else {
      onCloseExports();
    }
  }

  function abortExportsInProgress() {
    dispatch(abortAllExports());
    onCloseAbortModal();
    onCloseExports();
  }

  usePreventRefresh({ preventRefresh: numInProgress >= 1 });

  const numFailedExports = useMemo(
    () =>
      Object.keys(exportsInProgress).filter((exportId) =>
        isFailedExport(exportsInProgress[exportId].currentStatus)
      ).length,
    [exportsInProgress]
  );

  function getHeaderText() {
    if (numFailedExports) {
      return `${numFailedExports} ${addSuffixToExport(
        numFailedExports
      )} failed`;
    } else if (!numInProgress) {
      return `${addSuffixToExport(exports.length)} completed`;
    }
    return `${numInProgress} ${addSuffixToExport(numInProgress)} in progress`;
  }

  const bg = numFailedExports ? "brandRed.50" : "oneOffs.tableHeader";

  return (
    <>
      <Accordion
        allowToggle
        position="fixed"
        bottom={isOpenExports ? 0 : -12}
        maxH={isOpenExports ? undefined : 0}
        right="20px"
        bg={bg}
        w="300px"
        roundedTop="md"
        boxShadow="0 5px 5px 0 rgba(0, 0, 0, 0.2)"
        zIndex={999}
        transition="bottom 0.3s ease-in"
        overflowX="visible"
      >
        <AccordionItem borderColor="transparent" roundedTop="xl">
          {({ isExpanded }: { isExpanded: boolean }) => (
            <>
              <HStack
                justifyContent="space-between"
                bg={bg}
                pl={3}
                pr={1}
                roundedTop="md"
              >
                <HStack>
                  {numFailedExports && (
                    <Icon as={IoWarning} fontSize="lg" color="red.500" />
                  )}
                  <Text fontWeight="bold" fontSize="sm" color="text.200">
                    {getHeaderText()}
                  </Text>
                </HStack>

                <HStack>
                  <AccordionButton w="40px" _hover={{ bg: "" }}>
                    <IconButton
                      aria-label="hide-exports"
                      color="text.200"
                      variant="ghost"
                      fontSize="lg"
                      icon={
                        isExpanded ? (
                          <IoChevronDownSharp />
                        ) : (
                          <IoChevronUpSharp />
                        )
                      }
                      _hover={{ bg: "" }}
                    />
                  </AccordionButton>
                  <IconButton
                    aria-label="Remove-exports"
                    color="text.200"
                    variant="ghost"
                    fontSize="lg"
                    icon={<MdClose />}
                    _hover={{ bg: "" }}
                    onClick={onCloseExportsList}
                  />
                </HStack>
              </HStack>

              <AccordionPanel p={0} bg="white">
                <VStack maxH="250px" overflowY="auto" spacing={0}>
                  {exports.map((exportId) => {
                    return (
                      <ExportItem
                        key={exportId}
                        exportData={{
                          exportId,
                          ...exportsInProgress[exportId],
                        }}
                      />
                    );
                  })}
                </VStack>
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
      </Accordion>

      <AbortExportModal
        isOpen={isOpenAbortModal}
        onContinueExport={onCloseAbortModal}
        onCancelExport={abortExportsInProgress}
      />
    </>
  );
}
