import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../store";
import {
  exportStaticListApi,
  exportDynamicListApi,
  exportCampaignReportApi,
  getExportStatusApi,
  abortExportApi,
} from "../../../common/api/campaign/export";
import {
  EXPORT_STATUS,
  ExportResourceResp,
  ExportProgressConfig,
  ExportStatusResp,
  EXPORT_CAMPAIGN_REPORT_TYPE,
} from "../../../common/types/export";
import { toast } from "react-toastify";
import { LOADING_STATES } from "../../../common/constants/common";
import { RECIPIENT_EVENT_TYPE } from "../../../common/constants/campaign";
import { isFailed, isFailedExport } from "../../../common/helper/commonHelper";

const MAP_RECIPIENT_TYPE_TO_EXPORT_TYPE_ = {
  [RECIPIENT_EVENT_TYPE.ALL]: EXPORT_CAMPAIGN_REPORT_TYPE.ALL,
  [RECIPIENT_EVENT_TYPE.BOUNCE]: EXPORT_CAMPAIGN_REPORT_TYPE.BOUNCE,
  [RECIPIENT_EVENT_TYPE.NOT_SENT]: EXPORT_CAMPAIGN_REPORT_TYPE.NOT_SENT,
  [RECIPIENT_EVENT_TYPE.OPEN]: EXPORT_CAMPAIGN_REPORT_TYPE.OPEN,
  [RECIPIENT_EVENT_TYPE.CLICK]: EXPORT_CAMPAIGN_REPORT_TYPE.CLICK,
  [RECIPIENT_EVENT_TYPE.UNSUB]: EXPORT_CAMPAIGN_REPORT_TYPE.UNSUB,
  [RECIPIENT_EVENT_TYPE.SPAM]: EXPORT_CAMPAIGN_REPORT_TYPE.SPAM,
};

type ExportRecipients = {
  campaignId: string;
  recipientType: RECIPIENT_EVENT_TYPE;
};
interface InitExportState {
  exportsInProgress: {
    [exportId: string]: ExportProgressConfig;
  };
  assetsToExport: {
    [assetId: string]: { exportId: string; isLoading: LOADING_STATES };
  };
  exportsProcessed: string[];
  abortExports: boolean;
}

export const initialState: InitExportState = {
  exportsInProgress: {},
  assetsToExport: {},
  exportsProcessed: [],
  abortExports: false,
};

export const exportDynamicList = createAsyncThunk(
  "export/dynamic-list",
  async (dynamicListId: string) => {
    return await exportDynamicListApi(dynamicListId);
  }
);

export const exportStaticList = createAsyncThunk(
  "export/static-list",
  async (staticListId: string) => {
    return await exportStaticListApi(staticListId);
  }
);

export const exportCampaignReport = createAsyncThunk(
  "export/campaign-report",
  async ({ campaignId, recipientType }: ExportRecipients) => {
    return await exportCampaignReportApi({
      campaignId,
      templateId: null,
      reportType: MAP_RECIPIENT_TYPE_TO_EXPORT_TYPE_[recipientType],
    });
  }
);

export const getExportStatus = createAsyncThunk(
  "export/get-status",
  async (exportId: string) => {
    return getExportStatusApi(exportId);
  },
  {
    condition: (_, { getState }) => {
      const {
        export: { abortExports },
      } = getState() as RootState;

      return !abortExports;
    },
  }
);

export const abortExport = createAsyncThunk(
  "export/abort",
  async (exportId: string) => {
    return await abortExportApi(exportId);
  }
);

function handleExportPending(state: InitExportState, assetId: string) {
  state.assetsToExport = {
    ...state.assetsToExport,
    [assetId]: {
      exportId: "",
      isLoading: LOADING_STATES.LOADING,
    },
  };
  state.abortExports = false;
}

function handleExportRejected(
  state: InitExportState,
  assetId: string,
  errorMsg: string
) {
  state.assetsToExport = {
    ...state.assetsToExport,
    [assetId]: {
      exportId: "",
      isLoading: LOADING_STATES.FAILED,
    },
  };
  toast.error(errorMsg);
}

function handleExportFulfilled(
  state: InitExportState,
  assetId: string,
  payload: ExportResourceResp
) {
  const {
    filename,
    export_id: exportId,
    requested_at: requestedAt,
    current_status: currentStatus,
  } = payload;

  state.assetsToExport = {
    ...state.assetsToExport,
    [assetId]: {
      exportId,
      isLoading: LOADING_STATES.SUCCESS,
    },
  };
  state.exportsInProgress = {
    ...state.exportsInProgress,
    [exportId]: {
      filename,
      requestedAt,
      currentStatus,
      loading: LOADING_STATES.INIT,
      urls: null,
    },
  };
}

function setLoadingForExports(
  state: InitExportState,
  exportId: string,
  loading: LOADING_STATES
) {
  const { exportsInProgress, exportsProcessed } = state;

  if (exportId in exportsInProgress) {
    const status = exportsInProgress[exportId].currentStatus;

    state.exportsInProgress = {
      ...exportsInProgress,
      [exportId]: {
        ...exportsInProgress[exportId],
        loading,
        currentStatus: isFailed(loading) ? EXPORT_STATUS.FAILED : status,
      },
    };
  }
  if (isFailed(loading) && !exportsProcessed.includes(exportId)) {
    state.exportsProcessed = [...exportsProcessed, exportId];
  }
}

function handleExportStatusFulfilled(
  state: InitExportState,
  { payload }: PayloadAction<ExportStatusResp>
) {
  const { export_id: exportId, current_status: currentStatus, urls } = payload;
  const { exportsInProgress, exportsProcessed } = state;
  if (exportId in exportsInProgress) {
    state.exportsInProgress = {
      ...exportsInProgress,
      [exportId]: {
        ...exportsInProgress[exportId],
        urls,
        currentStatus,
        loading: LOADING_STATES.SUCCESS,
      },
    };
    if (isFailedExport(currentStatus) && !exportsProcessed.includes(exportId)) {
      state.exportsProcessed = [...exportsProcessed, exportId];
    }
  }
}

function setExportStatus(
  state: InitExportState,
  {
    exportId,
    status,
  }: {
    exportId: string;
    status: EXPORT_STATUS.DOWNLOADED | EXPORT_STATUS.FAILED;
  }
) {
  if (exportId in state.exportsInProgress) {
    state.exportsInProgress = {
      ...state.exportsInProgress,
      [exportId]: {
        ...state.exportsInProgress[exportId],
        currentStatus: status,
      },
    };
    if (!state.exportsProcessed.includes(exportId)) {
      state.exportsProcessed = [...state.exportsProcessed, exportId];
    }
  }
}

const exportSlice = createSlice({
  name: "exports",
  initialState,
  reducers: {
    resetExports(state) {
      state.exportsInProgress = {};
      state.exportsProcessed = [];
      state.assetsToExport = {};
    },
    abortAllExports(state) {
      state.abortExports = true;
    },
    setExportStatusDownloaded(
      state,
      { payload: exportId }: PayloadAction<string>
    ) {
      setExportStatus(state, {
        exportId: exportId,
        status: EXPORT_STATUS.DOWNLOADED,
      });
    },
    setExportStatusFailed(state, { payload: exportId }: PayloadAction<string>) {
      setExportStatus(state, {
        exportId: exportId,
        status: EXPORT_STATUS.FAILED,
      });
    },
  },
  extraReducers: (builder) => {
    builder

      // export dynamic list
      .addCase(
        exportDynamicList.pending,
        (state, { meta: { arg: dynamicListId } }) => {
          handleExportPending(state, dynamicListId);
        }
      )
      .addCase(
        exportDynamicList.fulfilled,
        (state, { payload, meta: { arg: dynamicListId } }) => {
          handleExportFulfilled(state, dynamicListId, payload);
        }
      )
      .addCase(
        exportDynamicList.rejected,
        (state, { meta: { arg: dynamicListId } }) => {
          handleExportRejected(
            state,
            dynamicListId,
            "Failed to export audience list"
          );
        }
      )

      //export static list
      .addCase(
        exportStaticList.pending,
        (state, { meta: { arg: staticListId } }) => {
          handleExportPending(state, staticListId);
        }
      )
      .addCase(
        exportStaticList.fulfilled,
        (state, { payload, meta: { arg: staticListId } }) => {
          handleExportFulfilled(state, staticListId, payload);
        }
      )
      .addCase(
        exportStaticList.rejected,
        (state, { meta: { arg: staticListId } }) => {
          handleExportRejected(
            state,
            staticListId,
            "Failed to export static list"
          );
        }
      )

      //export campaign report
      .addCase(exportCampaignReport.pending, (state, { meta: { arg } }) => {
        handleExportPending(state, arg.campaignId + arg.recipientType);
      })
      .addCase(
        exportCampaignReport.fulfilled,
        (state, { payload, meta: { arg } }) => {
          handleExportFulfilled(
            state,
            arg.campaignId + arg.recipientType,
            payload
          );
        }
      )
      .addCase(exportCampaignReport.rejected, (state, { meta: { arg } }) => {
        handleExportRejected(
          state,
          arg.campaignId + arg.recipientType,
          "Failed to export campaign report"
        );
      })

      //get export status
      .addCase(
        getExportStatus.pending,
        (state, { meta: { arg: exportId } }) => {
          setLoadingForExports(state, exportId, LOADING_STATES.LOADING);
        }
      )
      .addCase(getExportStatus.fulfilled, handleExportStatusFulfilled)
      .addCase(
        getExportStatus.rejected,
        (state, { meta: { arg: exportId } }) => {
          setLoadingForExports(state, exportId, LOADING_STATES.FAILED);
        }
      )
      .addCase(abortExport.pending, (state, { meta: { arg: exportId } }) => {
        const { exportsInProgress } = state;

        if (exportId in exportsInProgress) {
          delete state.exportsInProgress[exportId];
        }
      });
  },
});

export const {
  resetExports,
  abortAllExports,
  setExportStatusDownloaded,
  setExportStatusFailed,
} = exportSlice.actions;
export const selectExport = (state: RootState) => state.export;
export default exportSlice.reducer;
