import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAuthedConstantsApi } from "../api/integrations/generic";
import { fetchDomainListApi } from "../api/campaign/settings";
import { RootState } from "../../store";
import { AuthenticatedConstants, DomainType } from "../types/common";
import { listApiKeysApi } from "../api/auth/auth";
import { LOADING_STATES } from "../constants/common";
import { ApiKey } from "../types/auth";
import { TRIGGER_ENABLED_TENANTS_URL } from "../constants";

interface ConstantState {
  domainList: DomainType[];
  apiKeys: {
    list: ApiKey[];
    fetching: LOADING_STATES;
  };
  authedConstants: AuthenticatedConstants;
  triggerEnabledOrgs: {
    tenants: number[];
  };
}

const initialState: ConstantState = {
  authedConstants: {
    warehouse_types: {},
    salesforce_login_url: "",
    warehouse_sync_valid_intervals: {},
    metadata_fields: {
      sfdc_lead_id: "",
      sfdc_account_id: "",
      sfdc_opportunity_id: "",
      sfdc_contact_id: "",
      personal_email: "",
      account_domain: "",
      internal_account_id: "",
    },
    gcp_service_account_email: "",
  },
  domainList: [],
  apiKeys: {
    list: [],
    fetching: LOADING_STATES.INIT,
  },
  triggerEnabledOrgs: {
    tenants: [],
  },
};

export const listApiKeys = createAsyncThunk("constants/api-keys", async () => {
  return listApiKeysApi();
});

export const getAuthedConstants = createAsyncThunk(
  "constants/auth-constants",
  async () => {
    return getAuthedConstantsApi();
  }
);

export const getDomainList = createAsyncThunk("domain/list", async () => {
  return fetchDomainListApi();
});

export const getTriggerEnabledOrgs = createAsyncThunk(
  "constants/triggerEnabledOrgs",
  async () => {
    const response = await fetch(TRIGGER_ENABLED_TENANTS_URL, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return response.json();
  }
);

const constantsSlice = createSlice({
  name: "constants",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAuthedConstants.fulfilled, (state, action) => {
        state.authedConstants = action.payload;
      })
      .addCase(getDomainList.fulfilled, (state, action) => {
        state.domainList = action.payload.domain;
      })
      .addCase(listApiKeys.pending, (state, action) => {
        state.apiKeys.fetching = LOADING_STATES.LOADING;
      })
      .addCase(listApiKeys.fulfilled, (state, action) => {
        state.apiKeys.fetching = LOADING_STATES.SUCCESS;
        state.apiKeys.list = action.payload.api_keys;
      })
      .addCase(listApiKeys.rejected, (state, action) => {
        state.apiKeys.fetching = LOADING_STATES.FAILED;
      })
      .addCase(getTriggerEnabledOrgs.fulfilled, (state, action) => {
        state.triggerEnabledOrgs = action.payload;
      });
  },
});

export const selectConstants = (state: RootState) => state.constants;

export default constantsSlice.reducer;
