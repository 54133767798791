import { Badge, Switch, Text, Box } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { formatISO } from "date-fns";
import { capitalize } from "lodash";
import { useState, useRef, useEffect, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SALESFORCE_SYNC_STATE } from "../../../../../common/constants/campaign";
import {
  CUSTOM_DATE_RANGE_LABELS,
  getStartDate,
} from "../../../../../common/constants/common";
import {
  ObjectSettings,
  SalesforceSyncRun,
  SALESFORCE_OBJECT_TYPE,
  SyncCount,
  SyncCountDetails,
} from "../../../../../common/types/connection";
import CustomDateRangePicker from "../../../../../components/CustomDateRangePicker";
import { DataTable } from "../../../../../components/data-table/DataTable";
import { FormatDate } from "../../../../../components/DateTimeRangeFilter";
import SyncHistoryTableEntry from "../../../../../components/SyncHistoryTableEntry";
import TableHeader from "../../../../../components/TableHeader";
import SubHeader from "../../components/SubHeader";
import {
  selectConnection,
  listSalesforceSyncRuns,
  setSalesforceSyncHistoryPage,
} from "../../connectionSlice";

const STATE_COLORS = {
  [SALESFORCE_SYNC_STATE.FINISHED]: "green",
  [SALESFORCE_SYNC_STATE.CANCELLED]: "red",
  [SALESFORCE_SYNC_STATE.PROCESSING]: "yellow",
  [SALESFORCE_SYNC_STATE.SCHEDULED]: "blue",
};

const SALESFORCE_SYNC_STATE_DISPLAY = {
  [SALESFORCE_SYNC_STATE.FINISHED]: "finished",
  [SALESFORCE_SYNC_STATE.CANCELLED]: "cancelled",
  [SALESFORCE_SYNC_STATE.PROCESSING]: "processing",
  [SALESFORCE_SYNC_STATE.SCHEDULED]: "scheduled",
};

export default function SalesforceSyncHistory({
  connectionId,
  showErrorsDuringDuration,
}: {
  connectionId: string;
  showErrorsDuringDuration: (date: Date) => void;
}) {
  const dispatch = useDispatch();
  const {
    salesforce: { syncRuns, connection },
  } = useSelector(selectConnection);

  const [showEmptyRuns, setShowEmptyRuns] = useState(false);

  const personReadData = useRef(0);
  const personWriteData = useRef(0);
  const accountReadData = useRef(0);
  const accountWriteData = useRef(0);
  const opportunityReadData = useRef(0);
  const opportunityWriteData = useRef(0);
  const personReadError = useRef(0);
  const accountReadError = useRef(0);
  const opportunityReadError = useRef(0);
  const personWriteError = useRef(0);
  const accountWriteError = useRef(0);
  const opportunityWriteError = useRef(0);

  const addSyncValues = (value: SyncCountDetails) => {
    return (value.updated ?? 0) + (value.inserted ?? 0) + (value.failed ?? 0);
  };

  const formatSendData = useCallback(
    (
      readVal: SyncCount,
      writeVal: SyncCount,
      objectSettings: ObjectSettings,
      objName: keyof SyncCount
    ) => {
      let newReadVal, newWriteVal, permissions;
      newReadVal = readVal[objName];
      newWriteVal = writeVal[objName];
      permissions = objectSettings[objName];
      const error = {
        readError: newReadVal.failed,
        writeError: newWriteVal.failed,
      };
      const data = {
        name: capitalize(
          objName === SALESFORCE_OBJECT_TYPE.OPPORTUNITY
            ? objName
            : `${objName}s`
        ),
        updated: addSyncValues(newWriteVal),
        imported: addSyncValues(newReadVal),
        error,
        permissions,
      };
      return data;
    },
    []
  );

  const columnHelper = createColumnHelper<SalesforceSyncRun>();

  const columns = useMemo(
    () => [
      columnHelper.accessor("start_time", {
        header: () => <TableHeader headerText="Synced On" />,
        size: 240,
        cell: (info) => {
          return (
            <Text marginLeft="20px">
              {<FormatDate date={info.getValue()} showTime />}
            </Text>
          );
        },
      }),
      columnHelper.display({
        id: "person",
        header: () => <TableHeader headerText="Person" />,
        cell: (info) => {
          const leadData = formatSendData(
            info.row.original.sync_read_count,
            info.row.original.sync_write_count,
            connection?.object_settings as ObjectSettings,
            SALESFORCE_OBJECT_TYPE.LEAD
          );
          const contactData = formatSendData(
            info.row.original.sync_read_count,
            info.row.original.sync_write_count,
            connection?.object_settings as ObjectSettings,
            SALESFORCE_OBJECT_TYPE.CONTACT
          );
          personReadError.current =
            leadData.error.readError + contactData.error.readError;
          personWriteError.current =
            leadData.error.writeError + contactData.error.writeError;
          personReadData.current = leadData.imported + contactData.imported;
          personWriteData.current = leadData.updated + contactData.updated;
          return (
            <SyncHistoryTableEntry
              readData={personReadData.current}
              writeData={personWriteData.current}
              type={"Person"}
              sendData={[contactData, leadData]}
              showErrorsDuringDuration={showErrorsDuringDuration}
              currentDate={info.row.original.start_time}
            />
          );
        },
      }),
      columnHelper.display({
        header: () => <TableHeader headerText="Accounts" />,
        id: "accounts",
        cell: (info) => {
          const accountData = formatSendData(
            info.row.original.sync_read_count,
            info.row.original.sync_write_count,
            connection?.object_settings as ObjectSettings,
            SALESFORCE_OBJECT_TYPE.ACCOUNT
          );
          accountReadData.current = accountData.imported;
          accountWriteData.current = accountData.updated;
          accountReadError.current = accountData.error.readError;
          accountWriteError.current = accountData.error.writeError;
          return (
            <SyncHistoryTableEntry
              readData={accountReadData.current}
              writeData={accountWriteData.current}
              type={"Account"}
              sendData={[accountData]}
              showErrorsDuringDuration={showErrorsDuringDuration}
              currentDate={info.row.original.start_time}
            />
          );
        },
      }),
      columnHelper.display({
        header: () => (
          <TableHeader headerText="Opportunity" isComingSoon={true} />
        ),
        id: "opportunity",
        cell: (info) => {
          const opportunityData = formatSendData(
            info.row.original.sync_read_count,
            info.row.original.sync_write_count,
            connection?.object_settings as ObjectSettings,
            SALESFORCE_OBJECT_TYPE.OPPORTUNITY
          );
          opportunityReadData.current = opportunityData.imported;
          opportunityWriteData.current = opportunityData.updated;
          opportunityReadError.current = opportunityData.error.readError;
          opportunityWriteError.current = opportunityData.error.writeError;
          return (
            <SyncHistoryTableEntry
              readData={opportunityReadData.current}
              writeData={opportunityWriteData.current}
              type={"Opportunity"}
              sendData={[opportunityData]}
              showErrorsDuringDuration={showErrorsDuringDuration}
              currentDate={info.row.original.start_time}
            />
          );
        },
      }),
      columnHelper.display({
        header: () => <TableHeader headerText="Total Records" />,
        id: "total_records",
        cell: (info) => {
          const totalReadData =
            personReadData.current +
            accountReadData.current +
            opportunityReadData.current;
          const totalWriteData =
            personWriteData.current +
            accountWriteData.current +
            opportunityWriteData.current;
          const totalReadError =
            personReadError.current +
            accountReadError.current +
            opportunityReadError.current;
          const totalWriteError =
            personWriteError.current +
            accountWriteError.current +
            opportunityWriteError.current;
          const totalError = {
            readError: totalReadError,
            writeError: totalWriteError,
          };
          const totalPermission = true;
          const totalData = {
            name: "Total Records",
            updated: totalWriteData,
            imported: totalReadData,
            error: totalError,
            permissions: totalPermission,
          };
          return (
            <SyncHistoryTableEntry
              readData={totalReadData}
              writeData={totalWriteData}
              type={"Total"}
              sendData={[totalData]}
              showErrorsDuringDuration={showErrorsDuringDuration}
              currentDate={info.row.original.start_time}
            />
          );
        },
      }),
      columnHelper.accessor("state", {
        header: () => <TableHeader headerText="Sync State" />,
        cell: (info) => (
          <Badge
            fontSize="xs"
            colorScheme={STATE_COLORS[info.getValue()]}
            variant="solid"
            marginLeft="20px"
          >
            {SALESFORCE_SYNC_STATE_DISPLAY[info.getValue()] ?? "error"}
          </Badge>
        ),
      }),
    ],
    [
      connection?.object_settings,
      formatSendData,
      showErrorsDuringDuration,
      columnHelper,
    ]
  );

  const [[startDate, endDate], setDateRange] = useState([
    getStartDate(CUSTOM_DATE_RANGE_LABELS.LAST_WEEK),
    new Date(),
  ]);

  function onDateChange(startTime: Date, endTime: Date) {
    setDateRange([startTime, endTime]);
  }

  useEffect(() => {
    if (connectionId) {
      dispatch(
        listSalesforceSyncRuns({
          connectionId,
          shouldIncludeEmptyRuns: showEmptyRuns,
          startTime: formatISO(startDate),
          endTime: formatISO(endDate),
          pageSize: syncRuns.pageSize,
          pageNumber: syncRuns.currentPageNo,
        })
      );
    }
  }, [
    dispatch,
    connectionId,
    syncRuns.currentPageNo,
    syncRuns.pageSize,
    showEmptyRuns,
    startDate,
    endDate,
  ]);

  return (
    <Box p="5" bg="white" rounded="md">
      <SubHeader title={`Sync history (${syncRuns.count})`}>
        <Text>Hide empty runs</Text>
        <Switch
          isChecked={!showEmptyRuns}
          onChange={() => setShowEmptyRuns(!showEmptyRuns)}
        />
        <CustomDateRangePicker
          startDate={startDate}
          endDate={endDate}
          onDateRangeChange={onDateChange}
        />
      </SubHeader>
      <Box w="100%" mt={2}>
        <DataTable
          fetchingList={syncRuns.fetchingList}
          changingPage={syncRuns.changingPage}
          list={syncRuns.list}
          totalPageSize={syncRuns.pageSize}
          setPage={(pageNo: number) =>
            dispatch(setSalesforceSyncHistoryPage(pageNo))
          }
          totalPageCount={syncRuns.totalPageCount}
          currentPage={syncRuns.currentPageNo}
          columns={columns}
          emptyMsg="No sync history found"
          popoverPresent={true}
          tablePadding={{
            customCellPadding: "0px",
          }}
        />
      </Box>
    </Box>
  );
}
