import { HStack, VStack, Flex, Box, Text, StackProps } from "@chakra-ui/react";
import IconWithTooltip from "../../../../../components/IconWithTooltip";
import { SalesforceConnection } from "../../../../../common/types/connection";
import InputFieldWithError from "../../../../../components/InputFieldWithError";

export function ApiAllocationForm({
  connectionDetails,
  allocated,
  setAllocated,
  ...props
}: {
  connectionDetails: SalesforceConnection;
  allocated: number | undefined;
  setAllocated: (n: number) => void;
} & StackProps) {
  return (
    <HStack px={4} py="3" spacing="7" {...props}>
      <VStack
        alignItems="flex-start"
        justifyContent="space-between"
        height="80px"
      >
        <Flex>
          <Text fontSize="sm">Allocated to Inflection</Text>
          <IconWithTooltip
            label="Data will stop syncing between Inflection and Salesforce once this limit is reached. No data will be lost. Syncing will resume once the total number of API calls made in the past 24 hours is below this number."
            tooltipProps={{ fontSize: "xs" }}
            iconContainerProps={{ as: "div", ml: "2" }}
            color="gray.300"
            fontSize="xs"
          />
        </Flex>
        <Box pr="3">
          <InputFieldWithError
            bg="white"
            max={connectionDetails.org_daily_max}
            width="180px"
            labelText=""
            value={allocated}
            type="number"
            paddingY="0"
            onChange={(e) => setAllocated(+e.target.value)}
          />
        </Box>
      </VStack>

      <VStack
        px="5"
        alignItems="flex-start"
        justifyContent="space-between"
        height="80px"
      >
        <Flex>
          <Text fontSize="sm">Limited by Salesforce</Text>
        </Flex>
        <Text pb="2" color="gray.500">
          / {connectionDetails.org_daily_max}
        </Text>
      </VStack>
    </HStack>
  );
}
