import {
  Person,
  PersonComplete,
  PersonEvent,
  PersonLookup,
  PersonUpdateFields,
  PersonMarketingActivitiesDetails,
  SessionData,
} from "../../types/person";
import {
  MarketingPersonListItem,
  CAMPAIGN_CONTEXT,
  EmailSentDetails,
} from "../../types/campaign";
import { PaginatedList, SearchAssetsRequestType } from "../../types/common";
import { SOURCES } from "../../types/unifiedMapping";
import { campaignRequest } from "./api";

function getNewPersonMarketingActivitiesBaseUrl(personId: string) {
  return `/persons/${personId}/marketing-activities`;
}

export async function listPersonApi({
  pageSize,
  pageNo,
  searchKeyword,
  columnsToSearchIn,
  filters,
}: SearchAssetsRequestType): Promise<PaginatedList<Person>> {
  return campaignRequest({
    url: "/person.list",
    data: {
      page_size: pageSize,
      page_number: pageNo,
      query: {
        search: {
          keyword: searchKeyword,
          fields: columnsToSearchIn,
        },
      },
      filters,
    },
    shouldRetryApiCall: true,
  });
}

export async function createPersonApi(email: string): Promise<{
  success: boolean;
  marketing_person_id: string;
  person_id: string;
}> {
  return campaignRequest({
    url: "/person.create",
    data: {
      email,
    },
  });
}

export async function getPersonApi(
  person_id: string
): Promise<{ rows: PersonComplete[] }> {
  return campaignRequest({
    url: "/person.get",
    data: { person_id },
    shouldRetryApiCall: true,
  });
}

export async function listPersonActivitiesApi({
  pageSize,
  pageNo,
  personId,
  searchKeyword,
  columnsToSearchIn,
}: { personId: string } & SearchAssetsRequestType): Promise<
  PaginatedList<PersonEvent>
> {
  return campaignRequest({
    url: "/person.activities",
    data: {
      page_size: pageSize,
      page_number: pageNo,
      person_id: personId,
      query: {
        search: {
          keyword: searchKeyword,
          fields: columnsToSearchIn,
        },
      },
    },
    shouldRetryApiCall: true,
  });
}

export async function listPersonMarketingActivitiesApi(
  page_size: number,
  page_number: number,
  person_id: string,
  start_time: string,
  end_time: string
): Promise<PaginatedList<PersonMarketingActivitiesDetails>> {
  return campaignRequest({
    url: "/person.marketing-activities",
    data: { page_size, page_number, person_id, start_time, end_time },
    shouldRetryApiCall: true,
  });
}

export async function getEmailSentApi(
  person_id: string,
  correlation_id: string
): Promise<{ email: EmailSentDetails }> {
  return campaignRequest({
    url: "/person.email-sent",
    data: { person_id, correlation_id },
  });
}

export async function personLookupApi(
  email: string,
  campaignContext: CAMPAIGN_CONTEXT
): Promise<{ rows: PersonLookup[] }> {
  return campaignRequest({
    url: "/person.lookup",
    data: {
      email,
      campaign_context: campaignContext,
    },
  });
}

export async function updatePersonApi(
  data: PersonUpdateFields
): Promise<{ status: boolean }> {
  return campaignRequest({
    url: "/person.update",
    data,
  });
}

export async function removePersonApi(
  contactId: string,
  email: string
): Promise<{ success: boolean }> {
  return campaignRequest({
    url: "/person.remove",
    data: { contact_id: contactId, email },
  });
}

export async function listProductPersonApi(
  personId: string
): Promise<{ product_users: { [key: string]: any }[] }> {
  return campaignRequest({
    url: "/person.product-user.list",
    data: { person_id: personId },
    shouldRetryApiCall: true,
  });
}

export async function listMarketingPersonApi(
  email: string,
  source: SOURCES
): Promise<{ rows: MarketingPersonListItem[] }> {
  return campaignRequest({
    url: "/marketing-person.list",
    data: { email, source },
    shouldRetryApiCall: true,
  });
}

export async function getOrganisationTraitsApi(
  orgId: string
): Promise<{ organisation_details: [{ [key: string]: any }] }> {
  return campaignRequest({
    url: "/person.org-trait",
    data: {
      organisation_id: orgId,
    },
  });
}

export async function getSessionSummaryApi(
  personId: string,
  sessionId: string
): Promise<{ data: SessionData }> {
  return campaignRequest({
    method: "GET",
    url: `${getNewPersonMarketingActivitiesBaseUrl(
      personId
    )}/sessions/${sessionId}/page_visits`,
  });
}
