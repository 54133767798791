import { Icon, useDisclosure, VStack } from "@chakra-ui/react";
import { useCallback, useEffect, useMemo, memo, useContext } from "react";
import { FaCog, FaPlus } from "react-icons/fa";
import { NodeProps } from "reactflow";
import { FLOW_ACTIONS } from "../../../../../../common/constants/campaign";
import {
  BranchByFilterActionOptions,
  DynamicListType,
} from "../../../../../../common/types/campaign";
import { ActionNodeArgs } from "../../../../../../common/types/flow";
import DynamicList from "../../../../../../components/dynamic-list/DynamicList";
import CommonDynamicListDrawer from "../../../../../../components/dynamic-list/CommonDynamicListDrawer";
import IButton from "../../../../../../components/IButton";
import { WIDGET_OPTIONS_DETAILS } from "../constants";
import { isActionDataSame } from "../helpers";
import WidgetContainer from "../WidgetContainer";
import WrapperWithScrollBar from "../WrapperWithScrollbar";
import {
  CampaignBuilderContext,
  selectFlow,
  setFlowValidity,
} from "../../flowSlice";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../../../../store";

const optionDetails = WIDGET_OPTIONS_DETAILS[FLOW_ACTIONS.BRANCH_BY_FILTER];

function BranchByFilterWidget({
  data: { action, groupId, isCandidate, props, selectedExit, selectedGoto },
}: NodeProps<ActionNodeArgs>) {
  const { saveDraft, setActions, readonly } = props;
  const identities = useMemo(() => {
    return {
      actionId: action.action_id,
      groupId,
      branchId: action.branch_id,
    };
  }, [action, groupId]);
  const actionOptions = useMemo(
    () => action.action_options as BranchByFilterActionOptions,
    [action]
  );

  const { campaignContext } = useContext(CampaignBuilderContext);
  const { flowValidity } = useSelector(selectFlow);
  const dispatch = useAppDispatch();

  const setValidityCallback = useCallback(
    (valid: boolean) => {
      dispatch(setFlowValidity({ [identities.actionId]: valid }));
    },
    [dispatch, identities.actionId]
  );

  useEffect(() => {
    setValidityCallback(
      !!actionOptions && !!actionOptions.condition?.conditions?.length
    );
  }, [actionOptions, setValidityCallback]);

  function setOptions(conditions: DynamicListType[]) {
    const options = {
      primary_branch_id: actionOptions?.primary_branch_id ?? "",
      default_branch_id: actionOptions?.default_branch_id ?? "",
      condition: {
        conditions: conditions,
      },
    };
    const wasConditionEmpty = !actionOptions.condition?.conditions?.length;
    setActions(options, identities.actionId, identities.groupId);
    saveDraft(options, identities, wasConditionEmpty);
    onClose();
  }

  const { isOpen, onOpen, onClose } = useDisclosure();

  const dynamicList = useMemo(
    () => actionOptions?.condition?.conditions ?? [],
    [actionOptions?.condition?.conditions]
  );

  return (
    <WidgetContainer
      invalidMessage={
        flowValidity[identities.actionId] ? "" : "Add condition for branching"
      }
      identities={identities}
      title={optionDetails.label}
      icon={optionDetails.icon}
      color={optionDetails.color}
      isCandidate={isCandidate}
      selectedExit={selectedExit}
      selectedGoto={selectedGoto}
      isDisabled={readonly}
    >
      <VStack alignItems="flex-start" w="100%" px="5" py="6" spacing={4}>
        <WrapperWithScrollBar maxHeight="240px" actionId={identities.actionId}>
          <DynamicList
            dynamicListData={actionOptions?.condition?.conditions ?? []}
            onChange={() => {}}
            sidebar={false}
            activeErrorCheck={false}
            readOnlyMode
            campaignContext={campaignContext}
          />
        </WrapperWithScrollBar>
        {!readonly && (
          <IButton
            size="sm"
            variant="link"
            leftIcon={
              <Icon
                color="brand.blue"
                as={
                  actionOptions?.condition?.conditions?.length ? FaCog : FaPlus
                }
                fontSize={"14px"}
              />
            }
            onClick={onOpen}
            color="brand.blue"
            name="add-custom"
            pl={3}
          >
            {actionOptions?.condition?.conditions?.length
              ? "Edit the dynamic list"
              : "Add dynamic list"}
          </IButton>
        )}
      </VStack>
      <CommonDynamicListDrawer
        isOpen={isOpen}
        onClose={onClose}
        dynamicListData={dynamicList ?? []}
        submitButtonProps={{
          label: "Save",
          onSubmit: (dynamicList: DynamicListType[]) => setOptions(dynamicList),
          props: {
            isLoading: false,
          },
        }}
        campaignContext={campaignContext}
      />
    </WidgetContainer>
  );
}

export default memo(BranchByFilterWidget, isActionDataSame);
