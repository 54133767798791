import {
  isSameDay,
  startOfMonth,
  startOfQuarter,
  startOfYear,
  sub,
} from "date-fns";
import { Range } from "react-date-range";
import urls from "../../urls";
import { PaginationType, RollbarDataType } from "../types/common";

export const HEADER_HEIGHT = "45px";

export enum LOADING_STATES {
  INIT = 3,
  SUCCESS = 2,
  LOADING = 1,
  FAILED = 0,
}

//TODO: create a function with pagesize param
export const INITIAL_PAGINATION: PaginationType<any> = {
  list: null,
  totalPageCount: null,
  currentPageNo: 1,
  pageSize: 30,
  changingPage: false,
  fetchingList: false,
  count: 0,
};

export const INITIAL_PAGINATION_15_ITEMS: PaginationType<any> = {
  ...INITIAL_PAGINATION,
  pageSize: 15,
};

export type CodemirrorTheme = "light" | "dark";

export enum ALERT_TYPE {
  DELETE = "delete",
  REMOVE = "remove",
  DEACTIVATE = "deactivate",
}

export const DeletionModalLabels = {
  [ALERT_TYPE.DELETE]: {
    label: "Delete",
    title: "deletion",
  },
  [ALERT_TYPE.REMOVE]: {
    label: "Remove",
    title: "removal",
  },
  [ALERT_TYPE.DEACTIVATE]: {
    label: "Deactivate",
    title: "deactivation",
  },
};

export enum CODEMIRROR_SIZE {
  SINGLE_LINE = "single-line",
  TEXT_AREA = "text-area",
  MINI_TEXT_AREA = "mini-text-area",
  EDITOR = "editor",
  MINI_EDITOR = "mini-editor",
  READ_ONLY_CODE_EDITOR = "readonly-code-editor",
}

export const PRODUCTION_HOST = "app.inflection.io";
export const STAGING_HOST = "app.inflectionapp.net";

export enum ASSET_TYPES {
  CAMPAIGN = "campaign",
  TEMPLATE = "template",
  TOKEN = "token",
  STATIC_LIST = "static_list",
  WEBHOOK = "webhook",
  FORMS = "form",
}

export const ASSET_TYPES_INFO = {
  [ASSET_TYPES.CAMPAIGN]: { display: "journey", url: urls.journey },
  [ASSET_TYPES.TEMPLATE]: { display: "email", url: urls.email },
  [ASSET_TYPES.TOKEN]: { display: "token", url: urls.token },
  [ASSET_TYPES.STATIC_LIST]: { display: "static list", url: urls.staticList },
  [ASSET_TYPES.WEBHOOK]: { display: "webhook", url: urls.webhook },
  [ASSET_TYPES.FORMS]: { display: "form", url: urls.form },
};

export const DELETION_MODAL_TYPES_INFO = {
  ...ASSET_TYPES_INFO,
  invite: { display: "invite" },
  domain: { display: "domain" },
  subdomain: { display: "subdomain" },
};
export enum TAG_CONNECTOR {
  AND = "AND",
  OR = "OR",
}

export enum CUSTOM_DATE_RANGE_LABELS {
  LAST_DAY = "Last one day",
  LAST_WEEK = "Last one week",
  ONE_MONTH = "This month so far",
  LAST_ONE_MONTH = "Last one month",
  QUARTER = "This quarter so far",
  LAST_6_MONTHS = "Last 6 months",
  YEAR_SO_FAR = "This year so far",
  LAST_YEAR = "Last one year",
  ALL_TIME = "All Time",
}

export function getStartDate(label: CUSTOM_DATE_RANGE_LABELS): Date {
  // returns the start date of the custom ranges
  // as of now end date = new Date() for all ranges

  switch (label) {
    case CUSTOM_DATE_RANGE_LABELS.LAST_DAY:
      return sub(new Date(), { days: 1 });
    case CUSTOM_DATE_RANGE_LABELS.LAST_WEEK:
      return sub(new Date(), { weeks: 1 });
    case CUSTOM_DATE_RANGE_LABELS.ONE_MONTH:
      return startOfMonth(new Date());
    case CUSTOM_DATE_RANGE_LABELS.LAST_ONE_MONTH:
      return sub(new Date(), { months: 1 });
    case CUSTOM_DATE_RANGE_LABELS.QUARTER:
      return startOfQuarter(new Date());
    case CUSTOM_DATE_RANGE_LABELS.LAST_6_MONTHS:
      return sub(new Date(), { months: 6 });
    case CUSTOM_DATE_RANGE_LABELS.YEAR_SO_FAR:
      return startOfYear(new Date());
    case CUSTOM_DATE_RANGE_LABELS.LAST_YEAR:
      return sub(new Date(), { years: 1 });
    case CUSTOM_DATE_RANGE_LABELS.ALL_TIME:
      return new Date(0);
  }
}

export const MAPPED_CUSTOM_DATE_RANGES = Object.values(
  CUSTOM_DATE_RANGE_LABELS
).map((label) => {
  return {
    label,
    range: () => ({
      startDate: getStartDate(label),
      endDate: new Date(),
      label,
    }),
    isSelected(range: Range) {
      const definedRange = this.range();
      return (
        isSameDay(range.startDate ?? 0, definedRange.startDate ?? 0) &&
        isSameDay(range.endDate ?? 0, definedRange.endDate ?? 0)
      );
    },
  };
});

export const DEFAULT_RETRY_COUNT = 3;

export const HAS_REFRESHED = "has_refreshed";

export const INIT_ROLLBAR_DATA: RollbarDataType = {
  id: "",
  username: "",
  email: "",
  organisation_id: "",
  organisation_name: "",
};

export const SEARCH_QUERY_PARAM = "search";
export const PAGE_NUM_PARAM = "page";
export const FILTER_PARAM = "filter";

export enum API_RESPONSE_STATUS {
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
}

export const EMPTY_CONTEXT = "(empty)";

export const EMPTY_STRING = "";

export const ANTI_SPAM_POLICY_LINK =
  "https://www.inflection.io/terms#:~:text=Prohibition%20against%20Unsolicited%20Email%2FSpam";

// TODO : Update it with a proper list of timezone and abbreviations - wo DST

export const ALL_TIMEZONES_WITH_ABBREVIATION = [
  {
    timezoneName: "Africa/Abidjan",
    abbreviation: "GMT",
  },
  {
    timezoneName: "Africa/Accra",
    abbreviation: "GMT",
  },
  {
    timezoneName: "Africa/Addis_Ababa",
    abbreviation: "EAT",
  },
  {
    timezoneName: "Africa/Algiers",
    abbreviation: "CET",
  },
  {
    timezoneName: "Africa/Asmara",
    abbreviation: "EAT",
  },
  {
    timezoneName: "Africa/Asmera",
    abbreviation: "EAT",
  },
  {
    timezoneName: "Africa/Bamako",
    abbreviation: "GMT",
  },
  {
    timezoneName: "Africa/Bangui",
    abbreviation: "WAT",
  },
  {
    timezoneName: "Africa/Banjul",
    abbreviation: "GMT",
  },
  {
    timezoneName: "Africa/Bissau",
    abbreviation: "GMT",
  },
  {
    timezoneName: "Africa/Blantyre",
    abbreviation: "CAT",
  },
  {
    timezoneName: "Africa/Brazzaville",
    abbreviation: "WAT",
  },
  {
    timezoneName: "Africa/Bujumbura",
    abbreviation: "CAT",
  },
  {
    timezoneName: "Africa/Cairo",
    abbreviation: "EET",
  },
  {
    timezoneName: "Africa/Casablanca",
    abbreviation: "+01",
  },
  {
    timezoneName: "Africa/Ceuta",
    abbreviation: "CEST",
  },
  {
    timezoneName: "Africa/Conakry",
    abbreviation: "GMT",
  },
  {
    timezoneName: "Africa/Dakar",
    abbreviation: "GMT",
  },
  {
    timezoneName: "Africa/Dar_es_Salaam",
    abbreviation: "EAT",
  },
  {
    timezoneName: "Africa/Djibouti",
    abbreviation: "EAT",
  },
  {
    timezoneName: "Africa/Douala",
    abbreviation: "WAT",
  },
  {
    timezoneName: "Africa/El_Aaiun",
    abbreviation: "+01",
  },
  {
    timezoneName: "Africa/Freetown",
    abbreviation: "GMT",
  },
  {
    timezoneName: "Africa/Gaborone",
    abbreviation: "CAT",
  },
  {
    timezoneName: "Africa/Harare",
    abbreviation: "CAT",
  },
  {
    timezoneName: "Africa/Johannesburg",
    abbreviation: "SAST",
  },
  {
    timezoneName: "Africa/Juba",
    abbreviation: "CAT",
  },
  {
    timezoneName: "Africa/Kampala",
    abbreviation: "EAT",
  },
  {
    timezoneName: "Africa/Khartoum",
    abbreviation: "CAT",
  },
  {
    timezoneName: "Africa/Kigali",
    abbreviation: "CAT",
  },
  {
    timezoneName: "Africa/Kinshasa",
    abbreviation: "WAT",
  },
  {
    timezoneName: "Africa/Lagos",
    abbreviation: "WAT",
  },
  {
    timezoneName: "Africa/Libreville",
    abbreviation: "WAT",
  },
  {
    timezoneName: "Africa/Lome",
    abbreviation: "GMT",
  },
  {
    timezoneName: "Africa/Luanda",
    abbreviation: "WAT",
  },
  {
    timezoneName: "Africa/Lubumbashi",
    abbreviation: "CAT",
  },
  {
    timezoneName: "Africa/Lusaka",
    abbreviation: "CAT",
  },
  {
    timezoneName: "Africa/Malabo",
    abbreviation: "WAT",
  },
  {
    timezoneName: "Africa/Maputo",
    abbreviation: "CAT",
  },
  {
    timezoneName: "Africa/Maseru",
    abbreviation: "SAST",
  },
  {
    timezoneName: "Africa/Mbabane",
    abbreviation: "SAST",
  },
  {
    timezoneName: "Africa/Mogadishu",
    abbreviation: "EAT",
  },
  {
    timezoneName: "Africa/Monrovia",
    abbreviation: "GMT",
  },
  {
    timezoneName: "Africa/Nairobi",
    abbreviation: "EAT",
  },
  {
    timezoneName: "Africa/Ndjamena",
    abbreviation: "WAT",
  },
  {
    timezoneName: "Africa/Niamey",
    abbreviation: "WAT",
  },
  {
    timezoneName: "Africa/Nouakchott",
    abbreviation: "GMT",
  },
  {
    timezoneName: "Africa/Ouagadougou",
    abbreviation: "GMT",
  },
  {
    timezoneName: "Africa/Porto-Novo",
    abbreviation: "WAT",
  },
  {
    timezoneName: "Africa/Sao_Tome",
    abbreviation: "GMT",
  },
  {
    timezoneName: "Africa/Timbuktu",
    abbreviation: "GMT",
  },
  {
    timezoneName: "Africa/Tripoli",
    abbreviation: "EET",
  },
  {
    timezoneName: "Africa/Tunis",
    abbreviation: "CET",
  },
  {
    timezoneName: "Africa/Windhoek",
    abbreviation: "CAT",
  },
  {
    timezoneName: "America/Adak",
    abbreviation: "HDT",
  },
  {
    timezoneName: "America/Anchorage",
    abbreviation: "AKDT",
  },
  {
    timezoneName: "America/Anguilla",
    abbreviation: "AST",
  },
  {
    timezoneName: "America/Antigua",
    abbreviation: "AST",
  },
  {
    timezoneName: "America/Araguaina",
    abbreviation: "-03",
  },
  {
    timezoneName: "America/Argentina/Buenos_Aires",
    abbreviation: "-03",
  },
  {
    timezoneName: "America/Argentina/Catamarca",
    abbreviation: "-03",
  },
  {
    timezoneName: "America/Argentina/ComodRivadavia",
    abbreviation: "-03",
  },
  {
    timezoneName: "America/Argentina/Cordoba",
    abbreviation: "-03",
  },
  {
    timezoneName: "America/Argentina/Jujuy",
    abbreviation: "-03",
  },
  {
    timezoneName: "America/Argentina/La_Rioja",
    abbreviation: "-03",
  },
  {
    timezoneName: "America/Argentina/Mendoza",
    abbreviation: "-03",
  },
  {
    timezoneName: "America/Argentina/Rio_Gallegos",
    abbreviation: "-03",
  },
  {
    timezoneName: "America/Argentina/Salta",
    abbreviation: "-03",
  },
  {
    timezoneName: "America/Argentina/San_Juan",
    abbreviation: "-03",
  },
  {
    timezoneName: "America/Argentina/San_Luis",
    abbreviation: "-03",
  },
  {
    timezoneName: "America/Argentina/Tucuman",
    abbreviation: "-03",
  },
  {
    timezoneName: "America/Argentina/Ushuaia",
    abbreviation: "-03",
  },
  {
    timezoneName: "America/Aruba",
    abbreviation: "AST",
  },
  {
    timezoneName: "America/Asuncion",
    abbreviation: "-04",
  },
  {
    timezoneName: "America/Atikokan",
    abbreviation: "EST",
  },
  {
    timezoneName: "America/Atka",
    abbreviation: "HDT",
  },
  {
    timezoneName: "America/Bahia",
    abbreviation: "-03",
  },
  {
    timezoneName: "America/Bahia_Banderas",
    abbreviation: "CST",
  },
  {
    timezoneName: "America/Barbados",
    abbreviation: "AST",
  },
  {
    timezoneName: "America/Belem",
    abbreviation: "-03",
  },
  {
    timezoneName: "America/Belize",
    abbreviation: "CST",
  },
  {
    timezoneName: "America/Blanc-Sablon",
    abbreviation: "AST",
  },
  {
    timezoneName: "America/Boa_Vista",
    abbreviation: "-04",
  },
  {
    timezoneName: "America/Bogota",
    abbreviation: "-05",
  },
  {
    timezoneName: "America/Boise",
    abbreviation: "MDT",
  },
  {
    timezoneName: "America/Buenos_Aires",
    abbreviation: "-03",
  },
  {
    timezoneName: "America/Cambridge_Bay",
    abbreviation: "MDT",
  },
  {
    timezoneName: "America/Campo_Grande",
    abbreviation: "-04",
  },
  {
    timezoneName: "America/Cancun",
    abbreviation: "EST",
  },
  {
    timezoneName: "America/Caracas",
    abbreviation: "-04",
  },
  {
    timezoneName: "America/Catamarca",
    abbreviation: "-03",
  },
  {
    timezoneName: "America/Cayenne",
    abbreviation: "-03",
  },
  {
    timezoneName: "America/Cayman",
    abbreviation: "EST",
  },
  {
    timezoneName: "America/Chicago",
    abbreviation: "CDT",
  },
  {
    timezoneName: "America/Chihuahua",
    abbreviation: "CST",
  },
  {
    timezoneName: "America/Ciudad_Juarez",
    abbreviation: "MDT",
  },
  {
    timezoneName: "America/Coral_Harbour",
    abbreviation: "EST",
  },
  {
    timezoneName: "America/Cordoba",
    abbreviation: "-03",
  },
  {
    timezoneName: "America/Costa_Rica",
    abbreviation: "CST",
  },
  {
    timezoneName: "America/Creston",
    abbreviation: "MST",
  },
  {
    timezoneName: "America/Cuiaba",
    abbreviation: "-04",
  },
  {
    timezoneName: "America/Curacao",
    abbreviation: "AST",
  },
  {
    timezoneName: "America/Danmarkshavn",
    abbreviation: "GMT",
  },
  {
    timezoneName: "America/Dawson",
    abbreviation: "MST",
  },
  {
    timezoneName: "America/Dawson_Creek",
    abbreviation: "MST",
  },
  {
    timezoneName: "America/Denver",
    abbreviation: "MST",
  },
  {
    timezoneName: "America/Detroit",
    abbreviation: "EDT",
  },
  {
    timezoneName: "America/Dominica",
    abbreviation: "AST",
  },
  {
    timezoneName: "America/Edmonton",
    abbreviation: "MDT",
  },
  {
    timezoneName: "America/Eirunepe",
    abbreviation: "-05",
  },
  {
    timezoneName: "America/El_Salvador",
    abbreviation: "CST",
  },
  {
    timezoneName: "America/Ensenada",
    abbreviation: "PDT",
  },
  {
    timezoneName: "America/Fort_Nelson",
    abbreviation: "MST",
  },
  {
    timezoneName: "America/Fort_Wayne",
    abbreviation: "EDT",
  },
  {
    timezoneName: "America/Fortaleza",
    abbreviation: "-03",
  },
  {
    timezoneName: "America/Glace_Bay",
    abbreviation: "ADT",
  },
  {
    timezoneName: "America/Godthab",
    abbreviation: "-01",
  },
  {
    timezoneName: "America/Goose_Bay",
    abbreviation: "ADT",
  },
  {
    timezoneName: "America/Grand_Turk",
    abbreviation: "EDT",
  },
  {
    timezoneName: "America/Grenada",
    abbreviation: "AST",
  },
  {
    timezoneName: "America/Guadeloupe",
    abbreviation: "AST",
  },
  {
    timezoneName: "America/Guatemala",
    abbreviation: "CST",
  },
  {
    timezoneName: "America/Guayaquil",
    abbreviation: "-05",
  },
  {
    timezoneName: "America/Guyana",
    abbreviation: "-04",
  },
  {
    timezoneName: "America/Halifax",
    abbreviation: "ADT",
  },
  {
    timezoneName: "America/Havana",
    abbreviation: "CDT",
  },
  {
    timezoneName: "America/Hermosillo",
    abbreviation: "MST",
  },
  {
    timezoneName: "America/Indiana/Indianapolis",
    abbreviation: "EDT",
  },
  {
    timezoneName: "America/Indiana/Knox",
    abbreviation: "CDT",
  },
  {
    timezoneName: "America/Indiana/Marengo",
    abbreviation: "EDT",
  },
  {
    timezoneName: "America/Indiana/Petersburg",
    abbreviation: "EDT",
  },
  {
    timezoneName: "America/Indiana/Tell_City",
    abbreviation: "CDT",
  },
  {
    timezoneName: "America/Indiana/Vevay",
    abbreviation: "EDT",
  },
  {
    timezoneName: "America/Indiana/Vincennes",
    abbreviation: "EDT",
  },
  {
    timezoneName: "America/Indiana/Winamac",
    abbreviation: "EDT",
  },
  {
    timezoneName: "America/Indianapolis",
    abbreviation: "EDT",
  },
  {
    timezoneName: "America/Inuvik",
    abbreviation: "MDT",
  },
  {
    timezoneName: "America/Iqaluit",
    abbreviation: "EDT",
  },
  {
    timezoneName: "America/Jamaica",
    abbreviation: "EST",
  },
  {
    timezoneName: "America/Jujuy",
    abbreviation: "-03",
  },
  {
    timezoneName: "America/Juneau",
    abbreviation: "AKDT",
  },
  {
    timezoneName: "America/Kentucky/Louisville",
    abbreviation: "EDT",
  },
  {
    timezoneName: "America/Kentucky/Monticello",
    abbreviation: "EDT",
  },
  {
    timezoneName: "America/Knox_IN",
    abbreviation: "CDT",
  },
  {
    timezoneName: "America/Kralendijk",
    abbreviation: "AST",
  },
  {
    timezoneName: "America/La_Paz",
    abbreviation: "-04",
  },
  {
    timezoneName: "America/Lima",
    abbreviation: "-05",
  },
  {
    timezoneName: "America/Los_Angeles",
    abbreviation: "PST",
  },
  {
    timezoneName: "America/Louisville",
    abbreviation: "EDT",
  },
  {
    timezoneName: "America/Lower_Princes",
    abbreviation: "AST",
  },
  {
    timezoneName: "America/Maceio",
    abbreviation: "-03",
  },
  {
    timezoneName: "America/Managua",
    abbreviation: "CST",
  },
  {
    timezoneName: "America/Manaus",
    abbreviation: "-04",
  },
  {
    timezoneName: "America/Marigot",
    abbreviation: "AST",
  },
  {
    timezoneName: "America/Martinique",
    abbreviation: "AST",
  },
  {
    timezoneName: "America/Matamoros",
    abbreviation: "CDT",
  },
  {
    timezoneName: "America/Mazatlan",
    abbreviation: "MST",
  },
  {
    timezoneName: "America/Mendoza",
    abbreviation: "-03",
  },
  {
    timezoneName: "America/Menominee",
    abbreviation: "CDT",
  },
  {
    timezoneName: "America/Merida",
    abbreviation: "CST",
  },
  {
    timezoneName: "America/Metlakatla",
    abbreviation: "AKDT",
  },
  {
    timezoneName: "America/Mexico_City",
    abbreviation: "CST",
  },
  {
    timezoneName: "America/Miquelon",
    abbreviation: "-02",
  },
  {
    timezoneName: "America/Moncton",
    abbreviation: "ADT",
  },
  {
    timezoneName: "America/Monterrey",
    abbreviation: "CST",
  },
  {
    timezoneName: "America/Montevideo",
    abbreviation: "-03",
  },
  {
    timezoneName: "America/Montreal",
    abbreviation: "EDT",
  },
  {
    timezoneName: "America/Montserrat",
    abbreviation: "AST",
  },
  {
    timezoneName: "America/Nassau",
    abbreviation: "EDT",
  },
  {
    timezoneName: "America/New_York",
    abbreviation: "EST",
  },
  {
    timezoneName: "America/Nipigon",
    abbreviation: "EDT",
  },
  {
    timezoneName: "America/Nome",
    abbreviation: "AKDT",
  },
  {
    timezoneName: "America/Noronha",
    abbreviation: "-02",
  },
  {
    timezoneName: "America/North_Dakota/Beulah",
    abbreviation: "CDT",
  },
  {
    timezoneName: "America/North_Dakota/Center",
    abbreviation: "CDT",
  },
  {
    timezoneName: "America/North_Dakota/New_Salem",
    abbreviation: "CDT",
  },
  {
    timezoneName: "America/Nuuk",
    abbreviation: "-01",
  },
  {
    timezoneName: "America/Ojinaga",
    abbreviation: "CDT",
  },
  {
    timezoneName: "America/Panama",
    abbreviation: "EST",
  },
  {
    timezoneName: "America/Pangnirtung",
    abbreviation: "EDT",
  },
  {
    timezoneName: "America/Paramaribo",
    abbreviation: "-03",
  },
  {
    timezoneName: "America/Phoenix",
    abbreviation: "MST",
  },
  {
    timezoneName: "America/Port-au-Prince",
    abbreviation: "EDT",
  },
  {
    timezoneName: "America/Port_of_Spain",
    abbreviation: "AST",
  },
  {
    timezoneName: "America/Porto_Acre",
    abbreviation: "-05",
  },
  {
    timezoneName: "America/Porto_Velho",
    abbreviation: "-04",
  },
  {
    timezoneName: "America/Puerto_Rico",
    abbreviation: "AST",
  },
  {
    timezoneName: "America/Punta_Arenas",
    abbreviation: "-03",
  },
  {
    timezoneName: "America/Rainy_River",
    abbreviation: "CDT",
  },
  {
    timezoneName: "America/Rankin_Inlet",
    abbreviation: "CDT",
  },
  {
    timezoneName: "America/Recife",
    abbreviation: "-03",
  },
  {
    timezoneName: "America/Regina",
    abbreviation: "CST",
  },
  {
    timezoneName: "America/Resolute",
    abbreviation: "CDT",
  },
  {
    timezoneName: "America/Rio_Branco",
    abbreviation: "-05",
  },
  {
    timezoneName: "America/Rosario",
    abbreviation: "-03",
  },
  {
    timezoneName: "America/Santa_Isabel",
    abbreviation: "PDT",
  },
  {
    timezoneName: "America/Santarem",
    abbreviation: "-03",
  },
  {
    timezoneName: "America/Santiago",
    abbreviation: "-04",
  },
  {
    timezoneName: "America/Santo_Domingo",
    abbreviation: "AST",
  },
  {
    timezoneName: "America/Sao_Paulo",
    abbreviation: "-03",
  },
  {
    timezoneName: "America/Scoresbysund",
    abbreviation: "-01",
  },
  {
    timezoneName: "America/Shiprock",
    abbreviation: "MDT",
  },
  {
    timezoneName: "America/Sitka",
    abbreviation: "AKDT",
  },
  {
    timezoneName: "America/St_Barthelemy",
    abbreviation: "AST",
  },
  {
    timezoneName: "America/St_Johns",
    abbreviation: "NDT",
  },
  {
    timezoneName: "America/St_Kitts",
    abbreviation: "AST",
  },
  {
    timezoneName: "America/St_Lucia",
    abbreviation: "AST",
  },
  {
    timezoneName: "America/St_Thomas",
    abbreviation: "AST",
  },
  {
    timezoneName: "America/St_Vincent",
    abbreviation: "AST",
  },
  {
    timezoneName: "America/Swift_Current",
    abbreviation: "CST",
  },
  {
    timezoneName: "America/Tegucigalpa",
    abbreviation: "CST",
  },
  {
    timezoneName: "America/Thule",
    abbreviation: "ADT",
  },
  {
    timezoneName: "America/Thunder_Bay",
    abbreviation: "EDT",
  },
  {
    timezoneName: "America/Tijuana",
    abbreviation: "PDT",
  },
  {
    timezoneName: "America/Toronto",
    abbreviation: "EDT",
  },
  {
    timezoneName: "America/Tortola",
    abbreviation: "AST",
  },
  {
    timezoneName: "America/Vancouver",
    abbreviation: "PDT",
  },
  {
    timezoneName: "America/Virgin",
    abbreviation: "AST",
  },
  {
    timezoneName: "America/Whitehorse",
    abbreviation: "MST",
  },
  {
    timezoneName: "America/Winnipeg",
    abbreviation: "CDT",
  },
  {
    timezoneName: "America/Yakutat",
    abbreviation: "AKDT",
  },
  {
    timezoneName: "America/Yellowknife",
    abbreviation: "MDT",
  },
  {
    timezoneName: "Antarctica/Casey",
    abbreviation: "+08",
  },
  {
    timezoneName: "Antarctica/Davis",
    abbreviation: "+07",
  },
  {
    timezoneName: "Antarctica/DumontDUrville",
    abbreviation: "+10",
  },
  {
    timezoneName: "Antarctica/Macquarie",
    abbreviation: "AEST",
  },
  {
    timezoneName: "Antarctica/Mawson",
    abbreviation: "+05",
  },
  {
    timezoneName: "Antarctica/McMurdo",
    abbreviation: "NZST",
  },
  {
    timezoneName: "Antarctica/Palmer",
    abbreviation: "-03",
  },
  {
    timezoneName: "Antarctica/Rothera",
    abbreviation: "-03",
  },
  {
    timezoneName: "Antarctica/South_Pole",
    abbreviation: "NZST",
  },
  {
    timezoneName: "Antarctica/Syowa",
    abbreviation: "+03",
  },
  {
    timezoneName: "Antarctica/Troll",
    abbreviation: "+02",
  },
  {
    timezoneName: "Antarctica/Vostok",
    abbreviation: "+05",
  },
  {
    timezoneName: "Arctic/Longyearbyen",
    abbreviation: "CEST",
  },
  {
    timezoneName: "Asia/Aden",
    abbreviation: "+03",
  },
  {
    timezoneName: "Asia/Almaty",
    abbreviation: "+05",
  },
  {
    timezoneName: "Asia/Amman",
    abbreviation: "+03",
  },
  {
    timezoneName: "Asia/Anadyr",
    abbreviation: "+12",
  },
  {
    timezoneName: "Asia/Aqtau",
    abbreviation: "+05",
  },
  {
    timezoneName: "Asia/Aqtobe",
    abbreviation: "+05",
  },
  {
    timezoneName: "Asia/Ashgabat",
    abbreviation: "+05",
  },
  {
    timezoneName: "Asia/Ashkhabad",
    abbreviation: "+05",
  },
  {
    timezoneName: "Asia/Atyrau",
    abbreviation: "+05",
  },
  {
    timezoneName: "Asia/Baghdad",
    abbreviation: "+03",
  },
  {
    timezoneName: "Asia/Bahrain",
    abbreviation: "+03",
  },
  {
    timezoneName: "Asia/Baku",
    abbreviation: "+04",
  },
  {
    timezoneName: "Asia/Bangkok",
    abbreviation: "+07",
  },
  {
    timezoneName: "Asia/Barnaul",
    abbreviation: "+07",
  },
  {
    timezoneName: "Asia/Beirut",
    abbreviation: "EEST",
  },
  {
    timezoneName: "Asia/Bishkek",
    abbreviation: "+06",
  },
  {
    timezoneName: "Asia/Brunei",
    abbreviation: "+08",
  },
  {
    timezoneName: "Asia/Calcutta",
    abbreviation: "IST",
  },
  {
    timezoneName: "Asia/Chita",
    abbreviation: "+09",
  },
  {
    timezoneName: "Asia/Choibalsan",
    abbreviation: "+08",
  },
  {
    timezoneName: "Asia/Chongqing",
    abbreviation: "CST",
  },
  {
    timezoneName: "Asia/Chungking",
    abbreviation: "CST",
  },
  {
    timezoneName: "Asia/Colombo",
    abbreviation: "+0530",
  },
  {
    timezoneName: "Asia/Dacca",
    abbreviation: "+06",
  },
  {
    timezoneName: "Asia/Damascus",
    abbreviation: "+03",
  },
  {
    timezoneName: "Asia/Dhaka",
    abbreviation: "+06",
  },
  {
    timezoneName: "Asia/Dili",
    abbreviation: "+09",
  },
  {
    timezoneName: "Asia/Dubai",
    abbreviation: "+04",
  },
  {
    timezoneName: "Asia/Dushanbe",
    abbreviation: "+05",
  },
  {
    timezoneName: "Asia/Famagusta",
    abbreviation: "EEST",
  },
  {
    timezoneName: "Asia/Gaza",
    abbreviation: "EEST",
  },
  {
    timezoneName: "Asia/Harbin",
    abbreviation: "CST",
  },
  {
    timezoneName: "Asia/Hebron",
    abbreviation: "EEST",
  },
  {
    timezoneName: "Asia/Ho_Chi_Minh",
    abbreviation: "+07",
  },
  {
    timezoneName: "Asia/Hong_Kong",
    abbreviation: "HKT",
  },
  {
    timezoneName: "Asia/Hovd",
    abbreviation: "+07",
  },
  {
    timezoneName: "Asia/Irkutsk",
    abbreviation: "+08",
  },
  {
    timezoneName: "Asia/Istanbul",
    abbreviation: "+03",
  },
  {
    timezoneName: "Asia/Jakarta",
    abbreviation: "WIB",
  },
  {
    timezoneName: "Asia/Jayapura",
    abbreviation: "WIT",
  },
  {
    timezoneName: "Asia/Jerusalem",
    abbreviation: "IDT",
  },
  {
    timezoneName: "Asia/Kabul",
    abbreviation: "+0430",
  },
  {
    timezoneName: "Asia/Kamchatka",
    abbreviation: "+12",
  },
  {
    timezoneName: "Asia/Karachi",
    abbreviation: "PKT",
  },
  {
    timezoneName: "Asia/Kashgar",
    abbreviation: "+06",
  },
  {
    timezoneName: "Asia/Kathmandu",
    abbreviation: "+0545",
  },
  {
    timezoneName: "Asia/Katmandu",
    abbreviation: "+0545",
  },
  {
    timezoneName: "Asia/Khandyga",
    abbreviation: "+09",
  },
  {
    timezoneName: "Asia/Kolkata",
    abbreviation: "IST",
  },
  {
    timezoneName: "Asia/Krasnoyarsk",
    abbreviation: "+07",
  },
  {
    timezoneName: "Asia/Kuala_Lumpur",
    abbreviation: "+08",
  },
  {
    timezoneName: "Asia/Kuching",
    abbreviation: "+08",
  },
  {
    timezoneName: "Asia/Kuwait",
    abbreviation: "+03",
  },
  {
    timezoneName: "Asia/Macao",
    abbreviation: "CST",
  },
  {
    timezoneName: "Asia/Macau",
    abbreviation: "CST",
  },
  {
    timezoneName: "Asia/Magadan",
    abbreviation: "+11",
  },
  {
    timezoneName: "Asia/Makassar",
    abbreviation: "WITA",
  },
  {
    timezoneName: "Asia/Manila",
    abbreviation: "PST",
  },
  {
    timezoneName: "Asia/Muscat",
    abbreviation: "+04",
  },
  {
    timezoneName: "Asia/Nicosia",
    abbreviation: "EEST",
  },
  {
    timezoneName: "Asia/Novokuznetsk",
    abbreviation: "+07",
  },
  {
    timezoneName: "Asia/Novosibirsk",
    abbreviation: "+07",
  },
  {
    timezoneName: "Asia/Omsk",
    abbreviation: "+06",
  },
  {
    timezoneName: "Asia/Oral",
    abbreviation: "+05",
  },
  {
    timezoneName: "Asia/Phnom_Penh",
    abbreviation: "+07",
  },
  {
    timezoneName: "Asia/Pontianak",
    abbreviation: "WIB",
  },
  {
    timezoneName: "Asia/Pyongyang",
    abbreviation: "KST",
  },
  {
    timezoneName: "Asia/Qatar",
    abbreviation: "+03",
  },
  {
    timezoneName: "Asia/Qostanay",
    abbreviation: "+05",
  },
  {
    timezoneName: "Asia/Qyzylorda",
    abbreviation: "+05",
  },
  {
    timezoneName: "Asia/Rangoon",
    abbreviation: "+0630",
  },
  {
    timezoneName: "Asia/Riyadh",
    abbreviation: "+03",
  },
  {
    timezoneName: "Asia/Saigon",
    abbreviation: "+07",
  },
  {
    timezoneName: "Asia/Sakhalin",
    abbreviation: "+11",
  },
  {
    timezoneName: "Asia/Samarkand",
    abbreviation: "+05",
  },
  {
    timezoneName: "Asia/Seoul",
    abbreviation: "KST",
  },
  {
    timezoneName: "Asia/Shanghai",
    abbreviation: "CST",
  },
  {
    timezoneName: "Asia/Singapore",
    abbreviation: "+08",
  },
  {
    timezoneName: "Asia/Srednekolymsk",
    abbreviation: "+11",
  },
  {
    timezoneName: "Asia/Taipei",
    abbreviation: "CST",
  },
  {
    timezoneName: "Asia/Tashkent",
    abbreviation: "+05",
  },
  {
    timezoneName: "Asia/Tbilisi",
    abbreviation: "+04",
  },
  {
    timezoneName: "Asia/Tehran",
    abbreviation: "+0330",
  },
  {
    timezoneName: "Asia/Tel_Aviv",
    abbreviation: "IDT",
  },
  {
    timezoneName: "Asia/Thimbu",
    abbreviation: "+06",
  },
  {
    timezoneName: "Asia/Thimphu",
    abbreviation: "+06",
  },
  {
    timezoneName: "Asia/Tokyo",
    abbreviation: "JST",
  },
  {
    timezoneName: "Asia/Tomsk",
    abbreviation: "+07",
  },
  {
    timezoneName: "Asia/Ujung_Pandang",
    abbreviation: "WITA",
  },
  {
    timezoneName: "Asia/Ulaanbaatar",
    abbreviation: "+08",
  },
  {
    timezoneName: "Asia/Ulan_Bator",
    abbreviation: "+08",
  },
  {
    timezoneName: "Asia/Urumqi",
    abbreviation: "+06",
  },
  {
    timezoneName: "Asia/Ust-Nera",
    abbreviation: "+10",
  },
  {
    timezoneName: "Asia/Vientiane",
    abbreviation: "+07",
  },
  {
    timezoneName: "Asia/Vladivostok",
    abbreviation: "+10",
  },
  {
    timezoneName: "Asia/Yakutsk",
    abbreviation: "+09",
  },
  {
    timezoneName: "Asia/Yangon",
    abbreviation: "+0630",
  },
  {
    timezoneName: "Asia/Yekaterinburg",
    abbreviation: "+05",
  },
  {
    timezoneName: "Asia/Yerevan",
    abbreviation: "+04",
  },
  {
    timezoneName: "Atlantic/Azores",
    abbreviation: "+00",
  },
  {
    timezoneName: "Atlantic/Bermuda",
    abbreviation: "ADT",
  },
  {
    timezoneName: "Atlantic/Canary",
    abbreviation: "WEST",
  },
  {
    timezoneName: "Atlantic/Cape_Verde",
    abbreviation: "-01",
  },
  {
    timezoneName: "Atlantic/Faeroe",
    abbreviation: "WEST",
  },
  {
    timezoneName: "Atlantic/Faroe",
    abbreviation: "WEST",
  },
  {
    timezoneName: "Atlantic/Jan_Mayen",
    abbreviation: "CEST",
  },
  {
    timezoneName: "Atlantic/Madeira",
    abbreviation: "WEST",
  },
  {
    timezoneName: "Atlantic/Reykjavik",
    abbreviation: "GMT",
  },
  {
    timezoneName: "Atlantic/South_Georgia",
    abbreviation: "-02",
  },
  {
    timezoneName: "Atlantic/St_Helena",
    abbreviation: "GMT",
  },
  {
    timezoneName: "Atlantic/Stanley",
    abbreviation: "-03",
  },
  {
    timezoneName: "Australia/ACT",
    abbreviation: "AEST",
  },
  {
    timezoneName: "Australia/Adelaide",
    abbreviation: "ACST",
  },
  {
    timezoneName: "Australia/Brisbane",
    abbreviation: "AEST",
  },
  {
    timezoneName: "Australia/Broken_Hill",
    abbreviation: "ACST",
  },
  {
    timezoneName: "Australia/Canberra",
    abbreviation: "AEST",
  },
  {
    timezoneName: "Australia/Currie",
    abbreviation: "AEST",
  },
  {
    timezoneName: "Australia/Darwin",
    abbreviation: "ACST",
  },
  {
    timezoneName: "Australia/Eucla",
    abbreviation: "+0845",
  },
  {
    timezoneName: "Australia/Hobart",
    abbreviation: "AEST",
  },
  {
    timezoneName: "Australia/LHI",
    abbreviation: "+1030",
  },
  {
    timezoneName: "Australia/Lindeman",
    abbreviation: "AEST",
  },
  {
    timezoneName: "Australia/Lord_Howe",
    abbreviation: "+1030",
  },
  {
    timezoneName: "Australia/Melbourne",
    abbreviation: "AEST",
  },
  {
    timezoneName: "Australia/NSW",
    abbreviation: "AEST",
  },
  {
    timezoneName: "Australia/North",
    abbreviation: "ACST",
  },
  {
    timezoneName: "Australia/Perth",
    abbreviation: "AWST",
  },
  {
    timezoneName: "Australia/Queensland",
    abbreviation: "AEST",
  },
  {
    timezoneName: "Australia/South",
    abbreviation: "ACST",
  },
  {
    timezoneName: "Australia/Sydney",
    abbreviation: "AEST",
  },
  {
    timezoneName: "Australia/Tasmania",
    abbreviation: "AEST",
  },
  {
    timezoneName: "Australia/Victoria",
    abbreviation: "AEST",
  },
  {
    timezoneName: "Australia/West",
    abbreviation: "AWST",
  },
  {
    timezoneName: "Australia/Yancowinna",
    abbreviation: "ACST",
  },
  {
    timezoneName: "Brazil/Acre",
    abbreviation: "-05",
  },
  {
    timezoneName: "Brazil/DeNoronha",
    abbreviation: "-02",
  },
  {
    timezoneName: "Brazil/East",
    abbreviation: "-03",
  },
  {
    timezoneName: "Brazil/West",
    abbreviation: "-04",
  },
  {
    timezoneName: "CET",
    abbreviation: "CEST",
  },
  {
    timezoneName: "CST6CDT",
    abbreviation: "CDT",
  },
  {
    timezoneName: "Canada/Atlantic",
    abbreviation: "ADT",
  },
  {
    timezoneName: "Canada/Central",
    abbreviation: "CDT",
  },
  {
    timezoneName: "Canada/Eastern",
    abbreviation: "EDT",
  },
  {
    timezoneName: "Canada/Mountain",
    abbreviation: "MDT",
  },
  {
    timezoneName: "Canada/Newfoundland",
    abbreviation: "NDT",
  },
  {
    timezoneName: "Canada/Pacific",
    abbreviation: "PDT",
  },
  {
    timezoneName: "Canada/Saskatchewan",
    abbreviation: "CST",
  },
  {
    timezoneName: "Canada/Yukon",
    abbreviation: "MST",
  },
  {
    timezoneName: "Chile/Continental",
    abbreviation: "-04",
  },
  {
    timezoneName: "Chile/EasterIsland",
    abbreviation: "-06",
  },
  {
    timezoneName: "Cuba",
    abbreviation: "CDT",
  },
  {
    timezoneName: "EET",
    abbreviation: "EEST",
  },
  {
    timezoneName: "EST",
    abbreviation: "EST",
  },
  {
    timezoneName: "EST5EDT",
    abbreviation: "EDT",
  },
  {
    timezoneName: "Egypt",
    abbreviation: "EET",
  },
  {
    timezoneName: "Eire",
    abbreviation: "IST",
  },
  {
    timezoneName: "Etc/GMT",
    abbreviation: "GMT",
  },
  {
    timezoneName: "Etc/GMT+0",
    abbreviation: "GMT",
  },
  {
    timezoneName: "Etc/GMT+1",
    abbreviation: "-01",
  },
  {
    timezoneName: "Etc/GMT+10",
    abbreviation: "-10",
  },
  {
    timezoneName: "Etc/GMT+11",
    abbreviation: "-11",
  },
  {
    timezoneName: "Etc/GMT+12",
    abbreviation: "-12",
  },
  {
    timezoneName: "Etc/GMT+2",
    abbreviation: "-02",
  },
  {
    timezoneName: "Etc/GMT+3",
    abbreviation: "-03",
  },
  {
    timezoneName: "Etc/GMT+4",
    abbreviation: "-04",
  },
  {
    timezoneName: "Etc/GMT+5",
    abbreviation: "-05",
  },
  {
    timezoneName: "Etc/GMT+6",
    abbreviation: "-06",
  },
  {
    timezoneName: "Etc/GMT+7",
    abbreviation: "-07",
  },
  {
    timezoneName: "Etc/GMT+8",
    abbreviation: "-08",
  },
  {
    timezoneName: "Etc/GMT+9",
    abbreviation: "-09",
  },
  {
    timezoneName: "Etc/GMT-0",
    abbreviation: "GMT",
  },
  {
    timezoneName: "Etc/GMT-1",
    abbreviation: "+01",
  },
  {
    timezoneName: "Etc/GMT-10",
    abbreviation: "+10",
  },
  {
    timezoneName: "Etc/GMT-11",
    abbreviation: "+11",
  },
  {
    timezoneName: "Etc/GMT-12",
    abbreviation: "+12",
  },
  {
    timezoneName: "Etc/GMT-13",
    abbreviation: "+13",
  },
  {
    timezoneName: "Etc/GMT-14",
    abbreviation: "+14",
  },
  {
    timezoneName: "Etc/GMT-2",
    abbreviation: "+02",
  },
  {
    timezoneName: "Etc/GMT-3",
    abbreviation: "+03",
  },
  {
    timezoneName: "Etc/GMT-4",
    abbreviation: "+04",
  },
  {
    timezoneName: "Etc/GMT-5",
    abbreviation: "+05",
  },
  {
    timezoneName: "Etc/GMT-6",
    abbreviation: "+06",
  },
  {
    timezoneName: "Etc/GMT-7",
    abbreviation: "+07",
  },
  {
    timezoneName: "Etc/GMT-8",
    abbreviation: "+08",
  },
  {
    timezoneName: "Etc/GMT-9",
    abbreviation: "+09",
  },
  {
    timezoneName: "Etc/GMT0",
    abbreviation: "GMT",
  },
  {
    timezoneName: "Etc/Greenwich",
    abbreviation: "GMT",
  },
  {
    timezoneName: "Etc/UCT",
    abbreviation: "UTC",
  },
  {
    timezoneName: "Etc/UTC",
    abbreviation: "UTC",
  },
  {
    timezoneName: "Etc/Universal",
    abbreviation: "UTC",
  },
  {
    timezoneName: "Etc/Zulu",
    abbreviation: "UTC",
  },
  {
    timezoneName: "Europe/Amsterdam",
    abbreviation: "CEST",
  },
  {
    timezoneName: "Europe/Andorra",
    abbreviation: "CEST",
  },
  {
    timezoneName: "Europe/Astrakhan",
    abbreviation: "+04",
  },
  {
    timezoneName: "Europe/Athens",
    abbreviation: "EEST",
  },
  {
    timezoneName: "Europe/Belfast",
    abbreviation: "BST",
  },
  {
    timezoneName: "Europe/Belgrade",
    abbreviation: "CEST",
  },
  {
    timezoneName: "Europe/Berlin",
    abbreviation: "CET",
  },
  {
    timezoneName: "Europe/Bratislava",
    abbreviation: "CEST",
  },
  {
    timezoneName: "Europe/Brussels",
    abbreviation: "CEST",
  },
  {
    timezoneName: "Europe/Bucharest",
    abbreviation: "EEST",
  },
  {
    timezoneName: "Europe/Budapest",
    abbreviation: "CEST",
  },
  {
    timezoneName: "Europe/Busingen",
    abbreviation: "CEST",
  },
  {
    timezoneName: "Europe/Chisinau",
    abbreviation: "EEST",
  },
  {
    timezoneName: "Europe/Copenhagen",
    abbreviation: "CEST",
  },
  {
    timezoneName: "Europe/Dublin",
    abbreviation: "IST",
  },
  {
    timezoneName: "Europe/Gibraltar",
    abbreviation: "CEST",
  },
  {
    timezoneName: "Europe/Guernsey",
    abbreviation: "BST",
  },
  {
    timezoneName: "Europe/Helsinki",
    abbreviation: "EEST",
  },
  {
    timezoneName: "Europe/Isle_of_Man",
    abbreviation: "BST",
  },
  {
    timezoneName: "Europe/Istanbul",
    abbreviation: "+03",
  },
  {
    timezoneName: "Europe/Jersey",
    abbreviation: "BST",
  },
  {
    timezoneName: "Europe/Kaliningrad",
    abbreviation: "EET",
  },
  {
    timezoneName: "Europe/Kiev",
    abbreviation: "EEST",
  },
  {
    timezoneName: "Europe/Kirov",
    abbreviation: "MSK",
  },
  {
    timezoneName: "Europe/Kyiv",
    abbreviation: "EEST",
  },
  {
    timezoneName: "Europe/Lisbon",
    abbreviation: "WEST",
  },
  {
    timezoneName: "Europe/Ljubljana",
    abbreviation: "CEST",
  },
  {
    timezoneName: "Europe/London",
    abbreviation: "BST",
  },
  {
    timezoneName: "Europe/Luxembourg",
    abbreviation: "CEST",
  },
  {
    timezoneName: "Europe/Madrid",
    abbreviation: "CEST",
  },
  {
    timezoneName: "Europe/Malta",
    abbreviation: "CEST",
  },
  {
    timezoneName: "Europe/Mariehamn",
    abbreviation: "EEST",
  },
  {
    timezoneName: "Europe/Minsk",
    abbreviation: "+03",
  },
  {
    timezoneName: "Europe/Monaco",
    abbreviation: "CEST",
  },
  {
    timezoneName: "Europe/Moscow",
    abbreviation: "MSK",
  },
  {
    timezoneName: "Europe/Nicosia",
    abbreviation: "EEST",
  },
  {
    timezoneName: "Europe/Oslo",
    abbreviation: "CEST",
  },
  {
    timezoneName: "Europe/Paris",
    abbreviation: "CEST",
  },
  {
    timezoneName: "Europe/Podgorica",
    abbreviation: "CEST",
  },
  {
    timezoneName: "Europe/Prague",
    abbreviation: "CEST",
  },
  {
    timezoneName: "Europe/Riga",
    abbreviation: "EEST",
  },
  {
    timezoneName: "Europe/Rome",
    abbreviation: "CEST",
  },
  {
    timezoneName: "Europe/Samara",
    abbreviation: "+04",
  },
  {
    timezoneName: "Europe/San_Marino",
    abbreviation: "CEST",
  },
  {
    timezoneName: "Europe/Sarajevo",
    abbreviation: "CEST",
  },
  {
    timezoneName: "Europe/Saratov",
    abbreviation: "+04",
  },
  {
    timezoneName: "Europe/Simferopol",
    abbreviation: "MSK",
  },
  {
    timezoneName: "Europe/Skopje",
    abbreviation: "CEST",
  },
  {
    timezoneName: "Europe/Sofia",
    abbreviation: "EEST",
  },
  {
    timezoneName: "Europe/Stockholm",
    abbreviation: "CEST",
  },
  {
    timezoneName: "Europe/Tallinn",
    abbreviation: "EEST",
  },
  {
    timezoneName: "Europe/Tirane",
    abbreviation: "CEST",
  },
  {
    timezoneName: "Europe/Tiraspol",
    abbreviation: "EEST",
  },
  {
    timezoneName: "Europe/Ulyanovsk",
    abbreviation: "+04",
  },
  {
    timezoneName: "Europe/Uzhgorod",
    abbreviation: "EEST",
  },
  {
    timezoneName: "Europe/Vaduz",
    abbreviation: "CEST",
  },
  {
    timezoneName: "Europe/Vatican",
    abbreviation: "CEST",
  },
  {
    timezoneName: "Europe/Vienna",
    abbreviation: "CEST",
  },
  {
    timezoneName: "Europe/Vilnius",
    abbreviation: "EEST",
  },
  {
    timezoneName: "Europe/Volgograd",
    abbreviation: "MSK",
  },
  {
    timezoneName: "Europe/Warsaw",
    abbreviation: "CEST",
  },
  {
    timezoneName: "Europe/Zagreb",
    abbreviation: "CEST",
  },
  {
    timezoneName: "Europe/Zaporozhye",
    abbreviation: "EEST",
  },
  {
    timezoneName: "Europe/Zurich",
    abbreviation: "CEST",
  },
  {
    timezoneName: "GB",
    abbreviation: "BST",
  },
  {
    timezoneName: "GB-Eire",
    abbreviation: "BST",
  },
  {
    timezoneName: "GMT",
    abbreviation: "GMT",
  },
  {
    timezoneName: "GMT+0",
    abbreviation: "GMT",
  },
  {
    timezoneName: "GMT-0",
    abbreviation: "GMT",
  },
  {
    timezoneName: "GMT0",
    abbreviation: "GMT",
  },
  {
    timezoneName: "Greenwich",
    abbreviation: "GMT",
  },
  {
    timezoneName: "HST",
    abbreviation: "HST",
  },
  {
    timezoneName: "Hongkong",
    abbreviation: "HKT",
  },
  {
    timezoneName: "Iceland",
    abbreviation: "GMT",
  },
  {
    timezoneName: "Indian/Antananarivo",
    abbreviation: "EAT",
  },
  {
    timezoneName: "Indian/Chagos",
    abbreviation: "+06",
  },
  {
    timezoneName: "Indian/Christmas",
    abbreviation: "+07",
  },
  {
    timezoneName: "Indian/Cocos",
    abbreviation: "+0630",
  },
  {
    timezoneName: "Indian/Comoro",
    abbreviation: "EAT",
  },
  {
    timezoneName: "Indian/Kerguelen",
    abbreviation: "+05",
  },
  {
    timezoneName: "Indian/Mahe",
    abbreviation: "+04",
  },
  {
    timezoneName: "Indian/Maldives",
    abbreviation: "+05",
  },
  {
    timezoneName: "Indian/Mauritius",
    abbreviation: "+04",
  },
  {
    timezoneName: "Indian/Mayotte",
    abbreviation: "EAT",
  },
  {
    timezoneName: "Indian/Reunion",
    abbreviation: "+04",
  },
  {
    timezoneName: "Iran",
    abbreviation: "+0330",
  },
  {
    timezoneName: "Israel",
    abbreviation: "IDT",
  },
  {
    timezoneName: "Jamaica",
    abbreviation: "EST",
  },
  {
    timezoneName: "Japan",
    abbreviation: "JST",
  },
  {
    timezoneName: "Kwajalein",
    abbreviation: "+12",
  },
  {
    timezoneName: "Libya",
    abbreviation: "EET",
  },
  {
    timezoneName: "MET",
    abbreviation: "MEST",
  },
  {
    timezoneName: "MST",
    abbreviation: "MST",
  },
  {
    timezoneName: "MST7MDT",
    abbreviation: "MDT",
  },
  {
    timezoneName: "Mexico/BajaNorte",
    abbreviation: "PDT",
  },
  {
    timezoneName: "Mexico/BajaSur",
    abbreviation: "MST",
  },
  {
    timezoneName: "Mexico/General",
    abbreviation: "CST",
  },
  {
    timezoneName: "NZ",
    abbreviation: "NZST",
  },
  {
    timezoneName: "NZ-CHAT",
    abbreviation: "+1245",
  },
  {
    timezoneName: "Navajo",
    abbreviation: "MDT",
  },
  {
    timezoneName: "PRC",
    abbreviation: "CST",
  },
  {
    timezoneName: "PST8PDT",
    abbreviation: "PDT",
  },
  {
    timezoneName: "Pacific/Apia",
    abbreviation: "+13",
  },
  {
    timezoneName: "Pacific/Auckland",
    abbreviation: "NZST",
  },
  {
    timezoneName: "Pacific/Bougainville",
    abbreviation: "+11",
  },
  {
    timezoneName: "Pacific/Chatham",
    abbreviation: "+1245",
  },
  {
    timezoneName: "Pacific/Chuuk",
    abbreviation: "+10",
  },
  {
    timezoneName: "Pacific/Easter",
    abbreviation: "-06",
  },
  {
    timezoneName: "Pacific/Efate",
    abbreviation: "+11",
  },
  {
    timezoneName: "Pacific/Enderbury",
    abbreviation: "+13",
  },
  {
    timezoneName: "Pacific/Fakaofo",
    abbreviation: "+13",
  },
  {
    timezoneName: "Pacific/Fiji",
    abbreviation: "+12",
  },
  {
    timezoneName: "Pacific/Funafuti",
    abbreviation: "+12",
  },
  {
    timezoneName: "Pacific/Galapagos",
    abbreviation: "-06",
  },
  {
    timezoneName: "Pacific/Gambier",
    abbreviation: "-09",
  },
  {
    timezoneName: "Pacific/Guadalcanal",
    abbreviation: "+11",
  },
  {
    timezoneName: "Pacific/Guam",
    abbreviation: "ChST",
  },
  {
    timezoneName: "Pacific/Honolulu",
    abbreviation: "HST",
  },
  {
    timezoneName: "Pacific/Johnston",
    abbreviation: "HST",
  },
  {
    timezoneName: "Pacific/Kanton",
    abbreviation: "+13",
  },
  {
    timezoneName: "Pacific/Kiritimati",
    abbreviation: "+14",
  },
  {
    timezoneName: "Pacific/Kosrae",
    abbreviation: "+11",
  },
  {
    timezoneName: "Pacific/Kwajalein",
    abbreviation: "+12",
  },
  {
    timezoneName: "Pacific/Majuro",
    abbreviation: "+12",
  },
  {
    timezoneName: "Pacific/Marquesas",
    abbreviation: "-0930",
  },
  {
    timezoneName: "Pacific/Midway",
    abbreviation: "SST",
  },
  {
    timezoneName: "Pacific/Nauru",
    abbreviation: "+12",
  },
  {
    timezoneName: "Pacific/Niue",
    abbreviation: "-11",
  },
  {
    timezoneName: "Pacific/Norfolk",
    abbreviation: "+11",
  },
  {
    timezoneName: "Pacific/Noumea",
    abbreviation: "+11",
  },
  {
    timezoneName: "Pacific/Pago_Pago",
    abbreviation: "SST",
  },
  {
    timezoneName: "Pacific/Palau",
    abbreviation: "+09",
  },
  {
    timezoneName: "Pacific/Pitcairn",
    abbreviation: "-08",
  },
  {
    timezoneName: "Pacific/Pohnpei",
    abbreviation: "+11",
  },
  {
    timezoneName: "Pacific/Ponape",
    abbreviation: "+11",
  },
  {
    timezoneName: "Pacific/Port_Moresby",
    abbreviation: "+10",
  },
  {
    timezoneName: "Pacific/Rarotonga",
    abbreviation: "-10",
  },
  {
    timezoneName: "Pacific/Saipan",
    abbreviation: "ChST",
  },
  {
    timezoneName: "Pacific/Samoa",
    abbreviation: "SST",
  },
  {
    timezoneName: "Pacific/Tahiti",
    abbreviation: "-10",
  },
  {
    timezoneName: "Pacific/Tarawa",
    abbreviation: "+12",
  },
  {
    timezoneName: "Pacific/Tongatapu",
    abbreviation: "+13",
  },
  {
    timezoneName: "Pacific/Truk",
    abbreviation: "+10",
  },
  {
    timezoneName: "Pacific/Wake",
    abbreviation: "+12",
  },
  {
    timezoneName: "Pacific/Wallis",
    abbreviation: "+12",
  },
  {
    timezoneName: "Pacific/Yap",
    abbreviation: "+10",
  },
  {
    timezoneName: "Poland",
    abbreviation: "CEST",
  },
  {
    timezoneName: "Portugal",
    abbreviation: "WEST",
  },
  {
    timezoneName: "ROC",
    abbreviation: "CST",
  },
  {
    timezoneName: "ROK",
    abbreviation: "KST",
  },
  {
    timezoneName: "Singapore",
    abbreviation: "+08",
  },
  {
    timezoneName: "Turkey",
    abbreviation: "+03",
  },
  {
    timezoneName: "UCT",
    abbreviation: "UTC",
  },
  {
    timezoneName: "US/Alaska",
    abbreviation: "AKDT",
  },
  {
    timezoneName: "US/Aleutian",
    abbreviation: "HDT",
  },
  {
    timezoneName: "US/Arizona",
    abbreviation: "MST",
  },
  {
    timezoneName: "US/Central",
    abbreviation: "CDT",
  },
  {
    timezoneName: "US/East-Indiana",
    abbreviation: "EDT",
  },
  {
    timezoneName: "US/Eastern",
    abbreviation: "EDT",
  },
  {
    timezoneName: "US/Hawaii",
    abbreviation: "HST",
  },
  {
    timezoneName: "US/Indiana-Starke",
    abbreviation: "CDT",
  },
  {
    timezoneName: "US/Michigan",
    abbreviation: "EDT",
  },
  {
    timezoneName: "US/Mountain",
    abbreviation: "MDT",
  },
  {
    timezoneName: "US/Pacific",
    abbreviation: "PDT",
  },
  {
    timezoneName: "US/Samoa",
    abbreviation: "SST",
  },
  {
    timezoneName: "UTC",
    abbreviation: "UTC",
  },
  {
    timezoneName: "Universal",
    abbreviation: "UTC",
  },
  {
    timezoneName: "W-SU",
    abbreviation: "MSK",
  },
  {
    timezoneName: "WET",
    abbreviation: "WEST",
  },
  {
    timezoneName: "Zulu",
    abbreviation: "UTC",
  },
];

export enum TABLE_FILTER_VARIANTS {
  CAMPAIGN = "campaign",
  EMAIL = "email",
  STATIC_LIST = "staticlist",
  TOKEN = "token",
  WEBHOOK = "webhook",
  FORMS = "forms",
  CONTACTS = "contacts",
  SL_CONTACTS = "static_list_contacts",
}

export enum SUBFILTER_TYPE {
  LIST = "list",
  USER = "users",
  DATE = "date",
  TAGS = "tags",
  DL = "dynamic-list",
}

export const FILTER_DISPLAY_HEIGHT = "39px";
