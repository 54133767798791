import { Box } from "@chakra-ui/react";
import {
  BaseEdge,
  EdgeLabelRenderer,
  EdgeProps,
  getSmoothStepPath,
} from "reactflow";
import { EdgeDataProps } from "../../../../../common/types/flow";
import { AddButton } from "./AddButton";

export default function CustomStepEdge({
  id,
  source,
  target,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
  data,
}: EdgeProps<EdgeDataProps>) {
  const [edgePath, labelX, labelY] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  return (
    <>
      <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
      {data?.isStepAddable && (
        <EdgeLabelRenderer>
          <Box
            style={{
              position: "absolute",
              transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
              fontSize: 12,
              // everything inside EdgeLabelRenderer has no pointer events by default
              // if you have an interactive element, set pointer-events: all
              pointerEvents: "all",
            }}
            className="nodrag nopan"
          >
            <AddButton
              parentActionId={data.prevActionId ?? ""}
              branchId={data?.branchId ?? ""}
              groupId={data?.groupId}
              isFlowEmpty={false}
              isBetweenFlowSteps={true}
              props={{ readonly: data?.readonly }}
            />
          </Box>
        </EdgeLabelRenderer>
      )}
    </>
  );
}
