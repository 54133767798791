import { Flex, HStack, Switch, Text, VStack } from "@chakra-ui/react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { isInit } from "../../../../common/helper/commonHelper";
import { DataTable } from "../../../../components/data-table/DataTable";
import ITitle from "../../../../components/ITitle";
import { useAppDispatch } from "../../../../store";
import {
  getCampaignHistory,
  getCampaignHistoryStats,
  selectCampaignReports,
  setReportsPage,
  setShowNonEmptyRunsFilter,
} from "./campaignReportsSlice";
import { campaignHistoryTableHeader } from "./constantHelper";

export default function CampaignHistory() {
  const dispatch = useAppDispatch();
  const {
    filterData: { timeRange, templateId },
    campaignHistory,
    showNonEmptyRuns,
  } = useSelector(selectCampaignReports);

  useEffect(() => {
    if (timeRange.startDate && timeRange.endDate)
      dispatch(getCampaignHistory());
  }, [
    dispatch,
    campaignHistory.currentPageNo,
    timeRange,
    templateId,
    showNonEmptyRuns,
  ]);

  useEffect(() => {
    if (
      campaignHistory.list?.length &&
      campaignHistory.list.every((run) => isInit(run.stats_loading))
    ) {
      dispatch(
        getCampaignHistoryStats(campaignHistory.list.map((run) => run.run_id))
      );
    }
  }, [campaignHistory.list, dispatch]);

  return (
    <VStack w="100%">
      <HStack w="100%" justifyContent="space-between" mb={3} mt={5}>
        <ITitle
          title="Past journey runs"
          fontSize="18px"
          fontWeight="bold"
          alignSelf="flex-start"
          capitalize={false}
        />
        <Flex alignItems="center">
          <Switch
            isChecked={showNonEmptyRuns}
            onChange={() =>
              dispatch(setShowNonEmptyRunsFilter(!showNonEmptyRuns))
            }
          />
          <Text ml={2}>Hide empty runs</Text>
        </Flex>
      </HStack>
      <DataTable
        fetchingList={campaignHistory.fetchingList}
        changingPage={campaignHistory.changingPage}
        list={campaignHistory.list}
        totalPageSize={campaignHistory.pageSize}
        setPage={(pageNo) => dispatch(setReportsPage(pageNo))}
        totalPageCount={campaignHistory.totalPageCount}
        currentPage={campaignHistory.currentPageNo}
        columns={campaignHistoryTableHeader}
        emptyMsg="No run  history"
      />
    </VStack>
  );
}
