// Used for storing the auth token in local storage
export const AUTH_TOKEN_KEY = "authToken";
export const CURRENT_PATH = "currentPath";
export const PREV_PATH = "prevPath";

export const ROLLBAR_DATA_KEY = "rollbarData";

export const INTEGRATIONS_BASE_URL = `${process.env.REACT_APP_API_URL}/api/v1/`;
export const CAMPAIGN_BASE_URL_V1 = `${process.env.REACT_APP_CAMPAIGN_API_URL}/api/v1/`;
export const CAMPAIGN_BASE_URL_V2 = `${process.env.REACT_APP_CAMPAIGN_API_URL}/api/v2/`;
export const CAMPAIGN_BASE_URL_V3 = `${process.env.REACT_APP_CAMPAIGN_API_URL}/api/v3/`;
export const AUTH_BASE_URL = `${process.env.REACT_APP_AUTH_API_URL}/api/v1/`;
export const SETTING_BASE_URL = `${process.env.REACT_APP_SETTING_API_URL}/api/v1/`;
export const REALTIME_CAMPAIGN_BASE_URL = `${process.env.REACT_APP_WEB_REALTIME_CAMPAIGN_API_URL}/`;
export const SF_INTEGRATIONS_BASE_URL = `${process.env.REACT_APP_SF_INTEGRATIONS_API_URL}/api/v1/`;
export const TRIGGER_ENABLED_TENANTS_URL = `${process.env.REACT_APP_TRIGGER_ENABLED_API_URL}`;
export const WEBSITE_TRACKING_BASE_URL = `${process.env.REACT_APP_WEBSITE_TRACKING_API_URL}/api/v1`;

export const ROLLBAR_ACCESS_TOKEN = "4dd15f4f34bf47699f2f56cb39342bc4";

export const FULLSTORY_KEY = "17K798";
export const SENTRY_KEY =
  "https://e49ee3491a3c461880fac03fd50bc599@o1106738.ingest.sentry.io/6133444";

export const CLIENT_APP_VERSION = 20240511;
export const VERSION_MISMATCH_BANNER_ID = "version-mismatch-banner";
export const VERSION_HEADER_NAME = "client-app-version";
