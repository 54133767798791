import {
  FLOW_ACTIONS,
  UPDATE_VALUE_TYPE,
} from "../../../../../common/constants/campaign";
import {
  FaBell,
  FaCodeBranch,
  FaEnvelope,
  FaGlobe,
  FaHourglassStart,
  FaList,
  FaSalesforce,
  FaEdit,
} from "react-icons/fa";
import { BsKeyboardFill } from "react-icons/bs";
import { HiCodeBracketSquare } from "react-icons/hi2";
import { AiFillCalculator } from "react-icons/ai";
import { MdMerge } from "react-icons/md";
import { IconOptionsType } from "../../../../../common/types/flow";
import { IconType } from "react-icons";
import { CenterProps } from "@chakra-ui/react";
import { MarkerType } from "reactflow";
import { IoCopy } from "react-icons/io5";
import { DESTINATION_TYPES } from "../../../../../common/types/unifiedMapping";

type WidgetOptionType = {
  label: string;
  icon: IconType;
  color: string;
  height: number;
  iconProperties?: CenterProps;
};

export const WIDGET_OPTIONS_DETAILS: {
  [key in FLOW_ACTIONS]: WidgetOptionType;
} = {
  [FLOW_ACTIONS.SEND_EMAIL]: {
    label: "Send email",
    icon: FaEnvelope,
    color: "#19579C",
    height: 190,
  },
  [FLOW_ACTIONS.SEND_INTERNAL_EMAIL]: {
    label: "Send internal email alert",
    icon: FaBell,
    color: "#D6A243",
    height: 180,
  },
  [FLOW_ACTIONS.ADD_TO_STATIC_LIST]: {
    label: "Add to static list",
    icon: FaList,
    color: "#ae2661",
    height: 160,
  },
  [FLOW_ACTIONS.REMOVE_FROM_STATIC_LIST]: {
    label: "Remove from static list",
    icon: FaList,
    color: "#ae2661",
    height: 160,
  },
  [FLOW_ACTIONS.DELAY]: {
    label: "Add delay",
    icon: FaHourglassStart,
    color: "#825A15",
    height: 160,
  },
  [FLOW_ACTIONS.WEBHOOK]: {
    label: "Run webhook",
    icon: FaGlobe,
    color: "#207B51",
    height: 160,
  },
  [FLOW_ACTIONS.BRANCH_BY_FILTER]: {
    label: "Branch by filter",
    icon: FaCodeBranch,
    color: "#E55A36",
    height: 360,
  },
  [FLOW_ACTIONS.SALESFORCE]: {
    label: "Sync Contact with Salesforce",
    icon: FaSalesforce,
    color: "#19579C",
    height: 180,
  },
  [FLOW_ACTIONS.SALESFORCE_CAMPAIGN]: {
    label: "Add to Salesforce Campaign",
    icon: FaSalesforce,
    color: "#19579C",
    height: 180,
  },
  [FLOW_ACTIONS.BRANCH_BY_TOKEN]: {
    label: "Branch by token",
    icon: FaCodeBranch,
    color: "#E55A36",
    height: 350,
  },
  [FLOW_ACTIONS.BRANCH_BY_VALUE]: {
    label: "Branch by value",
    icon: FaCodeBranch,
    color: "#E55A36",
    height: 350,
  },
  [FLOW_ACTIONS.UPDATE_VALUE]: {
    label: "Update value",
    icon: FaEdit,
    color: "#A250A2",
    height: 320,
  },
  [FLOW_ACTIONS.GOTO]: {
    label: "Merge branches",
    icon: MdMerge,
    color: "#3182ce",
    height: 80,
    iconProperties: {
      transform: "rotate(180deg)",
    },
  },
  [FLOW_ACTIONS.GROUP]: {
    label: "Nested journey",
    icon: IoCopy,
    color: "#a3b4c2",
    height: 0,
  },
};

export const WIDGET_OPTIONS = [
  {
    label: "Email Actions",
    actions: [
      {
        ...WIDGET_OPTIONS_DETAILS[FLOW_ACTIONS.SEND_EMAIL],
        type: FLOW_ACTIONS.SEND_EMAIL,
      },
      {
        ...WIDGET_OPTIONS_DETAILS[FLOW_ACTIONS.SEND_INTERNAL_EMAIL],
        type: FLOW_ACTIONS.SEND_INTERNAL_EMAIL,
      },
    ],
  },
  {
    label: "Journey Controls",
    actions: [
      {
        ...WIDGET_OPTIONS_DETAILS[FLOW_ACTIONS.DELAY],
        type: FLOW_ACTIONS.DELAY,
      },
      {
        ...WIDGET_OPTIONS_DETAILS[FLOW_ACTIONS.BRANCH_BY_FILTER],
        type: FLOW_ACTIONS.BRANCH_BY_FILTER,
      },
      {
        ...WIDGET_OPTIONS_DETAILS[FLOW_ACTIONS.BRANCH_BY_TOKEN],
        type: FLOW_ACTIONS.BRANCH_BY_TOKEN,
      },
      {
        ...WIDGET_OPTIONS_DETAILS[FLOW_ACTIONS.BRANCH_BY_VALUE],
        type: FLOW_ACTIONS.BRANCH_BY_VALUE,
      },
      {
        ...WIDGET_OPTIONS_DETAILS[FLOW_ACTIONS.GOTO],
        type: FLOW_ACTIONS.GOTO,
      },
      {
        ...WIDGET_OPTIONS_DETAILS[FLOW_ACTIONS.GROUP],
        type: FLOW_ACTIONS.GROUP,
      },
    ],
  },
  {
    label: "Integrations",
    actions: [
      {
        ...WIDGET_OPTIONS_DETAILS[FLOW_ACTIONS.WEBHOOK],
        type: FLOW_ACTIONS.WEBHOOK,
      },
      {
        ...WIDGET_OPTIONS_DETAILS[FLOW_ACTIONS.SALESFORCE_CAMPAIGN],
        type: FLOW_ACTIONS.SALESFORCE_CAMPAIGN,
      },
    ],
  },
  {
    label: "List Actions",
    actions: [
      {
        ...WIDGET_OPTIONS_DETAILS[FLOW_ACTIONS.ADD_TO_STATIC_LIST],
        type: FLOW_ACTIONS.ADD_TO_STATIC_LIST,
      },
      {
        ...WIDGET_OPTIONS_DETAILS[FLOW_ACTIONS.REMOVE_FROM_STATIC_LIST],
        type: FLOW_ACTIONS.REMOVE_FROM_STATIC_LIST,
      },
    ],
  },
  {
    label: "Data Actions",
    actions: [
      {
        ...WIDGET_OPTIONS_DETAILS[FLOW_ACTIONS.UPDATE_VALUE],
        type: FLOW_ACTIONS.UPDATE_VALUE,
      },
    ],
  },
];

export enum BRANCH_BY_FILTER_LABEL {
  TRUE = "True",
  FALSE = "False",
  DEFAULT = "Default",
}

export const FILTER_TYPE_TEXT = "filter_type";
export const FILTER_TEXT = "filter";

export enum UPDATE_VALUE_LABEL {
  TOKEN = "Token",
  CUSTOM_VALUE = "Custom value",
  FORMULA = "Formula",
}

export const UPDATE_BY_OPTIONS: IconOptionsType[] = [
  {
    label: UPDATE_VALUE_LABEL.TOKEN,
    value: UPDATE_VALUE_TYPE.TOKEN,
    icon: HiCodeBracketSquare,
  },
  {
    label: UPDATE_VALUE_LABEL.CUSTOM_VALUE,
    value: UPDATE_VALUE_TYPE.FORMULA,
    icon: BsKeyboardFill,
  },
  {
    label: UPDATE_VALUE_LABEL.FORMULA,
    value: UPDATE_VALUE_TYPE.FORMULA,
    icon: AiFillCalculator,
  },
];

export enum NODE_TYPES {
  ACTION_NODE = "action",
  INSERT_NODE = "insert",
  DYNAMIC_LIST_COUNT = "dl-count",
  EDGE_LABEL = "edge-label",
}

export const INIT_FLOW_NODE_ID = "init-flow";
export const WIDGET_CONTAINER_CLASS = "widget-container";

export const SET_VALUE = "set_value";

export const DEFAULT_EDGE_COLOR = "#8D9EBC";
const blue500 = "#3182ce";
const yellow400 = "#ECC94B";

export const edgeStyle = { stroke: DEFAULT_EDGE_COLOR, strokeWidth: "4px" };
export const gotoEdgeSelectedStyle = { stroke: blue500, strokeWidth: "6px" };
export const exitEdgeSelectedStyle = { stroke: yellow400, strokeWidth: "6px" };
export const defaultEdgeMarkerProps = {
  type: MarkerType.Arrow,
  color: DEFAULT_EDGE_COLOR,
};
export const gotoEdgeMarkerProps = { type: MarkerType.Arrow, color: blue500 };
export const exitEdgeMarkerProps = { type: MarkerType.Arrow, color: yellow400 };

export enum HANDLE_IDS {
  TOP_TARGET = "top-target",
  BOTTOM_SOURCE = "bottom-source",
  LEFT_TARGET = "left-target",
  RIGHT_TARGET = "right-target",
  LEFT_SOURCE = "left-source",
  RIGHT_SOURCE = "right-source",
}

export const TYPE_TO_DESTINATION_TYPE = {
  string: DESTINATION_TYPES.STRING,
  number: DESTINATION_TYPES.INTEGER,
  datetime: DESTINATION_TYPES.DATETIME,
  boolean: DESTINATION_TYPES.BOOLEAN,
};

export type ValidTokenDatatypes = keyof typeof TYPE_TO_DESTINATION_TYPE;

export const VALID_BRANCH_TOKEN_DATA_TYPES = Object.keys(
  TYPE_TO_DESTINATION_TYPE
) as ValidTokenDatatypes[];

export enum EMAIL_PREVIEW_VARIANTS {
  SELECTION = "select",
  UPDATION = "update",
  READONLY = "readonly",
}
