import {
  BadgeProps,
  Badge,
  Switch,
  SwitchProps,
  SkeletonText,
} from "@chakra-ui/react";
import { EMAIL_SETTINGS_STATE } from "../../../../common/constants/settings";
import { ACTIONS } from "../../../../components/ViewAndEditButtons";

const EMAIL_STATE_PROPS: { [key in EMAIL_SETTINGS_STATE]: BadgeProps } = {
  [EMAIL_SETTINGS_STATE.ENABLED]: { bgColor: "green.500" },
  [EMAIL_SETTINGS_STATE.DISABLED]: { opacity: 0.7, bgColor: "gray.400" },
};

export default function EmailSettingsBadgeInput({
  state,
  mode,
  editSwitchProps,
  loading = false,
}: {
  state: EMAIL_SETTINGS_STATE;
  mode: ACTIONS;
  editSwitchProps?: SwitchProps;
  loading?: boolean;
}) {
  function displayBadge() {
    switch (mode) {
      case ACTIONS.VIEW:
        return (
          <Badge
            color="white"
            variant="solid"
            fontSize="10px"
            borderRadius="3px"
            fontWeight="extrabold"
            textAlign="center"
            px={2}
            py={1}
            my="0"
            w="70px"
            {...(EMAIL_STATE_PROPS[state] ??
              EMAIL_STATE_PROPS[EMAIL_SETTINGS_STATE.DISABLED])}
          >
            {state}
          </Badge>
        );

      case ACTIONS.EDIT:
        return (
          <>
            {editSwitchProps && (
              <Switch size="sm" colorScheme="green" {...editSwitchProps} />
            )}
          </>
        );
    }
  }

  return (
    <SkeletonText w="70px" skeletonHeight="6" isLoaded={!loading} noOfLines={1}>
      {displayBadge()}
    </SkeletonText>
  );
}
