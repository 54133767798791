import { Box, Flex, Grid } from "@chakra-ui/react";
import { cloneDeep } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { components } from "react-select";
import {
  ACCOUNT_SYNC_OPTIONS,
  NONE_DESTINATION,
} from "../../../../../../../../../../../common/constants/unifiedMapping";
import {
  findFields,
  findSource,
  getObjectSettings,
} from "../../../../../../../../../../../common/helper/unifiedMappingHelper";
import { SALESFORCE_OBJECT_TYPE } from "../../../../../../../../../../../common/types/connection";
import {
  AccountFormFieldDataTypes,
  AccountMappingElement,
  AccountSource,
  AccountSyncPreference,
  ACCOUNT_SYNC_PREFERENCE,
  ConnectionListItem,
  Destination,
  SOURCES,
  SUB_TYPES,
} from "../../../../../../../../../../../common/types/unifiedMapping";
import DropdownWithSearch from "../../../../../../../../../../../components/DropdownWithSearch";
import RemoveRowButton from "../../../../../../../../../../../components/RemoveRowButton";
import { selectConnection } from "../../../../../../../../connectionSlice";
import { MappingTableColumnTitle } from "../../../../../../../../../../../components/unified-mapping/MappingTableColumnTitle";
import { ReadonlyField } from "../../../../../../../../../../../components/unified-mapping/ReadOnlyFieldMapping";
import { ConnectionNameWithSourceLogo } from "../../../../../../../../../../../components/unified-mapping/ConnectionNameWithSourceLogoMapping";

export default function AccountMappingColumnRow({
  sources,
  selectedSource,
  syncPreferences,
  data,
  onChange,
  account,
  hideTitle,
  disableSource,
  readonly,
  remove,
}: {
  sources: ConnectionListItem[];
  selectedSource: AccountSource | null;
  syncPreferences: ACCOUNT_SYNC_PREFERENCE | null;
  data: AccountMappingElement;
  onChange: (data: AccountMappingElement) => void;
  account: Destination | null;
  hideTitle?: boolean;
  disableSource?: boolean;
  readonly?: boolean;
  remove: () => void;
}) {
  const {
    unifiedConnection: { salesforceConnections },
  } = useSelector(selectConnection);

  function findSyncPreference(val: ACCOUNT_SYNC_PREFERENCE | null) {
    return val
      ? ACCOUNT_SYNC_OPTIONS.find((x) => x.value === val) || null
      : null;
  }

  const [sourceField, setSourceField] = useState<ConnectionListItem | null>(
    findSource(selectedSource, sources)
  );
  const [accountField, setAccountField] = useState<Destination | null>(account);

  const [syncPreference, setSyncPreference] =
    useState<AccountSyncPreference | null>(findSyncPreference(syncPreferences));

  function propogateChange({
    source,
    account,
    syncPreference,
  }: {
    source: ConnectionListItem;
    account: Destination | null;
    syncPreference: AccountSyncPreference | null;
  }) {
    const dataCopy = cloneDeep(data);
    if (dataCopy.source?.length) {
      dataCopy.source?.forEach((x) => {
        x.connection_id = source?.connection_id || "";
        x.field = account as Destination;
        x.sync_preference = syncPreference?.value || null;
      });
    } else {
      dataCopy.source = [
        {
          connection_id: source.connection_id || "",
          field: account as Destination,
          sync_preference: syncPreference?.value || null,
          type: source.source,
        },
      ];
    }
    onChange({ ...dataCopy });
  }

  const isSalesforce = useMemo(
    () => !!sourceField && sourceField.source === SOURCES.SALESFORCE,
    [sourceField]
  );

  const objectSettings = useMemo(
    () => getObjectSettings(salesforceConnections, sourceField),
    [salesforceConnections, sourceField]
  );

  enum FORM_FIELD {
    SOURCE = "source",
    ACCOUNT = "account",
    SYNC = "sync",
  }

  function onChangeLocal(field: FORM_FIELD, data: AccountFormFieldDataTypes) {
    switch (field) {
      case FORM_FIELD.SOURCE:
        setSourceField(data as ConnectionListItem);
        propogateChange({
          source: data as ConnectionListItem,
          account: null,
          syncPreference: null,
        });
        break;
      case FORM_FIELD.ACCOUNT:
        setAccountField(data as Destination);
        propogateChange({
          source: sourceField!,
          account: data as Destination,
          syncPreference: ACCOUNT_SYNC_OPTIONS[0],
        });
        break;
      case FORM_FIELD.SYNC:
        setSyncPreference(data as AccountSyncPreference);
        propogateChange({
          source: sourceField!,
          account: accountField,
          syncPreference: ACCOUNT_SYNC_OPTIONS[0] as AccountSyncPreference,
        });
        break;
    }
  }

  useEffect(() => {
    // Set local state when input params are updated
    setSourceField(findSource(selectedSource, sources));
    setAccountField(account);
    setSyncPreference(findSyncPreference(syncPreferences));
  }, [selectedSource, account, syncPreferences, sources]);

  const firstRender = useRef(true);
  useEffect(() => {
    if (!firstRender.current) {
      setAccountField(null);
      setSyncPreference(null);
    } else {
      firstRender.current = false;
    }
  }, [sourceField]);

  function filterFields(objectType: SALESFORCE_OBJECT_TYPE) {
    const fields = findFields({
      objects:
        sourceField && salesforceConnections
          ? salesforceConnections[sourceField.connection_id]?.objects
          : [],
      objectType,
      fieldDataType: data.destination.type,
    });
    return [...fields, NONE_DESTINATION];
  }

  return (
    <Flex width="100%" p="12px" alignItems="center">
      <Grid width="100%" templateColumns="repeat(4, 1fr)" gap={12}>
        {/* DESTINATION */}
        {/* ------------------------------ */}

        {hideTitle ? (
          <Box></Box>
        ) : (
          <MappingTableColumnTitle
            type={
              data.destination.sub_type === SUB_TYPES.ID
                ? SUB_TYPES.ID
                : data.destination.type
            }
            name={data.destination.display}
            removable={data.destination.custom}
          />
        )}

        {/* SOURCE */}
        {/* ----------------------------- */}

        {/* Source type and connection id */}
        {disableSource || readonly ? (
          <Flex>
            {sourceField && (
              <ConnectionNameWithSourceLogo
                px="2"
                source={sourceField.source}
                label={sourceField.name}
              />
            )}
          </Flex>
        ) : (
          <DropdownWithSearch
            isSearchable={true}
            value={sourceField}
            getOptionValue={(x) => x.connection_id}
            getOptionLabel={(x) => x.name}
            options={sources || []}
            onChange={(option) => onChangeLocal(FORM_FIELD.SOURCE, option)}
            components={{
              Control: ({ children, ...rest }) => (
                <components.Control {...rest}>
                  <Flex ml="2" w="100%">
                    <ConnectionNameWithSourceLogo
                      source={rest.getValue()[0]?.source}
                      label={""}
                    />
                    {children}
                  </Flex>
                </components.Control>
              ),
              Option: ({ children, ...rest }) => (
                <components.Option {...rest}>
                  <Flex ml="2" w="100%">
                    <ConnectionNameWithSourceLogo
                      source={rest.data.source}
                      label={children?.toString() || ""}
                    />
                  </Flex>
                </components.Option>
              ),
            }}
          />
        )}

        {/* SOURCE > field  */}
        {readonly ? (
          objectSettings && !objectSettings.account ? (
            <Box></Box>
          ) : (
            <ReadonlyField>{accountField?.display} </ReadonlyField>
          )
        ) : ((isSalesforce && objectSettings?.contact) || !isSalesforce) &&
          sourceField ? (
          <DropdownWithSearch
            isSearchable={true}
            getOptionValue={(x) => x.name}
            value={accountField}
            getOptionLabel={(x) => x.display}
            options={filterFields(SALESFORCE_OBJECT_TYPE.ACCOUNT)}
            onChange={(option) => onChangeLocal(FORM_FIELD.ACCOUNT, option)}
          />
        ) : (
          <Box></Box>
        )}

        {/* SOURCE > SYNC PREFERENCE */}
        {readonly ? (
          <ReadonlyField>{syncPreference?.label}</ReadonlyField>
        ) : (
          isSalesforce &&
          sourceField && (
            <ReadonlyField>{ACCOUNT_SYNC_OPTIONS[0].label}</ReadonlyField>
          )
        )}
      </Grid>
      {data.destination.custom && !readonly ? (
        <RemoveRowButton onClick={remove} />
      ) : (
        <Box width="32px"></Box>
      )}
    </Flex>
  );
}
