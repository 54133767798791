import { Text, UnorderedList, VStack } from "@chakra-ui/react";
import { useState } from "react";
import { FaCircle } from "react-icons/fa";
import { isFulfilled } from "../../../../../common/helper/commonHelper";
import { SalesforceConnection } from "../../../../../common/types/connection";
import IModal from "../../../../../components/IModal";
import { ListItemComponent } from "../../../../../components/ListItemComponent";
import { useAppDispatch } from "../../../../../store";
import { completeSetup } from "../../connectionSlice";

export default function SalesforceOverrideModal({
  connectionDetails,
  isOpen,
  onClose,
  goPreviousBox,
}: {
  connectionDetails: SalesforceConnection;
  isOpen: boolean;
  onClose: () => void;
  goPreviousBox: () => void;
}) {
  const [buttonLoading, setButtonLoading] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const dispatch = useAppDispatch();
  async function startSync() {
    const data = {
      connection_id: connectionDetails.connection_id,
      automap: true,
      start_sync: true,
    };
    setButtonDisabled(true);
    setButtonLoading(true);
    const result = await dispatch(completeSetup(data));
    if (isFulfilled(result.meta.requestStatus)) {
      onClose();
    }
  }

  return (
    <IModal
      isOpen={true}
      onClose={goPreviousBox}
      hideCloseButton={isOpen}
      size="xl"
      header={{
        title: `Start Sync confirmation`,
      }}
      primaryButton={{
        label: "Start Sync Now",
        props: {
          onClick: startSync,
          isLoading: buttonLoading,
        },
      }}
      secondaryButton={{
        label: "Cancel",
        props: {
          onClick: goPreviousBox,
          isDisabled: buttonDisabled,
        },
      }}
    >
      <VStack alignItems="flex-start">
        <Text fontSize="sm">
          Are you sure you want to start the Salesforce sync? Starting the sync
          now will:
        </Text>
        <UnorderedList pl="20px">
          <ListItemComponent IconType={FaCircle}>
            <Text as="b">
              <Text as="u">Override any previous field mappings</Text>
            </Text>{" "}
            including mappings of any other connections like segment etc, and
            replace it with the default Salesforce field mapping.
          </ListItemComponent>
          <ListItemComponent IconType={FaCircle}>
            Start the sync immediately
          </ListItemComponent>
        </UnorderedList>
        <Text fontSize="sm">
          If you want to map the fields manually and start the sync later, click
          on Custom Settings and finish setup later
        </Text>
      </VStack>
    </IModal>
  );
}
