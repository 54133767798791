import { PersonDestinationFields, PersonDestination } from "./person";

export enum IMPORT_CONTACTS_CSV {
  UPLOAD_FILE,
  MAP_CONTACT_FIELDS,
  UPLOAD_SUMMARY,
  IMPORT_CONTACTS,
}

export enum COLUMN_FILTERS {
  ALL_FIELDS = "All Fields",
  MAPPED_FIELDS = "Mapped Fields",
  UNMAPPED_FIELDS = "Unmapped Fields",
}

export enum UPLOAD_CONTACTS_TO {
  STATIC_LIST = "static_list",
  PERSON_DB = "person_db",
}

export enum IMPORT_ACTIONS {
  CREATE = "CREATE",
  UPDATE = "UPDATE",
  CREATE_AND_UPDATE = "CREATE_UPDATE",
}

export type GetUrlResp = {
  upload_id: string;
  s3_url: string;
};

export type CsvRecords = { [csvField: string]: string }[];

export type FileValidationResp = {
  upload_id: string;
  headers: string[];
  records: CsvRecords;
};

export type DestIdType = keyof PersonDestinationFields | null;

export type CsvMappingWithDestId = { [csvColumn: string]: DestIdType };

export type CsvMappingWithPersonDest = {
  [csvColumn: string]: null | PersonDestination;
};

export type CsvMappingResp = {
  upload_id: string;
  file_to_datastore_mapping: CsvMappingWithPersonDest;
};

export type MappedFieldsWithDestId = {
  csvColumn: string;
  destId: DestIdType;
};

export type UploadApiErrors = null | {
  message: string;
  name: string;
  extra_data: any;
};

export type GetUploadSummaryResp = {
  upload_id: string;
  total_contacts: number;
  new_contacts: number;
  errors: number;
  report_available: boolean;
};

export type UploadSummaryType = {
  newContacts: number;
  totalContacts: number;
  reportAvailable: boolean;
  errors: number;
};

export type ContactImportCsv = {
  uploadId: string;
  importAction: IMPORT_ACTIONS;
  updateOnlyEmptyFieldsInDb: boolean;
  updateDbWithBlankValueInCsv: boolean;
  staticListId?: string;
  testRun?: boolean;
};

export type ContactImportResp = {
  upload_id: string;
  dry_run: boolean;
  plan: {
    insert_count: number;
    update_count: number;
    static_list?: {
      name: string;
      insert_count: number;
    };
  };
};

export type ContactImportPlan = {
  newContacts: number;
  updatableContacts: number;
  staticList?: {
    name: string;
    insertCount: number;
  };
};

export type ErrorsWithMeta<T> = {
  data: T | null;
  error: UploadApiErrors;
  meta: {
    status: string;
    timestamp: string;
    request_id: string;
  };
};
